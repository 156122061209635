<template>
  <div class="d-flex flex-column">
    <v-row no-gutters>
      <v-col>
        <AppBarPagina></AppBarPagina>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <BannerPagina id="inicio"></BannerPagina>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <QueEsPagina id="que-es"></QueEsPagina>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <CaracteristicasPesajePagina
          id="caracteristicas"
        ></CaracteristicasPesajePagina>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <CaracteristicasBarredoresPagina></CaracteristicasBarredoresPagina>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <TestimoniosPagina
          id="testimonios"
          :testimonios="getArrayTestimonios"
        ></TestimoniosPagina>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <ContactoPagina
          id="contacto"
          @click:enviar="enviarContacto"
        ></ContactoPagina>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <FooterPagina></FooterPagina>
      </v-col>
    </v-row>
    <v-btn
      :width="isMobile ? '75px' : '90px'"
      :height="isMobile ? '75px' : '90px'"
      bottom
      icon
      right
      fixed
      href="https://wa.me/56962186683?text=Hola"
      target="_blank"
      class="button-whatsapp"
    >
      <v-img
        :width="isMobile ? '60px' : '80px'"
        :height="isMobile ? '60px' : '80px'"
        contain
        :src="require('../assets/png/one-page/whatsapp.png')"
      ></v-img>
    </v-btn>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
//tipos
import { Mail } from "@/typings/store/plugins/easyFirestore/mail";
//componentes
import QueEsPagina from "@/components/pagina/QueEs.vue";
import AppBarPagina from "@/components/pagina/AppBar.vue";
import BannerPagina from "@/components/pagina/Banner.vue";
import FooterPagina from "@/components/pagina/Footer.vue";
import ContactoPagina from "@/components/pagina/ContactoPagina.vue";
import TestimoniosPagina from "@/components/pagina/Testimonios.vue";
import CaracteristicasPesajePagina from "@/components/pagina/CaracteristicasPesaje.vue";
import CaracteristicasBarredoresPagina from "@/components/pagina/CaracteristicasBarredores.vue";

const routeGuard = (vm: Vue): boolean => {
  const mobile = window.innerWidth < 1264;
  const rutasMovil = vm.getRutasUsuario.filter((_ruta) => _ruta.mobile);
  const primeraRuta =
    mobile && rutasMovil.length > 0 ? rutasMovil[0] : vm.getRutasUsuario[0];
  if (primeraRuta?.to && vm.$route.name !== primeraRuta.to.name) {
    vm.$router.push(primeraRuta.to);
  }
  return false;
};

@Component({
  components: {
    QueEsPagina,
    AppBarPagina,
    FooterPagina,
    BannerPagina,
    ContactoPagina,
    TestimoniosPagina,
    CaracteristicasPesajePagina,
    CaracteristicasBarredoresPagina,
  },
  computed: mapGetters({
    getArrayTestimonios: "testimonios/getArray",
    getRutasUsuario: "usuario/getRutas",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  methods: mapActions({
    setMail: "mail/set",
  }),
})
export default class VistaInicio extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  enviarContacto(mail: Mail): void {
    this.setMail(mail);
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
</script>

<style scoped>
.button-whatsapp {
  right: 5%;
}
</style>
