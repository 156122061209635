<template>
  <v-tooltip bottom v-if="show">
    <template v-slot:activator="{ on, attrs }">
      <v-chip color="rgba(254, 200, 2, 0.25)" v-on="on" v-bind="attrs">
        <svg-icon
          name="ag-alert-triangle-fill"
          color="warning"
          width="12px"
        ></svg-icon>
      </v-chip>
    </template>
    <span class="metropolis">
      {{ texto }}
    </span>
  </v-tooltip>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
import { HorarioBarredor } from "@/typings/components/barredores/barredores";

@Component({
  components: { SvgIcon },
})
export default class ChipTiempo extends Vue {
  @Prop() readonly tiempo!: number;
  @Prop() readonly barredor!: Barredor;
  @Prop() readonly fecha!: string;

  get horario(): HorarioBarredor {
    const regex =
      /^(2[0-3]|[0-1][0-9]):[0-5][0-9]-(2[0-3]|[0-1][0-9]):[0-5][0-9]$/;
    let [horaInicio, minutosInicio] = [0, 0];
    let [horaFin, minutosFin] = [23, 0];
    if (this.barredor.horario && regex.test(this.barredor.horario)) {
      const [horarioInicio, horarioFin] = this.barredor.horario.split("-");
      [horaInicio, minutosInicio] = horarioInicio.split(":").map(Number);
      [horaFin, minutosFin] = horarioFin.split(":").map(Number);
    }
    const fechaInicio = this.fecha.toDate();
    fechaInicio.setHours(horaInicio, minutosInicio, 0, 0);
    const fechaFin = this.fecha.toDate();
    fechaFin.setHours(horaFin, minutosFin, 59, 999);
    return {
      fechaInicio,
      fechaFin,
    };
  }

  get show(): boolean {
    const tiempo = this.tiempo ?? 0;
    const hoy = new Date();
    return (
      tiempo > 1800000 &&
      hoy >= this.horario.fechaInicio &&
      hoy <= this.horario.fechaFin
    );
  }

  get texto(): string {
    const tiempo = this.msToTime(this.tiempo ?? 0);
    return this.$t(
      "barredores.tab.barredores.list.chip-ultimo-registro.tooltip",
      { msg: tiempo }
    ) as string;
  }
}
</script>
