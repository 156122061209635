<template>
  <div class="mx-percent mt-lg-13 mt-16">
    <v-row no-gutters align="center">
      <v-col cols="12" lg="6">
        <v-row>
          <v-col
            class="text-lg-start text-center font-weight-semi-bold poppins font-size-lg-32 font-size-25 dialogDark--text"
            v-html="$t('pagina.que-es.titulo')"
          ></v-col>
        </v-row>
        <v-row no-gutters class="mt-8">
          <v-col
            class="line-break poppins font-weight-light font-size-16 dialogDark--text"
          >
            {{ $t("pagina.que-es.parrafo") }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" class="mt-8 mt-lg-0 pl-8">
        <v-img
          :src="require('../../assets/webp/preview-monitoreo.webp')"
          contain
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue } from "vue-property-decorator";

@Component
export default class QueEsPagina extends Vue {}
</script>
