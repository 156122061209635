<template>
  <v-card color="#FAFAFA" class="rounded-input elevation-black" height="221px">
    <div class="d-flex flex-column fill-height">
      <v-row no-gutters class="flex-grow-0 pt-2">
        <v-col class="black--text font-weight-bold metropolis pl-2">
          {{ $t("monitoreo.tab.desechos.grafico-1.title") }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <LineChart
            :series="series"
            :title="titulo"
            :labels="labels"
            :formatter="formatter"
          ></LineChart>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { PesajeMonitoreoDesecho } from "@/typings/components/monitoreo/desecho";
//componentes
import LineChart from "@/components/monitoreo/charts/LineChart.vue";

@Component({
  components: { LineChart },
})
export default class TotalDesechos extends Vue {
  @Prop() readonly items!: Array<PesajeMonitoreoDesecho>;
  @Prop() readonly periodo!: number;
  @Prop() readonly fechas!: Array<Date>;
  @Prop() readonly unidad?: string;

  get diferenciaDias(): number {
    const [fechaInicio, fechaFin] = this.fechas;
    if (fechaFin && fechaInicio) {
      const diferencia = fechaFin.getTime() - fechaInicio.getTime();
      return Math.round(diferencia / (1000 * 60 * 60 * 24));
    }
    return 0;
  }

  get fechasValidas(): Array<Date> {
    const fechas = [];
    const [fechaInicio, fechaFin] = this.fechas;
    const fecha = new Date(fechaInicio);
    while (fecha <= fechaFin) {
      fechas.push(new Date(fecha));
      if (
        this.periodo === 1 ||
        (this.periodo === 6 && this.diferenciaDias <= 8)
      ) {
        fecha.setDate(fecha.getDate() + 1);
      } else if (
        this.periodo === 2 ||
        (this.periodo === 6 && this.diferenciaDias <= 31)
      ) {
        fecha.setDate(fecha.getDate() + 3);
      } else if (
        this.periodo === 3 ||
        (this.periodo === 6 && this.diferenciaDias <= 91)
      ) {
        fecha.setDate(fecha.getDate() + 7);
      } else {
        fecha.setMonth(fecha.getMonth() + 1);
      }
    }
    return fechas;
  }

  get labels(): Array<string> {
    return this.fechasValidas.map((fecha) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return fecha.format("DD/MM/YYYY");
      } else if (this.periodo <= 5) {
        return fecha.format("MMMM");
      } else {
        return fecha.format(this.diferenciaDias <= 91 ? "DD/MM/YYYY" : "MMMM");
      }
    });
  }

  get series(): Array<{ name: string; data: Array<number> }> {
    const helper: { [key: number]: number } = {};
    for (const fecha of this.fechasValidas) {
      helper[fecha.getTime()] = 0;
    }
    for (const pesaje of this.items) {
      const fechaPesaje = new Date(pesaje.fecha);
      if (fechaPesaje) {
        fechaPesaje.setHours(0, 0, 0, 0);
        const fecha = this.obtenerFecha(fechaPesaje);
        if (fecha) {
          if (!helper[fecha.getTime()]) {
            helper[fecha.getTime()] = 0;
          }
          helper[fecha.getTime()] += pesaje.valor;
        }
      }
    }
    return [
      {
        name: this.$t("monitoreo.tab.desechos.grafico-1.text") as string,
        data: Object.values(helper),
      },
    ];
  }

  get total(): number {
    return this.series[0].data.reduce((a, b) => a + b, 0);
  }

  get titulo(): string {
    return this.formatter(this.total);
  }

  obtenerFecha(fechaPesaje: Date): Date {
    const fecha = this.fechasValidas.find((f) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return f.getTime() >= fechaPesaje.getTime();
      } else if (this.periodo <= 5) {
        return f.getMonth() === fechaPesaje.getMonth();
      } else {
        return this.diferenciaDias <= 91
          ? f.getTime() >= fechaPesaje.getTime()
          : f.getMonth() === fechaPesaje.getMonth();
      }
    });
    return fecha ?? this.fechasValidas[this.fechasValidas.length - 1];
  }

  formatter(value: number): string {
    if (this.unidad === "t") {
      return `${(value / 1000).toFixed(1)} T`;
    } else {
      return `${value} ${this.unidad}`;
    }
  }
}
</script>

<style scoped></style>
