<template>
  <div class="confirm__container">
    <div class="confirm__title">
      {{ t("titulo") }}
    </div>
    <div class="confirm__message">
      {{ t("mensaje") }}
    </div>
    <div class="confirm__buttons">
      <button @click="onClickYes" class="confirm__button-yes">
        {{ t("botones.si") }}
      </button>
      <button @click="onClickNo" class="confirm__button-no">
        {{ t("botones.no") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue";

// i18n
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "ConfirmLogout",
  emits: ["click:no", "click:yes"],
  setup(_props, ctx) {
    // Methods

    const t = (key: string) => i18n.t(`app-core.confirmar-logout.${key}`) as string;

    // Emits

    const onClickYes = () => {
      ctx.emit("click:yes");
    };

    const onClickNo = () => {
      ctx.emit("click:no");
    };

    return {
      // Methods
      t,
      // Emits
      onClickNo,
      onClickYes,
    };
  },
});
</script>

<style scoped>
.confirm__container {
  row-gap: 1rem;
  display: grid;
  width: 17.9375rem;
  height: 10.5625rem;
  background: #ffffff;
  padding: 1rem 0.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 7px rgba(53, 79, 87, 0.2);
}

.confirm__title {
  color: #283e44;
  font-weight: 700;
  text-align: center;
  font-size: 1.5625rem;
  line-height: 1.5625rem;
  font-family: "Poppins", sans-serif;
}

.confirm__message {
  color: #9babb1;
  font-size: 1rem;
  text-align: center;
  line-height: 1.125rem;
  font-family: "Poppins", sans-serif;
}

.confirm__buttons {
  display: grid;
  column-gap: 1.5rem;
  grid-auto-flow: column;
  justify-content: center;
}

.confirm__button-no,
.confirm__button-yes {
  color: #ffffff;
  width: 4.125rem;
  height: 2.75rem;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 0.5rem;
  line-height: 1.1875rem;
  font-family: "Poppins", sans-serif;
}

.confirm__button-yes {
  background-color: #00ae3c;
}

.confirm__button-no {
  background-color: #80e1ff;
}
</style>
