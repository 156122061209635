<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-row no-gutters>
        <v-col
          class="text-center primary--text text-h6 metropolis font-weight-bold"
        >
          {{ $t("pesajes.formulario.title") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2" align="center">
        <v-col cols="4" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold">
              {{ $t("pesajes.formulario.vehiculo.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-select
            solo
            dense
            return-object
            color="primary"
            item-text="patente"
            hide-details="auto"
            :placeholder="t('pesajes.formulario.vehiculo.placeholder')"
            class="elevation-input-dense metropolis rounded-input"
            :items="arrayVehiculosPesaje"
            :error-messages="erroresVehiculo"
            v-model="vehiculo"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis darker--text text-uppercase">
                {{ item.patente }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span
                class="metropolis text-uppercase text-truncate darker--text"
              >
                {{ item.patente }}
              </span>
            </template>
            <template v-slot:append>
              <v-icon color="primary"> mdi-chevron-down </v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2" align="center">
        <v-col cols="4" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold">
              {{ $t("pesajes.formulario.cliente.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-select
            solo
            dense
            return-object
            color="primary"
            item-text="nombre"
            hide-details="auto"
            :placeholder="t('pesajes.formulario.cliente.placeholder')"
            class="elevation-input-dense metropolis rounded-input"
            :items="arrayClientesPesaje"
            :error-messages="erroresCliente"
            v-model="cliente"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis darker--text text-capitalize">
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span
                class="metropolis text-capitalize text-truncate darker--text"
              >
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:append>
              <v-icon color="primary"> mdi-chevron-down </v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2" align="center">
        <v-col cols="4" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold">
              {{ $t("pesajes.formulario.desecho.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-select
            solo
            dense
            hide-details
            return-object
            color="primary"
            item-text="nombre"
            :placeholder="t('pesajes.formulario.desecho.placeholder')"
            class="elevation-input-dense metropolis rounded-input"
            :items="arrayDesechosPesaje"
            :error-messages="erroresDesecho"
            v-model="desecho"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis darker--text text-capitalize">
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span
                class="metropolis text-capitalize text-truncate darker--text"
              >
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:append>
              <v-icon color="primary"> mdi-chevron-down </v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2" align="center">
        <v-col cols="4" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold">
              {{ $t("pesajes.formulario.servicio.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-select
            solo
            dense
            return-object
            color="primary"
            item-text="nombre"
            hide-details="auto"
            :placeholder="t('pesajes.formulario.servicio.placeholder')"
            class="elevation-input-dense metropolis rounded-input"
            :items="arrayServiciosPesaje"
            :error-messages="erroresServicio"
            v-model="servicio"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis darker--text text-capitalize">
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span
                class="metropolis text-capitalize text-truncate darker--text"
              >
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:append>
              <v-icon color="primary"> mdi-chevron-down </v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2" align="center">
        <v-col cols="4" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold">
              {{ $t("pesajes.formulario.ruta.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col cols="8">
          <v-select
            solo
            dense
            return-object
            color="primary"
            item-text="nombre"
            hide-details="auto"
            :placeholder="t('pesajes.formulario.ruta.placeholder')"
            class="elevation-input-dense metropolis rounded-input"
            :items="arrayRutasPesajePesaje"
            :error-messages="erroresRutaPesaje"
            v-model="rutaPesaje"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis darker--text text-capitalize">
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span
                class="metropolis text-capitalize text-truncate darker--text"
              >
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:append>
              <v-icon color="primary"> mdi-chevron-down </v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col>
          <EntradaSalidaEditar
            :pesaje="pesaje"
            :mostrar-galeria="mostrarGaleria"
            :editar-galeria="editarGaleria"
            :arrayChoferes="arrayChoferesPesaje"
            :cargando="cargando"
            @click:editar-imagenes="onClickEditarImagenes"
            @click:guardar="clickGuardar"
          ></EntradaSalidaEditar>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// helpers
import { log } from "@/helpers/env";
import { pesajeDataToPatch } from "@/helpers/pesaje";
// tipos
import {
  Pesaje,
  PesajeData,
  PesajePatch,
  PesajeSet,
} from "@/typings/store/plugins/easyFirestore/pesajes";
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import { DataEntradaSalida } from "@/typings/components/pesajes/formulario";
// componentes
import EntradaSalidaEditar from "@/components/pesajes/EntradaSalidaEditar.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    EntradaSalidaEditar,
  },
  props: {
    pesaje: {
      default: null,
      type: Object as () => Pesaje | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
    arrayChoferes: {
      default: () => [],
      type: Array as () => Chofer[],
    },
    arrayClientes: {
      default: () => [],
      type: Array as () => Cliente[],
    },
    arrayDesechos: {
      default: () => [],
      type: Array as () => Desecho[],
    },
    arrayVehiculos: {
      default: () => [],
      type: Array as () => Vehiculo[],
    },
    arrayServicios: {
      default: () => [],
      type: Array as () => Servicio[],
    },
    arrayRutasPesaje: {
      default: () => [],
      type: Array as () => RutaPesaje[],
    },
    editarGaleria: {
      default: false,
      type: Boolean,
    },
    mostrarGaleria: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["click:guardar", "click:editar-imagenes"],
  setup(props, ctx) {
    const { t } = useI18n();

    const arrayChoferesPesaje = computed((): Array<Chofer> => {
      const choferesPesaje = props.arrayChoferes.slice();
      const choferEntrada = props.pesaje?.entrada?.chofer;
      if (choferEntrada) {
        if (
          !choferesPesaje.find(
            (chofer) => chofer.nombre === choferEntrada.nombre
          )
        ) {
          choferesPesaje.push(choferEntrada);
        }
      }
      const choferSalida = props.pesaje?.salida?.chofer;
      if (choferSalida) {
        if (
          !choferesPesaje.find(
            (chofer) => chofer.nombre === choferSalida.nombre
          )
        ) {
          choferesPesaje.push(choferSalida);
        }
      }
      return choferesPesaje;
    });

    const arrayVehiculosPesaje = computed((): Array<Vehiculo> => {
      const vehiculosPesaje = props.arrayVehiculos.slice();
      const vehiculoPesaje = props.pesaje?.vehiculo;
      if (vehiculoPesaje) {
        if (
          !vehiculosPesaje.find(
            (vehiculo) => vehiculo.patente === vehiculoPesaje.patente
          )
        ) {
          vehiculosPesaje.push(vehiculoPesaje);
        }
      }
      return vehiculosPesaje;
    });

    const arrayClientesPesaje = computed((): Array<Cliente> => {
      const clientesPesaje = props.arrayClientes.slice();
      const clientePesaje = props.pesaje?.cliente;
      if (clientePesaje) {
        if (
          !clientesPesaje.find(
            (cliente) => cliente.nombre === clientePesaje.nombre
          )
        ) {
          clientesPesaje.push(clientePesaje);
        }
      }
      return clientesPesaje;
    });

    const arrayDesechosPesaje = computed((): Array<Desecho> => {
      const desechosPesaje = props.arrayDesechos.slice();
      const desechoPesaje = props.pesaje?.desecho;
      if (desechoPesaje) {
        if (
          !desechosPesaje.find(
            (desecho) => desecho.nombre === desechoPesaje.nombre
          )
        ) {
          desechosPesaje.push(desechoPesaje);
        }
      }
      return desechosPesaje;
    });

    const arrayServiciosPesaje = computed((): Array<Servicio> => {
      const serviciosPesaje = props.arrayServicios.slice();
      const servicioPesaje = props.pesaje?.servicio;
      if (servicioPesaje) {
        if (
          !serviciosPesaje.find(
            (servicio) => servicio.nombre === servicioPesaje.nombre
          )
        ) {
          serviciosPesaje.push(servicioPesaje);
        }
      }
      return serviciosPesaje;
    });

    const arrayRutasPesajePesaje = computed((): Array<RutaPesaje> => {
      const rutasPesajePesaje = props.arrayRutasPesaje.slice();
      const rutaPesajePesaje = props.pesaje?.ruta;
      if (rutaPesajePesaje) {
        if (
          !rutasPesajePesaje.find(
            (rutaPesaje) => rutaPesaje.nombre === rutaPesajePesaje.nombre
          )
        ) {
          rutasPesajePesaje.push(rutaPesajePesaje);
        }
      }
      return rutasPesajePesaje;
    });

    const pesajeData = ref<PesajeData>({});

    const cliente = computed({
      get: () => {
        const cliente =
          pesajeData.value.cliente || props.pesaje?.cliente || null;
        return cliente;
      },
      set: (value) => {
        if (value) {
          const nuevo = Object.assign({}, pesajeData.value, { cliente: value });
          pesajeData.value = nuevo;
        }
      },
    });

    const vehiculo = computed({
      get: () => {
        const vehiculo =
          pesajeData.value.vehiculo || props.pesaje?.vehiculo || null;
        return vehiculo;
      },
      set: (value) => {
        if (value) {
          const nuevo = Object.assign({}, pesajeData.value, {
            vehiculo: value,
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const desecho = computed({
      get: () => {
        const desecho =
          pesajeData.value.desecho || props.pesaje?.desecho || null;
        return desecho;
      },
      set: (value) => {
        if (value) {
          const nuevo = Object.assign({}, pesajeData.value, { desecho: value });
          pesajeData.value = nuevo;
        }
      },
    });

    const servicio = computed({
      get: () => {
        const servicio =
          pesajeData.value.servicio || props.pesaje?.servicio || null;
        return servicio;
      },
      set: (value) => {
        if (value) {
          const nuevo = Object.assign({}, pesajeData.value, {
            servicio: value,
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const rutaPesaje = computed({
      get: () => {
        const rutaPesaje = pesajeData.value.ruta || props.pesaje?.ruta || null;
        return rutaPesaje;
      },
      set: (value) => {
        if (value) {
          const nuevo = Object.assign({}, pesajeData.value, { ruta: value });
          pesajeData.value = nuevo;
        }
      },
    });

    const state = reactive({
      cliente,
      desecho,
      servicio,
      vehiculo,
      rutaPesaje,
    });

    const rules = {
      cliente: { required },
      desecho: { required },
      servicio: { required },
      vehiculo: { required },
      rutaPesaje: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresCliente = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.cliente.$dirty) return errores;
      if (v$.value.cliente.required.$invalid) {
        const error = t("pesajes.formulario.cliente.error-message") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresVehiculo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.vehiculo.$dirty) return errores;
      if (v$.value.vehiculo.required.$invalid) {
        const error = t("pesajes.formulario.vehiculo.error-message") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresDesecho = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.desecho.$dirty) return errores;
      if (v$.value.desecho.required.$invalid) {
        const error = t("pesajes.formulario.desecho.error-message") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresServicio = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.servicio.$dirty) return errores;
      if (v$.value.servicio.required.$invalid) {
        const error = t("pesajes.formulario.servicio.error-message") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresRutaPesaje = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rutaPesaje.$dirty) return errores;
      if (v$.value.rutaPesaje.required.$invalid)
        errores.push("La ruta es requerida");
      return errores;
    });

    const onClickEditarImagenes = (): void => {
      ctx.emit("click:editar-imagenes");
    };

    const onClickGuardar = (pesaje: Pesaje | PesajeSet | PesajePatch): void => {
      ctx.emit("click:guardar", pesaje);
    };

    const clickGuardar = (data: DataEntradaSalida): void => {
      v$.value.$touch();
      if (log) {
        console.log("data formulario pesaje", data);
        console.log("vuelidate formulario pesaje", v$.value);
      }
      if (!data.formularioValido) return;
      if (v$.value.$invalid) return;
      if (props.pesaje?.id) {
        const nuevo = Object.assign({}, pesajeData.value, data.pesajeData);
        const doc: PesajePatch = pesajeDataToPatch(props.pesaje.id, nuevo);
        onClickGuardar(doc);
      } else {
        const doc: PesajeSet = {
          fotos: [],
          cliente: cliente.value,
          vehiculo: vehiculo.value,
          capacidad: 0,
          cobro: 0,
          desecho: desecho.value,
          servicio: servicio.value,
          ruta: rutaPesaje.value,
          entrada: {
            fecha: data.pesajeData.fechaEntrada || "",
            peso: data.pesajeData.pesoEntrada || 0,
            observacion: data.pesajeData.observacionEntrada || "",
            chofer: data.pesajeData.choferEntrada || null,
          },
          salida: {
            fecha: data.pesajeData.fechaSalida || "",
            peso: data.pesajeData.pesoSalida || 0,
            observacion: data.pesajeData.observacionSalida || "",
            chofer: data.pesajeData.choferSalida || null,
          },
          estado: "finalizado",
          sincronizacion: {
            enviado: new Date().toISOString(),
            recibido: null,
          },
        };
        onClickGuardar(doc);
      }
    };

    return {
      t,
      v$,
      arrayChoferesPesaje,
      arrayClientesPesaje,
      arrayDesechosPesaje,
      arrayServiciosPesaje,
      arrayVehiculosPesaje,
      arrayRutasPesajePesaje,
      cliente,
      desecho,
      servicio,
      vehiculo,
      rutaPesaje,
      erroresCliente,
      erroresDesecho,
      erroresServicio,
      erroresVehiculo,
      erroresRutaPesaje,
      onClickEditarImagenes,
      onClickGuardar,
      clickGuardar,
    };
  },
});
</script>
