<template>
  <v-card flat color="transparent">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="end" align="center">
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch
                  v-model="switchValue"
                  :loading="cargando"
                  color="primary"
                  class="ma-0 pa-0"
                  dense
                  hide-details
                  inset
                ></v-switch>
              </div>
            </template>
            <span class="metropolis">
              {{
                switchValue
                  ? $t("rastreadores.tabla.opciones.estados.0")
                  : $t("rastreadores.tabla.opciones.estados.1")
              }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <icon-button
            :icon="
              isSelected ? 'fas-circle-xmark' : 'ionic-md-information-circle'
            "
            :color="isSelected ? 'error' : 'primary'"
            @click="onClickInfo"
          >
            {{
              $t(
                `rastreadores.tabla.opciones.boton-informacion.${
                  isSelected ? "cerrar" : "abrir"
                }`
              )
            }}
          </icon-button>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
//composition
import { computed, defineComponent, ref, watch } from "vue";
//helpers
import { log } from "@/helpers/env";
//tipos
import { Item } from "@/typings/components/rastreadores/tabla";
import { Rastreador } from "@/typings/store/plugins/easyFirestore/rastreadores";
//componentes
import IconButton from "@/components/custom/IconButton.vue";

export default defineComponent({
  name: "OpcionesTabla",
  components: {
    IconButton,
  },
  emits: ["click:informacion", "click:switch"],
  props: {
    item: {
      type: Object as () => Item,
      required: true,
    },
    seleccionado: {
      type: Object as () => Rastreador | null,
    },
  },
  setup(props, ctx) {
    const cargando = ref(false);

    const estado = computed(() => props.item.rastreador.estado);

    const switchValue = computed({
      get() {
        return estado.value !== "deshabilitado";
      },
      set(value) {
        if (log) {
          console.log("set switchValue", value);
        }
        onClickSwitch();
        cargando.value = true;
      },
    });

    watch(estado, () => {
      cargando.value = false;
    });

    const isSelected = computed(() => {
      return !!props.seleccionado && props.seleccionado.id === props.item.id;
    });

    const onClickSwitch = () => {
      ctx.emit("click:switch", props.item);
    };

    const onClickInfo = () => {
      ctx.emit("click:informacion", props.item);
    };

    return {
      cargando,
      switchValue,
      isSelected,
      onClickInfo,
    };
  },
});
</script>
