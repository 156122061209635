<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-on="on"
        v-bind="attrs"
        :style="circleStyle"
        class="tooltip-estado__circle"
        @click.stop="onClickSincronizar"
      >
        <SvgIcon
          width="1rem"
          height="1rem"
          :name="icono"
          color="#ffffff"
        />
      </div>
    </template>
    <span class="metropolis line-break">
      {{ textoTooltip }}
    </span>
  </v-tooltip>
</template>

<script lang="ts">
// Vue
import {computed, defineComponent} from "vue";

// Composables
import {useI18n} from "@/composables/i18n";

// Tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "TooltipEstado",
  components: {
    SvgIcon,
  },
  props: {
    pesaje: {
      required: true,
      type: Object as () => Pesaje,
    },
  },
  emits: [
    "click:sincronizar",
  ],
  setup(props, ctx) {
    // Composables

    const {t} = useI18n();

    // Computed

    const circleStyle = computed(() => ({
      'background-color': props.pesaje.estado === "finalizado" ? "var(--v-primary-base)" : "var(--v-text-base)",
    }));

    const estadoSincronizacion = computed(() => {
      const sincronizacion = props.pesaje.sincronizacion;
      if (!sincronizacion) return 1;
      const now = Date.now();
      const timePesaje = new Date(sincronizacion.enviado).getTime();
      const diferencia = Math.abs(now - timePesaje);
      if (diferencia <= 10000) return 0;
      if (!sincronizacion.recibido) return 2;
      return 1;
    });

    const textoTooltip = computed(() => {
      const index = props.pesaje.estado === "finalizado" ? 0 : 1;
      const textEstado = text(`estado.${index}`);
      const textSicronizacion = text(`sincronizacion.${estadoSincronizacion.value}`);
      return `${textEstado} / ${textSicronizacion}`;
    });

    const icono = computed(() => {
      const iconos = ["far-rotate", "far-check", "far-pause"];
      return iconos[estadoSincronizacion.value];
    });

    // Methods

    const text = (key: string) => t(`bascula.tabla.${key}`) as string;

    // Emits

    const onClickSincronizar = () => {
      if (estadoSincronizacion.value === 2) {
        ctx.emit("click:sincronizar", props.pesaje);
      }
    };

    return {
      // Computed
      icono,
      circleStyle,
      textoTooltip,
      // Emits
      onClickSincronizar,
    };
  },
});
</script>

<style scoped>
.tooltip-estado__circle {
  display: flex;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
</style>
