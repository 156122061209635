<template>
  <v-app-bar
    flat
    app
    dense
    height="160px"
    color="background"
    :class="appBarClass"
  >
    <div class="app-bar__content">
      <div class="app-bar__data">
        <div class="app-bar__row">
          <div class="app-bar__client-name">
            {{ nombreEmpresa }}
          </div>
          <div v-if="mostrarNotificaciones">
            <ListaNotificaciones
              :pesajes="pesajes.getArray.value"
            ></ListaNotificaciones>
          </div>
          <div class="app-bar__user-name">
            {{ nombreUsuario }}
          </div>
        </div>
        <div class="app-bar__row">
          <div class="app-bar__route-name">
            {{ nombreRuta }}
          </div>
          <div class="app-bar__user-position">
            {{ nombreCargo }}
          </div>
        </div>
      </div>
      <svg-icon
        width="5.5rem"
        height="5.5rem"
        color="secondary"
        name="ag-user-circle"
      />
    </div>
  </v-app-bar>
</template>

<script lang="ts">
// Vue
import {defineComponent, computed} from "vue";

// Composables
import {useI18n} from "@/composables/i18n";
import {useRutas} from "@/composables/rutas";
import {useRouter} from "@/composables/router";
import {useUsuario} from "@/composables/usuario";
import {usePesajes} from "@/composables/pesajes";
import {useEmpresas} from "@/composables/empresas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ListaNotificaciones from "@/components/ListaNotificaciones.vue";

export default defineComponent({
  components: {
    SvgIcon,
    ListaNotificaciones,
  },
  props: {
    miniVariant: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Composables

    const i18n = useI18n();
    const rutas = useRutas();
    const router = useRouter();
    const usuario = useUsuario();
    const pesajes = usePesajes();
    const empresas = useEmpresas();

    // Data

    const rutasNotificaciones = ["bascula", "reportes", "pesajes"];

    // Computed

    const mostrarNotificaciones = computed((): boolean => {
      return rutasNotificaciones.includes(router.routeName.value);
    });

    const nombreEmpresa = computed((): string => {
      const usuarioTemp = usuario.get.value;
      if (!usuarioTemp) return "";
      return empresas.getById(usuarioTemp.empresa)?.nombre ?? "";
    });

    const nombreUsuario = computed((): string => {
      const usuarioTemp = usuario.get.value;
      if (!usuarioTemp) return "";
      return `${usuarioTemp.nombres} ${usuarioTemp.apellidos}` ?? "";
    });

    const nombreRuta = computed((): string => {
      const name = router.routeName.value;
      const ruta = rutas.getArray.value.find((_ruta) => _ruta.id === name);
      return ruta?.text ?? "";
    });

    const nombreCargo = computed((): string => {
      const usuarioTemp = usuario.get.value;
      if (!usuarioTemp) return "";
      return usuarioTemp.cargo ?? (i18n.t("app-bar.no-position-message") as string);
    });

    const appBarClass = computed(() => ({
      'app-bar__container': true,
      'app-bar__mini': props.miniVariant,
      'app-bar__normal': !props.miniVariant
    }));

    return {
      // Composables
      pesajes,
      // Computed
      nombreRuta,
      nombreCargo,
      appBarClass,
      nombreEmpresa,
      nombreUsuario,
      mostrarNotificaciones,
    };
  },
});
</script>

<style scoped>
.bar__container :deep(.v-toolbar__content) {
  padding: 0 !important;
}

.app-bar__content {
  width: 100%;
  display: grid;
  column-gap: 1rem;
  padding: 0 1.25rem;
  align-items: center;
  grid-template-columns: 1fr auto;
}

.app-bar__data {
  display: grid;
  row-gap: 1.5rem;
}

.app-bar__row {
  display: grid;
  align-items: end;
  grid-auto-flow: column;
  justify-content: space-between;
  font-family: 'Metropolis', sans-serif;
}

.app-bar__client-name,
.app-bar__user-position {
  font-weight: 800;
  font-style: normal;
  font-size: 2.375rem;
  line-height: 2.375rem;
  color: var(--v-primary-base);
}

.app-bar__user-name,
.app-bar__route-name {
  font-weight: 800;
  font-size: 3.125rem;
  line-height: 3.125rem;
  color: var(--v-dialogDark-base);
}

.app-bar__user-position {
  color: var(--v-secondary-base);
}

.app-bar__normal {
  left: 281px !important;
}

.app-bar__mini {
  left: 139px !important;
}
</style>
