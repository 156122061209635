<template>
  <v-img
    :src="require('../../assets/webp/background-banner.webp')"
    :lazy-src="require('../../assets/webp/background-banner-lazy.webp')"
    width="100%"
    :contain="!isMobile"
    position="top"
    class="relative"
  >
    <div class="d-flex flex-column fill-height">
      <v-row no-gutters justify="center" class="mt-18 flex-grow-0">
        <v-col cols="auto">
          <v-img
            :src="require('../../assets/svg/sistema/isotipo.svg')"
            :width="isMobile ? '60px' : '95px'"
            :height="isMobile ? '60px' : '95px'"
            contain
          ></v-img>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-lg-4 mt-5 flex-grow-0 mx-percent">
        <v-col
          class="text-center line-break white--text nimbus font-size-lg-53 font-size-33 line-height-1"
        >
          {{ $t("pagina.banner.titulo") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-lg-4 mt-8 flex-grow-0 mx-percent">
        <v-col class="text-center line-break white--text poppins font-size-14">
          {{ $t("pagina.banner.subtitulo") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-lg-10 row-macbook" justify="center">
        <v-col cols="auto">
          <v-img
            :width="isMobile ? '280px' : '700px'"
            :src="require('../../assets/webp/preview-macbook.webp')"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </v-img>
</template>

<script lang="ts">
//decoradores
import { Component, Vue } from "vue-property-decorator";

@Component
export default class BannerPagina extends Vue {
  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
</script>

<style scoped>
@media screen and (min-width: 1264px) {
  .font-size-lg-53 {
    font-size: 53px !important;
  }
}

@media screen and (max-width: 1264px) {
  .relative {
    position: relative !important;
    overflow: initial !important;
    margin-bottom: 25px;
    padding-bottom: 160px;
  }

  .row-macbook {
    width: 100%;
    position: absolute;
    bottom: -25px;
  }
}

.line-height-1 {
  line-height: 1.1 !important;
}
</style>
