<template>
  <div>
    <v-dialog
      max-width="296px"
      v-model="dialogo"
      content-class="border-radius-12"
    >
      <div class="dialogo-desconectados__container">
        <div class="dialogo-desconectados__title">
          {{ t("titulo") }}
        </div>
        <div class="dialogo-desconectados__items">
          <ItemDialogo
            color="#D86C6C"
            :key="barredor.id"
            :barredor="barredor"
            v-for="barredor in desconectados.desconectados"
          />
          <ItemDialogo
            color="#FEC802"
            :key="barredor.id"
            :barredor="barredor"
            v-for="barredor in desconectados.hibernando"
          />
        </div>
      </div>
    </v-dialog>
    <div class="dialogo-desconectados__button-container">
      <div @click="abrirDialogo" class="dialogo-desconectados__button">
        {{ textoBoton }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import BarredoresDesconectados from "@/helpers/BarredoresDesconectados";

// i18n
import {Values} from "vue-i18n";
import i18n from "@/plugins/i18n";

// Componentes
import ItemDialogo from "@/components/alertasBarredores/dialogoDesconectados/ItemDialogo.vue";

export default defineComponent({
  name: "DialogoDesconectados",
  components: {
    ItemDialogo,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    desconectados: {
      required: true,
      type: Object as () => BarredoresDesconectados,
    },
  },
  emits: ["input"],
  setup(props, ctx) {
    // VModel

    const dialogo = computed({
      get() {
        return props.value;
      },
      set(value: boolean) {
        ctx.emit("input", value);
      },
    });

    // Computed

    const textoBoton = computed(() => t("boton", {desconectados: props.desconectados.length}));

    // Methods

    const t = (key: string, values?: Values) => i18n.t(`alertas-barredores.dialogo-desconectados.${key}`, values) as string;

    const abrirDialogo = () => {
      if (props.desconectados.length === 0) return;
      dialogo.value = true;
    };

    return {
      // Data
      dialogo,
      // Computed
      textoBoton,
      // Methods
      t,
      abrirDialogo,
    };
  },
});
</script>

<style scoped>
.dialogo-desconectados__button-container {
  left: 4rem;
  right: 4rem;
  bottom: 1rem;
  display: flex;
  position: fixed;
  justify-content: center;
}

.dialogo-desconectados__button {
  color: #d86c6c;
  font-weight: 600;
  padding: 0.625rem;
  font-size: 0.8rem;
  user-select: none;
  border-radius: 1rem;
  line-height: 1.1875rem;
  font-family: "Poppins", sans-serif;
  background: rgba(255, 255, 255, 0.8);
  transition: opacity 50ms ease-in-out;
  box-shadow: 0 4px 7px rgba(53, 79, 87, 0.2);
}

.dialogo-desconectados__button:active {
  opacity: 0.8;
}

.dialogo-desconectados__container {
  display: grid;
  padding: 1rem;
  max-height: 75vh;
  overflow-y: auto;
  row-gap: 1.125rem;
  background: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 4px 7px rgba(53, 79, 87, 0.2);
}

.dialogo-desconectados__title {
  color: #283e44;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  font-family: "Poppins", sans-serif;
}

.dialogo-desconectados__items {
  display: grid;
  row-gap: 1.125rem;
}
</style>
