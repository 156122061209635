<template>
  <v-card color="#FAFAFA" class="rounded-input elevation-black" height="221px">
    <div class="d-flex flex-column fill-height">
      <v-row no-gutters class="flex-grow-0 pt-2">
        <v-col class="black--text font-weight-bold metropolis pl-2">
          {{ $t("monitoreo.tab.vehiculos.grafico-1.title") }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <LineChart
            :series="series"
            :title="pesajes.length.toString()"
            :labels="labels"
          ></LineChart>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
//decoradores
import {Component, Prop, Vue} from "vue-property-decorator";
//tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import LineChart from "../charts/LineChart.vue";

@Component({
  components: {LineChart},
})
export default class TotalVehiculos extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;
  @Prop() readonly periodo!: number;
  @Prop() readonly fechas!: Array<Date>;

  get diferenciaDias(): number {
    const [fechaInicio, fechaFin] = this.fechas;
    if (fechaFin && fechaInicio) {
      const diferencia = fechaFin.getTime() - fechaInicio.getTime();
      return Math.round(diferencia / (1000 * 60 * 60 * 24));
    }
    return 0;
  }

  get fechasValidas(): Array<Date> {
    const fechas = [];
    const [fechaInicio, fechaFin] = this.fechas;
    const fecha = new Date(fechaInicio);
    while (fecha <= fechaFin) {
      fechas.push(new Date(fecha));
      if (
        this.periodo === 1 ||
        (this.periodo === 6 && this.diferenciaDias <= 8)
      ) {
        fecha.setDate(fecha.getDate() + 1);
      } else if (
        this.periodo === 2 ||
        (this.periodo === 6 && this.diferenciaDias <= 31)
      ) {
        fecha.setDate(fecha.getDate() + 3);
      } else if (
        this.periodo === 3 ||
        (this.periodo === 6 && this.diferenciaDias <= 91)
      ) {
        fecha.setDate(fecha.getDate() + 7);
      } else {
        fecha.setMonth(fecha.getMonth() + 1);
      }
    }
    return fechas;
  }

  get labels(): Array<string> {
    return this.fechasValidas.map((fecha) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return fecha.format("DD/MM/YYYY");
      } else if (this.periodo <= 5) {
        return fecha.format("MMMM");
      } else {
        return fecha.format(this.diferenciaDias <= 91 ? "DD/MM/YYYY" : "MMMM");
      }
    });
  }

  get series(): Array<{ name: string; data: Array<number> }> {
    const helper: { [key: number]: number } = {};
    for (const fecha of this.fechasValidas) {
      helper[fecha.getTime()] = 0;
    }
    for (const pesaje of this.pesajes) {
      const fechaPesaje = pesaje.entrada.fecha?.toDate();
      if (fechaPesaje) {
        fechaPesaje.setHours(0, 0, 0, 0);
        const fecha = this.obtenerFecha(fechaPesaje);
        if (fecha) {
          if (!helper[fecha.getTime()]) {
            helper[fecha.getTime()] = 0;
          }
          helper[fecha.getTime()]++;
        }
      }
    }
    return [{
      data: Object.values(helper),
      name: this.$t("monitoreo.tab.vehiculos.grafico-1.text") as string,
    }];
  }

  obtenerFecha(fechaPesaje: Date): Date {
    const fecha = this.fechasValidas.find((fecha) => {
      if (this.periodo >= 1 && this.periodo <= 3) {
        return fecha.getTime() >= fechaPesaje.getTime();
      } else if (this.periodo <= 5) {
        return fecha.getMonth() === fechaPesaje.getMonth();
      } else {
        return this.diferenciaDias <= 91 ? fecha.getTime() >= fechaPesaje.getTime() : fecha.getMonth() === fechaPesaje.getMonth();
      }
    });
    return fecha ?? this.fechasValidas[this.fechasValidas.length - 1];
  }
}
</script>
