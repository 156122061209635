<template>
  <v-card color="dialog" class="rounded-input elevation-dark">
    <v-card-text>
      <v-row no-gutters class="pt-4" align="center">
        <v-col
          cols="8"
          offset="2"
          class="text-h6 metropolis white--text text-center text-capitalize font-weight-bold"
        >
          {{ $t("barredores.tab.rutas.informacion.title") }}
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            x-small
            width="44px"
            height="44px"
            class="gradient-error elevation-error rounded-input"
            @click="onClickCerrar"
          >
            <v-icon color="white"> mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3">
        <v-col>
          <GmapMap
            :center="ultima"
            :zoom="17"
            :options="{
              clickableIcons: false,
              draggableCursor: 'default',
              styles: require('@/assets/json/gmap/MapStyles.json'),
            }"
            map-type-id="terrain"
            class="google-map"
          >
            <GmapPolyline
              :path.sync="path"
              :options="{
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }"
            ></GmapPolyline>
            <GmapMarker v-if="ultima" :position="ultima"></GmapMarker>
          </GmapMap>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3" align="center" justify="space-between">
        <v-col>
          <v-row no-gutters align="center">
            <v-col
              cols="3"
              class="white--text font-weight-bold metropolis text-body-1"
            >
              {{ $t("barredores.tab.rutas.informacion.label.0") }}
            </v-col>
            <v-col cols="9" class="pl-2 metropolis text-body-1 white--text">
              {{ nombre }}
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-2 pt-md-0">
          <v-row no-gutters align="center" justify="end">
            <v-col
              cols="auto"
              class="white--text font-weight-bold metropolis text-body-1 pr-2"
            >
              {{ $t("barredores.tab.rutas.informacion.label.1") }}
            </v-col>
            <v-col cols="auto" class="pl-2">
              <v-card
                flat
                class="rounded-input border-grey"
                :color="color"
                width="40px"
                height="40px"
              ></v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Posicion } from "@/typings/store/plugins/easyFirestore/pingsRastreador";
import { RutaBarredor } from "@/typings/store/plugins/easyFirestore/rutasBarredor";

@Component
export default class InformacionRutaBarredor extends Vue {
  @Prop() readonly rutaBarredor!: RutaBarredor;

  get nombre(): string {
    return this.rutaBarredor.nombre;
  }

  get color(): string {
    return this.rutaBarredor.color;
  }

  get path(): Array<Posicion> {
    return this.rutaBarredor.posiciones;
  }

  get ultima(): Posicion {
    return this.path[this.path.length - 1];
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 960px) {
  .google-map {
    height: 35vh;
  }
}
</style>
