<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3" class="pr-2">
            <v-row no-gutters justify="space-between">
              <v-col cols="auto" class="metropolis font-weight-bold">
                {{ t("servicios.formulario.nombre.label") }}
              </v-col>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="t('servicios.formulario.nombre.placeholder')"
              class="rounded-input elevation-input-dense metropolis"
              v-model="nombre"
              :error-messages="nombreErrors"
              @focus="v$.nombre.$reset()"
              @blur="v$.nombre.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3" class="pr-2">
            <v-row no-gutters justify="space-between">
              <v-col cols="auto" class="metropolis font-weight-bold">
                {{ t("servicios.formulario.tipo.label") }}
              </v-col>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-select
              dense
              solo
              hide-details="auto"
              :placeholder="t('servicios.formulario.tipo.placeholder')"
              class="metropolis rounded-input elevation-input-dense"
              v-model="tipo"
              :items="tipos"
              :error-messages="tipoErrors"
              @focus="v$.tipo.$reset()"
              @blur="v$.tipo.$touch()"
              :menu-props="{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }"
            >
              <template v-slot:item="{ item }">
                <span class="metropolis text-body-1 darker--text">
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span class="metropolis text-body-1 darker--text">
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:append>
                <v-icon color="primary"> mdi-chevron-down </v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3" class="metropolis text-body-1 font-weight-bold">
            {{ t("servicios.formulario.descripcion.label") }}
          </v-col>
          <v-col cols="9">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="t('servicios.formulario.descripcion.placeholder')"
              class="rounded-input elevation-input-dense metropolis"
              v-model="descripcion"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0 pt-2" justify="center">
      <v-col cols="6" class="pr-2">
        <v-btn
          block
          large
          :class="`rounded-input elevation-primary gradient-primary ${
            crearDisabled ? 'button-inactive button-disabled' : ''
          }`"
          @click="guardar"
        >
          <svg-icon name="three-dots" v-if="cargando"></svg-icon>
          <v-row v-else no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon
                :name="servicio ? 'ag-save' : 'servicios'"
                color="white"
              ></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ textoBoton }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="6" class="pl-2" v-if="servicio">
        <v-btn
          block
          large
          class="rounded-input elevation-error gradient-error button-disabled button-inactive"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <v-icon color="white"> mdi-delete</v-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ t("servicios.formulario.boton-eliminar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import {
  Servicio,
  TipoServicio,
} from "@/typings/store/plugins/easyFirestore/servicios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    servicio: {
      default: null,
      type: Object as () => Servicio | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    const servicio = this.servicio;
    if (servicio?.id) {
      this.id = servicio.id;
      this.nombre = servicio.nombre ?? "";
      this.tipo = servicio.tipo ?? null;
      this.descripcion = servicio.descripcion ?? "";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref<string | null>(null);
    const nombre = ref("");
    const tipo = ref<TipoServicio | null>(null);
    const descripcion = ref("");
    const flag = ref(true);

    const tipos = computed((): Array<{ text: string; value: TipoServicio }> => {
      return [
        {
          text: t("servicios.formulario.tipo.values.0") as string,
          value: "Egreso",
        },
        {
          text: t("servicios.formulario.tipo.values.1") as string,
          value: "Ingreso",
        },
      ];
    });

    const titulo = computed((): string => {
      const index = props.servicio ? 1 : 0;
      return t(`servicios.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.servicio ? 0 : 1;
      return t(`servicios.formulario.boton.text.${index}`) as string;
    });

    const state = reactive({
      nombre,
      tipo,
    });

    const rules = {
      nombre: { required },
      tipo: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errors;
      if (v$.value.nombre.required.$invalid)
        errors.push(
          t("servicios.formulario.nombre.error-messages[0]") as string
        );
      if (nombre.value.length > 20)
        errors.push(
          t("servicios.formulario.nombre.error-messages[1]") as string
        );
      return errors;
    });

    const tipoErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errors;
      if (v$.value.tipo.required.$invalid)
        errors.push(t("servicios.formulario.tipo.error-message") as string);
      return errors;
    });

    const crearDisabled = computed((): boolean => {
      if (props.servicio) return false;
      return !nombre.value || !tipo.value || nombreErrors.value.length > 0;
    });

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || !tipo.value) {
        return;
      }
      flag.value = false;
      const data: Servicio = {
        nombre: nombre.value.toLowerCase(),
        tipo: tipo.value,
        descripcion: descripcion.value.toLowerCase(),
      };
      if (props.servicio) data.id = props.servicio.id;

      ctx.emit("click:guardar", data);
    };

    return {
      t,
      v$,
      id,
      nombre,
      tipo,
      descripcion,
      flag,
      tipos,
      titulo,
      textoBoton,
      nombreErrors,
      tipoErrors,
      crearDisabled,
      guardar,
    };
  },
});
</script>
