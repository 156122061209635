<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    hide-default-footer
    height="calc(100% - 89px)"
    :items-per-page="9"
    class="elevation-black white fill-height table rounded-input"
  >
    <template v-slot:[`item.nombre`]="{item}">
      <table-item
        :value="item.nombre"
        :classes="getClasses(item)"
      ></table-item>
    </template>
    <template v-slot:[`item.tipo`]="{item}">
      <table-item
        :value="item.tipo"
        :classes="getClasses(item)"
      ></table-item>
    </template>
    <template v-slot:[`item.precio`]="{item}">
      <table-item
        :value="item.precio"
        :classes="getClasses(item)"
      ></table-item>
    </template>
    <template v-slot:[`item.tipoPago`]="{item}">
      <table-item
        :value="item.tipoPago"
        :classes="getClasses(item)"
      ></table-item>
    </template>
    <template v-slot:[`item.opciones`]="{item}">
      <OpcionesTabla
        :item="item"
        :seleccionado="plan"
        @click:informacion="onClickInformacion"
        @click:switch="onClickSwitch"
      ></OpcionesTabla>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer
        :value="props"
      ></data-table-footer>
    </template>
  </v-data-table>
</template>

<script lang="ts">
//composition
import {computed, defineComponent} from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import {Plan} from "@/typings/store/plugins/easyFirestore/planes";
import {Header, Item} from "@/typings/components/planes/tabla";
//componentes
import TableItem from "@/components/custom/TableItem.vue";
import OpcionesTabla from "@/components/planes/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

export default defineComponent({
  name: "TablaPlanes",
  components: {
    DataTableFooter,
    TableItem,
    OpcionesTabla
  },
  emits: [
    "click:informacion",
    "click:switch"
  ],
  props: {
    search: {
      type: String,
      required: true
    },
    planes: {
      type: Array as () => Array<Plan>,
      required: true
    },
    plan: {
      type: Object as () => Plan | null
    }
  },
  setup(props, ctx) {
    const noRegistrado = computed(() => {
      return i18n.t("rastreadores.tabla.no-registrado") as string;
    });

    const headers = computed<Array<Header>>(() => {
      return [{
        text: i18n.t("planes.tabla.headers.0") as string,
        value: "nombre"
      }, {
        text: i18n.t("planes.tabla.headers.1") as string,
        value: "tipo"
      }, {
        text: i18n.t("planes.tabla.headers.2") as string,
        value: "precio"
      }, {
        text: i18n.t("planes.tabla.headers.3") as string,
        value: "tipoPago"
      }, {
        text: "",
        value: "opciones",
        sortable: false
      }];
    });

    const items = computed<Array<Item>>(() => {
      return props.planes.map(plan => {
        const id = plan.id ?? "";
        const nombre = plan.nombre ?? noRegistrado.value;
        const tipo = plan.tipo ?? noRegistrado.value;
        const precio = plan.precio?.toCurrency("$") ?? noRegistrado.value;
        const tipoPago = plan.tipoPago ?? noRegistrado.value;
        return {
          id,
          nombre,
          tipo,
          precio,
          tipoPago,
          plan
        };
      });
    });

    const getClasses = ({id}: Item, uppercase?: boolean): string => {
      const classes = [`pa-0 metropolis text-center text-body-1 text-${uppercase ? "uppercase" : "capitalize"}`];
      if (props.plan?.id === id) classes.push("primary--text font-weight-bold");
      return classes.join(" ");
    }

    const onClickInformacion = ({plan}: Item) => {
      ctx.emit("click:informacion", plan);
    }

    const onClickSwitch = ({plan}: Item) => {
      ctx.emit("click:switch", plan);
    }

    return {
      headers,
      items,
      getClasses,
      onClickInformacion,
      onClickSwitch
    };
  }
});
</script>
