<template>
  <v-app-bar
    app
    flat
    tile
    color="#F7F7F7"
    height="56px"
    width="100%"
    dense
    class="app-bar"
  >
    <v-row no-gutters justify="center" align="center">
      <v-col class="mx-percent">
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="auto">
            <svg-icon
              :src="require('../../assets/svg/sistema/logotipo.svg')"
              :width="isMobile ? '120px' : '136px'"
              height="auto"
            ></svg-icon>
          </v-col>
          <v-col cols="auto">
            <v-row no-gutters align="center">
              <template v-if="!isMobile">
                <v-col
                  cols="auto"
                  v-for="boton in botones"
                  :key="`boton-app-bar-${boton.id}`"
                >
                  <v-btn
                    text
                    class="poppins font-size-13 letter-spacing border-radius-23 font-weight-medium dialogDark--text"
                    @click="buttonScrollTo(boton.to)"
                  >
                    {{ boton.name }}
                  </v-btn>
                </v-col>
              </template>
              <v-col cols="auto">
                <v-btn
                  text
                  class="poppins button-login font-weight-medium font-size-13 dialogDark--text letter-spacing"
                  x-large
                  :width="isMobile ? '120px' : '146px'"
                  height="38px"
                  @click="goLogin"
                >
                  {{ $t("pagina.app-bar.boton-iniciar-sesion.texto") }}
                </v-btn>
              </v-col>
              <v-col cols="auto" v-if="isMobile" class="pl-2">
                <v-menu
                  offset-y
                  min-width="100vw"
                  tile
                  content-class="elevation-0"
                  nudge-top="-12px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on="on" v-bind="attrs" small>
                      <svg-icon name="ag-menu" color="overlay"></svg-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="boton in botones"
                      :key="`boton-menu-${boton.id}`"
                      @click="buttonScrollTo(boton.to)"
                      dense
                    >
                      <v-list-item-subtitle
                        class="text-center letter-spacing font-size-13 font-weight-medium dialogDark--text poppins"
                      >
                        {{ boton.name }}
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Vue } from "vue-property-decorator";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class AppBarPagina extends Vue {
  get botones(): Array<{ id: number; to: string; name: string }> {
    return [
      {
        id: 1,
        to: "inicio",
        name: this.$t("pagina.app-bar.botones-rutas.0") as string,
      },
      {
        id: 2,
        to: "que-es",
        name: this.$t("pagina.app-bar.botones-rutas.1") as string,
      },
      {
        id: 3,
        to: "caracteristicas",
        name: this.$t("pagina.app-bar.botones-rutas.2") as string,
      },
      {
        id: 4,
        to: "testimonios",
        name: this.$t("pagina.app-bar.botones-rutas.3") as string,
      },
      {
        id: 5,
        to: "contacto",
        name: this.$t("pagina.app-bar.botones-rutas.4") as string,
      },
    ];
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }

  @Emit("click:logout")
  onClickLogout(): void {
    return;
  }

  buttonScrollTo(to: string): void {
    this.$vuetify.goTo(`#${to}`);
  }

  goLogin(): void {
    this.$router.push({ name: "login" });
  }
}
</script>

<style scoped>
.button-login {
  border-radius: 23px !important;
  border: 2px solid black;
}

.border-radius-23 {
  border-radius: 23px !important;
}

.app-bar >>> .v-toolbar__content {
  padding: 0 !important;
}

.letter-spacing {
  letter-spacing: 1px;
}
</style>
