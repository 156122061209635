<template>
  <v-row no-gutters justify="space-between" align="end">
    <v-col cols="8">
      <v-row no-gutters align="end">
        <v-col cols="4" class="pr-2">
          <v-text-field
            hide-details
            dense
            solo
            clearable
            color="text"
            :placeholder="$t('desechos.search.placeholder')"
            class="metropolis rounded-input elevation-input"
            v-model="search"
          >
            <template v-slot:append>
              <svg-icon name="ag-search" color="text"></svg-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto" v-if="mostrarBotonAgregar">
      <add-button
        icon="desechos"
        :disabled="buttonDisabled"
        @click="onClickAgregar"
      >
        {{ $t("desechos.boton-add.text") }}
      </add-button>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { FiltrosDesecho } from "@/typings/components/desechos/filtros";
//componentes

import AddButton from "@/components/custom/AddButton.vue";
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    AddButton,
    SvgIcon,
  },
})
export default class FiltrosDesechos extends Vue {
  @VModel() filtros!: FiltrosDesecho;
  @Prop() readonly buttonDisabled!: boolean;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonAgregar(): boolean {
    return this.permisos.desechos !== "ver";
  }

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }
}
</script>
