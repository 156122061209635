<template>
  <v-text-field
    hide-details
    dense
    solo
    clearable
    color="text"
    :placeholder="placeholder"
    class="metropolis rounded-input elevation-input"
    v-model="buscar"
  >
    <template v-slot:append>
      <svg-icon
        name="ag-search"
        color="text"
      ></svg-icon>
    </template>
  </v-text-field>
</template>

<script lang="ts">
//composition
import {defineComponent, computed} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "SearchTextField",
  components: {
    SvgIcon
  },
  emits: [
    "input"
  ],
  props: {
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type:String,
      required: true
    }
  },
  setup(props, ctx) {
    const buscar = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    return {
      buscar
    };
  }
})
</script>
