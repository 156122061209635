<template>
  <v-row no-gutters align="end" justify="space-between">
    <v-col cols="8">
      <v-row no-gutters align="end">
        <v-col cols="3" class="pr-2">
          <search-text-field
            :placeholder="String($t('rastreadores.filtros.buscar'))"
            v-model="buscar"
          ></search-text-field>
        </v-col>
        <v-col cols="3" class="px-2">
          <filter-select
            :label="String($t('rastreadores.filtros.estado.label'))"
            :placeholder="String($t('rastreadores.filtros.estado.placeholder'))"
            :items="estados"
            v-model="estado"
          ></filter-select>
        </v-col>
        <v-col cols="3" class="px-2">
          <filter-select
            :label="String($t('rastreadores.filtros.tipo.label'))"
            :placeholder="String($t('rastreadores.filtros.tipo.placeholder'))"
            :items="tipos"
            v-model="tipo"
          ></filter-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto">
      <add-button
        @click="onClickAgregar"
        icon="fas-tablet-rugged"
        :disabled="rastreadorSeleccionado !== null"
      >
        {{ $t("rastreadores.filtros.boton") }}
      </add-button>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//composition
import { computed, defineComponent } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { FiltrosRastreador } from "@/typings/components/rastreadores/filtros";
import {
  EstadoRastreador,
  Rastreador,
  TipoRastreador,
} from "@/typings/store/plugins/easyFirestore/rastreadores";
//componentes
import FilterSelect from "@/components/custom/FilterSelect.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";
import AddButton from "@/components/custom/AddButton.vue";

export default defineComponent({
  name: "FiltrosRastreadores",
  components: {
    AddButton,
    FilterSelect,
    SearchTextField,
  },
  props: {
    value: {
      type: Object as () => FiltrosRastreador,
      required: true,
    },
    rastreadorSeleccionado: {
      type: Object as () => Rastreador | null,
    },
  },
  emits: ["input", "click:agregar"],
  setup(props, ctx) {
    const buscar = computed({
      get() {
        return props.value.buscar;
      },
      set(value: string) {
        const filtros = props.value;
        filtros.buscar = value ?? "";
        ctx.emit("input", filtros);
      },
    });

    const estados = computed(() => {
      return [
        {
          text: i18n.t("rastreadores.filtros.estado.values.0") as string,
          value: "habilitado",
        },
        {
          text: i18n.t("rastreadores.filtros.estado.values.1") as string,
          value: "deshabilitado",
        },
      ];
    });

    const estado = computed({
      get() {
        return props.value.estado;
      },
      set(value: EstadoRastreador | null) {
        const filtros = props.value;
        filtros.estado = value ?? null;
        ctx.emit("input", filtros);
      },
    });

    const tipos = computed(() => {
      return [
        {
          text: i18n.t("rastreadores.filtros.tipo.values.0") as string,
          value: "persona",
        },
        {
          text: i18n.t("rastreadores.filtros.tipo.values.1") as string,
          value: "vehiculo",
        },
      ];
    });

    const tipo = computed({
      get() {
        return props.value.tipo;
      },
      set(value: TipoRastreador | null) {
        const filtros = props.value;
        filtros.tipo = value ?? null;
        ctx.emit("input", filtros);
      },
    });

    const onClickAgregar = () => {
      ctx.emit("click:agregar");
    };

    return {
      buscar,
      estados,
      estado,
      tipos,
      tipo,
      onClickAgregar,
    };
  },
});
</script>
