<template>
  <v-card flat color="transparent">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="end" align="center">
        <v-col cols="auto">
          <icon-button
            :icon="isSelected ? 'fas-circle-xmark' : 'ionic-md-information-circle'"
            :color="isSelected ? 'error' : 'primary'"
            @click="onClickInfo"
          >
            {{ $t(`servicios.tabla.boton-informacion.${isSelected ? "cerrar" : "tooltip"}`) }}
          </icon-button>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { ItemTablaServicios } from "@/typings/components/servicios/tabla";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: ItemTablaServicios;
  @Prop() readonly seleccionado!: Servicio | null;

  get isSelected(): boolean {
    return (
      this.seleccionado !== null && this.seleccionado.id === this.item.data.id
    );
  }

  @Emit("click:informacion")
  onClickInfo(): ItemTablaServicios {
    return this.item;
  }
}
</script>
