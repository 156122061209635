<template>
  <v-card flat color="transparent">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="auto" :class="classes">
          {{ value }}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
//composition
import {defineComponent} from "vue";

export default defineComponent({
  name: "TableItem",
  props: {
    value: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      default: ""
    }
  }
})
</script>
