<template>
  <v-row no-gutters class="row-buttons flex-grow-0" align="end">
    <v-col cols="3">
      <v-btn
        block
        :height="tab === 0 ? '50px' : '44px'"
        depressed
        color="yellow"
        :class="{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'button-inactive': tab !== 0,
        }"
        @click="tab = 0"
      >
        {{ $t("transporte.tab.choferes.text") }}
      </v-btn>
    </v-col>
    <v-col cols="3">
      <v-btn
        color="purple"
        block
        :height="tab === 1 ? '50px' : '44px'"
        depressed
        :class="{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'button-inactive': tab !== 1,
        }"
        @click="tab = 1"
      >
        {{ $t("transporte.tab.flota.text") }}
      </v-btn>
    </v-col>
    <v-col cols="3">
      <v-btn
        color="error"
        block
        :height="tab === 2 ? '50px' : '44px'"
        depressed
        :class="{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'button-inactive': tab !== 2,
        }"
        @click="tab = 2"
      >
        {{ $t("transporte.tab.rutas.text") }}
      </v-btn>
    </v-col>
    <v-col cols="3">
      <v-btn
        color="primary"
        block
        :height="tab === 3 ? '50px' : '44px'"
        depressed
        :class="{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'button-inactive': tab !== 3,
        }"
        @click="tab = 3"
      >
        {{ $t("transporte.tab.identificadores.text") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//decoradores
import { Component, VModel, Vue } from "vue-property-decorator";

@Component
export default class TabsTransporte extends Vue {
  @VModel() tab!: number;
}
</script>

<style scoped>
.row-buttons {
  height: 50px;
}

.button {
  transition: all 150ms ease;
  opacity: 1;
}
</style>
