<template>
  <v-menu
    offset-y
    :min-width="width"
    :top="top"
    transition="slide-y-transition"
    :content-class="`elevation-black rounded-input ${top ? 'rounded-b-0' : 'rounded-t-0'}`"
  >
    <template v-slot:activator="{ attrs, on, value }">
      <v-card
        color="white"
        ref="activator-card"
        :class="`elevation-black rounded-input ${ value ? (top ? 'rounded-t-0' : 'rounded-b-0') : ''}`"
      >
        <v-card-text class="px-4 py-2">
          <v-row
            no-gutters
            align="center"
            class="metropolis text-body-1 black--text"
          >
            <v-col cols="4">
              {{ $t(`${parent}.menu.servicio.label`) }}
            </v-col>
            <v-col class="font-weight-bold text-capitalize">
              {{ nombre }}
            </v-col>
            <v-col cols="auto" v-if="servicio">
              <v-btn icon small v-on="on" v-bind="attrs" @click="openMenu">
                <svg-icon
                  class="elevation-primary"
                  :name=" value ? top ? 'ionic-md-chevron-down' : 'ionic-md-chevron-up' : 'ionic-md-information-circle'"
                  color="primary"
                  width="20px"
                  height="20px"
                ></svg-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <v-card
      flat
      :class="`rounded-input ${ top ? 'rounded-b-0 info-card-top' : 'rounded-t-0 info-card'}`"
      v-if="servicio"
    >
      <v-card-text>
        <v-row no-gutters class="text-body-1 metropolis text--text">
          <v-col cols="4">
            {{ $t(`${parent}.menu.servicio.informacion.0`) }}
          </v-col>
          <v-col class="font-weight-bold text-capitalize">
            {{ nombre }}
          </v-col>
        </v-row>
        <v-row no-gutters class="text-body-1 metropolis text--text">
          <v-col cols="4">
            {{ $t(`${parent}.menu.servicio.informacion.2`) }}
          </v-col>
          <v-col class="font-weight-bold text-capitalize">
            {{ tipo }}
          </v-col>
        </v-row>
        <v-row no-gutters class="text-body-1 metropolis text--text">
          <v-col cols="4">
            {{ $t(`${parent}.menu.servicio.informacion.3`) }}
          </v-col>
          <v-col class="font-weight-bold text-capitalize">
            {{ descripcion }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class MenuInfoServicio extends Vue {
  @Prop() readonly servicio!: Servicio | null;
  @Prop({ type: Boolean }) readonly top!: boolean;
  @Prop() readonly parent!: string;

  width = 0;

  get activatorCard(): HTMLElement | null {
    return ((this.$refs["activator-card"] as (Vue & { $el: HTMLElement }) | null)?.$el ?? null);
  }

  get noValueMessage(): string {
    return this.$t(`${this.parent}.menu.no-value-message`) as string;
  }

  get nombre(): string {
    return this.servicio?.nombre || this.noValueMessage;
  }

  get tipo(): string {
    return this.servicio?.tipo || this.noValueMessage;
  }

  get descripcion(): string {
    return this.servicio?.descripcion || this.noValueMessage;
  }

  openMenu(): void {
    const bounds = this.activatorCard?.getBoundingClientRect();
    this.width = bounds?.width ?? 0;
  }
}
</script>

<style scoped>
.info-card {
  border-top: 1px solid #d2dce1 !important;
}

.info-card-top {
  border-bottom: 1px solid #d2dce1 !important;
}
</style>
