<template>
  <div class="fill-height">
    <v-data-table
      height="calc(100% - 89px)"
      :headers="headers"
      :items="items"
      class="elevation-black white fill-height table rounded-input metropolis"
      hide-default-footer
      :items-per-page="8"
      :search="search"
    >
      <template v-slot:[`item.error`]="{ item }">
        <ItemCirculo
          :pesaje="item.pesaje"
          :errores="item.errores"
          @click:sincronizar="onClickSincronizar"
        ></ItemCirculo>
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        <ItemTabla
          :valor="item.fecha"
          :clases="getClasses(item)"
        ></ItemTabla>
      </template>
      <template v-slot:[`item.entrada`]="{ item }">
        <ItemTabla
          :valor="item.entrada"
          :clases="getClasses(item)"
        ></ItemTabla>
      </template>
      <template v-slot:[`item.salida`]="{ item }">
        <ItemTabla
          :valor="item.salida"
          :clases="getClasses(item)"
        ></ItemTabla>
      </template>
      <template v-slot:[`item.pesoNeto`]="{ item }">
        <ItemTabla
          :valor="item.pesoNeto"
          :clases="getClasses(item)"
        ></ItemTabla>
      </template>
      <template v-slot:[`item.cliente`]="{ item }">
        <ItemTabla
          :valor="item.cliente"
          :clases="getClasses(item, 'capitalize')"
        ></ItemTabla>
      </template>
      <template v-slot:[`item.patente`]="{ item }">
        <ItemTabla
          :valor="item.patente"
          :clases="getClasses(item, 'uppercase')"
        ></ItemTabla>
      </template>
      <template v-slot:[`item.opciones`]="{ item }">
        <OpcionesTabla
          :item="item"
          :seleccionado="pesaje"
          @click:anular="onClickAnular"
          @click:informacion="onClickInformacion"
          :mostrar-boton-anular="mostrarBotonAnular"
        ></OpcionesTabla>
      </template>
      <template v-slot:footer="{ props }">
        <data-table-footer :value="props"></data-table-footer>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
// Vue
import {computed, defineComponent} from "vue";

// Composables
import {useI18n} from "@/composables/i18n";

// Tipos
import {Item} from "@/typings/components/pesajes/tabla";
import {Permisos} from "@/typings/store/modules/permisos";
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";
import {Clientes} from "@/typings/store/plugins/easyFirestore/clientes";
import {Vehiculo} from "@/typings/store/plugins/easyFirestore/vehiculos";
import {Servicios} from "@/typings/store/plugins/easyFirestore/servicios";

//Componentes
import ItemTabla from "@/components/pesajes/tabla/Item.vue";
import OpcionesTabla from "@/components/pesajes/tabla/Opciones.vue";
import ItemCirculo from "@/components/pesajes/tabla/ItemCirculo.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

export default defineComponent({
  name: "TablaPesajes",
  components: {
    ItemTabla,
    ItemCirculo,
    OpcionesTabla,
    DataTableFooter,
  },
  props: {
    pesajes: {
      required: true,
      type: Array as () => Pesaje[],
    },
    servicios: {
      required: true,
      type: Object as () => Servicios,
    },
    clientes: {
      required: true,
      type: Object as () => Clientes,
    },
    search: {
      type: String,
      required: true,
    },
    pesaje: {
      type: Object as () => Pesaje | null,
    },
    permisos: {
      required: true,
      type: Object as () => Permisos,
    },
  },
  emits: [
    "click:anular",
    "click:informacion",
    "click:sincronizar",
  ],
  setup(props, ctx) {
    // Composables

    const {t} = useI18n();

    // Computed

    const mostrarBotonAnular = computed(() => props.permisos.pesajes === "eliminar");

    const headers = computed(() => [{
      text: "",
      value: "error",
      sortable: false,
    }, {
      value: "fecha",
      text: text("headers.0"),
    }, {
      value: "entrada",
      text: text("headers.1"),
    }, {
      value: "salida",
      text: text("headers.2"),
    }, {
      value: "patente",
      text: text("headers.3"),
    }, {
      value: "cliente",
      text: text("headers.4"),
    }, {
      value: "pesoNeto",
      text: text("headers.5"),
    }, {
      text: "",
      sortable: false,
      value: "opciones",
    }]);

    const items = computed(() => {
      return props.pesajes.map((pesaje) => {
        const fecha = pesaje.entrada?.fecha ? pesaje.entrada.fecha.toDate().format("DD-MM-YYYY") : "-";
        const entrada = pesaje.entrada?.fecha ? pesaje.entrada?.fecha.toDate().format("HH:mm") : "-";
        const salida = pesaje.salida?.fecha ? pesaje.salida?.fecha.toDate().format("HH:mm") : "-";
        const patente = patenteVehiculo(pesaje.vehiculo ?? "-");
        const mayoresCero = pesaje.entrada.peso > 0 && pesaje.salida.peso > 0;
        const peso = pesaje.entrada.peso - pesaje.salida.peso;
        const pesoFinal = pesaje.servicio?.tipo === "Egreso" ? peso * -1 : peso;
        const pesoNeto = mayoresCero ? pesoFinal.toCurrency() : "-";
        const errores: Array<string> = [];
        if (errorCliente(pesaje)) {
          const error = text("errores.0");
          errores.push(error);
        }
        if (errorServicio(pesaje)) {
          const error = text("errores.1");
          errores.push(error);
        }
        if (errorCapacidad(pesaje)) {
          const error = text("errores.2");
          errores.push(error);
        }
        if (errorCampos(pesaje)) {
          const error = text("errores.3");
          errores.push(error);
        }
        if (errorImg(pesaje)) {
          const error = text("errores.4");
          errores.push(error);
        }
        const cliente = pesaje.cliente?.nombre || "-";
        return {
          fecha,
          salida,
          pesaje,
          errores,
          entrada,
          patente,
          cliente,
          pesoNeto,
        };
      });
    });

    // Methods

    const text = (key: string) => t(`pesajes.tabla.${key}`) as string;

    const patenteVehiculo = (val: Vehiculo | string) => {
      if (typeof val === "string") return val;
      return val.patente;
    };

    const errorCliente = (pesaje: Pesaje) => {
      const id = pesaje?.cliente?.id;
      return !id || !props.clientes || !props.clientes[id];
    };

    const errorServicio = (pesaje: Pesaje) => {
      const id = pesaje?.servicio?.id;
      return !id || !props.servicios || !props.servicios[id];
    };

    const errorCapacidad = (pesaje: Pesaje) => {
      return !pesaje?.capacidad || isNaN(pesaje.capacidad) || pesaje.capacidad <= 0;
    };

    const errorCampos = (pesaje: Pesaje) => {
      if (!pesaje?.vehiculo || !pesaje?.desecho || !pesaje?.ruta) return true;
      if (!pesaje?.entrada?.fecha || !pesaje?.entrada?.chofer) return true;
      if (!pesaje.entrada.peso || isNaN(pesaje.entrada.peso) || pesaje.entrada.peso <= 0) return true;
      if (!pesaje?.salida?.fecha || !pesaje?.salida?.chofer) return true;
      return !pesaje.salida.peso || isNaN(pesaje.salida.peso) || pesaje.salida.peso <= 0;
    };

    const errorImg = (pesaje: Pesaje) => {
      const id = pesaje.cliente?.id;
      const cliente = props.clientes[id ?? ""];
      if (cliente && cliente.tipo?.toLowerCase() === "municipal") {
        return pesaje.fotos.length === 0;
      }
      return false;
    };

    const getClasses = ({pesaje}: Item, textTranform?: "uppercase" | "capitalize") => {
      const seleccionado = props.pesaje?.id === pesaje.id;
      const classes = ["pa-0 metropolis text-center text-body-1"];
      if (textTranform === "uppercase") classes.push("text-uppercase");
      if (textTranform === "capitalize") classes.push("text-capitalize");
      if (seleccionado) classes.push("primary--text font-weight-bold");
      return classes.join(" ");
    };

    // Emits

    const onClickInformacion = ({pesaje}: Item) => {
      ctx.emit("click:informacion", pesaje);
    };

    const onClickAnular = ({pesaje}: Item) => {
      ctx.emit("click:anular", pesaje);
    };

    const onClickSincronizar = (pesaje: Pesaje) => {
      ctx.emit("click:sincronizar", pesaje);
    };

    return {
      // Computed
      items,
      headers,
      mostrarBotonAnular,
      // Methods
      text,
      getClasses,
      // Emits
      onClickAnular,
      onClickSincronizar,
      onClickInformacion,
    };
  },
});
</script>

<style scoped>
.line-break {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
