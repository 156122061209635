<template>
  <v-btn
    height="48px"
    color="primary-light"
    depressed
    :class="`rounded-input ${disabled ? 'button-disabled button-inactive' : ''}`"
    large
    :ripple="false"
    @click="onClick"
  >
    <v-row no-gutters justify="center" align="center">
      <v-col cols="auto" class="pr-2" v-if="icon">
        <svg-icon
          :name="icon"
          width="20px"
          height="20px"
          color="primary"
        ></svg-icon>
      </v-col>
      <v-col class="metropolis font-weight-bold primary--text text-body-1 text-initial">
        <slot></slot>
      </v-col>
    </v-row>
  </v-btn>
</template>

<script lang="ts">
//composition
import {defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "AddButton",
  components: {
    SvgIcon
  },
  emits: [
    "click"
  ],
  props: {
    icon: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const onClick = () => {
      ctx.emit("click");
    };

    return {
      onClick
    };
  }
});
</script>
