<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="row-buttons flex-grow-0" align="end">
      <v-col cols="1">
        <v-btn
          block
          :height="tab === 0 ? '50px' : '44px'"
          depressed
          color="yellow"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 0 ? 'button-inactive' : ''
          }`"
          @click="tab = 0"
        >
          {{ $t("monitoreo.tab.vehiculos.text") }}
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn
          block
          :height="tab === 1 ? '50px' : '44px'"
          depressed
          color="purple"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 1 ? 'button-inactive' : ''
          }`"
          @click="tab = 1"
        >
          {{ $t("monitoreo.tab.desechos.text") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-tabs-items class="fill-height" v-model="tab">
          <v-tab-item class="fill-height pb-9 background">
            <TabVehiculo
              :fechas="fechas"
              :periodo="periodo"
              :pesajes="arrayPesajes"
            ></TabVehiculo>
          </v-tab-item>
          <v-tab-item class="fill-height pb-9 background">
            <TabDesechos
              :fechas="fechas"
              :periodo="periodo"
              :arrayPesajes="arrayPesajes"
              :unidad-desechos="unidadDesechos"
            ></TabDesechos>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// composition-api
import { defineComponent, PropType, computed } from "vue";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import TabVehiculo from "@/components/monitoreo/vehiculos/Tab.vue";
import TabDesechos from "@/components/monitoreo/desechos/TabDesechos.vue";

export default defineComponent({
  components: {
    TabVehiculo,
    TabDesechos,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    arrayPesajes: {
      type: Array as PropType<Pesaje[]>,
      required: true,
    },
    periodo: {
      type: Number as PropType<number>,
      required: true,
    },
    fechas: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    unidadDesechos: {
      type: String as PropType<"t"| "kg" | "m3">,
      required: true,
    },
  },
  emits: ["input"],
  setup(props, ctx) {
    const tab = computed({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });

    return { tab };
  },
});
</script>
