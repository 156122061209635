<template>
  <div class="d-flex flex-column fill-height">
    <v-row align="center" justify="center" no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("planes.informacion.titulo") }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('planes.informacion.labels.0'))"
              :value="nombre"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('planes.informacion.labels.1'))"
              :value="tipo"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('planes.informacion.labels.2'))"
              :value="precio"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('planes.informacion.labels.3'))"
              :value="tipoPago"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('planes.informacion.labels.4'))"
              :value="descuento"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('planes.informacion.labels.5'))"
              :value="descripcion"
            ></information-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0" justify="center">
      <v-col cols="4" class="pr-2">
        <action-button
          block
          color="warning"
          icon="ag-edit"
          @click="onClickEditar"
        >
          {{ $t("planes.informacion.boton-editar") }}
        </action-button>
      </v-col>
      <v-col cols="4" class="pl-2">
        <action-button
          block
          color="error"
          icon="mdi-delete"
          @click="onClickEliminar"
        >
          {{ $t("planes.informacion.boton-eliminar") }}
        </action-button>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//composition
import { computed, defineComponent } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Plan } from "@/typings/store/plugins/easyFirestore/planes";
//componentes
import InformationText from "@/components/custom/InformationText.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "InformacionPlan",
  components: {
    ActionButton,
    InformationText,
  },
  emits: ["click:editar", "click:eliminar"],
  props: {
    plan: {
      type: Object as () => Plan,
      required: true,
    },
  },
  setup(props, ctx) {
    const noRegistrado = computed(() => {
      return i18n.t("planes.informacion.no-registrado") as string;
    });

    const tipos = computed(() => {
      return {
        ["plan a"]: i18n.t("planes.informacion.tipos.0") as string,
        ["plan b"]: i18n.t("planes.informacion.tipos.1") as string,
        ["plan c"]: i18n.t("planes.informacion.tipos.2") as string,
        ["plan d"]: i18n.t("planes.informacion.tipos.3") as string,
      };
    });

    const tiposPago = computed(() => {
      return {
        ["mensual"]: i18n.t("planes.informacion.tipos-pago.0") as string,
        ["anual"]: i18n.t("planes.informacion.tipos-pago.1") as string,
      };
    });

    const nombre = computed(() => {
      return props.plan.nombre || noRegistrado.value;
    });

    const tipo = computed(() => {
      return props.plan.tipo
        ? tipos.value[props.plan.tipo]
        : noRegistrado.value;
    });

    const precio = computed(() => {
      return props.plan.precio?.toCurrency("$") || noRegistrado.value;
    });

    const tipoPago = computed(() => {
      return props.plan.tipoPago
        ? tiposPago.value[props.plan.tipoPago]
        : noRegistrado.value;
    });

    const descuento = computed(() => {
      return props.plan.descuento || noRegistrado.value;
    });

    const descripcion = computed(() => {
      return props.plan.descripcion || noRegistrado.value;
    });

    const onClickEditar = () => {
      ctx.emit("click:editar", props.plan);
    };

    const onClickEliminar = () => {
      ctx.emit("click:eliminar", props.plan);
    };

    return {
      nombre,
      tipo,
      precio,
      tipoPago,
      descuento,
      descripcion,
      onClickEditar,
      onClickEliminar,
    };
  },
});
</script>
