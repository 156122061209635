<template>
  <v-container class="py-0 my-0 pt-2">
    <v-row class="py-0 my-0" justify="center">
      <v-col v-if="mostrarBotonPermisos" cols="4" class="py-0">
        <v-btn
          block
          large
          class="rounded-input gradient-secondary elevation-secondary"
          :loading="guardando"
          @click="onClickPermisos"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon color="white" name="mdi-lock"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("users.buttons.permissions") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col v-if="mostrarBotonGuardar" cols="4" class="py-0">
        <v-btn
          block
          large
          :class="`rounded-input gradient-primary elevation-primary ${
            disabledGuardar ? 'button-disabled button-inactive' : ''
          }`"
          :loading="guardando"
          @click="onClickGuardar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon color="white" name="ag-save"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("users.buttons.save") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col v-if="mostrarBotonEditar" cols="4" class="py-0">
        <v-btn
          block
          color="warning"
          large
          :class="`rounded-input gradient-warning elevation-warning ${
            disabledEditar ? 'button-disabled button-inactive' : ''
          }`"
          :loading="guardando"
          @click="onClickEditar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon color="white" name="ag-edit"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("users.buttons.edit") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col v-if="mostrarBotonEliminar" cols="4" class="py-0">
        <v-btn
          block
          color="error"
          large
          class="rounded-input gradient-error elevation-error"
          :loading="guardando"
          @click="onClickEliminar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon color="white" name="eliminar"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("users.buttons.delete") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
// decoradores
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class BotonesUsuario extends Vue {
  @Prop() readonly estado!: number;
  @Prop() readonly guardando?: boolean;
  @Prop() readonly hidePermisos?: boolean;
  @Prop() readonly hideGuardar?: boolean;
  @Prop() readonly hideEliminar?: boolean;
  @Prop() readonly hideEditar?: boolean;
  @Prop() readonly disabledPermisos?: boolean;
  @Prop() readonly disabledGuardar?: boolean;
  @Prop() readonly disabledEliminar?: boolean;
  @Prop() readonly disabledEditar?: boolean;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonPermisos(): boolean {
    return (
      !this.hidePermisos &&
      this.permisos.usuarios !== "ver" &&
      this.estado === 1
    );
  }

  get mostrarBotonGuardar(): boolean {
    return !this.hideGuardar && this.permisos.usuarios !== "ver";
  }

  get mostrarBotonEditar(): boolean {
    return !this.hideEditar && this.permisos.usuarios !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return (
      !this.hideEliminar &&
      this.permisos.usuarios === "eliminar" &&
      this.estado === 1
    );
  }

  @Emit("click-permisos")
  onClickPermisos(): void {
    return;
  }

  @Emit("click-editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click-guardar")
  onClickGuardar(): void {
    return;
  }

  @Emit("click-eliminar")
  onClickEliminar(): void {
    return;
  }
}
</script>
