<template>
  <v-card color="dialog" class="rounded-input">
    <v-card-text class="py-8">
      <v-row dense no-gutters justify="center">
        <v-col
          cols="12"
          class="text-center white--text mensaje-confirmacion metropolis"
        >
          {{ text ? text : $t("mensaje-confirmacion.fallback") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center mt-8">
        <v-col cols="6" class="pr-2">
          <v-btn
            block
            height="59px"
            class="gradient-secondary elevation-dark white--text font-weight-bold metropolis text-initial rounded-input text-body-1"
            @click="onClickSi"
          >
            {{ $t("mensaje-confirmacion.boton-si.texto") }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="pl-2">
          <v-btn
            block
            height="59px"
            class="gradient-error elevation-dark white--text font-weight-bold metropolis text-initial rounded-input text-body-1"
            @click="onClickNo"
          >
            {{ $t("mensaje-confirmacion.boton-no.texto") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
//composition
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmationDialog",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  emits: ["click:yes", "click:no"],
  setup(props, ctx) {
    const onClickSi = (): void => {
      ctx.emit("click:yes");
    };

    const onClickNo = (): void => {
      ctx.emit("click:no");
    };

    return {
      onClickSi,
      onClickNo,
    };
  },
});
</script>

<style scoped>
.mensaje-confirmacion {
  font-size: 39px;
  line-height: 49px;
  font-weight: 800;
}
</style>
