<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="row-buttons flex-grow-0" align="end">
      <v-col cols="6" xl="5">
        <v-btn
          block
          :height="tab === 0 ? '50px' : '44px'"
          depressed
          color="yellow"
          :class="{
            'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
            'button-inactive': tab !== 0,
            'button-disabled': estado === 'salida',
          }"
          @click="tab = 0"
        >
          {{ $t("bascula.entrada-salida.tab.entrada.title") }}
        </v-btn>
      </v-col>
      <v-col cols="6" xl="5">
        <v-btn
          color="purple"
          block
          :height="tab === 1 ? '50px' : '44px'"
          depressed
          :class="{
            'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
            'button-inactive': tab !== 1,
            'button-disabled': estado === 'entrada',
          }"
          @click="tab = 1"
        >
          {{ $t("bascula.entrada-salida.tab.salida.title") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card
          color="#FFFFFF"
          height="100%"
          class="elevation-black rounded-input rounded-tl-0"
        >
          <v-card-text class="pa-0">
            <v-tabs-items v-model="tab" class="rounded-input rounded-tl-0">
              <v-tab-item>
                <v-row
                  no-gutters
                  class="px-4 pt-2 metropolis text-body-1 black--text"
                  justify="space-between"
                >
                  <v-col cols="auto" class="font-weight-bold">
                    {{ $t("bascula.entrada-salida.tab.entrada.fecha.label") }}
                  </v-col>
                  <v-col cols="auto">
                    {{
                      fechaEntrada
                        ? fechaEntrada.toDate().format("DD-MM-YYYY")
                        : "-"
                    }}
                  </v-col>
                  <v-col cols="auto" class="font-weight-bold">
                    {{ $t("bascula.entrada-salida.tab.entrada.hora.label") }}
                  </v-col>
                  <v-col cols="auto">
                    {{
                      fechaEntrada
                        ? fechaEntrada.toDate().format("HH:mm:ss")
                        : "-"
                    }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-4">
                  <v-col>
                    <v-card
                      color="dialogDark"
                      class="rounded-input elevation-black"
                    >
                      <v-card-text
                        class="pa-2 text-center white--text font-weight-bold text-h6 metropolis"
                      >
                        {{ pesoEntrada }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-4">
                  <v-col>
                    <v-btn
                      :color="fijado ? 'primary-light' : 'disabled'"
                      depressed
                      block
                      large
                      class="rounded-input"
                      :disabled="disabledFijar"
                      @click="onClickFijar"
                    >
                      <v-row no-gutters align="center" justify="center">
                        <v-col cols="auto" class="pr-2">
                          <svg-icon
                            name="ag-check"
                            :color="fijado ? 'primary' : 'dialogDark'"
                          ></svg-icon>
                        </v-col>
                        <v-col
                          cols="auto"
                          :class="`${
                            fijado ? 'primary--text' : 'dialogDark--text'
                          } metropolis text-initial font-weight-bold text-h6`"
                        >
                          {{ textoBotonFijar }}
                        </v-col>
                      </v-row>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-4">
                  <v-col>
                    <v-card color="white" class="rounded-input elevation-black">
                      <v-card-text class="pa-2">
                        <v-row no-gutters align="center">
                          <v-col
                            cols="3"
                            class="text-body-1 metropolis font-weight-bold black--text"
                          >
                            {{
                              $t(
                                "bascula.entrada-salida.tab.entrada.chofer.label"
                              )
                            }}
                          </v-col>
                          <v-col cols="9">
                            <v-combobox
                              solo
                              flat
                              dense
                              return-object
                              class="metropolis"
                              :placeholder="
                                $t(
                                  'bascula.entrada-salida.tab.entrada.chofer.placeholder'
                                )
                              "
                              item-text="nombre"
                              hide-details="auto"
                              :items="arrayChoferes"
                              :error-messages="choferEntradaErrors"
                              v-model="choferEntrada"
                              :menu-props="{
                                contentClass: 'rounded-input elevation-black',
                                transition: 'slide-y-transition',
                              }"
                            >
                              <template v-slot:item="{ item }">
                                <span
                                  class="metropolis darker--text text-capitalize"
                                >
                                  {{ item.nombre }}
                                </span>
                              </template>
                              <template v-slot:selection="{ item }">
                                <span
                                  class="metropolis darker--text text-capitalize text-truncate"
                                >
                                  {{ item.nombre }}
                                </span>
                              </template>
                              <template v-slot:append>
                                <svg-icon
                                  name="ionic-md-arrow-dropdown"
                                  color="text"
                                  width="12px"
                                  height="12px"
                                ></svg-icon>
                              </template>
                            </v-combobox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-2 pl-6 pr-4" align="center">
                  <v-col
                    cols="3"
                    class="metropolis font-weight-bold text-body-1 black--text"
                  >
                    {{ $t("bascula.entrada-salida.tab.entrada.detalle.label") }}
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      hide-details
                      dense
                      outlined
                      :placeholder="
                        $t(
                          'bascula.entrada-salida.tab.entrada.detalle.placeholder'
                        )
                      "
                      color="text"
                      class="border-input rounded-input"
                      v-model="observacionEntrada"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row
                  no-gutters
                  class="px-4 pt-2 metropolis text-body-1 black--text"
                  justify="space-between"
                >
                  <v-col cols="auto" class="font-weight-bold">
                    {{ $t("bascula.entrada-salida.tab.salida.fecha.label") }}
                  </v-col>
                  <v-col cols="auto">
                    {{
                      fechaSalida
                        ? fechaSalida.toDate().format("YYYY-MM-DD")
                        : "-"
                    }}
                  </v-col>
                  <v-col cols="auto" class="font-weight-bold">
                    {{ $t("bascula.entrada-salida.tab.salida.hora.label") }}
                  </v-col>
                  <v-col cols="auto">
                    {{
                      fechaSalida
                        ? fechaSalida.toDate().format("HH:mm:ss")
                        : "-"
                    }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-4">
                  <v-col>
                    <v-card
                      color="dialogDark"
                      class="rounded-input elevation-black"
                    >
                      <v-card-text
                        class="pa-2 text-center white--text font-weight-bold text-h6 metropolis"
                      >
                        {{ pesoSalida }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-4">
                  <v-col>
                    <v-btn
                      :color="fijado ? 'primary-light' : 'disabled'"
                      depressed
                      block
                      large
                      :disabled="disabledFijar"
                      @click="onClickFijar"
                    >
                      <v-row no-gutters align="center" justify="center">
                        <v-col cols="auto" class="pr-2">
                          <svg-icon
                            name="ag-check"
                            :color="fijado ? 'primary' : 'dialogDark'"
                          ></svg-icon>
                        </v-col>
                        <v-col
                          cols="auto"
                          :class="`${
                            fijado ? 'primary--text' : 'dialogDark--text'
                          } metropolis text-initial font-weight-bold text-h6`"
                        >
                          {{ textoBotonFijar }}
                        </v-col>
                      </v-row>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-4">
                  <v-col>
                    <v-card color="white" class="rounded-input elevation-black">
                      <v-card-text class="pa-2">
                        <v-row no-gutters align="center">
                          <v-col
                            cols="3"
                            class="text-body-1 metropolis font-weight-bold black--text"
                          >
                            {{
                              $t(
                                "bascula.entrada-salida.tab.salida.chofer.label"
                              )
                            }}
                          </v-col>
                          <v-col cols="9">
                            <v-combobox
                              solo
                              flat
                              dense
                              return-object
                              class="metropolis"
                              :placeholder="
                                $t(
                                  'bascula.entrada-salida.tab.salida.chofer.placeholder'
                                )
                              "
                              item-text="nombre"
                              hide-details="auto"
                              :items="arrayChoferes"
                              v-model="choferSalida"
                              :menu-props="{
                                contentClass: 'rounded-input elevation-black',
                                transition: 'slide-y-transition',
                              }"
                            >
                              <template v-slot:item="{ item }">
                                <span
                                  class="metropolis darker--text text-capitalize"
                                >
                                  {{ item.nombre }}
                                </span>
                              </template>
                              <template v-slot:selection="{ item }">
                                <span
                                  class="metropolis darker--text text-capitalize text-truncate"
                                >
                                  {{ item.nombre }}
                                </span>
                              </template>
                              <template v-slot:append>
                                <svg-icon
                                  name="ionic-md-arrow-dropdown"
                                  color="text"
                                  width="12px"
                                  height="12px"
                                ></svg-icon>
                              </template>
                            </v-combobox>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-2 pl-6 pr-4" align="center">
                  <v-col
                    cols="3"
                    class="metropolis font-weight-bold text-body-1 black--text"
                  >
                    {{ $t("bascula.entrada-salida.tab.salida.detalle.label") }}
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                      hide-details
                      dense
                      outlined
                      :placeholder="
                        $t(
                          'bascula.entrada-salida.tab.salida.detalle.placeholder'
                        )
                      "
                      color="text"
                      class="border-input rounded-input"
                      v-model="observacionSalida"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import {
  EstadoPesaje,
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class EntradaSalidaBascula extends Vue {
  @VModel() pesajeData!: PesajeData;
  @Prop() readonly pesaje!: Pesaje | null;
  @Prop() readonly arrayChoferes!: Array<Chofer>;
  @Prop() readonly fijado!: boolean;
  @Prop() readonly disabledFijar!: boolean;
  @Prop() readonly choferEntradaErrors!: Array<string>;

  created(): void {
    if (this.pesaje) {
      this.onEstadoChange(this.pesaje.estado);
    }
  }

  tab = 0;

  get textoBotonFijar(): string {
    const flag = this.fijado ? "fijado" : "fijar";
    return this.$t(
      `bascula.entrada-salida.tab.entrada.boton-fijar.${flag}`
    ) as string;
  }

  get fechaEntrada(): string {
    const fecha =
      this.pesajeData.fechaEntrada ?? (this.pesaje?.entrada?.fecha || "");
    return fecha;
  }

  get pesoEntrada(): string {
    const peso =
      this.pesajeData.pesoEntrada ?? (this.pesaje?.entrada?.peso || 0);
    return `${peso} kg`;
  }

  get choferEntrada(): Chofer | null {
    const chofer =
      this.pesajeData.choferEntrada || this.pesaje?.entrada?.chofer || null;
    return chofer;
  }
  set choferEntrada(value: Chofer | null) {
    let chofer: Chofer | null;
    if (typeof value === "string") {
      chofer = {
        nombre: (value as string).toLowerCase(),
        rut: "",
        cargo: "",
        fechaNacimiento: "",
        fechaContratacion: "",
        contacto: "",
        estado: "habilitado",
        foto: null,
      };
    } else {
      chofer = Object.assign({}, value);
    }
    this.$set(this.pesajeData, "choferEntrada", chofer);
  }

  get observacionEntrada(): string {
    const observacion =
      this.pesajeData.observacionEntrada ||
      this.pesaje?.entrada?.observacion ||
      "";
    return observacion;
  }
  set observacionEntrada(value: string) {
    this.$set(this.pesajeData, "observacionEntrada", value);
  }

  get fechaSalida(): string {
    const fecha =
      this.pesajeData.fechaSalida ?? (this.pesaje?.salida?.fecha || "");
    return fecha;
  }

  get pesoSalida(): string {
    const peso = this.pesajeData.pesoSalida ?? (this.pesaje?.salida?.peso || 0);
    return `${peso} kg`;
  }

  get choferSalida(): Chofer | null {
    const chofer =
      this.pesajeData.choferSalida || this.pesaje?.salida?.chofer || null;
    return chofer;
  }
  set choferSalida(value: Chofer | null) {
    let chofer: Chofer | null;
    if (typeof value == "string") {
      chofer = {
        nombre: (value as string).toLowerCase(),
        rut: "",
        cargo: "",
        fechaNacimiento: "",
        fechaContratacion: "",
        contacto: "",
        estado: "habilitado",
        foto: null,
      };
    } else {
      chofer = Object.assign({}, value);
    }
    this.$set(this.pesajeData, "choferSalida", chofer);
  }

  get observacionSalida(): string {
    const observacion =
      this.pesajeData.observacionSalida ||
      this.pesaje?.salida?.observacion ||
      "";
    return observacion;
  }
  set observacionSalida(value: string) {
    this.$set(this.pesajeData, "observacionSalida", value);
  }

  get estado(): EstadoPesaje {
    return this.pesajeData.estado || this.pesaje?.estado || "entrada";
  }

  @Watch("estado")
  onEstadoChange(value: EstadoPesaje): void {
    if (value === "salida") {
      this.tab = 1;
    } else {
      this.tab = 0;
    }
  }

  @Emit("click:fijar")
  onClickFijar(): void {
    return;
  }
}
</script>

<style scoped>
.row-buttons {
  height: 50px;
}

.button {
  transition: all 150ms ease;
  opacity: 1;
}
</style>
