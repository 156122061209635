<template>
  <div class="user-information__container">
    <div @click.stop="opened = !opened">
      <svg-icon
        width="1.5rem"
        color="primary"
        height="1.5rem"
        name="fas-user"
      />
    </div>
    <transition name="slide-from-left">
      <div
        v-if="opened"
        ref="userInformationMenu"
        class="user-information__menu"
      >
        <div class="user-information__empresa">
          {{ empresa.nombre }}
        </div>
        <div class="user-information__nombre">
          {{ usuario.nombres }}
        </div>
        <div class="user-information__tipo">
          {{ usuario.tipo }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
// Vue
import {defineComponent, onUnmounted, ref} from "vue";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Empresa} from "@/typings/store/plugins/easyFirestore/empresas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "UserInformation",
  components: {
    SvgIcon,
  },
  props: {
    usuario: {
      required: true,
      type: Object as () => Usuario,
    },
    empresa: {
      required: true,
      type: Object as () => Empresa,
    },
  },
  setup() {
    //Data

    const opened = ref(false);

    const userInformationMenu = ref<HTMLDivElement | null>(null);

    // Methods

    const handleClick = (e: MouseEvent) => {
      if (opened.value && userInformationMenu.value && !userInformationMenu.value.contains(e.target as Node)) {
        opened.value = false;
      }
    };

    window.addEventListener('click', handleClick);


    onUnmounted(() => {
      window.removeEventListener('click', handleClick);
    });

    return {
      // Data
      opened,
      userInformationMenu,
    };
  },
});
</script>

<style scoped>
.user-information__container {
  position: relative;
}

.user-information__menu {
  left: 1rem;
  top: 2.75rem;
  display: grid;
  padding: 1rem;
  row-gap: 0.5rem;
  position: fixed;
  border-radius: 0.75rem;
  background-color: #ffffff;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  box-shadow: 0 4px 7px rgba(53, 79, 87, 0.2);
}

.user-information__empresa {
  color: #283E44;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
}

.user-information__tipo,
.user-information__nombre {
  color: #9BABB1;
  font-size: 0.8rem;
  line-height: 1.0625rem;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
}

.slide-from-left-enter-active {
  animation-name: left-to-right;
}

.slide-from-left-leave-active {
  animation-direction: reverse;
  animation-name: left-to-right;
}

@keyframes left-to-right {
  0% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
