<template>
  <v-card
    color="dialog"
    class="rounded-input elevation-dark"
  >
    <v-card-text class="py-8">
      <v-row no-gutters justify="center">
        <v-col cols="auto">
          <v-icon
            color="secondary"
            size="125px"
            v-if="icon.startsWith('mdi')"
          >
            {{ icon }}
          </v-icon>
          <svg-icon
            v-else
            :name="icon"
            color="secondary"
            width="125px"
            height="125px"
          ></svg-icon>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3" justify="center">
        <v-col cols="10" class="text-center white--text metropolis mensaje-exito">
          {{ text }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">

//composition
import {onMounted, defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "SuccessDialog",
  components: {
    SvgIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      default: 3000
    }
  },
  emits: [
    "close"
  ],
  setup(props, ctx) {
    onMounted(() => {
      window.setTimeout(() => {
        ctx.emit("close");
      }, props.duration);
    });
  }
})
</script>

<style scoped>
.mensaje-exito {
  font-size: 39px !important;
  line-height: 49px !important;
  font-weight: 800 !important;
}
</style>
