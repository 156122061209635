import { render, staticRenderFns } from "./BotonCapturar.vue?vue&type=template&id=81ddf08a&scoped=true"
import script from "./BotonCapturar.vue?vue&type=script&lang=ts"
export * from "./BotonCapturar.vue?vue&type=script&lang=ts"
import style0 from "./BotonCapturar.vue?vue&type=style&index=0&id=81ddf08a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81ddf08a",
  null
  
)

export default component.exports