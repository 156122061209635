<template>
  <transition name="slide-from-left">
    <div
      v-if="alerta"
      @click="onClickAlerta"
      class="alerta-barredores__cotainer"
    >
      <div @click.stop="alerta = false">
        <svg-icon
          color="#ffffff"
          name="fas-circle-x"
        />
      </div>
      <div class="alerta-barredores__content">
        <div class="alerta-barredores__text">
          {{ texto }}
        </div>
        <div class="alerta-barredores__action">
          {{ accion }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
// Vue
import {computed, defineComponent, ref, watch} from "vue";

// i18n
import i18n from "@/plugins/i18n";
import {Choice, Values} from "vue-i18n";

// Helpers
import BarredoresDesconectados from "@/helpers/BarredoresDesconectados";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "AlertaBarredores",
  components: {
    SvgIcon,
  },
  props: {
    desconectados: {
      required: true,
      type: Object as () => BarredoresDesconectados
    },
  },
  emits: [
    "click:alerta",
  ],
  setup(props, ctx) {
    // Data

    const alerta = ref(false);

    // Computed

    const desconectadosWatch = computed(() => props.desconectados);

    const texto = computed(() => {
      if (props.desconectados.length === 0) return "";
      const length = props.desconectados.length;
      const nombrePrimero = props.desconectados.all[0]?.nombre ?? "";
      return tc("texto", length, {
        nombre: nombrePrimero,
        cantidad: length - 1,
      });
    });

    const accion = computed(() => {
      const length = props.desconectados.length;
      if (length === 0) return "";
      if (length === 1 && !props.desconectados.all[0]?.telefono) return "";
      return tc("accion", length);
    });

    // Methods

    const tc = (key: string, choice?: Choice, values?: Values) => i18n.tc(`alertas-barredores.alerta.${key}`, choice, values);

    const onClickAlerta = () => {
      alerta.value = false;
      if (props.desconectados.length === 1) {
        const telefono = props.desconectados.all[0]?.telefono;
        if (!telefono) return;
        window.open(`tel:${telefono}`);
      } else {
        ctx.emit("click:alerta");
      }
    };

    // Watch

    watch(desconectadosWatch, (value) => {
      if (value.length > 0) alerta.value = true;
    }, {
      deep: true,
      immediate: true,
    });

    return {
      // Data
      alerta,
      // Computed
      texto,
      accion,
      // Methods
      tc,
      onClickAlerta,
    };
  },
});
</script>

<style scoped>
.alerta-barredores__cotainer {
  left: 1rem;
  right: 1rem;
  top: 3.75rem;
  display: grid;
  padding: 0.5rem;
  position: fixed;
  column-gap: 0.5rem;
  background: #D86C6C;
  border-radius: 0.75rem;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  grid-template-columns: auto 1fr;
  transition: opacity 50ms ease-in-out;
  animation-timing-function: ease-in-out;
  box-shadow: 0 4px 7px rgba(53, 79, 87, 0.2);
}

.alerta-barredores__cotainer:active {
  opacity: 0.8;
}

.alerta-barredores__content {
  display: grid;
  row-gap: 0.5rem;
}

.alerta-barredores__text {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.1875rem;
  font-family: 'Poppins', sans-serif;
}

.alerta-barredores__action {
  color: #FFFFFF;
  font-size: 0.8rem;
  line-height: 1.0625rem;
  font-family: 'Poppins', sans-serif;
}

.slide-from-left-enter-active {
  animation-name: left-to-right;
}

.slide-from-left-leave-active {
  animation-direction: reverse;
  animation-name: left-to-right;
}

@keyframes left-to-right {
  0% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
