<template>
  <div
    class="item-dialogo__container"
  >
    <div>
      <svg-icon
        :color="color"
        width="0.75rem"
        name="ag-square"
        height="0.75rem"
      />
    </div>
    <div class="item-dialogo__numero">
      {{ barredor.numero }}.
    </div>
    <div class="item-dialogo__nombre">
      {{ barredor.nombre }}
    </div>
    <div
      v-if="barredor.telefono"
      class="item-dialogo__phone"
    >
      <a :href="`tel:${barredor.telefono}`">
        <svg-icon
          color="#00AE3C"
          width="1.25rem"
          name="fas-phone"
          height="1.25rem"
        />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import {defineComponent} from "vue";

// Tipos
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ItemDialogo",
  components: {
    SvgIcon,
  },
  props: {
    color: {
      type: String,
      require: true,
    },
    barredor: {
      required: true,
      type: Object as () => Barredor,
    },
  },
});
</script>

<style scoped>
.item-dialogo__container {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr auto;
}

.item-dialogo__numero,
.item-dialogo__nombre {
  color: #283E44;
  font-size: 1rem;
  line-height: 1.125rem;
  font-family: 'Poppins', sans-serif;
}

.item-dialogo__numero {
  padding-left: 0.75rem;
}

.item-dialogo__nombre {
  padding-left: 0.25rem;
}
</style>
