<template>
  <div class="d-flex flex-column fill-height">
    <v-row align="center" justify="center" no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("rastreadores.informacion.titulo") }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('rastreadores.informacion.labels.0'))"
              :value="imei"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('rastreadores.informacion.labels.1'))"
              :value="marca"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('rastreadores.informacion.labels.2'))"
              :value="modelo"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('rastreadores.informacion.labels.3'))"
              :value="String(tipo)"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :label="String($t('rastreadores.informacion.labels.4'))"
              :value="empresa"
            ></information-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0" justify="center">
      <v-col cols="4" class="pr-2">
        <action-button
          block
          color="warning"
          icon="ag-edit"
          @click="onClickEditar"
        >
          {{ $t("rastreadores.informacion.boton-editar") }}
        </action-button>
      </v-col>
      <v-col cols="4" class="pl-2">
        <action-button
          block
          color="error"
          icon="mdi-delete"
          @click="onClickEliminar"
        >
          {{ $t("rastreadores.informacion.boton-eliminar") }}
        </action-button>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//composition
import { computed, defineComponent, PropType } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import { Rastreador } from "@/typings/store/plugins/easyFirestore/rastreadores";
//componentes
import InformationText from "@/components/custom/InformationText.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "InformacionRastreador",
  components: {
    ActionButton,
    InformationText,
  },
  emits: ["click:editar", "click:eliminar"],
  props: {
    rastreador: {
      type: Object as PropType<Rastreador | null>,
      required: true,
    },
    empresas: {
      type: Array as () => Array<Empresa>,
      required: true,
    },
  },
  setup(props, ctx) {
    const noRegistrado = computed(() => {
      return i18n.t("rastreadores.informacion.no-registrado") as string;
    });

    const tipos = computed(() => {
      return {
        ["persona"]: i18n.t("rastreadores.informacion.tipos.0"),
        ["vehiculo"]: i18n.t("rastreadores.informacion.tipos.1"),
      };
    });

    const imei = computed(() => {
      return props.rastreador?.imei || noRegistrado.value;
    });

    const marca = computed(() => {
      return props.rastreador?.marca || noRegistrado.value;
    });

    const modelo = computed(() => {
      return props.rastreador?.modelo || noRegistrado.value;
    });

    const tipo = computed(() => {
      return props.rastreador?.tipo
        ? tipos.value[props.rastreador.tipo]
        : noRegistrado.value;
    });

    const empresa = computed(() => {
      const empresa = props.empresas.find(
        (empresa) => empresa.id === props.rastreador?.empresa
      );
      return empresa?.nombre || noRegistrado.value;
    });

    const onClickEditar = () => {
      ctx.emit("click:editar", props.rastreador);
    };

    const onClickEliminar = () => {
      ctx.emit("click:eliminar", props.rastreador);
    };

    return {
      imei,
      marca,
      modelo,
      tipo,
      empresa,
      onClickEditar,
      onClickEliminar,
    };
  },
});
</script>
