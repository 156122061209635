<template>
  <v-dialog
    v-model="dialogo"
    width="560px"
    persistent
    content-class="rounded-input"
  >
    <v-card width="560px" class="rounded-input elevation-black" color="dialog">
      <v-container fluid class="py-7">
        <v-row justify="center" align="center">
          <v-col
            class="py-16 text-center text-h4 metropolis white--text font-weight-bold"
          >
            {{ $t("reportes.dialogo-progreso.progreso", { texto, progreso }) }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
// decoradores
import { Vue, Component, Model, Prop } from "vue-property-decorator";

@Component
export default class DialogoGenerandoReporte extends Vue {
  @Model("input", { type: Boolean }) readonly value!: boolean;

  @Prop() readonly progreso!: number;
  @Prop() readonly texto!: string;

  get dialogo(): boolean {
    return this.value;
  }

  set dialogo(value: boolean) {
    this.$emit("input", value);
  }
}
</script>
