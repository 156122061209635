<template>
  <v-navigation-drawer
    app
    clipped-left
    width="281px"
    color="#F8F9FA"
    v-model="drawer"
    class="navigation-drawer"
    :mobile-breakpoint="null"
    mini-variant-width="139px"
    :mini-variant="miniVariant"
  >
    <v-row
      no-gutters
      :class="miniVariant ? 'ml-7 mr-3 mt-9 mb-8' : 'ml-7 mr-3 pt-9 pl-5 pr-16 pb-8'"
      justify="center"
    >
      <v-col cols="auto" @click="miniVariant = !miniVariant">
        <svg-icon
          width="100%"
          height="40px"
          v-if="!miniVariant"
          :src="require('@/assets/svg/sistema/logotipo.svg')"
        />
        <svg-icon
          v-else
          width="40px"
          height="40px"
          :src="require('@/assets/svg/sistema/isotipo.svg')"
        />
      </v-col>
    </v-row>
    <ListaRutas
      :rutas="rutas"
      @logout="logout"
      v-model="miniVariant"
    ></ListaRutas>
    <v-dialog
      max-width="565"
      overlay-opacity="0.8"
      overlay-color="overlay"
      v-model="dialogoMensajeConfirmacion"
      content-class="rounded-input elevation-dark"
    >
      <MensajeConfirmacion
        @click:yes="onLogout"
        :text="$t('nav-drawer.mensaje.text')"
        @click:no="dialogoMensajeConfirmacion = false"
      ></MensajeConfirmacion>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  Emit,
  VModel,
} from "vue-property-decorator";

import {Ruta} from "@/typings/store/modules/rutas";

import SvgIcon from "@/components/custom/SvgIcon.vue";
import ListaRutas from "@/components/navDrawer/ListaRutas.vue";
import MensajeConfirmacion from "@/components/custom/ConfirmationDialog.vue";

@Component({
  components: {
    MensajeConfirmacion,
    SvgIcon,
    ListaRutas,
  },
})
export default class NavDrawer extends Vue {
  @VModel() drawer!: boolean;
  @Prop() readonly rutas!: Ruta[];

  created(): void {
    this.miniVariant = this.$vuetify.breakpoint.width < 1920;
  }

  miniVariant = true;
  dialogoMensajeConfirmacion = false;

  @Emit("logout")
  onLogout(): void {
    return;
  }

  @Emit("mini-variant-change")
  onMiniVariantChange(value: boolean): boolean {
    return value;
  }

  @Watch("miniVariant", {immediate: true})
  onWatchMiniVariant(newValue: boolean): void {
    this.onMiniVariantChange(newValue);
  }

  logout(): void {
    this.dialogoMensajeConfirmacion = true;
  }
}
</script>

<style scoped>
.navigation-drawer >>> .v-navigation-drawer__border {
  display: none;
}
</style>
