<template>
  <v-card
    height="100%"
    class="rounded-input elevation-black rounded-tl-0 mr-12 mb-9"
  >
    <v-container class="fill-height">
      <v-row no-gutters class="fill-height">
        <v-col cols="7" class="d-flex flex-column pr-2">
          <v-row no-gutters class="flex-grow-1">
            <v-col cols="6" class="pr-2">
              <TotalVehiculos
                :pesajes="pesajes"
                :periodo="periodo"
                :fechas="fechas"
              ></TotalVehiculos>
            </v-col>
            <v-col cols="6" class="pl-2">
              <TipoClienteVehiculos
                :pesajes="pesajes"
              ></TipoClienteVehiculos>
            </v-col>
          </v-row>
          <v-row no-gutters class="pt-3 flex-grow-1">
            <v-col>
              <TotalExtendidoVehiculos
                :pesajes="pesajes"
                :periodo="periodo"
                :fechas="fechas"
              ></TotalExtendidoVehiculos>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5" class="pl-2">
          <TiempoDescargaVehiculos
            :pesajes="pesajes"
            :periodo="periodo"
            :fechas="fechas"
          ></TiempoDescargaVehiculos>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
//decoradores
import {Component, Prop, Vue} from "vue-property-decorator";
//tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import TotalVehiculos from "@/components/monitoreo/vehiculos/Total.vue";
import TotalExtendidoVehiculos from "@/components/monitoreo/vehiculos/TotalExtendido.vue";
import TipoClienteVehiculos from "@/components/monitoreo/vehiculos/TipoCliente.vue";
import TiempoDescargaVehiculos from "@/components/monitoreo/vehiculos/TiempoDescarga.vue";

@Component({
  components: {
    TiempoDescargaVehiculos,
    TipoClienteVehiculos,
    TotalExtendidoVehiculos,
    TotalVehiculos
  }
})
export default class TabVehiculo extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;
  @Prop() readonly periodo!: number;
  @Prop() readonly fechas!: Array<Date>;

}
</script>
