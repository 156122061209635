<template>
  <v-row no-gutters align="end">
    <v-col cols="2" class="pr-2">
      <v-text-field
        hide-details
        dense
        solo
        clearable
        color="text"
        :placeholder="$t('pesajes.search.placeholder')"
        class="metropolis rounded-input elevation-input"
        v-model="search"
      >
        <template v-slot:append>
          <svg-icon name="ag-search" color="text"></svg-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="2" class="px-2">
      <v-row no-gutters class="px-5">
        <v-col class="text--text metropolis text-body-1">
          {{ $t("pesajes.filtro.fecha.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="slide-y-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            content-class="rounded-input elevation-black"
          >
            <template v-slot:activator="{ on, attrs, value }">
              <v-card
                v-on="on"
                v-bind="attrs"
                class="elevation-black rounded-input card-menu"
                :ripple="false"
              >
                <v-card-text
                  class="py-3 px-5 metropolis text-body-1 darker--text"
                >
                  <v-row no-gutters align="center">
                    <v-col class="text-truncate" cols="11">
                      {{ fechasMostrar }}
                    </v-col>
                    <v-col cols="1" class="text-right">
                      <svg-icon
                        :class="`select-icon ${value ? 'selected' : ''}`"
                        name="ionic-md-arrow-dropdown"
                        color="text"
                        width="12px"
                        height="12px"
                      ></svg-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
            <v-date-picker
              locale="es-es"
              v-model="fechas"
              class="metropolis calendar range"
              no-title
              range
              :allowed-dates="$allowedDatesBeforeToday"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="px-2">
      <v-row no-gutters class="px-5">
        <v-col class="text--text metropolis text-body-1">
          {{ $t("pesajes.filtro.vehiculo.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-select
            solo
            dense
            clearable
            hide-details
            return-object
            color="black"
            :placeholder="$t('pesajes.filtro.vehiculo.placeholder')"
            item-text="patente"
            class="rounded-input elevation-input metropolis"
            :items="vehiculos"
            v-model="filtroVehiculo"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis text-body-1 text-uppercase">
                {{ item.patente }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="metropolis text-body-1 text-uppercase">
                {{ item.patente }}
              </span>
            </template>
            <template v-slot:append>
              <svg-icon
                name="ionic-md-arrow-dropdown"
                width="12px"
                height="12px"
                color="text"
              ></svg-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="px-2">
      <v-row no-gutters class="px-5">
        <v-col class="metropolis text--text text-body-1">
          {{ $t("pesajes.filtro.servicios.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-select
            solo
            dense
            multiple
            clearable
            hide-details
            return-object
            color="black"
            :placeholder="$t('pesajes.filtro.servicios.placeholder')"
            item-text="nombre"
            class="rounded-input elevation-input metropolis"
            :items="servicios"
            v-model="filtroServicios"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item, attrs }">
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="attrs.inputValue"
                    hide-details
                    dense
                    class="pa-0 ma-0"
                  ></v-checkbox>
                </v-col>
                <v-col class="metropolis text-body-1 text-capitalize">
                  {{ item.nombre }}
                </v-col>
              </v-row>
            </template>
            <template v-slot:selection="{ index }">
              <v-col
                cols="auto"
                v-if="index === 0"
                class="text-capitalize metropolis text-truncate pa-0"
              >
                {{ textoFiltroServicios }}
              </v-col>
            </template>
            <template v-slot:append>
              <svg-icon
                name="ionic-md-arrow-dropdown"
                width="12px"
                height="12px"
                color="text"
              ></svg-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="px-2">
      <v-row no-gutters class="px-5">
        <v-col class="metropolis text--text text-body-1">
          {{ $t("pesajes.filtro.clientes.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-select
            solo
            dense
            multiple
            clearable
            hide-details
            return-object
            color="black"
            :placeholder="$t('pesajes.filtro.clientes.placeholder')"
            item-text="nombre"
            class="rounded-input elevation-input metropolis"
            :items="clientes"
            v-model="filtroClientes"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item, attrs }">
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="attrs.inputValue"
                    hide-details
                    dense
                    class="pa-0 ma-0"
                  ></v-checkbox>
                </v-col>
                <v-col class="metropolis text-body-1 text-capitalize">
                  {{ item.nombre }}
                </v-col>
              </v-row>
            </template>
            <template v-slot:selection="{ index }">
              <v-col
                cols="auto"
                v-if="index === 0"
                class="text-capitalize text-truncate metropolis text-body-1 pa-0"
              >
                {{ textoFiltroClientes }}
              </v-col>
            </template>
            <template v-slot:append>
              <svg-icon
                name="ionic-md-arrow-dropdown"
                width="12px"
                height="12px"
                color="text"
              ></svg-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pl-2 pb-3">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="metropolis font-weight-bold text-body-1">
          {{ $t("pesajes.filtro.switch-error.label") }}
        </v-col>
        <v-col cols="auto" class="pl-4">
          <v-switch
            v-model="filtroErrores"
            hide-details
            dense
            class="pa-0 ma-0"
            inset
            color="error"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
//tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { FiltrosPesaje } from "@/typings/components/pesajes/filtros";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosPesajes extends Vue {
  @VModel() filtros!: FiltrosPesaje;
  @Prop() readonly vehiculos!: Array<Vehiculo>;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly clientes!: Array<Cliente>;

  menu = false;

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  get fechas(): Array<string> {
    return this.filtros.fechas;
  }

  set fechas(value: Array<string>) {
    this.filtros.fechas = value;
  }

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD/MM/YY"))
      .join(" ~ ");
  }

  get filtroVehiculo(): Vehiculo | null {
    return this.filtros.vehiculo;
  }

  set filtroVehiculo(value: Vehiculo | null) {
    this.filtros.vehiculo = value;
  }

  get filtroServicios(): Array<Servicio> {
    return this.filtros.servicios;
  }

  set filtroServicios(value: Array<Servicio>) {
    this.filtros.servicios = value;
  }

  get filtroClientes(): Array<Cliente> {
    return this.filtros.clientes;
  }

  set filtroClientes(value: Array<Cliente>) {
    this.filtros.clientes = value;
  }

  get filtroErrores(): boolean {
    return this.filtros.errores;
  }

  set filtroErrores(value: boolean) {
    this.filtros.errores = value;
  }

  get textoFiltroClientes(): string {
    const length = this.filtroClientes.length;
    if (length === 1) {
      return this.filtroClientes[0].nombre;
    } else {
      return this.$tc("pesajes.filtro.clientes.selected", length);
    }
  }

  get textoFiltroServicios(): string {
    const length = this.filtroServicios.length;
    if (length === 1) {
      return this.filtroServicios[0].nombre;
    } else {
      return this.$tc("pesajes.filtro.servicios.selected", length);
    }
  }

  @Watch("fechas")
  onFechasChange(): void {
    const [fechaInicio, fechaFin] = this.fechas.map((fecha) => fecha.toDate());
    if (fechaInicio && fechaFin && fechaInicio > fechaFin) {
      this.fechas = this.fechas.reverse();
    }
  }
}
</script>
