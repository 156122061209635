<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters>
      <v-col cols="7">
        <v-row
          no-gutters
          justify="space-between"
          class="pt-10 flex-grow-0"
          align="end"
        >
          <v-col>
            <v-row no-gutters>
              <v-col class="metropolis text--text text-body-1 px-5">
                {{ $t("barredores.filtro.fecha.label") }}
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="3">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                  transition="slide-y-transition"
                  content-class="rounded-input elevation-black"
                >
                  <template v-slot:activator="{ on, attrs, value }">
                    <v-card
                      v-on="on"
                      v-bind="attrs"
                      class="elevation-black rounded-input card-menu"
                      :ripple="false"
                    >
                      <v-card-text
                        class="py-3 px-5 metropolis text-body-1 darker--text"
                      >
                        <v-row no-gutters align="center">
                          <v-col class="text-truncate" cols="11">
                            {{ fecha }}
                          </v-col>
                          <v-col cols="1" class="text-right">
                            <svg-icon
                              :class="`select-icon ${value ? 'selected' : ''}`"
                              name="ionic-md-arrow-dropdown"
                              color="text"
                              width="12px"
                              height="12px"
                            ></svg-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
                  <v-date-picker
                    v-model="fecha"
                    no-title
                    @input="menu = false"
                    class="metropolis calendar"
                    :allowed-dates="$allowedDatesBarredores"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" class="px-2 pb-2" v-if="cargando">
            <svg-icon
              :src="require('@/assets/svg/sistema/cargando.svg')"
              color="primary"
              width="32px"
              height="32px"
            ></svg-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-4">
      <v-col cols="7">
        <v-tabs-items v-model="tabMapa">
          <v-tab-item transition="fade-transition">
            <MapaBarredores
              v-if="tabMapa === 0"
              v-model="tiempoPings"
              :fecha="fecha"
              :rutas="getRutasBarredor"
              :empresa="getEmpresaUsuario"
              :seleccionados="seleccionados"
              :items="getPingsRastreadorArray"
              :barredores="getArrayBarredores"
              @change:seleccionado="cambiarSeleccionado"
            ></MapaBarredores>
          </v-tab-item>
          <v-tab-item transition="fade-transition">
            <MapaMonitoreo
              v-if="tabMapa === 1"
              v-model="pingActualMonitoreo"
              :fecha="fecha"
              :center="center"
              :data-monitoreo="dataMonitoreo"
              :pings-rastreador="getPingsRastreadorArray"
            ></MapaMonitoreo>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="5" class="pl-7 pr-12">
        <v-row no-gutters>
          <v-col>
            <TabsBarredores
              v-model="tab"
              :usuario="getUsuario"
            ></TabsBarredores>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-card
              class="rounded-input elevation-black rounded-tl-0"
              height="calc(100vh - 380px)"
            >
              <v-card-text class="fill-height pa-0">
                <v-tabs-items class="fill-height rounded-input" v-model="tab">
                  <v-tab-item class="fill-height rounded-input">
                    <ListaBarredores
                      :fecha="fecha"
                      v-model="seleccionados"
                      :tiempo-pings="tiempoPings"
                      :permisos="getPermisosUsuario"
                      :barredores="getArrayBarredores"
                      :rastreadores="getArrayRastreadores"
                      :seleccion-multiple="seleccionMultiple"
                      @click:seleccionar="cambiarSeleccionado"
                      @click:mostrar-ruta="mostrarRutaBarredor"
                      @click:nueva-ruta="abrirDialogoNuevaRuta"
                      @click:editar="abrirDialogoEditarBarredor"
                      @click:agregar="abrirDialogoAgregarBarredor"
                      @click:eliminar="abrirDialogoEliminarBarredor"
                      @input:seleccion-multiple="seleccionMultiple = $event"
                    ></ListaBarredores>
                  </v-tab-item>
                  <v-tab-item class="fill-height rounded-input">
                    <ListaRutasBarredor
                      :rutas-barredor="getArrayRutasBarredor"
                      :permisos="getPermisosUsuario"
                      @click:agregar="abrirDialogoAgregarRuta"
                      @click:informacion="abrirDialogoInformacionRuta"
                      @click:editar="abrirDialogoEditarRuta"
                      @click:eliminar="abrirDialogoEliminarRuta"
                    ></ListaRutasBarredor>
                  </v-tab-item>
                  <v-tab-item class="fill-height rounded-input">
                    <MonitoreoBarredores
                      :barredores="getArrayBarredores"
                      :pings-rastreador="getPingsRastreadorArray"
                      :ping-actual="pingActualMonitoreo"
                      :fecha="fecha"
                      v-model="dataMonitoreo"
                      @click:eliminar-ping="eliminarPingRastreador"
                      @click:eliminar-ruta-completa="eliminarRutaCompleta"
                    ></MonitoreoBarredores>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoFormularioBarredor"
      overlay-opacity="0.8"
      overlay-color="overlay"
      content-class="rounded-input elevation-dark"
      width="744px"
    >
      <FormularioBarredor
        v-if="dialogoFormularioBarredor"
        :rutas="getArrayRutasBarredor"
        :barredor="barredor"
        :barredores="getArrayBarredores"
        :rastreadores="getArrayRastreadores"
        @click:cerrar="cerrarDialogoFormularioBarredor"
        @click:guardar="guardarBarredor"
      ></FormularioBarredor>
    </v-dialog>
    <v-dialog
      v-model="dialogoFormularioRuta"
      overlay-opacity="0.8"
      overlay-color="overlay"
      content-class="rounded-input elevation-dark"
      width="1000px"
    >
      <FormularioRutaBarredor
        v-if="dialogoFormularioRuta"
        :ruta-barredor="ruta"
        :pings-rastreador="getPingsRastreadorArray"
        @click:cerrar="cerrarDialogoFormularioRuta"
        @click:guardar="guardarRuta"
      ></FormularioRutaBarredor>
    </v-dialog>
    <v-dialog
      v-model="dialogoInformacionRuta"
      overlay-opacity="0.8"
      overlay-color="overlay"
      content-class="rounded-input elevation-dark"
      width="1000px"
    >
      <InformacionRutaBarredor
        v-if="dialogoInformacionRuta"
        :ruta-barredor="ruta"
        @click:cerrar="cerrarDialogoInformacionRuta"
      ></InformacionRutaBarredor>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoNuevaRuta"
      overlay-opacity="0.8"
      overlay-color="overlay"
      content-class="rounded-input elevation-dark"
      width="1000px"
    >
      <NuevaRutaBarredor
        v-if="dialogoNuevaRuta"
        :barredores="barredoresOrdenados"
        :empresa="getEmpresaUsuario"
        @click:guardar="guardarNuevaRuta"
        @click:cerrar="cerrarDialogoNuevaRuta"
      ></NuevaRutaBarredor>
    </v-dialog>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue, Watch } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
//helpers
import "@/helpers/extensions";
import routeGuard from "@/helpers/routeGuard";
//tipos
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
import {
  TiempoPings,
  RutaBarredor,
} from "@/typings/store/plugins/easyFirestore/rutasBarredor";
import {
  PingRastreador,
  PingsRastreador,
  Posicion,
} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import MapaMonitoreo from "@/components/barredores/monitoreo/Mapa.vue";
import MapaBarredores from "@/components/barredores/Mapa.vue";
import TabsBarredores from "@/components/barredores/Tabs.vue";
import ListaBarredores from "@/components/barredores/Lista.vue";
import NuevaRutaBarredor from "@/components/barredores/rutas/NuevaRuta.vue";
import ListaRutasBarredor from "@/components/barredores/rutas/Lista.vue";
import FormularioBarredor from "@/components/barredores/FormularioBarredor.vue";
import MonitoreoBarredores from "@/components/barredores/monitoreo/Monitoreo.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import FormularioRutaBarredor from "@/components/barredores/rutas/FormularioRuta.vue";
import InformacionRutaBarredor from "@/components/barredores/rutas/Informacion.vue";

@Component({
  components: {
    SvgIcon,
    SuccessDialog,
    MapaMonitoreo,
    MapaBarredores,
    TabsBarredores,
    ListaBarredores,
    NuevaRutaBarredor,
    ListaRutasBarredor,
    FormularioBarredor,
    ConfirmationDialog,
    MonitoreoBarredores,
    FormularioRutaBarredor,
    InformacionRutaBarredor,
  },
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  methods: mapActions({
    setBarredor: "barredores/set",
    deleteBarredor: "barredores/delete",
    setRutaBarredor: "rutasBarredor/set",
    setPingRastreador: "pingsRastreador/set",
    deleteRutaBarredor: "rutasBarredor/delete",
    deletePingRastreador: "pingsRastreador/delete",
    cargarRegistrosPingsRastreador: "pingsRastreador/cargarRegistros",
    limpiarRegistrosPingsRastreador: "pingsRastreador/limpiarRegistros",
    updateRegistroPingsRastreador: "pingsRastreador/updateRegistrosCache",
  }),
  computed: mapGetters({
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getRutasBarredor: "rutasBarredor/get",
    getEmpresaUsuario: "usuario/getEmpresa",
    getPermisosUsuario: "usuario/getPermisos",
    getArrayBarredores: "barredores/getArray",
    getArrayRastreadores: "rastreadores/getArray",
    getArrayRutasBarredor: "rutasBarredor/getArray",
    getPingsRastreadorArray: "pingsRastreador/getArray",
  }),
})
export default class Barredores extends Vue {
  created(): void {
    if (!routeGuard()) {
      return;
    }
    this.fecha = new Date().format("YYYY-MM-DD");
    this.loadedDates.push(this.fecha);
  }

  tab = 0;
  fecha = "";
  menu = false;
  cargando = false;
  dialogoNuevaRuta = false;
  seleccionMultiple = false;
  tiempoPings: TiempoPings = {};
  dialogoFormularioRuta = false;
  seleccionados: Barredor[] = [];
  dialogoInformacionRuta = false;
  loadedDates: Array<string> = [];
  ruta: RutaBarredor | null = null;
  barredor: Barredor | null = null;
  dialogoFormularioBarredor = false;
  pingActualMonitoreo: PingRastreador | null = null;
  dialogoConfirmacion: {
    model: boolean;
    texto: string;
    si: (() => void) | null;
    no: (() => void) | null;
  } = {
    model: false,
    texto: "",
    si: null,
    no: null,
  };
  dataMonitoreo: {
    tipo: "sin-movimiento" | "ruta";
    barredor: Barredor | null;
  } = {
    tipo: "sin-movimiento",
    barredor: null,
  };
  dialogoExito = {
    model: false,
    mensaje: "",
    icono: "",
  };

  get tabMapa(): number {
    return this.tab === 2 ? 1 : 0;
  }

  get center(): Posicion {
    return this.getEmpresaUsuario.posicion ?? { lat: -35.428252, lng: -71.664684 }
  }

  get barredoresOrdenados(): Array<Barredor>{
    const barredores=this.getArrayBarredores;
    return barredores.sort((barredorA,barredorB) => {
      return barredorA.numero-barredorB.numero;
    })
   
  }

  @Watch("tabMapa")
  onTabMapaChange(val: number): void {
    if (val === 0) {
      this.dataMonitoreo = {
        tipo: "sin-movimiento",
        barredor: null,
      };
    }
  }

  @Watch("seleccionMultiple")
  onSeleccionMultipleChange(val: boolean): void {
    if(!val) {
      this.seleccionados.splice(1);
    }
  }

  @Watch("fecha")
  async onFechaChange(): Promise<void> {
    if (!this.loadedDates.includes(this.fecha)) {
      this.loadedDates.push(this.fecha);
      this.cargando = true;
      const fecha = `${this.fecha} 00:00:00`.toDate();
      fecha.setHours(0, 0, 0, 0);
      try {
        await this.cargarRegistrosPingsRastreador({
          fechas: [fecha],
        });
      } catch (error) {
        console.error(error);
      }
      this.cargando = false;
    }
  }

  abrirDialogoAgregarBarredor(): void {
    this.dialogoFormularioBarredor = true;
    this.barredor = null;
  }

  abrirDialogoEditarBarredor(barredor: Barredor): void {
    this.dialogoFormularioBarredor = true;
    this.barredor = barredor;
  }

  cerrarDialogoFormularioBarredor(): void {
    this.dialogoFormularioBarredor = false;
    this.barredor = null;
  }

  abrirDialogoNuevaRuta(): void {
    this.dialogoNuevaRuta = true;
  }

  cerrarDialogoNuevaRuta(): void {
    this.dialogoNuevaRuta = false;
  }

  abrirDialogoAgregarRuta(): void {
    this.dialogoFormularioRuta = true;
    this.ruta = null;
  }

  abrirDialogoEditarRuta(ruta: RutaBarredor): void {
    this.dialogoFormularioRuta = true;
    this.ruta = ruta;
  }

  cerrarDialogoFormularioRuta(): void {
    this.dialogoFormularioRuta = false;
    this.ruta = null;
  }

  abrirDialogoInformacionRuta(ruta: RutaBarredor): void {
    this.dialogoInformacionRuta = true;
    this.ruta = ruta;
  }

  cerrarDialogoInformacionRuta(): void {
    this.dialogoInformacionRuta = false;
    this.ruta = null;
  }

  abrirDialogoExito(mensaje: string, icono: string): void {
    this.dialogoExito = {
      model: true,
      mensaje,
      icono,
    };
  }

  abrirDialogoEliminarBarredor(barredor: Barredor): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t("barredores.tab.barredores.confirmacion.delete") as string,
      si: () => {
        this.eliminarBarredor(barredor);
        this.cerrarDialogoConfirmacion();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  abrirDialogoEliminarRuta(ruta: RutaBarredor): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t("barredores.tab.rutas.confirmacion.delete") as string,
      si: () => {
        this.eliminarRuta(ruta);
        this.cerrarDialogoConfirmacion();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  cerrarDialogoConfirmacion(): void {
    this.dialogoConfirmacion = {
      model: false,
      texto: "",
      si: null,
      no: null,
    };
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      mensaje: "",
      icono: "",
    };
  }

  guardarNuevaRuta(data: Array<PingRastreador>): void {
    let fecha;
    const pings: PingsRastreador = {};
    for (const ping of data) {
      if (!fecha) fecha = ping.fecha.toDate();
      this.setPingRastreador(ping);
      if (!ping.id) continue;
      pings[ping.id] = ping;
    }
    if (fecha) {
      fecha.setHours(0, 0, 0, 0);
      this.updateRegistroPingsRastreador({
        date: fecha,
        registros: pings,
        tipo: "agregar",
      });
    }
    this.abrirDialogoExito(
      this.$t("barredores.tab.barredores.exito-ruta.created") as string,
      "ag-save"
    );
    this.cerrarDialogoNuevaRuta();
  }

  mostrarRutaBarredor(barredor: Barredor): void {
    barredor.mostrarRuta = !barredor.mostrarRuta;
    this.setBarredor(barredor);
  }

  eliminarBarredor(barredor: Barredor): void {
    if (!barredor.id) return;
    this.deleteBarredor(barredor.id);
  }

  eliminarRuta(ruta: RutaBarredor): void {
    if (!ruta.id) return;
    this.deleteRutaBarredor(ruta.id);
  }

  eliminarPingRastreador(ping: PingRastreador): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t("barredores.tab.monitoreo.confirmacion.delete") as string,
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
      si: () => {
        if (!ping.id) return;
        this.deletePingRastreador(ping.id);
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  eliminarRutaCompleta(barredor: Barredor): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "barredores.tab.monitoreo.confirmacion.delete-all"
      ) as string,
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
      si: () => {
        const [horarioEntrada, horarioSalida] = barredor.horario.split("-");
        const [horaEntrada, minutosEntrada] = horarioEntrada
          .split(":")
          .map(Number);
        const [horaSalida, minutosSalida] = horarioSalida
          .split(":")
          .map(Number);
        const fechaEntrada = this.fecha.toDate();
        fechaEntrada.setHours(horaEntrada, minutosEntrada, 0, 0);
        const fechaSalida = this.fecha.toDate();
        fechaSalida.setHours(horaSalida, minutosSalida, 59, 999);
        const pings: PingsRastreador = {};
        for (const ping of this.getPingsRastreadorArray) {
          if (!ping.id) continue;
          const fechaPing = ping.fecha.toDate();
          const flagValido = ping.valido;
          const flagBarredor = ping.barredores.some(
            (b) => b.id === barredor.id
          );
          const flagFecha =
            fechaPing >= fechaEntrada && fechaPing <= fechaSalida;
          if (flagValido && flagBarredor && flagFecha) {
            this.deletePingRastreador(ping.id);
            pings[ping.id] = ping;
          }
        }
        this.updateRegistroPingsRastreador({
          date: fechaEntrada,
          registros: pings,
          tipo: "eliminar",
        });
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  guardarBarredor(barredor: Barredor): void {
    this.setBarredor(barredor);
    const nuevo = !this.barredor;
    this.cerrarDialogoFormularioBarredor();
    if (!nuevo) {
      this.abrirDialogoExito(
        this.$t("barredores.tab.barredores.exito-barredor.edited") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t("barredores.tab.barredores.exito-barredor.created") as string,
        "bxs-user-plus",
      );
    }
  }

  guardarRuta(ruta: RutaBarredor): void {
    this.setRutaBarredor(ruta);
    const nuevo = !this.ruta;
    this.cerrarDialogoFormularioRuta();
    if (!nuevo) {
      this.abrirDialogoExito(
        this.$t("barredores.tab.rutas.exito-ruta.edited") as string,
        "ag-save",
      );
    } else {
      this.abrirDialogoExito(
        this.$t("barredores.tab.rutas.exito-ruta.created") as string,
        "ag-map-marker-plus",
      );
    }
  }

  cambiarSeleccionado(barredor: Barredor): void {
    const index = this.seleccionados.findIndex(seleccionado => seleccionado.id === barredor.id);
    if (index > -1) {
      if (this.seleccionMultiple) {
        this.seleccionados.splice(index, 1);
      } else {
        this.seleccionados = [];
      }
    } else {
      if (this.seleccionMultiple) {
        this.seleccionados.push(barredor);
      } else {
        this.seleccionados = [barredor];
      }
    }
  }
}
</script>

<style>
.v-application--wrap--barredores {
  min-height: calc(100vh - 56px) !important;
}

.row-buttons {
  height: 50px;
}

.button {
  transition: all 150ms ease;
  opacity: 1;
}
</style>
