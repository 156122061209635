<template>
  <v-card color="#FAFAFA" class="rounded-input elevation-black" height="221px">
    <div class="d-flex flex-column fill-height">
      <v-row no-gutters class="flex-grow-0 pt-2">
        <v-col class="black--text font-weight-bold metropolis pl-2">
          {{ $t("monitoreo.tab.vehiculos.grafico-2.title") }}
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col>
          <DonutChart :series="series" :labels="labels"></DonutChart>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import DonutChart from "@/components/monitoreo/charts/DonutChart.vue";

@Component({
  components: {
    DonutChart,
  },
})
export default class TipoClienteVehiculos extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;

  get labels(): Array<string> {
    return [
      this.$t("monitoreo.tab.vehiculos.grafico-2.tipo.0") as string,
      this.$t("monitoreo.tab.vehiculos.grafico-2.tipo.1") as string,
      this.$t("monitoreo.tab.vehiculos.grafico-2.tipo.2") as string,
    ];
  }

  get series(): Array<number> {
    const helper: { [key: string]: number } = {
      empresa: 0,
      particular: 0,
      municipal: 0,
    };
    for (const pesaje of this.pesajes) {
      const tipo = pesaje.cliente?.tipo;
      if (tipo) {
        helper[tipo] = (helper[tipo] || 0) + 1;
      }
    }
    return Object.values(helper);
  }
}
</script>

<style scoped></style>
