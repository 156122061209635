<template>
  <div class="fill-height d-flex flex-column pa-4">
    <v-row no-gutters justify="end" align="center" class="flex-grow-0">
      <v-col cols="auto" v-if="mostrarBotonAgregar">
        <v-btn
          color="primary-light"
          class="rounded-input"
          depressed
          @click="onClickAgregar"
        >
          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon name="ag-map-marker-plus" color="primary"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="metropolis font-weight-bold text-body-1 text-initial primary--text"
            >
              {{ $t("barredores.tab.rutas.list.boton-add.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-3 overflow-auto scrollbar">
      <v-col>
        <v-row
          no-gutters
          v-for="rutaBarredor in rutasBarredor"
          :key="rutaBarredor.id"
          align="center"
        >
          <v-col cols="auto">
            <v-icon size="20px" :color="rutaBarredor.color">
              mdi-circle
            </v-icon>
          </v-col>
          <v-col class="darker--text metropolis pl-2 text-body-1">
            {{ rutaBarredor.nombre }}
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="onClickInformacion(rutaBarredor)"
                >
                  <svg-icon
                    name="ionic-md-information-circle"
                    color="primary"
                    width="20px"
                    height="20px"
                  ></svg-icon>
                </v-btn>
              </template>
              <span class="metropolis">
                {{ $t("barredores.tab.rutas.list.boton-informacion.tooltip") }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto" v-if="mostrarBotonEditar">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="onClickEditar(rutaBarredor)"
                >
                  <svg-icon
                    name="ag-edit"
                    color="yellow"
                    width="20px"
                    height="20px"
                  ></svg-icon>
                </v-btn>
              </template>
              <span class="metropolis">
                {{ $t("barredores.tab.rutas.list.boton-editar.tooltip") }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="auto" v-if="mostrarBotonEliminar">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="error"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="onClickEliminar(rutaBarredor)"
                >
                  <v-icon size="20px"> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span class="metropolis">
                {{ $t("barredores.tab.rutas.list.boton-eliminar.tooltip") }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//componentes
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { RutaBarredor } from "../../../typings/store/plugins/easyFirestore/rutasBarredor";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class ListaRutasBarredor extends Vue {
  @Prop() readonly rutasBarredor!: Array<RutaBarredor>;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonAgregar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEditar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.barredores === "eliminar";
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }

  @Emit("click:informacion")
  onClickInformacion(rutaBarredor: RutaBarredor): RutaBarredor {
    return rutaBarredor;
  }

  @Emit("click:editar")
  onClickEditar(rutaBarredor: RutaBarredor): RutaBarredor {
    return rutaBarredor;
  }

  @Emit("click:eliminar")
  onClickEliminar(rutaBarredor: RutaBarredor): RutaBarredor {
    return rutaBarredor;
  }
}
</script>

<style scoped>
.border-white {
  border: 1px solid white !important;
}
</style>
