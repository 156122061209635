<template>
  <v-card flat color="transparent">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="auto" :class="clases">
          {{ valor }}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// decoradores
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ItemTabla extends Vue {
  @Prop() readonly valor!: string;
  @Prop() readonly clases?: string;
}
</script>
