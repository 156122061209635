<template>
  <apex-chart
    width="90%"
    height="90%"
    type="donut"
    :series="series"
    :options="options"
    class="donut-chart"
    v-if="!actualizando"
  ></apex-chart>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {ApexOptions} from "apexcharts";

interface CustomOptions {
  series: Array<number>;
  seriesIndex: number;
  w: {
    globals: {
      colors: Array<string>;
    };
    config: {
      labels: Array<string>;
    };
  };
}

@Component
export default class ColumnChart extends Vue {
  @Prop() readonly series!: Array<number>;
  @Prop() readonly labels!: Array<string>;
  @Prop() readonly formatter?: (val: number) => string;

  actualizando = false;

  get options(): ApexOptions {
    return {
      chart: {
        type: 'donut',
      },
      tooltip: {
        custom: (opts: CustomOptions) => {
          const seriesIndex = opts.seriesIndex;
          const label = opts.w.config.labels[seriesIndex] || "";
          const color = opts.w.globals.colors[seriesIndex] || "#000000";
          const value = opts.series[seriesIndex] || 0;
          const total = opts.series.reduce((a, b) => a + b, 0);
          const percent = total > 0 ? Math.round((value / total) * 100) : 0;
          const formatted = this.formatter ? this.formatter(value) : value.toString();
          return `<div class="font-size-12 metropolis white--text px-3 py-2" style="background: ${color}">${label}: <b>${formatted}</b> (${percent}%)</div>`;
        },
      },
      dataLabels: {
        style: {
          fontFamily: "Metropolis",
          fontSize: "12px",
          fontWeight: "regular",
        },
        formatter(val: number): string {
          if (val > 20) {
            return `${Math.round(val)}%`;
          }
          return "";
        },
      },
      labels: this.labels,
      legend: {
        position: "left",
        fontFamily: "Metropolis",
        labels: {
          colors: this.$vuetify.theme.themes.light.text as string,
        },
      },
    };
  }
}
</script>

<style scoped>
.donut-chart >>> .apexcharts-legend-marker {
  border-radius: 4px !important;
}
</style>
