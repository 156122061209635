<template>
  <v-row no-gutters align="end">
    <v-col cols="2" class="pr-6">
      <v-row no-gutters class="px-5">
        <v-col class="text--text metropolis text-body-1">
          {{ $t("reportes.filtro.fecha.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="slide-y-transition"
            content-class="rounded-input elevation-black"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs, value }">
              <v-card
                v-on="on"
                v-bind="attrs"
                class="elevation-black rounded-input card-menu"
                :ripple="false"
              >
                <v-card-text
                  class="py-3 px-5 metropolis text-body-1 darker--text"
                >
                  <v-row no-gutters align="center">
                    <v-col class="text-truncate" cols="11">
                      {{ fechasMostrar }}
                    </v-col>
                    <v-col cols="1" class="text-right">
                      <svg-icon
                        :class="`select-icon ${value ? 'selected' : ''}`"
                        name="ionic-md-arrow-dropdown"
                        color="text"
                        width="12px"
                        height="12px"
                      ></svg-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
            <v-date-picker
              locale="es-es"
              v-model="fechas"
              no-title
              range
              class="range metropolis calendar"
              :allowed-dates="$allowedDatesBeforeToday"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="pr-6">
      <v-row no-gutters class="px-5">
        <v-col class="text--text metropolis text-body-1">
          {{ $t("reportes.filtro.vehiculo.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-select
            solo
            dense
            clearable
            hide-details
            return-object
            color="black"
            :placeholder="$t('reportes.filtro.vehiculo.placeholder')"
            item-text="patente"
            class="rounded-input elevation-input metropolis"
            :items="vehiculos"
            v-model="filtroVehiculo"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis text-body-1 text-uppercase">
                {{ item.patente }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="metropolis text-body-1 text-uppercase">
                {{ item.patente }}
              </span>
            </template>
            <template v-slot:append>
              <svg-icon
                name="ionic-md-arrow-dropdown"
                width="12px"
                height="12px"
                color="text"
              ></svg-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="pr-6">
      <v-row no-gutters class="px-5">
        <v-col class="metropolis text--text text-body-1">
          {{ $t("reportes.filtro.clientes.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-select
            solo
            dense
            multiple
            clearable
            hide-details
            return-object
            color="black"
            :placeholder="$t('reportes.filtro.clientes.placeholder')"
            item-text="nombre"
            class="rounded-input elevation-input metropolis"
            :items="clientes"
            :disabled="!!clienteUsuario"
            v-model="filtroClientes"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item, attrs }">
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="attrs.inputValue"
                    hide-details
                    dense
                    class="pa-0 ma-0"
                  ></v-checkbox>
                </v-col>
                <v-col class="metropolis text-body-1 text-capitalize">
                  {{ item.nombre }}
                </v-col>
              </v-row>
            </template>
            <template v-slot:selection="{ index }">
              <v-col
                cols="auto"
                v-if="index === 0"
                class="text-capitalize text-truncate metropolis text-body-1 pa-0"
              >
                {{ textoFiltroClientes }}
              </v-col>
            </template>
            <template v-slot:append>
              <svg-icon
                name="ionic-md-arrow-dropdown"
                width="12px"
                height="12px"
                color="text"
              ></svg-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="pr-6">
      <v-row no-gutters class="px-5">
        <v-col class="metropolis text--text text-body-1">
          {{ $t("reportes.filtro.servicios.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-select
            solo
            dense
            multiple
            clearable
            hide-details
            return-object
            color="black"
            :placeholder="$t('reportes.filtro.servicios.placeholder')"
            item-text="nombre"
            class="rounded-input elevation-input metropolis"
            :items="servicios"
            v-model="filtroServicios"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item, attrs }">
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="attrs.inputValue"
                    hide-details
                    dense
                    class="pa-0 ma-0"
                  ></v-checkbox>
                </v-col>
                <v-col class="metropolis text-body-1 text-capitalize">
                  {{ item.nombre }}
                </v-col>
              </v-row>
            </template>
            <template v-slot:selection="{ index }">
              <v-col
                cols="auto"
                v-if="index === 0"
                class="text-capitalize metropolis text-truncate pa-0"
              >
                {{ textoFiltroServicios }}
              </v-col>
            </template>
            <template v-slot:append>
              <svg-icon
                name="ionic-md-arrow-dropdown"
                width="12px"
                height="12px"
                color="text"
              ></svg-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2" class="pr-6">
      <v-row no-gutters class="px-5">
        <v-col class="text--text text-body-1 metropolis">
          {{ $t("reportes.filtro.desecho.label") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-select
            solo
            dense
            clearable
            hide-details
            return-object
            color="black"
            :placeholder="$t('reportes.filtro.desecho.placeholder')"
            item-text="nombre"
            class="rounded-input elevation-input metropolis"
            :items="desechos"
            v-model="filtroDesecho"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:item="{ item }">
              <span class="metropolis text-body-1 text-capitalize">
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span
                class="metropolis text-body-1 text-capitalize text-truncate"
              >
                {{ item.nombre }}
              </span>
            </template>
            <template v-slot:append>
              <svg-icon
                name="ionic-md-arrow-dropdown"
                width="12px"
                height="12px"
                color="text"
              ></svg-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="2">
      <v-row no-gutters align="center" justify="end">
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                depressed
                width="48px"
                height="48px"
                class="rounded-input elevation-error gradient-error"
                @click="onClickImprimirTodos"
                v-on="on"
                v-bind="attrs"
              >
                <svg-icon
                  name="ags-print"
                  width="24px"
                  height="24px"
                  color="white"
                ></svg-icon>
              </v-btn>
            </template>
            <span class="metropolis text-body-1">
              {{ $tc("reportes.boton.imprimir.tooltip", cantidadRegistros) }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto" class="px-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                depressed
                width="48px"
                height="48px"
                class="rounded-input elevation-secondary gradient-secondary"
                @click="onClickCrearPDF"
                v-on="on"
                v-bind="attrs"
              >
                <svg-icon
                  name="pdf"
                  width="24px"
                  height="24px"
                  color="white"
                ></svg-icon>
              </v-btn>
            </template>
            <span class="metropolis text-body-1">
              {{ $t("reportes.boton.pdf.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                depressed
                width="48px"
                height="48px"
                class="rounded-input elevation-primary gradient-primary"
                @click="onClickCrearExcel"
                v-on="on"
                v-bind="attrs"
              >
                <svg-icon
                  name="excel"
                  width="24px"
                  height="24px"
                  color="white"
                ></svg-icon>
              </v-btn>
            </template>
            <span class="metropolis">
              {{ $t("reportes.boton.excel.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//decoradores
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
//tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { FiltrosReporte } from "@/typings/components/reportes/filtros";
//componentes
import SvgIcon from "../custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosReportes extends Vue {
  @VModel() filtros!: FiltrosReporte;
  @Prop() readonly vehiculos!: Array<Vehiculo>;
  @Prop() readonly clientes!: Array<Cliente>;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly desechos!: Array<Desecho>;
  @Prop() readonly clienteUsuario!: Cliente | null;
  @Prop() readonly cantidadRegistros!: number;

  menu = false;

  get fechas(): Array<string> {
    return this.filtros.fechas;
  }

  set fechas(value: Array<string>) {
    this.filtros.fechas = value;
  }

  get fechasMostrar(): string {
    return this.fechas
      .map((fecha) => fecha.toDate().format("DD/MM/YY"))
      .join(" ~ ");
  }

  get filtroVehiculo(): Vehiculo | null {
    return this.filtros.vehiculo;
  }

  set filtroVehiculo(value: Vehiculo | null) {
    this.filtros.vehiculo = value;
  }

  get filtroClientes(): Array<Cliente> {
    return this.filtros.clientes;
  }

  set filtroClientes(value: Array<Cliente>) {
    this.filtros.clientes = value;
  }

  get filtroServicios(): Array<Servicio> {
    return this.filtros.servicios;
  }

  set filtroServicios(value: Array<Servicio>) {
    this.filtros.servicios = value;
  }

  get filtroDesecho(): Desecho | null {
    return this.filtros.desecho;
  }

  set filtroDesecho(value: Desecho | null) {
    this.filtros.desecho = value;
  }

  get textoFiltroClientes(): string {
    const length = this.filtroClientes.length;
    if (length === 1) {
      return this.filtroClientes[0].nombre;
    } else {
      return this.$tc("reportes.filtro.clientes.selected", length);
    }
  }

  get textoFiltroServicios(): string {
    const length = this.filtroServicios.length;
    if (length === 1) {
      return this.filtroServicios[0].nombre;
    } else {
      return this.$tc("reportes.filtro.servicios.selected", length);
    }
  }

  @Emit("click:crear-pdf")
  onClickCrearPDF(): void {
    return;
  }

  @Emit("click:crear-excel")
  onClickCrearExcel(): void {
    return;
  }

  @Emit("click:imprimir-todos")
  onClickImprimirTodos(): void {
    return;
  }

  @Watch("fechas")
  onFechasChange(): void {
    const [fechaInicio, fechaFin] = this.fechas.map((fecha) => fecha.toDate());
    if (fechaInicio && fechaFin && fechaInicio > fechaFin) {
        this.fechas.reverse();
    }
  }
}
</script>
