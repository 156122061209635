<template>
  <div class="loading__container">
    <div class="loading__content">
      <img
        alt="WeighTruck"
        src="../assets/svg/sistema/logotipo.svg"
      >
      <div class="loading__bar-container">
        <div
          ref="loading__bar"
          class="loading__bar"
          :style="loadingBarStyle"
          @animationend="onAnimationEndHandler"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import {computed, defineComponent, ref, watch} from "vue";

// Helpers
import {log} from "@/helpers/env";

export default defineComponent({
  name: "CargandoWeighTruck",
  props: {
    cargando: {
      type: Boolean,
    },
  },
  emits: [
    "cerrar",
  ],
  setup(props, ctx) {
    // Data

    const startDate = ref(new Date());
    const animationEnded = ref(false);

    // Computed

    const loadingBarStyle = computed(() => {
      let duration = "20s";
      if (!props.cargando) {
        if (!animationEnded.value) {
          const seconds = (new Date().getTime() - startDate.value.getTime()) / 1000;
          duration = `${seconds + 1}s`;
        } else {
          duration = "5s";
        }
      }
      if (log) console.log("loading duration:", duration);
      return {
        "animation-duration": duration,
      };
    });

    // Watch

    watch(() => props.cargando, (cargando: boolean) => {
      if (!cargando && animationEnded.value) onCerrar();
    });

    // Methods

    const onAnimationEndHandler = () => {
      animationEnded.value = true;
      if (!props.cargando) onCerrar();
    };

    // Emits

    const onCerrar = () => {
      ctx.emit("cerrar");
    };

    return {
      // Computed
      loadingBarStyle,
      // Methods
      onAnimationEndHandler,
    };
  },
});
</script>

<style scoped>
.loading__container {
  width: 100vw;
  height: 100vh;
  display: grid;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
  background-color: var(--v-background-base);
}

.loading__content {
  display: flex;
  row-gap: 5rem;
  align-items: center;
  flex-direction: column;
}

.loading__content img {
  width: 37.375rem;
}

.loading__bar-container {
  height: 1rem;
  width: 26.375rem;
  background: white;
  border-radius: 0.5rem;
}

.loading__bar {
  width: 0;
  height: 1rem;
  border-radius: 0.5rem;
  animation-name: loading;
  animation-duration: 20s;
  animation-fill-mode: forwards;
  background-size: 26.375rem 1rem;
  animation-timing-function: linear;
  background-image: linear-gradient(90deg, #00AE3C -2.13%, #1FC7FB 102.37%);
}

@media screen and (max-width: 79rem) {
  .loading__content img {
    width: 17.5rem;
  }

  .loading__bar-container {
    width: 14rem;
  }

  .loading__bar {
    background-size: 14rem 1rem;
  }
}

@keyframes loading {
  to {
    width: 100%;
  }
}
</style>
