<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="row-buttons flex-grow-0" align="end">
      <v-col cols="3">
        <v-btn
          block
          :height="tab === 0 ? '50px' : '44px'"
          depressed
          color="yellow"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 0 ? 'button-inactive' : ''
          }`"
          @click="tab = 0"
        >
          {{
            t("bascula.dialogo-manual.entrada-salida-manual.tab.entrada.title")
          }}
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          block
          :height="tab === 1 ? '50px' : '44px'"
          depressed
          color="purple"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 1 ? 'button-inactive' : ''
          }`"
          @click="tab = 1"
          :disabled="disabledTabSalida"
        >
          {{
            t("bascula.dialogo-manual.entrada-salida-manual.tab.salida.title")
          }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card
          color="dialogDark"
          height="100%"
          class="elevation-black rounded-input rounded-tl-0"
        >
          <v-card-text class="pa-0">
            <v-tabs-items
              v-model="tab"
              class="rounded-input rounded-tl-0 dialogDark"
            >
              <v-tab-item>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col class="pr-2">
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          cols="4"
                          class="metropolis white--text font-weight-bold text-body-1"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.fecha.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-menu
                            v-model="menuFechaEntrada"
                            :close-on-content-click="false"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            content-class="rounded-input elevation-black"
                            transition="slide-y-transition"
                          >
                            <template v-slot:activator="{ on, attrs, value }">
                              <v-card
                                v-on="on"
                                v-bind="attrs"
                                flat
                                tile
                                color="transparent"
                                class="card-menu"
                                :ripple="false"
                                height="38px"
                              >
                                <v-card-text
                                  class="py-1 px-3 fill-height metropolis text-body-1 white--text"
                                >
                                  <v-row
                                    no-gutters
                                    align="center"
                                    class="fill-height"
                                  >
                                    <v-col class="text-truncate" cols="11">
                                      {{ fechaEntrada }}
                                    </v-col>
                                    <v-col cols="1" class="text-right">
                                      <svg-icon
                                        :class="`select-icon ${
                                          value ? 'selected' : ''
                                        }`"
                                        name="ionic-md-arrow-dropdown"
                                        color="text"
                                        width="12px"
                                        height="12px"
                                      ></svg-icon>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </template>
                            <v-date-picker
                              locale="es-es"
                              v-model="fechaEntrada"
                              class="calendar metropolis"
                              no-title
                              @input="menuFechaEntrada = false"
                              :allowed-dates="$allowedDatesBeforeToday"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col class="pl-2">
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          cols="4"
                          class="metropolis white--text font-weight-bold text-body-1"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.hora.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            dark
                            solo
                            flat
                            dense
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.hora.placeholder'
                              )
                            "
                            type="time"
                            class="metropolis"
                            hide-details="auto"
                            background-color="transparent"
                            :error-messages="erroresHoraEntrada"
                            v-model="horaEntrada"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col>
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          class="white--text metropolis text-body-1 font-weight-bold"
                          cols="4"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.peso.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            solo
                            flat
                            dark
                            dense
                            :suffix="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.peso.suffix'
                              )
                            "
                            background-color="transparent"
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.peso.placeholder'
                              )
                            "
                            hide-details="auto"
                            class="text-center metropolis font-weight-bold rounded-input"
                            :error-messages="erroresPesoEntrada"
                            v-model.number="pesoEntrada"
                            v-mask="['#########']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col>
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          class="white--text metropolis text-body-1 font-weight-bold"
                          cols="4"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.chofer.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-combobox
                            solo
                            flat
                            dark
                            dense
                            return-object
                            item-text="nombre"
                            class="metropolis"
                            hide-details="auto"
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.chofer.placeholder'
                              )
                            "
                            background-color="transparent"
                            :items="arrayChoferes"
                            :error-messages="erroresChoferEntrada"
                            v-model="choferEntrada"
                            :menu-props="{
                              contentClass: 'rounded-input elevation-black',
                              transition: 'slide-y-transition',
                            }"
                          >
                            <template v-slot:item="{ item }">
                              <span
                                class="metropolis darker--text text-capitalize"
                              >
                                {{ item.nombre }}
                              </span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="metropolis white--text text-capitalize text-truncate"
                              >
                                {{ item.nombre }}
                              </span>
                            </template>
                            <template v-slot:append>
                              <svg-icon
                                name="ionic-md-arrow-dropdown"
                                color="text"
                                width="12px"
                                height="12px"
                              ></svg-icon>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col>
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          class="white--text metropolis text-body-1 font-weight-bold"
                          cols="4"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.entrada.detalle.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            solo
                            flat
                            dark
                            dense
                            class="metropolis"
                            hide-details="auto"
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.entrada.detalle.placeholder'
                              )
                            "
                            background-color="transparent"
                            v-model="observacionEntrada"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col class="pr-2">
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          cols="4"
                          class="metropolis white--text font-weight-bold text-body-1"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.salida.fecha.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-menu
                            v-model="menuFechaSalida"
                            :close-on-content-click="false"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            content-class="rounded-input elevation-black"
                            transition="slide-y-transition"
                          >
                            <template v-slot:activator="{ on, attrs, value }">
                              <v-card
                                v-on="on"
                                v-bind="attrs"
                                flat
                                tile
                                color="transparent"
                                class="card-menu"
                                :ripple="false"
                                height="38px"
                              >
                                <v-card-text
                                  class="py-1 px-3 fill-height metropolis text-body-1 white--text"
                                >
                                  <v-row
                                    no-gutters
                                    align="center"
                                    class="fill-height"
                                  >
                                    <v-col class="text-truncate" cols="11">
                                      {{ fechaSalida }}
                                    </v-col>
                                    <v-col cols="1" class="text-right">
                                      <svg-icon
                                        :class="`select-icon ${
                                          value ? 'selected' : ''
                                        }`"
                                        name="ionic-md-arrow-dropdown"
                                        color="text"
                                        width="12px"
                                        height="12px"
                                      ></svg-icon>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </template>
                            <v-date-picker
                              locale="es-es"
                              v-model="fechaSalida"
                              class="calendar metropolis"
                              no-title
                              @input="menuFechaSalida = false"
                              :allowed-dates="$allowedDatesBeforeToday"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col class="pl-2">
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          cols="4"
                          class="metropolis white--text font-weight-bold text-body-1"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.salida.hora.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            solo
                            flat
                            dark
                            dense
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.salida.hora.placeholder'
                              )
                            "
                            type="time"
                            class="metropolis"
                            hide-details="auto"
                            background-color="transparent"
                            v-model="horaSalida"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col>
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          class="white--text metropolis text-body-1 font-weight-bold"
                          cols="4"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.salida.peso.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            solo
                            flat
                            dark
                            dense
                            :suffix="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.salida.peso.suffix'
                              )
                            "
                            background-color="transparent"
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.salida.peso.placeholder'
                              )
                            "
                            :error-messages="erroresPesoSalida"
                            hide-details="auto"
                            class="text-center metropolis font-weight-bold rounded-input"
                            v-model.number="pesoSalida"
                            v-mask="['#########']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col>
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          class="white--text metropolis text-body-1 font-weight-bold"
                          cols="4"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.salida.chofer.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-combobox
                            solo
                            flat
                            dark
                            dense
                            return-object
                            class="metropolis"
                            item-text="nombre"
                            hide-details="auto"
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.salida.chofer.placeholder'
                              )
                            "
                            :error-messages="erroresChoferSalida"
                            background-color="transparent"
                            :items="arrayChoferes"
                            v-model="choferSalida"
                            :menu-props="{
                              contentClass: 'rounded-input elevation-black',
                              transition: 'slide-y-transition',
                            }"
                          >
                            <template v-slot:item="{ item }">
                              <span
                                class="metropolis darker--text text-capitalize"
                              >
                                {{ item.nombre }}
                              </span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span
                                class="metropolis white--text text-capitalize text-truncate"
                              >
                                {{ item.nombre }}
                              </span>
                            </template>
                            <template v-slot:append>
                              <svg-icon
                                name="ionic-md-arrow-dropdown"
                                color="text"
                                width="12px"
                                height="12px"
                              ></svg-icon>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-2 pt-2">
                  <v-col>
                    <div class="border-grey rounded-input">
                      <v-row no-gutters align="center" class="px-2">
                        <v-col
                          class="white--text metropolis text-body-1 font-weight-bold"
                          cols="4"
                        >
                          {{
                            t(
                              "bascula.dialogo-manual.entrada-salida-manual.tab.salida.detalle.label"
                            )
                          }}
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            solo
                            flat
                            dark
                            dense
                            hide-details
                            class="metropolis"
                            :placeholder="
                              t(
                                'bascula.dialogo-manual.entrada-salida-manual.tab.salida.detalle.placeholder'
                              )
                            "
                            background-color="transparent"
                            v-model="observacionSalida"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <v-row no-gutters class="px-2 pt-4 pb-2" justify="center">
              <v-col cols="auto">
                <v-btn
                  large
                  class="elevation-black gradient-primary rounded-input px-8"
                  @click="guardar"
                >
                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="auto" class="pr-2">
                      <svg-icon
                        name="ag-save"
                        color="white"
                        width="16px"
                        height="16px"
                      ></svg-icon>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="metropolis text-initial font-weight-bold text-body-1 white--text pl-2"
                    >
                      {{
                        t(
                          "bascula.dialogo-manual.entrada-salida-manual.boton-guardar.text"
                        )
                      }}
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import {
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    value: {
      required: true,
      type: Object as () => PesajeData,
    },
    pesaje: {
      default: null,
      type: Object as () => Pesaje | null,
    },
    arrayChoferes: {
      type: Array as () => Chofer[],
    },
  },
  created(): void {
    if (this.pesaje?.estado === "salida") {
      this.tab = 1;
    } else {
      this.tab = 0;
    }
  },
  emits: ["click:guardar", "input"],
  setup(props, ctx) {
    const { t } = useI18n();

    const editarEntrada = ref(true);
    const tab = ref(0);
    const menuFechaEntrada = ref(false);
    const menuFechaSalida = ref(false);

    const pesajeData = computed({
      get: () => props.value,
      set: (value: PesajeData) => ctx.emit("input", value),
    });

    const disabledTabSalida = computed((): boolean => {
      const pesoValido =
        !!props.pesaje?.entrada?.peso || !!pesajeData.value.pesoEntrada;
      const choferValido =
        !!props.pesaje?.entrada?.chofer || !!pesajeData.value.choferEntrada;
      const fechaValida =
        !!props.pesaje?.entrada?.fecha || !!pesajeData.value.fechaEntrada;
      return !pesoValido || !choferValido || !fechaValida;
    });

    const fechaEntrada = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaEntrada || props.pesaje?.entrada?.fecha;
        return (fecha ? fecha.toDate() : new Date()).format("YYYY-MM-DD");
      },
      set: (value) => {
        const fecha = value.toDate();
        const hoy = new Date();
        fecha.setHours(
          hoy.getHours(),
          hoy.getMinutes(),
          hoy.getSeconds(),
          hoy.getMilliseconds()
        );
        const nuevo = Object.assign({}, pesajeData.value, {
          fechaEntrada: fecha.toISOString(),
        });
        pesajeData.value = nuevo;
      },
    });

    const horaEntrada = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaEntrada || props.pesaje?.entrada?.fecha;
        return (fecha ? fecha.toDate() : new Date()).format("HH:mm");
      },
      set: (value) => {
        const [hora, minutos] = value.split(":");
        if (hora && hora.length === 2 && minutos && minutos.length === 2) {
          const fechaRaw =
            pesajeData.value.fechaEntrada ??
            (props.pesaje?.entrada?.fecha || "");
          const fecha = fechaRaw?.toDate();
          fecha.setHours(parseInt(hora, 10));
          fecha.setMinutes(parseInt(minutos, 10));
          const nuevo = Object.assign({}, pesajeData.value, {
            fechaEntrada: fecha.toISOString(),
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const pesoEntrada = computed({
      get: () => {
        const peso =
          pesajeData.value.pesoEntrada ?? (props.pesaje?.entrada?.peso || 0);
        return peso;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          pesoEntrada: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const choferEntrada = computed({
      get: () => {
        const chofer =
          pesajeData.value.choferEntrada ||
          props.pesaje?.entrada?.chofer ||
          null;
        return chofer;
      },
      set: (value) => {
        let chofer: Chofer | null;
        if (typeof value === "string") {
          chofer = {
            nombre: (value as string).toLowerCase(),
            rut: "",
            cargo: "",
            fechaNacimiento: "",
            fechaContratacion: "",
            contacto: "",
            estado: "habilitado",
            foto: null,
          };
        } else {
          chofer = Object.assign({}, value);
        }
        const nuevo = Object.assign({}, pesajeData.value, {
          choferEntrada: chofer,
        });
        pesajeData.value = nuevo;
      },
    });

    const observacionEntrada = computed({
      get: () => {
        const observacion =
          pesajeData.value.observacionEntrada ||
          props.pesaje?.entrada?.observacion ||
          "";
        return observacion;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          observacionEntrada: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const fechaSalida = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaSalida || props.pesaje?.salida?.fecha;
        return fecha ? fecha.toDate().format("YYYY-MM-DD") : "";
      },
      set: (value) => {
        const fecha = value.toDate();
        const nuevo = Object.assign({}, pesajeData.value, {
          fechaSalida: fecha.toISOString(),
        });
        pesajeData.value = nuevo;
      },
    });

    const horaSalida = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaSalida || props.pesaje?.salida?.fecha;
        return fecha ? fecha.toDate().format("HH:mm") : "";
      },
      set: (value) => {
        const [hora, minutos] = value.split(":");
        if (hora && hora.length === 2 && minutos && minutos.length === 2) {
          const fechaRaw =
            pesajeData.value.fechaSalida ?? (props.pesaje?.salida?.fecha || "");
          const fecha = fechaRaw.toDate();
          fecha.setHours(parseInt(hora, 10));
          fecha.setMinutes(parseInt(minutos, 10));
          const nuevo = Object.assign({}, pesajeData.value, {
            fechaSalida: fecha.toISOString(),
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const pesoSalida = computed({
      get: () => {
        const peso =
          pesajeData.value.pesoSalida ?? (props.pesaje?.salida?.peso || 0);
        return peso;
      },
      set: (value) => {
        const peso = value || 0;
        const nuevo = Object.assign({}, pesajeData.value, {
          pesoSalida: peso,
        });
        pesajeData.value = nuevo;
      },
    });

    const choferSalida = computed({
      get: () => {
        const chofer =
          pesajeData.value.choferSalida || props.pesaje?.salida?.chofer || null;
        return chofer;
      },
      set: (value) => {
        let chofer: Chofer | null;
        if (typeof value == "string") {
          chofer = {
            nombre: (value as string).toLowerCase(),
            rut: "",
            cargo: "",
            fechaNacimiento: "",
            fechaContratacion: "",
            contacto: "",
            estado: "habilitado",
            foto: null,
          };
        } else {
          chofer = Object.assign({}, value);
        }
        const nuevo = Object.assign({}, pesajeData.value, {
          choferSalida: chofer,
        });
        pesajeData.value = nuevo;
      },
    });

    const observacionSalida = computed({
      get: () => {
        const observacion =
          pesajeData.value.observacionSalida ||
          props.pesaje?.salida?.observacion ||
          "";
        return observacion;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          observacionSalida: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const state = {
      fechaEntrada,
      horaEntrada,
      pesoEntrada,
      choferEntrada,
      fechaSalida,
      horaSalida,
      pesoSalida,
      choferSalida,
    };

    const rules = {
      fechaEntrada: { required },
      horaEntrada: { required },
      pesoEntrada: { required },
      choferEntrada: { required },
      fechaSalida: { required },
      horaSalida: { required },
      pesoSalida: { required },
      choferSalida: { required },
    };

    const v$ = useVuelidate(rules, state, { $scope: false });

    const erroresFechaEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaEntrada.$dirty) return errores;
      if (v$.value.fechaEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresHoraEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.horaEntrada.$dirty) return errores;
      if (v$.value.horaEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.hora.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresPesoEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.pesoEntrada.$dirty) return errores;
      if (v$.value.pesoEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.peso.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresChoferEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.choferEntrada.$dirty) return errores;
      if (v$.value.choferEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.chofer.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresFechaSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaSalida.$dirty) return errores;
      if (v$.value.fechaSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresHoraSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.horaSalida.$dirty) return errores;
      if (v$.value.horaSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.hora.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresPesoSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.pesoSalida.$dirty) return errores;
      if (v$.value.pesoSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.peso.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresChoferSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.choferSalida.$dirty) return errores;
      if (v$.value.choferSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.chofer.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onGuardar = (): void => {
      ctx.emit("click:guardar");
    };

    const guardar = (): void => {
      v$.value.$touch();
      onGuardar();
    };

    return {
      t,
      v$,
      editarEntrada,
      tab,
      menuFechaEntrada,
      menuFechaSalida,
      disabledTabSalida,
      fechaEntrada,
      horaEntrada,
      pesoEntrada,
      choferEntrada,
      observacionEntrada,
      fechaSalida,
      horaSalida,
      pesoSalida,
      choferSalida,
      observacionSalida,
      erroresFechaEntrada,
      erroresHoraEntrada,
      erroresPesoEntrada,
      erroresChoferEntrada,
      erroresFechaSalida,
      erroresHoraSalida,
      erroresPesoSalida,
      erroresChoferSalida,
      guardar,
    };
  },
});
</script>

<style scoped>
.row-buttons {
  height: 50px;
}

.button {
  transition: all 150ms ease;
  opacity: 1;
}
</style>
