<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    hide-default-footer
    height="calc(100% - 89px)"
    :items-per-page="9"
    class="elevation-black white fill-height table rounded-input"
  >
    <template v-slot:no-data>
      <span class="metropolis text-body-1 darker--text">
        {{ $t("servicios.tabla.no-data-message") }}
      </span>
    </template>
    <template v-slot:[`item.nombre`]="{ item }">
      <ItemTabla
        :valor="item.nombre"
        :clases="getClasses(item).capitalize"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.tipo`]="{ item }">
      <ItemTabla
        :valor="item.tipo"
        :clases="getClasses(item).capitalize"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.informacion`]="{ item }">
      <OpcionesTabla
        :item="item"
        :seleccionado="servicio"
        @click:informacion="onClickInformacion"
      ></OpcionesTabla>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer :value="props"></data-table-footer>
    </template>
  </v-data-table>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { ItemsTablaServicios } from "@/typings/components/servicios/tabla";
//componentes
import ItemTabla from "@/components/servicios/tabla/Item.vue";
import OpcionesTabla from "@/components/servicios/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

@Component({
  components: {
    ItemTabla,
    OpcionesTabla,
    DataTableFooter,
  },
})
export default class TablaServicioes extends Vue {
  @Prop() readonly servicio?: Servicio;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly search!: string;

  get headers(): Array<{ text: string; value: string; sortable?: boolean }> {
    return [
      {
        text: this.$t("servicios.tabla.headers[0]") as string,
        value: "nombre",
      },
      {
        text: this.$t("servicios.tabla.headers[2]") as string,
        value: "tipo",
      },
      {
        text: "",
        value: "informacion",
        sortable: false,
      },
    ];
  }

  get noValueMessage(): string {
    return this.$t("servicios.tabla.no-value-message") as string;
  }

  get items(): ItemsTablaServicios {
    return this.servicios.map((servicio) => {
      const id = servicio.id;
      const nombre = servicio.nombre;
      const tipo =
        servicio.tipo ??
        (this.$t("servicios.tabla.no-value-message") as string);
      const descripcion =
        servicio.descripcion ??
        (this.$t("servicios.tabla.no-value-message") as string);
      return {
        id,
        nombre,
        tipo,
        descripcion,
        data: servicio,
      };
    });
  }

  @Emit("click:informacion")
  onClickInformacion(servicio: Servicio): Servicio {
    return servicio;
  }

  getClasses({ data }: { data: Servicio }): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.servicio?.id === data.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }
}
</script>
