<template>
  <v-data-table
    :items-per-page="itemsPerPage"
    :headers="headers"
    :items="items"
    :search="search"
    hide-default-footer
    height="calc(96vh - 439px)"
    class="elevation-black white table rounded-input"
    v-resize="onResize"
  >
    <template v-slot:[`item.nombre`]="{ item }">
      <v-col cols="auto" :class="getClasses(item).capitalize">
        {{ item.nombre }}
      </v-col>
    </template>
    <template v-slot:[`item.tipo`]="{ item }">
      <v-col cols="auto" :class="getClasses(item).capitalize">
        {{ item.tipo }}
      </v-col>
    </template>
    <template v-slot:[`item.opciones`]="{ item }">
      <OpcionesTabla
        :item="item"
        :seleccionado="ruta"
        :permisos="permisos"
        @click:switch="clickSwitchEstado"
        @click:informacion="onClickInformacion"
      ></OpcionesTabla>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer :value="props"></data-table-footer>
    </template>
  </v-data-table>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import { Item } from "@/typings/components/transporte/rutas";
//componentes
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import OpcionesTabla from "@/components/transporte/rutas/tabla/Opciones.vue";

@Component({
  components: {
    DataTableFooter,
    OpcionesTabla,
  },
})
export default class TablaRutasPesaje extends Vue {
  @Prop() readonly arrayRutasPesaje!: Array<RutaPesaje>;
  @Prop() readonly search!: string;
  @Prop() readonly ruta!: RutaPesaje | null;
  @Prop() readonly permisos!: Permisos;

  itemsPerPage = 9;

  get mostrarSwitch(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get headers(): Array<{ value: string; text: string; sortable?: boolean }> {
    return [
      {
        text: this.$t("transporte.tab.rutas.tabla.headers[0]") as string,
        value: "nombre",
      },
      {
        text: this.$t("transporte.tab.rutas.tabla.headers[1]") as string,
        value: "tipo",
      },
      {
        text: "",
        value: "opciones",
        sortable: false,
      },
    ];
  }

  get items(): Array<Item> {
    return this.arrayRutasPesaje.map((ruta) => {
      const id = ruta.id ?? "";
      const nombre = ruta.nombre;
      const tipo = ruta.tipo;
      return {
        id,
        nombre,
        tipo,
        ruta,
      };
    });
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(ruta: RutaPesaje): RutaPesaje {
    return ruta;
  }

  @Emit("click:informacion")
  onClickInformacion(ruta: RutaPesaje): RutaPesaje {
    return ruta;
  }

  getClasses({ ruta }: Item): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.ruta?.id === ruta.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }

  clickSwitchEstado({ ruta }: Item): void {
    this.onClickSwitchEstado(ruta);
  }

  onResize(): void {
    const height = window.innerHeight;
    this.itemsPerPage = Math.floor((height * 0.96 - 439) / 50);
  }
}
</script>
