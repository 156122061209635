<template>
  <v-card color="dialog">
    <v-container>
      <v-row no-gutters justify="end" align="center">
        <v-col
          cols="8"
          class="text-center white--text metropolis text-h6 font-weight-bold"
        >
          {{ $t("bascula.dialogo-manual.title") }}
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            x-small
            width="45px"
            height="45px"
            depressed
            class="gradient-error rounded-input"
            @click="onClickCerrar"
          >
            <v-icon color="white"> mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <FormularioBascula
            :pesaje="pesaje"
            :arrayVehiculos="arrayVehiculos"
            :arrayDesechos="arrayDesechos"
            :arrayServicios="arrayServicios"
            :arrayClientes="arrayClientes"
            :arrayRutasPesaje="arrayRutasPesaje"
            :capacidadErrors="capacidadErrors"
            :ruta-errors="rutaErrors"
            :vehiculo-errors="vehiculoErrors"
            :desecho-errors="desechoErrors"
            :servicio-errors="servicioErrors"
            :cliente-errors="clienteErrors"
            manual
            v-model="pesajeData"
          ></FormularioBascula>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <EntradaSalidaManual
            :pesaje="pesaje"
            :arrayChoferes="arrayChoferes"
            @click:guardar="guardar"
            v-model="pesajeData"
          ></EntradaSalidaManual>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, PropType } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// helpers
import { log } from "@/helpers/env";
// tipos
import {
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
// componentes
import EntradaSalidaManual from "@/components/bascula/EntradaSalidaManual.vue";
import FormularioBascula from "@/components/bascula/Formulario.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";

export default defineComponent({
  components: {
    EntradaSalidaManual,
    FormularioBascula,
  },
  props: {
    pesaje: {
      default: null,
      type: Object as PropType<Pesaje | null>,
    },
    arrayVehiculos: {
      default: () => [],
      type: Array as () => Vehiculo[],
    },
    arrayServicios: {
      default: () => [],
      type: Array as () => Servicio[],
    },
    arrayDesechos: {
      default: () => [],
      type: Array as () => Desecho[],
    },
    arrayClientes: {
      default: () => [],
      type: Array as () => Cliente[],
    },
    arrayChoferes: {
      default: () => [],
      type: Array as () => Chofer[],
    },
    arrayRutasPesaje: {
      default: () => [],
      type: Array as () => RutaPesaje[],
    },
  },
  emits: ["click:cerrar", "click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const pesajeData = ref<PesajeData>({});

    const capacidadPesaje = computed((): number => {
      return pesajeData.value.capacidad || props.pesaje?.capacidad || 0;
    });

    const rutaPesaje = computed((): RutaPesaje | null => {
      return pesajeData.value.ruta || props.pesaje?.ruta || null;
    });

    const vehiculoPesaje = computed((): Vehiculo | null => {
      return pesajeData.value.vehiculo || props.pesaje?.vehiculo || null;
    });

    const clientePesaje = computed((): Cliente | null => {
      return pesajeData.value.cliente || props.pesaje?.cliente || null;
    });

    const desechoPesaje = computed((): Desecho | null => {
      return pesajeData.value.desecho || props.pesaje?.desecho || null;
    });

    const servicioPesaje = computed((): Servicio | null => {
      return pesajeData.value.servicio || props.pesaje?.servicio || null;
    });

    const choferEntradaPesaje = computed((): Chofer | null => {
      return (
        pesajeData.value.choferEntrada || props.pesaje?.entrada?.chofer || null
      );
    });

    const pesoEntradaPesaje = computed((): number => {
      return pesajeData.value.pesoEntrada || props.pesaje?.entrada?.peso || 0;
    });

    const state = {
      capacidadPesaje,
      rutaPesaje,
      vehiculoPesaje,
      clientePesaje,
      desechoPesaje,
      servicioPesaje,
      choferEntradaPesaje,
      pesoEntradaPesaje,
    }

    const rules = {
      capacidadPesaje: {
        required,
        minValue: minValue(1),
      },
      rutaPesaje: { required },
      vehiculoPesaje: { required },
      clientePesaje: { required },
      desechoPesaje: { required },
      servicioPesaje: { required },
      choferEntradaPesaje: { required },
      pesoEntradaPesaje: { required },
    };

    const v$ = useVuelidate(rules, state);

    const capacidadErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.capacidadPesaje?.$dirty) return errores;
      if (v$.value.capacidadPesaje.required.$invalid) {
        const error = t(
          "bascula.formulario.capacidad.error.required"
        ) as string;
        errores.push(error);
      }
      if (v$.value.capacidadPesaje.minValue.$invalid) {
        const error = t(
          "bascula.formulario.capacidad.error.minValue"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const vehiculoErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.vehiculoPesaje.$dirty) return errores;
      if (v$.value.vehiculoPesaje.required.$invalid) {
        const error = t("bascula.formulario.vehiculo.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const clienteErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.clientePesaje.$dirty) return errores;
      if (v$.value.clientePesaje.required.$invalid) {
        const error = t("bascula.formulario.cliente.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const desechoErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.desechoPesaje?.$dirty) return errores;
      if (v$.value.desechoPesaje.required.$invalid) {
        const error = t("bascula.formulario.desecho.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const servicioErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.servicioPesaje?.$dirty) return errores;
      if (v$.value.servicioPesaje.required.$invalid) {
        const error = t("bascula.formulario.servicio.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const rutaErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rutaPesaje.$dirty) return errores;
      if (v$.value.rutaPesaje.required.$invalid) {
        const error = t("bascula.formulario.ruta.error") as string;
        errores.push(error);
      }
      return errores;
    });

    const onClickCerrar = (): void => {
      ctx.emit("click:cerrar");
    };

    const onClickGuardar = (pesajeData: PesajeData): void => {
      ctx.emit("click:guardar", pesajeData);
    };

    const guardar = (): void => {
      v$.value.$touch();
      if(log){
        console.log("vuelidate dialogo manual", v$.value);
      }
      if (v$.value.$invalid) return;
      onClickGuardar(pesajeData.value);
    };

    return {
      t,
      v$,
      pesajeData,
      capacidadPesaje,
      rutaPesaje,
      vehiculoPesaje,
      clientePesaje,
      desechoPesaje,
      servicioPesaje,
      choferEntradaPesaje,
      pesoEntradaPesaje,
      capacidadErrors,
      vehiculoErrors,
      clienteErrors,
      desechoErrors,
      servicioErrors,
      rutaErrors,
      onClickCerrar,
      guardar,
    };
  },
});
</script>
