<template>
  <div>
    <GmapPolyline
      v-if="polyline"
      :path.sync="polyline"
      :options="{
        strokeColor: barredor.color,
        strokeOpacity: 1.0,
        strokeWeight: 4,
        zIndex: 20,
      }"
    ></GmapPolyline>
    <MarkerUltimaPosicion
      :zoom="zoom"
      :pings="pings"
      :horario="horario"
      :barredor="barredor"
      v-if="ultimaPosicion"
      :historico="historico"
      :seleccionados="seleccionados"
      :ultima-posicion="ultimaPosicion"
      @click:seleccionar="onClickSeleccionar"
    ></MarkerUltimaPosicion>
    <GmapMarker
      v-if="polyline && zoom > 15"
      :position="polyline[0]"
      :icon="{
        url: require('@/assets/svg/icons/fas/fas-map-marker-alt-secondary.svg'),
        size: { width: 32, height: 32, f: 'px', b: 'px' },
        scaledSize: { width: 32, height: 32, f: 'px', b: 'px' },
      }"
    ></GmapMarker>
  </div>
</template>

<script lang="ts">
//decoradores
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
//tipos
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {PingRastreador, Posicion} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
import {HorarioBarredor, UltimaPosicion} from "@/typings/components/barredores/barredores";
//componentes
import MarkerUltimaPosicion from "@/components/barredores/mapa/ruta/MarkerUltimaPosicion.vue";

@Component({
  components: {MarkerUltimaPosicion},
})
export default class RutaMapa extends Vue {
  @Prop() readonly zoom!: number;
  @Prop() readonly fecha!: string;
  @Prop() readonly barredor!: Barredor;
  @Prop() readonly historico!: boolean;
  @Prop() readonly horario!: HorarioBarredor;
  @Prop() readonly seleccionados!: Barredor[];
  @Prop() readonly pings!: Array<PingRastreador> | null;

  get flagSeleccionado(): boolean {
    return this.seleccionados.some(seleccionado => seleccionado.id === this.barredor.id);
  }

  get ultimaPosicion(): PingRastreador | null {
    return this.pings ? this.pings[this.pings.length - 1] ?? null : null;
  }

  get polyline(): Array<Posicion> | null {
    if (!this.flagSeleccionado || !this.pings) return null;
    const path: Array<Posicion> = [];
    for (const ping of this.pings) {
      const posicion = {
        lat: ping.posicion.lat,
        lng: ping.posicion.lng,
      };
      path.push(posicion);
    }
    return path;
  }

  @Emit("update:ultima-posicion")
  onUpateUltimaPosicion(ultimaPosicion: UltimaPosicion): UltimaPosicion {
    return ultimaPosicion;
  }

  @Emit("update:seleccionado")
  onUpdateSeleccionado(): void {
    return;
  }

  @Emit("click:seleccionar")
  onClickSeleccionar(): string {
    return this.barredor.id ?? "";
  }

  @Watch("flagSeleccionado")
  onFlagSeleccionadoChange(): void {
    this.onUpdateSeleccionado();
  }

  @Watch("ultimaPosicion", {immediate: true})
  onUltimaPosicionChange(val: PingRastreador | null): void {
    this.onUpateUltimaPosicion({
      ping: val,
      barredor: this.barredor,
    });
  }

}
</script>
