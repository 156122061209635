<template>
  <div>
    <v-row no-gutters v-if="!pesaje">
      <v-col>
        <v-row no-gutters class="pt-3 px-3">
          <v-col class="text-center text-h6"> Escane el código QR aquí </v-col>
        </v-row>
        <v-row no-gutters class="justify-center mt-3">
          <v-col cols="6">
            <qrcode-stream @decode="onDecode"></qrcode-stream>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card v-else class="ma-3 white--text" color="secondary">
      <v-row no-gutters class="pt-3 px-3">
        <v-col class="text-h6 text-center"> BOLETO PESAJE DE SALIDA </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Patente: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.vehiculo.patente.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Tipo camión: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.vehiculo.tipo.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Empresa: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.cliente.nombre.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Producto: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.desecho.nombre.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Hoja de ruta: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.ruta.nombre.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Fecha entrada: </v-col>
        <v-col cols="8" class="text-right">
          {{
            pesaje.entrada.fecha
              ? pesaje.entrada.fecha.toDate().format("DD-MM-YYYY")
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Hora entrada: </v-col>
        <v-col cols="8" class="text-right">
          {{
            pesaje.entrada.fecha
              ? pesaje.entrada.fecha.toDate().format("HH:mm:ss")
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Chofer entrada: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.entrada.chofer.nombre.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Obs. entrada: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.entrada.observacion.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Fecha salida: </v-col>
        <v-col cols="8" class="text-right">
          {{
            pesaje.salida.fecha
              ? pesaje.salida.fecha.toDate().format("DD-MM-YYYY")
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Hora salida: </v-col>
        <v-col cols="8" class="text-right">
          {{
            pesaje.salida.fecha
              ? pesaje.salida.fecha.toDate().format("HH:mm:ss")
              : ""
          }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Chofer salida: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.salida.chofer.nombre.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Obs. salida: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.salida.observacion.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Servicio: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.servicio.nombre.toUpperCase() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3">
        <v-divider dark></v-divider>
      </v-row>
      <v-row no-gutters class="pt-3 px-3 font-weight-medium">
        <v-col cols="=4"> Peso entrada: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.entrada.peso.toCurrency() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3 font-weight-medium">
        <v-col cols="=4"> Peso salida: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.salida.peso.toCurrency() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3 px-3 font-weight-medium">
        <v-col cols="=4"> Peso neto: </v-col>
        <v-col cols="8" class="text-right">
          {{ (pesaje.entrada.peso - pesaje.salida.peso).toCurrency() }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3">
        <v-divider dark></v-divider>
      </v-row>
      <v-row no-gutters class="pt-3 px-3">
        <v-col cols="=4"> Tipo báscula: </v-col>
        <v-col cols="8" class="text-right"> Completa </v-col>
      </v-row>
      <v-row no-gutters class="pa-3">
        <v-col cols="=4"> Peso camión: </v-col>
        <v-col cols="8" class="text-right">
          {{ pesaje.salida.peso.toCurrency() }}
        </v-col>
      </v-row>
    </v-card>
    <v-row no-gutters class="pa-3" v-if="pesaje">
      <v-col>
        <v-btn color="primary" block @click="pesaje = null">
          Volver a escanear
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogoEmpresa" max-width="600">
      <v-card>
        <v-row no-gutters class="pt-3 px-3">
          <v-col cols="8" offset="2" class="text-center">
            Ingrese el RUT de la empresa
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn @click="dialogoEmpresa = false" icon small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3 px-3">
          <v-col>
            <v-text-field
              outlined
              dense
              hide-details
              label="RUT Empresa"
              v-model="empresa"
              v-mask="['#######-X', '########-X']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-3">
          <v-col>
            <v-btn block color="primary" @click="aceptarEmpresa">
              Aceptar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
// composition-api
import { defineComponent, ref } from "vue";
// vue-qrcode-reader
import { QrcodeStream } from "vue-qrcode-reader";
//  composables
import { usePesajes } from "@/composables/pesajes";
import { useUsuario } from "@/composables/usuario";
// tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";

interface PesajeInner {
  vehiculo: {
    patente: string;
    tipo: string;
  };
  cliente: {
    nombre: string;
  };
  desecho: {
    nombre: string;
  };
  servicio: {
    nombre: string;
  };
  ruta: {
    nombre: string;
  };
  entrada: {
    peso: number;
    observacion: string;
    chofer: {
      nombre: string;
    };
    fecha: string;
  };
  salida: {
    peso: number;
    observacion: string;
    chofer: {
      nombre: string;
    };
    fecha: string;
  };
}

export default defineComponent({
  name:"VistaQR",
  components: {
    QrcodeStream,
  },
  setup() {
    const _usuario = useUsuario();
    const _pesajes = usePesajes();

    const pesaje = ref<PesajeInner | null>(null);
    const empresa = ref("");
    const dialogoEmpresa = ref(false);
    const scannedId = ref("");

    const pesajeToPesajeInner = (_pesaje: Pesaje) => {
      return {
        vehiculo: {
          patente: _pesaje.vehiculo?.patente ?? "",
          tipo: _pesaje.vehiculo?.tipo ?? "",
        },
        cliente: {
          nombre: _pesaje.cliente?.nombre ?? "",
        },
        desecho: {
          nombre: _pesaje.desecho?.nombre ?? "",
        },
        servicio: {
          nombre: _pesaje.servicio?.nombre ?? "",
        },
        ruta: {
          nombre: _pesaje.ruta?.nombre ?? "",
        },
        entrada: {
          peso: _pesaje.entrada?.peso ?? 0,
          observacion: _pesaje.entrada?.observacion ?? "",
          chofer: {
            nombre: _pesaje.entrada?.chofer?.nombre ?? "",
          },
          fecha: _pesaje.entrada?.fecha ?? "",
        },
        salida: {
          peso: _pesaje.salida?.peso ?? 0,
          observacion: _pesaje.salida?.observacion ?? "",
          chofer: {
            nombre: _pesaje.salida?.chofer?.nombre ?? "",
          },
          fecha: _pesaje.salida?.fecha ?? "",
        },
      };
    };

    const onDecode = async (val: string): Promise<void> => {
      const splitted = val.split("@");
      const id = splitted[0];
      const rutEmpresa: string | null =
        splitted[1] || (_usuario.getEmpresa.value.id ?? "");
      if (rutEmpresa) {
        await _pesajes.fetchAndAdd({
          pathVariables: {
            idEmpresa: rutEmpresa,
          },
          clauses: {
            where: [["id", "==", id]],
          },
        });
        const result = _pesajes.get.value[id];
        if (result) {
          pesaje.value = pesajeToPesajeInner(result);
        }
      } else {
        dialogoEmpresa.value = true;
        scannedId.value = id;
      }
    };

    const aceptarEmpresa = (): void => {
      onDecode(`${scannedId.value}@${empresa.value}`);
      dialogoEmpresa.value = false;
      scannedId.value = "";
      empresa.value = "";
    };

    return {
      pesaje,
      empresa,
      dialogoEmpresa,
      onDecode,
      aceptarEmpresa,
    };
  },
});
</script>
