<template>
  <v-row no-gutters justify="space-between" align="end">
    <v-col cols="8">
      <v-row no-gutters align="end">
        <v-col cols="4" class="pr-2">
          <search-text-field
            v-model="search"
            :placeholder="String($t('clientes.search.placeholder'))"
          ></search-text-field>
        </v-col>
        <v-col cols="3" class="px-2">
          <filter-select
            :items="tipos"
            v-model="filtroTipo"
            :placeholder="String($t('clientes.filtro.tipo.placeholder'))"
            :label="String($t('clientes.filtro.tipo.label'))"
          ></filter-select>
        </v-col>
        <v-col cols="3" class="pl-2">
          <filter-select
            :placeholder="String($t('clientes.filtro.estado.placeholder'))"
            :label="String($t('clientes.filtro.estado.label'))"
            :items="estados"
            v-model="filtroEstado"
          ></filter-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto" v-if="mostrarBotonAgregar">
      <add-button
        icon="ag-tie"
        :disabled="buttonDisabled"
        @click="onClickAgregar"
      >
        {{ $t("clientes.boton-add.text") }}
      </add-button>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//composition
import { computed, defineComponent } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { FiltrosCliente } from "../../typings/components/clientes/filtros";
import {
  EstadoCliente,
  TipoCliente,
} from "@/typings/store/plugins/easyFirestore/clientes";
//componentes
import SearchTextField from "@/components/custom/SearchTextField.vue";
import FilterSelect from "@/components/custom/FilterSelect.vue";
import AddButton from "@/components/custom/AddButton.vue";

export default defineComponent({
  name: "FiltrosClientes",
  components: {
    AddButton,
    FilterSelect,
    SearchTextField,
  },
  props: {
    value: {
      type: Object as () => FiltrosCliente,
      required: true,
    },
    buttonDisabled: {
      type: Boolean,
    },
    permisos: {
      type: Object as () => Permisos,
      required: true,
    },
  },
  emits: ["input", "click:agregar"],
  setup(props, ctx) {
    const mostrarBotonAgregar = props.permisos.clientes !== "ver";

    const estados = [
      {
        text: i18n.t("clientes.filtro.estado.values[0]") as string,
        value: "habilitado",
      },
      {
        text: i18n.t("clientes.filtro.estado.values[1]") as string,
        value: "deshabilitado",
      },
    ];

    const tipos = [
      {
        text: i18n.t("clientes.filtro.tipo.values[0]") as string,
        value: "empresa",
      },
      {
        text: i18n.t("clientes.filtro.tipo.values[1]") as string,
        value: "particular",
      },
      {
        text: i18n.t("clientes.filtro.tipo.values[2]") as string,
        value: "municipal",
      },
    ];

    const search = computed({
      get() {
        return props.value.search;
      },
      set(value: string) {
        const filtros = Object.assign({}, props.value);
        filtros.search = value || "";
        ctx.emit("input", filtros);
      },
    });

    const filtroTipo = computed({
      get() {
        return props.value.tipo;
      },
      set(value: TipoCliente | null) {
        const filtros = Object.assign({}, props.value);
        filtros.tipo = value;
        ctx.emit("input", filtros);
      },
    });

    const filtroEstado = computed({
      get() {
        return props.value.estado;
      },
      set(value: EstadoCliente | null) {
        const filtros = Object.assign({}, props.value);
        filtros.estado = value;
        ctx.emit("input", filtros);
      },
    });

    const onClickAgregar = () => {
      ctx.emit("click:agregar");
    };

    

    return {
      mostrarBotonAgregar,
      estados,
      tipos,
      search,
      filtroTipo,
      filtroEstado,
      onClickAgregar,
    };
  },
});
</script>
