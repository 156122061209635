<template>
  <v-row
    justify="center"
    align="center"
    no-gutters
  >
    <v-col cols="3">
      <v-row
        no-gutters
        align="center"
        justify="space-between"
      >
        <v-col
          cols="auto"
          :class="{
            'metropolis font-weight-bold': true,
            'white--text': dialog,
            'darker--text': !dialog
          }"
        >
          {{ label }}
        </v-col>
        <v-col
          cols="auto"
          class="pa-0 error--text metropolis"
          v-if="required"
        >
          *
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="9" class="pl-2">
      <v-text-field
        :solo="!dialog"
        :outlined="dialog"
        dense
        hide-details="auto"
        :class="{
          'rounded-input metropolis': true,
          'elevation-input-dense': !dialog,
          'border-input': dialog
        }"
        :dark="dialog"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        :readonly="readonly"
        :disabled="disabled"
        v-model="vModel"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @input="onInput"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//composition
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: "FormTextField",
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array as () => Array<string>,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    dialog: {
      type: Boolean
    }
  },
  emits: [
    "blur",
    "focus",
    "change",
    "input"
  ],
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    const onBlur = (ev: FocusEvent) => {
      ctx.emit("blur", ev);
    };

    const onFocus = (ev: FocusEvent) => {
      ctx.emit("focus", ev);
    };


    const onChange = (value: string) => {
      ctx.emit("change", value);
    };


    const onInput = (value: string) => {
      ctx.emit("input", value);
    };

    return {
      vModel,
      onBlur,
      onFocus,
      onChange,
      onInput
    };
  }
});
</script>
