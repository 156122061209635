<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ t("transporte.tab.rutas.formulario.nombre.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.rutas.formulario.nombre.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="nombre"
              :error-messages="erroresNombre"
              @focus="v$.nombre.$reset()"
              @input="v$.nombre.$touch()"
              @blur="v$.nombre.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ t("transporte.tab.rutas.formulario.tipo.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-select
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.rutas.formulario.tipo.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="tipo"
              :items="tipos"
              :error-messages="erroresTipo"
              @focus="v$.tipo.$reset()"
              @blur="v$.tipo.$touch()"
              :menu-props="{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }"
            >
              <template v-slot:item="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold"
                >
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold"
                >
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:append>
                <v-icon color="primary"> mdi-chevron-down </v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters align="start" class="pt-2">
          <v-col cols="3" class="metropolis text-body-1 darker--text">
            {{ t("transporte.tab.rutas.formulario.descripcion.label") }}
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-textarea
              solo
              dense
              no-resize
              auto-grow
              rows="2"
              hide-details="auto"
              :placeholder="
                t('transporte.tab.rutas.formulario.descripcion.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="descripcion"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0 pt-2" justify="center">
      <v-col cols="4">
        <v-btn
          large
          block
          height="48px"
          :class="`rounded-input elevation-primary gradient-primary ${
            cargando ? 'button-disabled' : ''
          }`"
          @click="guardar"
        >
          <svg-icon name="three-dots" v-if="cargando"></svg-icon>
          <v-row v-else no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon
                :name="ruta ? 'ag-save' : 'ag-add'"
                color="white"
              ></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ textoBoton }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import {
  EstadoRutaPesaje,
  RutaPesaje,
  TipoRutaPesaje,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: { SvgIcon },
  props: {
    ruta: {
      default: null,
      type: Object as () => RutaPesaje | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    if (this.ruta) {
      this.id = this.ruta.id ?? "";
      this.nombre = this.ruta.nombre;
      this.tipo = this.ruta.tipo;
      this.estado = this.ruta.estado ?? "habilitado";
      this.descripcion = this.ruta.descripcion ?? "";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const nombre = ref("");
    const tipo = ref<TipoRutaPesaje | null>(null);
    const estado = ref<EstadoRutaPesaje>("habilitado");
    const descripcion = ref("");

    const tipos = computed(
      (): Array<{ text: string; value: TipoRutaPesaje }> => {
        return [
          {
            text: t("transporte.tab.rutas.formulario.tipo.values[0]") as string,
            value: "rural",
          },
          {
            text: t("transporte.tab.rutas.formulario.tipo.values[1]") as string,
            value: "urbano",
          },
        ];
      }
    );

    const titulo = computed((): string => {
      const index = props.ruta ? 0 : 1;
      return t(`transporte.tab.rutas.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.ruta ? 0 : 1;
      return t(`transporte.tab.rutas.formulario.boton.text.${index}`) as string;
    });

    const state = reactive({
      nombre,
      tipo,
    });

    const rules = {
      nombre: { required },
      tipo: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid)
        errores.push(
          t(
            "transporte.tab.rutas.formulario.nombre.error-messages[0]"
          ) as string
        );
      if (nombre.value.length > 30)
        errores.push(
          t(
            "transporte.tab.rutas.formulario.nombre.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresTipo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errores;
      if (v$.value.tipo.required.$invalid)
        errores.push(
          t("transporte.tab.rutas.formulario.tipo.error-message") as string
        );
      return errores;
    });

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || nombre.value.length > 30) return;
      const ruta: RutaPesaje = {
        nombre: nombre.value,
        tipo: tipo.value ?? "rural",
        estado: estado.value,
        descripcion: descripcion.value,
      };
      if (props.ruta) ruta.id = props.ruta.id;
      ctx.emit("click:guardar", ruta);
    };

    return {
      t,
      v$,
      id,
      nombre,
      tipo,
      estado,
      descripcion,
      tipos,
      titulo,
      textoBoton,
      erroresNombre,
      erroresTipo,
      guardar,
    };
  },
});
</script>
