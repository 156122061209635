<template>
  <div class="relative">
    <button class="button-map" :title="titleHeatmap" @click="mostrarHeatmap">
      <v-icon>
        {{ showHeatmap ? "mdi-fire-off" : "mdi-fire" }}
      </v-icon>
    </button>
    <GmapMap
      :center="center"
      :zoom.sync="zoom"
      map-type-id="terrain"
      class="google-map"
      ref="map"
      :options="{
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: require('@/assets/json/gmap/MapStyles.json'),
      }"
      @bounds_changed="boundsChangedHandler"
    >
      <RutaMapa
        :zoom="zoom"
        :fecha="fecha"
        :key="barredor.id"
        :barredor="barredor"
        :historico="historico"
        :seleccionados="seleccionados"
        v-for="barredor in barredores"
        :pings="agrupados[barredor.id]"
        :horario="horarios[barredor.id]"
        @click:seleccionar="seleccionar"
        @update:seleccionado="centrarMapa"
        @update:ultima-posicion="updateUltimaPosicion"
      ></RutaMapa>
      <GmapPolyline
        v-for="polyline in polylinesRutasPlanificadas"
        :key="`polyline-planificada-${polyline.id}`"
        :path.sync="polyline.path"
        :options="{
          strokeColor: polyline.color,
          strokeOpacity: 0.2,
          strokeWeight: 15,
          zIndex: 19,
        }"
      ></GmapPolyline>
      <template v-if="zoom > 15">
        <GmapMarker
          v-for="polyline in polylinesRutasPlanificadas"
          :key="`marker-fin-plan-${polyline.id}`"
          :position="polyline.path[polyline.path.length - 1]"
          :icon="{
            url: require('@/assets/svg/icons/fas/fas-map-marker-alt-green.svg'),
            size: { width: 28, height: 28, f: 'px', b: 'px' },
            scaledSize: { width: 28, height: 28, f: 'px', b: 'px' },
          }"
        ></GmapMarker>
      </template>
    </GmapMap>
  </div>
</template>

<script lang="ts">
//decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

//helpers
import "@/helpers/extensions";
import {roundTo} from "@/helpers/number";

//google maps
import {gmapApi, google} from "vue2-google-maps";

//tipos
import {Empresa} from "@/typings/store/plugins/easyFirestore/empresas";
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {PingRastreador} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
import {TiempoPings, RutasBarredor} from "@/typings/store/plugins/easyFirestore/rutasBarredor";
import {HorariosBarredores, Polyline, UltimaPosicion} from "@/typings/components/barredores/barredores";

//componentes
import RutaMapa from "@/components/barredores/mapa/Ruta.vue";

@Component({
  components: {
    RutaMapa,
  },
})
export default class MapaBarredores extends Vue {
  @VModel() tiempoPings!: TiempoPings;
  @Prop() readonly fecha!: string;
  @Prop() readonly empresa!: Empresa;
  @Prop() readonly rutas!: RutasBarredor;
  @Prop() readonly seleccionados!: Barredor[];
  @Prop() readonly barredores!: Array<Barredor>;
  @Prop() readonly items!: Array<PingRastreador>;

  created(): void {
    if (this.empresa.posicion) {
      this.center = this.empresa.posicion;
    }
  }

  hoy = new Date();
  zoom = 14;
  center = {
    lat: -35.529852,
    lng: -71.490037,
  };
  heatmap: google.maps.visualization.HeatmapLayer | null = null;
  showHeatmap = false;

  get google(): google {
    return gmapApi();
  }

  get titleHeatmap(): string {
    const index = this.showHeatmap ? 1 : 0;
    return this.$t(`barredores.mapa.heatmap.tooltip.${index}`) as string;
  }

  get historico(): boolean {
    const fechaSeleccionada = this.fecha.toDate();
    fechaSeleccionada.setHours(23, 59, 59, 999);
    return fechaSeleccionada < this.hoy;
  }

  get horarios(): HorariosBarredores {
    const regex = /^(2[0-3]|[0-1]\d):[0-5]\d-(2[0-3]|[0-1]\d):[0-5]\d$/;
    const result: HorariosBarredores = {};
    for (const barredor of this.barredores) {
      if (!barredor.id) continue;
      let [horaInicio, minutosInicio] = [0, 0];
      let [horaFin, minutosFin] = [23, 59];
      if (barredor.horario && regex.test(barredor.horario)) {
        const [horarioInicio, horarioFin] = barredor.horario.split("-");
        [horaInicio, minutosInicio] = horarioInicio.split(":").map(Number);
        [horaFin, minutosFin] = horarioFin.split(":").map(Number);
      }
      const fechaInicio = this.fecha.toDate();
      fechaInicio.setHours(horaInicio, minutosInicio, 0, 0);
      const fechaFin = this.fecha.toDate();
      fechaFin.setHours(horaFin, minutosFin, 59, 999);
      result[barredor.id] = {
        fechaInicio,
        fechaFin,
      };
    }
    return result;
  }

  get itemsOrdenados(): Array<PingRastreador> {
    const items = this.items.slice();
    items.sort(
      (a, b) => a.fecha.toDate().getTime() - b.fecha.toDate().getTime(),
    );
    return items;
  }

  get agrupados(): { [id: string]: Array<PingRastreador> } {
    const result: { [id: string]: Array<PingRastreador> } = {};
    for (const ping of this.itemsOrdenados) {
      if (!ping.valido) continue;
      const fechaPing = ping.fecha.toDate();
      for (const barredor of ping.barredores) {
        if (!barredor.id) continue;
        const id = barredor.id;
        const horario = this.horarios[id];
        if (
          horario &&
          horario.fechaInicio <= fechaPing &&
          fechaPing <= horario.fechaFin
        ) {
          if (!result[id]) {
            result[id] = [];
          }
          result[id].push(ping);
        }
      }
    }
    return result;
  }

  get heatmapData(): Array<google.maps.visualization.WeightedLocation> {
    const result: Array<google.maps.visualization.WeightedLocation> = [];
    if (!this.google) return result;
    for (const id in this.agrupados) {
      this.agrupados[id].forEach((barredor, index, ordenados) => {
        if (ordenados.length - 1 == index) return;
        const barredor2 = ordenados[index + 1];
        const inicio = barredor.posicion;
        inicio.lat = roundTo(inicio.lat, 4);
        inicio.lng = roundTo(inicio.lng, 4);
        const fin = barredor2.posicion;
        fin.lat = roundTo(fin.lat, 4);
        fin.lng = roundTo(fin.lng, 4);
        const latDiff = fin.lat - inicio.lat;
        const lngDiff = fin.lng - inicio.lng;
        const latSign = Math.sign(latDiff);
        const lngSign = Math.sign(lngDiff);
        const latDiffAbs = Math.abs(latDiff);
        const lngDiffAbs = Math.abs(lngDiff);
        if (latDiffAbs > lngDiffAbs) {
          for (let i = 0; i < latDiffAbs; i += 0.0001) {
            const lat = inicio.lat + i * latSign;
            const lng = inicio.lng + (lngDiff / latDiffAbs) * i;
            result.push({
              location: new this.google.maps.LatLng(lat, lng),
              weight: 8,
            });
          }
        } else {
          for (let i = 0; i < lngDiffAbs; i += 0.0001) {
            const lat = inicio.lat + (latDiff / lngDiffAbs) * i;
            const lng = inicio.lng + i * lngSign;
            result.push({
              location: new this.google.maps.LatLng(lat, lng),
              weight: 8,
            });
          }
        }
      });
    }
    return result;
  }

  get polylinesRutasPlanificadas(): Array<Polyline> {
    const polylines: Array<Polyline> = [];
    for (const barredor of this.barredores) {
      if (!barredor.id) continue;
      if (this.seleccionados.some(seleccionado => seleccionado.id === barredor.id)) {
        const ruta = barredor.ruta?.id ? this.rutas[barredor.ruta.id] : null;
        if (ruta && barredor.mostrarRuta) {
          polylines.push({
            id: barredor.id,
            path: ruta.posiciones,
            color: barredor.color ?? "#FF0000",
          });
        }
      }
    }
    return polylines;
  }

  get map(): Vue & { $mapObject: google.maps.Map } {
    return this.$refs.map as Vue & { $mapObject: google.maps.Map };
  }

  @Emit("change:seleccionado")
  onChangeSeleccionado(barredor: Barredor): Barredor {
    return barredor;
  }

  @Watch("heatmapData")
  onHeatmapDataChanged(): void {
    if (this.heatmap) {
      this.heatmap.setData(this.heatmapData);
    }
  }

  mostrarHeatmap(): void {
    this.showHeatmap = !this.showHeatmap;
    if (this.showHeatmap) {
      this.heatmap = new this.google.maps.visualization.HeatmapLayer({
        data: this.heatmapData,
        map: this.map.$mapObject,
        radius: 10,
        opacity: 0.6,
        maxIntensity: 100,
      });
    } else if (this.heatmap) {
      this.heatmap.setMap(null);
    }
  }

  updateUltimaPosicion(ultimaPosicion: UltimaPosicion): void {
    if (!ultimaPosicion.barredor.id) return;
    const ultimoPing = ultimaPosicion.ping;
    const fecha = ultimoPing?.fecha?.toDate() ?? new Date();
    if (!ultimoPing) fecha.setHours(0, 0, 0, 0);
    const diferencia = new Date().getTime() - fecha.getTime();
    this.$set(this.tiempoPings, ultimaPosicion.barredor.id, diferencia);
  }

  centrarMapa(): void {
    const bounds = new this.google.maps.LatLngBounds();
    for (const seleccionado of this.seleccionados) {
      if (!seleccionado?.id) continue;
      const agrupados = this.agrupados[seleccionado.id];
      if (!agrupados) continue;
      const posiciones = agrupados.map(agrupado => agrupado.posicion);
      for (const posicion of posiciones) {
        bounds.extend(posicion);
      }
    }
    if (!bounds.isEmpty()) this.map.$mapObject.fitBounds(bounds);
  }

  boundsChangedHandler(): void {
    const zoom = this.map.$mapObject.getZoom();
    if (!zoom) return;
    this.zoom = zoom;
  }

  seleccionar(id: string): void {
    const barredor = this.barredores.find((barredor) => barredor.id === id);
    if (barredor) this.onChangeSeleccionado(barredor);
  }
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: calc(100vh - 330px);
  border-radius: 10px !important;
  overflow: hidden;
}

@media (max-width: 960px) {
  .google-map {
    height: calc(100vh - 298px);
  }
}

.relative {
  position: relative;
}

.button-map {
  background: none rgb(255, 255, 255);
  border: 0;
  margin: 10px;
  padding: 0;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px;
  overflow: hidden;
  top: 60px;
  right: 0;
  z-index: 100;
}

.button-map >>> i {
  color: #666666 !important;
}

.button-map:hover >>> i {
  color: #333333 !important;
}

.button-map:active >>> i {
  color: #111111 !important;
}
</style>
