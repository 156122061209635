<template>
  <v-card height="100%" color="dialog" class="rounded-input elevation-black">
    <v-card-text class="pa-0 fill-height">
      <v-img
        v-if="fotoSeleccionada"
        :src="fotoSeleccionada"
        class="rounded-input"
        height="100%"
        width="100%"
        contain
      >
        <v-row no-gutters justify="end" class="px-4 pt-4">
          <v-col cols="auto">
            <v-btn
              x-small
              width="45px"
              height="45px"
              depressed
              class="gradient-error rounded-input"
              @click="fotoSeleccionada = null"
            >
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
      <template v-else>
        <v-row no-gutters justify="end" class="px-4 pt-4">
          <v-col
            cols="8"
            class="white--text metropolis text-h6 font-weight-bold text-center"
          >
            {{ $t("bascula.dialogo-galeria-imagenes.title") }}
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn
              x-small
              width="45px"
              height="45px"
              depressed
              class="gradient-error rounded-input"
              @click="onClickCerrar"
            >
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2" v-if="fotos.length > 0">
          <v-col
            cols="3"
            v-for="(foto, index) in fotos"
            :key="`foto-${index}`"
            class="pa-2"
          >
            <v-card flat class="rounded-input" @click="seleccionarFoto(foto)">
              <v-img :src="foto" class="rounded-input"></v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else no-gutters class="pt-3 px-2" justify="center">
          <v-col cols="auto" class="text--text metropolis text-body-1">
            {{ $t("bascula.dialogo-galeria-imagenes.mensaje.no-data") }}
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
//componentes
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import {
  Archivos,
  Archivo,
} from "@/typings/store/plugins/easyFirestore/archivos";

@Component
export default class GaleriaImagenes extends Vue {
  @Prop() readonly pesaje!: Pesaje;
  @Prop() readonly archivos!: Archivos;

  fotoSeleccionada: string | null = null;

  get arrayArchivos(): Array<Archivo> {
    return Object.values(this.archivos);
  }

  get fotos(): Array<string> {
    return this.pesaje.fotos.map((foto) => {
      return foto.url ?? "";
    });
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }

  seleccionarFoto(foto: string): void {
    this.fotoSeleccionada = foto;
  }
}
</script>

<style scoped></style>
