import { render, staticRenderFns } from "./CaracteristicasPesaje.vue?vue&type=template&id=244d6727&scoped=true"
import script from "./CaracteristicasPesaje.vue?vue&type=script&lang=ts"
export * from "./CaracteristicasPesaje.vue?vue&type=script&lang=ts"
import style0 from "./CaracteristicasPesaje.vue?vue&type=style&index=0&id=244d6727&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244d6727",
  null
  
)

export default component.exports