<template>
  <v-card color="dialog" class="rounded-input">
    <v-card-text class="py-8">
      <v-row dense no-gutters align="center">
        <v-col class="pl-4">
          <v-row no-gutters justify="center">
            <v-col
              cols="auto"
              class="text-center white--text titulo metropolis"
            >
              {{ $t("bascula.sin-foto.titulo") }}
            </v-col>
          </v-row>
          <v-row dense no-gutters justify="center" class="pt-6">
            <v-col cols="12" class="white--text mensaje metropolis text-center">
              {{ $t("bascula.sin-foto.mensaje") }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense no-gutters align="center" justify="center" class="pt-6">
        <v-col cols="6" class="pr-2">
          <v-btn
            block
            height="48px"
            class="gradient-secondary elevation-dark white--text font-weight-bold metropolis text-initial rounded-input text-body-1"
            @click="onClickCerrar"
          >
            {{ $t("bascula.sin-foto.boton") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Vue } from "vue-property-decorator";

@Component
export default class DialogoSinFotos extends Vue {
  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }
}
</script>

<style scoped>
.titulo {
  font-size: 32px;
  font-weight: 800;
}

.mensaje {
  font-size: 24px;
  font-weight: 600;
}
</style>
