<template>
  <div class="d-flex flex-column fill-height">
    <v-row
      no-gutters
      align="end"
      justify="space-between"
      class="pt-10 mr-12 flex-grow-0"
    >
      <v-col cols="8">
        <FiltrosTransportes :tab="tab" v-model="filtros"></FiltrosTransportes>
      </v-col>
      <v-col cols="auto" v-if="mostrarBotonAgregar">
        <add-button
          icon="ag-add"
          :disabled="buttonDisabled"
          @click="abrirFormularioAgregar"
        >
          {{ $t("transporte.boton-add.text", { msg: textoAgregar }) }}
        </add-button>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-3 flex-grow-0">
      <v-col cols="6">
        <TabsTransporte v-model="tab"></TabsTransporte>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tabs-items class="fill-height" v-model="tab">
          <v-tab-item class="fill-height background">
            <TabChoferes
              v-if="tab === 0"
              :arrayChoferes="choferesFiltrados"
              :mostrar-detalle="mostrarDetalle"
              :mostrar-formulario="mostrarFormulario"
              :search="filtros.search"
              :chofer="chofer"
              :cargando="cargando"
              :usuario="getUsuario"
              :permisos="getPermisosUsuario"
              @click:guardar="guardarChofer"
              @click:informacion="clickInformacionChofer"
              @click:editar="abrirFormularioEditar"
              @click:eliminar="eliminarChofer"
              @click:switch-estado="cambiarEstadoChofer"
            ></TabChoferes>
          </v-tab-item>
          <v-tab-item class="fill-height background">
            <TabVehiculos
              v-if="tab === 1"
              :arrayVehiculos="vehiculosFiltrados"
              :mostrar-detalle="mostrarDetalle"
              :mostrar-formulario="mostrarFormulario"
              :search="filtros.search"
              :vehiculo="vehiculo"
              :cargando="cargando"
              :usuario="getUsuario"
              :permisos="getPermisosUsuario"
              @click:guardar="guardarVehiculo"
              @click:informacion="clickInformacionVehiculo"
              @click:editar="abrirFormularioEditar"
              @click:eliminar="eliminarVehiculo"
              @click:switch-estado="cambiarEstadoVehiculo"
            ></TabVehiculos>
          </v-tab-item>
          <v-tab-item class="fill-height background">
            <TabRutasPesaje
              v-if="tab === 2"
              :arrayRutasPesaje="rutasPesajeFiltradas"
              :mostrar-detalle="mostrarDetalle"
              :mostrar-formulario="mostrarFormulario"
              :search="filtros.search"
              :ruta-pesaje="ruta"
              :cargando="cargando"
              :permisos="getPermisosUsuario"
              @click:guardar="guardarRuta"
              @click:informacion="clickInformacionRuta"
              @click:editar="abrirFormularioEditar"
              @click:eliminar="eliminarRuta"
              @click:switch-estado="cambiarEstadoRuta"
            ></TabRutasPesaje>
          </v-tab-item>
          <v-tab-item class="fill-height background">
            <TabIdentificadores
              v-if="tab === 3"
              :arrayIdentificadores="identificadoresFiltrados"
              :arrayVehiculos="arrayVehiculos"
              :mostrar-detalle="mostrarDetalle"
              :mostrar-formulario="mostrarFormulario"
              :search="filtros.search"
              :identificador="identificador"
              :cargando="cargando"
              :permisos="getPermisosUsuario"
              @click:guardar="guardarIdentificador"
              @click:informacion="clickInformacionIdentificador"
              @click:editar="abrirFormularioEditar"
              @click:eliminar="eliminarIdentificador"
              @click:switch-estado="cambiarEstadoIdentificador"
            ></TabIdentificadores>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      max-width="565"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue, Watch } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//tipos
import {
  Chofer,
  ChoferPatch,
} from "@/typings/store/plugins/easyFirestore/choferes";
import {
  Vehiculo,
  VehiculoPatch,
} from "@/typings/store/plugins/easyFirestore/vehiculos";
import {
  RutaPesaje,
  RutaPesajePatch,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import {
  Identificador,
  IdentificadorPatch,
} from "@/typings/store/plugins/easyFirestore/identificadores";
import { FiltrosTransporte } from "@/typings/components/transporte/filtros";
import { DataFormularioChofer } from "@/typings/components/choferes/formulario";
import { DataFormularioVehiculo } from "@/typings/components/vehiculos/formulario";
//componentes
import TabChoferes from "@/components/transporte/choferes/Tab.vue";
import TabVehiculos from "@/components/transporte/vehiculos/Tab.vue";
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import TabsTransporte from "@/components/transporte/Tabs.vue";
import TabRutasPesaje from "@/components/transporte/rutas/Tab.vue";
import FiltrosTransportes from "@/components/transporte/Filtros.vue";
import TabIdentificadores from "@/components/transporte/identificadores/Tab.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import AddButton from "@/components/custom/AddButton.vue";

@Component({
  components: {
    AddButton,
    TabChoferes,
    TabVehiculos,
    SuccessDialog,
    TabRutasPesaje,
    TabsTransporte,
    FiltrosTransportes,
    TabIdentificadores,
    ConfirmationDialog,
  },
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArchivos: "archivos/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayChoferes: "choferes/getArray",
    getArrayVehiculos: "vehiculos/getArray",
    getPermisosUsuario: "usuario/getPermisos",
    getArrayRutasPesaje: "rutasPesaje/getArray",
    getArrayIdentificadores: "identificadores/getArray",
  }),
  methods: mapActions({
    setChofer: "choferes/set",
    setVehiculo: "vehiculos/set",
    deleteChofer: "choferes/delete",
    setRutaPesaje: "rutasPesaje/set",
    deleteVehiculo: "vehiculos/delete",
    setBlobArchivo: "archivos/setBlob",
    deleteRutaPesaje: "rutasPesaje/delete",
    setIdentificador: "identificadores/set",
    deleteIdentificador: "identificadores/delete",
  }),
})
export default class VistaTransporte extends Vue {
  created(): void {
    if (!routeGuard()) {
      return;
    }
  }

  tab = 0;
  cargando = false;
  mostrarFormulario = false;
  mostrarDetalle = false;
  chofer: Chofer | null = null;
  vehiculo: Vehiculo | null = null;
  ruta: RutaPesaje | null = null;
  identificador: Identificador | null = null;
  dialogoExito = {
    model: false,
    mensaje: "",
    icono: "",
  };
  dialogoConfirmacion: {
    model: boolean;
    texto: string;
    si: (() => void) | null;
    no: (() => void) | null;
  } = {
    model: false,
    texto: "",
    si: null,
    no: null,
  };
  filtros: FiltrosTransporte = {
    search: "",
    estado: null,
    tipoRuta: null,
    combustible: null,
    tipoVehiculo: null,
  };

  get buttonDisabled(): boolean {
    return (
      this.mostrarFormulario &&
      !(this.chofer || this.vehiculo || this.ruta || this.identificador)
    );
  }

  get mostrarBotonAgregar(): boolean {
    const permiso = this.getPermisosUsuario["transporte"] || null;
    return permiso === "editar" || permiso === "eliminar";
  }

  get choferesFiltrados(): Array<Chofer> {
    return this.getArrayChoferes.filter((chofer) => {
      return this.filtros.estado ? chofer.estado === this.filtros.estado : true;
    });
  }
  get textoAgregar(): string {
    switch (this.tab) {
      case 0:
        return this.$t("transporte.boton-add.message[0]") as string;
      case 1:
        return this.$t("transporte.boton-add.message[1]") as string;
      case 2:
        return this.$t("transporte.boton-add.message[2]") as string;
      case 3:
        return this.$t("transporte.boton-add.message[3]") as string;
      default:
        return "";
    }
  }
  get arrayVehiculos(): Array<Vehiculo> {
    return this.getArrayVehiculos;
  }

  get vehiculosFiltrados(): Array<Vehiculo> {
    return this.arrayVehiculos.filter((vehiculo) => {
      const filtroTipo = this.filtros.tipoVehiculo
        ? vehiculo.tipo === this.filtros.tipoVehiculo
        : true;
      const filtroCombustible = this.filtros.combustible
        ? vehiculo.combustible === this.filtros.combustible
        : true;
      const filtroEstado = this.filtros.estado
        ? vehiculo.estado === this.filtros.estado
        : true;
      return filtroTipo && filtroCombustible && filtroEstado;
    });
  }

  get rutasPesajeFiltradas(): Array<RutaPesaje> {
    return this.getArrayRutasPesaje.filter((ruta) => {
      const filtroTipo = this.filtros.tipoRuta
        ? ruta.tipo === this.filtros.tipoRuta
        : true;
      const filtroEstado = this.filtros.estado
        ? ruta.estado === this.filtros.estado
        : true;
      return filtroTipo && filtroEstado;
    });
  }

  get identificadoresFiltrados(): Array<Identificador> {
    return this.getArrayIdentificadores.filter((identificador) => {
      return this.filtros.estado
        ? identificador.estado === this.filtros.estado
        : true;
    });
  }

  @Watch("tab")
  onTabChange(): void {
    this.cerrarFormulario();
    this.limpiarFiltros();
  }

  limpiarFiltros(): void {
    this.filtros = {
      search: "",
      estado: null,
      tipoRuta: null,
      combustible: null,
      tipoVehiculo: null,
    };
  }

  abrirFormularioAgregar(): void {
    this.mostrarFormulario = true;
    this.mostrarDetalle = false;
    this.chofer = null;
    this.vehiculo = null;
    this.ruta = null;
    this.identificador = null;
  }

  abrirFormularioEditar(): void {
    this.mostrarFormulario = true;
    this.mostrarDetalle = false;
  }

  cerrarFormulario(): void {
    this.mostrarFormulario = false;
    this.mostrarDetalle = false;
    this.chofer = null;
    this.vehiculo = null;
    this.ruta = null;
    this.identificador = null;
  }

  abrirDialogoExito(mensaje: string, icono: string): void {
    this.dialogoExito = {
      model: true,
      mensaje: mensaje,
      icono: icono,
    };
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      mensaje: "",
      icono: "",
    };
  }

  cerrarMensajeConfirmacion(): void {
    this.dialogoConfirmacion = {
      model: false,
      texto: "",
      si: null,
      no: null,
    };
  }

  ///CHOFERES

  mostrarDetalleChofer(chofer: Chofer): void {
    if (this.chofer?.id === chofer.id) {
      this.chofer = null;
      this.mostrarDetalle = false;
    } else {
      this.mostrarDetalle = true;
      this.mostrarFormulario = false;
      this.chofer = chofer;
      this.vehiculo = null;
      this.ruta = null;
      this.identificador = null;
    }
  }

  clickInformacionChofer(chofer: Chofer): void {
    if (this.chofer && this.mostrarFormulario) {
      this.dialogoConfirmacion = {
        model: true,
        texto: this.$t(
          "transporte.tab.choferes.mensaje-confirmacion.message.save-changes"
        ) as string,
        si: () => {
          this.mostrarDetalleChofer(chofer);
          this.cerrarMensajeConfirmacion();
        },
        no: () => {
          this.cerrarMensajeConfirmacion();
        },
      };
    } else {
      this.mostrarDetalleChofer(chofer);
    }
  }

  async guardarChofer({
    chofer,
    archivo,
  }: DataFormularioChofer): Promise<void> {
    this.cargando = true;
    if (archivo) {
      const archivoId = await this.setBlobArchivo(archivo);
      const archivoBD = this.getArchivos[archivoId];
      if (archivoBD) {
        chofer.foto = archivoBD;
      }
    }
    const editado = !!this.chofer;
    await this.setChofer(chofer);
    this.cargando = false;
    this.cerrarFormulario();
    if (editado) {
      this.abrirDialogoExito(
        this.$t("transporte.tab.choferes.dialogo-exito.message.save") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t(
          "transporte.tab.choferes.dialogo-exito.message.create"
        ) as string,
        "ag-delivery-man"
      );
    }
  }

  cambiarEstadoChofer(chofer: Chofer): void {
    if (!chofer.id) return;
    const estado = chofer.estado;
    const doc: ChoferPatch = { id: chofer.id, estado: estado };
    doc.estado = estado === "deshabilitado" ? "habilitado" : "deshabilitado";
    this.setChofer(doc);
  }

  eliminarChofer(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "transporte.tab.choferes.mensaje-confirmacion.message.delete"
      ) as string,
      si: () => {
        this.deleteChofer(this.chofer?.id ?? "");
        this.cerrarMensajeConfirmacion();
        this.cerrarFormulario();
      },
      no: () => {
        this.cerrarMensajeConfirmacion();
      },
    };
  }

  ///VEHICULOS

  mostrarDetalleVehiculo(vehiculo: Vehiculo): void {
    if (this.vehiculo?.id === vehiculo.id) {
      this.vehiculo = null;
      this.mostrarDetalle = false;
    } else {
      this.mostrarDetalle = true;
      this.mostrarFormulario = false;
      this.vehiculo = vehiculo;
      this.chofer = null;
      this.ruta = null;
      this.identificador = null;
    }
  }

  clickInformacionVehiculo(vehiculo: Vehiculo): void {
    if (this.vehiculo && this.mostrarFormulario) {
      this.dialogoConfirmacion = {
        model: true,
        texto: this.$t(
          "transporte.tab.flota.mensaje-confirmacion.message.save-changes"
        ) as string,
        si: () => {
          this.mostrarDetalleVehiculo(vehiculo);
          this.cerrarMensajeConfirmacion();
        },
        no: () => {
          this.cerrarMensajeConfirmacion();
        },
      };
    } else {
      this.mostrarDetalleVehiculo(vehiculo);
    }
  }

  async guardarVehiculo({
    vehiculo,
    archivo,
  }: DataFormularioVehiculo): Promise<void> {
    this.cargando = true;
    if (archivo) {
      const archivoId = await this.setBlobArchivo(archivo);
      const archivoBD = this.getArchivos[archivoId];
      if (archivoBD) {
        vehiculo.foto = archivoBD;
      }
    }
    const editado = !!this.vehiculo;
    await this.setVehiculo(vehiculo);
    this.cargando = false;
    this.cerrarFormulario();
    if (editado) {
      this.abrirDialogoExito(
        this.$t("transporte.tab.flota.dialogo-exito.message.save") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t("transporte.tab.flota.dialogo-exito.message.create") as string,
        "flota"
      );
    }
  }

  cambiarEstadoVehiculo(vehiculo: Vehiculo): void {
    if (!vehiculo.id) return;
    const estado = vehiculo.estado;
    const doc: VehiculoPatch = { id: vehiculo.id, estado: estado };
    doc.estado = estado === "deshabilitado" ? "habilitado" : "deshabilitado";
    this.setVehiculo(doc);
  }

  eliminarVehiculo(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "transporte.tab.flota.mensaje-confirmacion.message.delete"
      ) as string,
      si: () => {
        this.deleteVehiculo(this.vehiculo?.id ?? "");
        this.cerrarMensajeConfirmacion();
        this.cerrarFormulario();
      },
      no: () => {
        this.cerrarMensajeConfirmacion();
      },
    };
  }

  ///RUTAS PESAJE

  mostrarDetalleRuta(ruta: RutaPesaje): void {
    if (this.ruta?.id === ruta.id) {
      this.mostrarDetalle = false;
      this.ruta = null;
    } else {
      this.mostrarDetalle = true;
      this.mostrarFormulario = false;
      this.ruta = ruta;
      this.chofer = null;
      this.vehiculo = null;
      this.identificador = null;
    }
  }

  clickInformacionRuta(ruta: RutaPesaje): void {
    if (this.ruta && this.mostrarFormulario) {
      this.dialogoConfirmacion = {
        model: true,
        texto: this.$t(
          "transporte.tab.rutas.mensaje-confirmacion.message.save-changes"
        ) as string,
        si: () => {
          this.mostrarDetalleRuta(ruta);
          this.cerrarMensajeConfirmacion();
        },
        no: () => {
          this.cerrarMensajeConfirmacion();
        },
      };
    } else {
      this.mostrarDetalleRuta(ruta);
    }
  }

  async guardarRuta(ruta: RutaPesaje): Promise<void> {
    this.cargando = true;
    const editado = !!this.ruta;
    await this.setRutaPesaje(ruta);
    this.cargando = false;
    this.cerrarFormulario();
    if (editado) {
      this.abrirDialogoExito(
        this.$t("transporte.tab.rutas.dialogo-exito.message.save") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t("transporte.tab.rutas.dialogo-exito.message.create") as string,
        "ags-location-arrow"
      );
    }
  }

  cambiarEstadoRuta(ruta: RutaPesaje): void {
    if (!ruta.id) return;
    const estado = ruta.estado;
    const doc: RutaPesajePatch = { id: ruta.id, estado: estado };
    doc.estado = estado === "deshabilitado" ? "habilitado" : "deshabilitado";
    this.setRutaPesaje(doc);
  }

  eliminarRuta(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "transporte.tab.rutas.mensaje-confirmacion.message.delete"
      ) as string,
      si: () => {
        this.deleteRutaPesaje(this.ruta?.id ?? "");
        this.cerrarMensajeConfirmacion();
        this.cerrarFormulario();
      },
      no: () => {
        this.cerrarMensajeConfirmacion();
      },
    };
  }

  ///IDENTIFICADORES

  mostrarDetalleIdentificador(identificador: Identificador): void {
    if (this.identificador?.id === identificador.id) {
      this.mostrarDetalle = false;
      this.identificador = null;
    } else {
      this.mostrarDetalle = true;
      this.mostrarFormulario = false;
      this.identificador = identificador;
      this.chofer = null;
      this.vehiculo = null;
      this.ruta = null;
    }
  }

  clickInformacionIdentificador(identificador: Identificador): void {
    if (this.identificador && this.mostrarFormulario) {
      this.dialogoConfirmacion = {
        model: true,
        texto: this.$t(
          "transporte.tab.identificadores.mensaje-confirmacion.message.save-changes"
        ) as string,
        si: () => {
          this.mostrarDetalleIdentificador(identificador);
          this.cerrarMensajeConfirmacion();
        },
        no: () => {
          this.cerrarMensajeConfirmacion();
        },
      };
    } else {
      this.mostrarDetalleIdentificador(identificador);
    }
  }

  async guardarIdentificador(identificador: Identificador): Promise<void> {
    this.cargando = true;
    const editado = !!this.identificador;
    await this.setIdentificador(identificador);
    this.cargando = false;
    this.cerrarFormulario();
    if (editado) {
      this.abrirDialogoExito(
        this.$t(
          "transporte.tab.identificadores.dialogo-exito.message.save"
        ) as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t(
          "transporte.tab.identificadores.dialogo-exito.message.create"
        ) as string,
        "identificador"
      );
    }
  }

  cambiarEstadoIdentificador(identificador: Identificador): void {
    if (!identificador.id) return;
    const estado = identificador.estado;
    const doc: IdentificadorPatch = { id: identificador.id, estado: estado };
    doc.estado = estado === "deshabilitado" ? "habilitado" : "deshabilitado";
    this.setIdentificador(doc);
  }

  eliminarIdentificador(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "transporte.tab.identificadores.mensaje-confirmacion.message.delete"
      ) as string,
      si: () => {
        this.deleteIdentificador(this.identificador?.id ?? "");
        this.cerrarMensajeConfirmacion();
        this.cerrarFormulario();
      },
      no: () => {
        this.cerrarMensajeConfirmacion();
      },
    };
  }
}
</script>
