<template>
  <div class="mx-percent mt-lg-35 mt-16">
    <v-row no-gutters>
      <v-col
        class="font-size-lg-32 font-size-25 poppins font-weight-semi-bold text-center line-break dialogDark--text"
        v-html="$t('pagina.caracteristicas-barredores.titulo')"
      ></v-col>
    </v-row>
    <v-row no-gutters class="pt-lg-16 pt-8">
      <v-col>
        <v-img
          :src="require('../../assets/webp/preview-barredores.webp')"
          contain
        ></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-lg-35 mt-8">
      <v-col
        cols="12"
        lg="4"
        v-for="caracteristica in caracteristicas"
        :key="`caracteristica-${caracteristica.id}`"
        :class="{
          'pr-6': caracteristica.id % 3 === 1 && !isMobile,
          'px-6': caracteristica.id % 3 === 2 && !isMobile,
          'pl-6': caracteristica.id % 3 === 0 && !isMobile,
          'pt-lg-9 pt-8':
            caracteristica.id / 3 > 1 || (caracteristica.id > 1 && isMobile),
        }"
      >
        <v-row no-gutters>
          <v-col cols="auto">
            <v-img
              :src="caracteristica.icono"
              height="68px"
              width="68px"
              contain
            ></v-img>
          </v-col>
          <v-col class="pl-7">
            <v-row no-gutters>
              <v-col
                class="font-size-18 poppins font-weight-medium dialogDark--text"
              >
                {{ caracteristica.titulo }}
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-lg-2 mt-4">
              <v-col
                class="font-size-16 poppins font-weight-light dialogDark--text"
              >
                {{ caracteristica.parrafo }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CaracteristicasBarredoresPagina extends Vue {
  get caracteristicas(): Array<{
    id: number;
    icono: string;
    titulo: string;
    parrafo: string;
  }> {
    return [
      {
        id: 1,
        icono: require("../../assets/png/one-page/marker.png"),
        titulo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.0.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.0.parrafo"
        ) as string,
      },
      {
        id: 2,
        icono: require("../../assets/png/one-page/map.png"),
        titulo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.1.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.1.parrafo"
        ) as string,
      },
      {
        id: 3,
        icono: require("../../assets/png/one-page/diagram.png"),
        titulo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.2.titulo"
        ) as string,
        parrafo: this.$t(
          "pagina.caracteristicas-barredores.caracteristicas.2.parrafo"
        ) as string,
      },
    ];
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
</script>

<style scoped>
@media screen and (min-width: 1264px) {
  .mt-lg-35 {
    margin-top: 140px !important;
  }
}
</style>
