<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="pt-10 mr-12 flex-grow-0">
      <v-col>
        <FiltrosDesechos
          :button-disabled="dialogoFormulario"
          :permisos="getPermisosUsuario"
          @click:agregar="abrirNuevo"
          v-model="filtros"
        ></FiltrosDesechos>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-7 pr-12 mb-9 flex-grow-1">
      <v-col cols="8">
        <TablaDesechos
          :desechos="items"
          :search="filtros.search"
          :desecho="desecho"
          @click:informacion="clickAbrirInformacion"
        ></TablaDesechos>
      </v-col>
      <v-col cols="4" class="pl-7">
        <DetalleDesecho
          v-if="desecho && dialogoInfo"
          :desecho="desecho"
          :permisos="getPermisosUsuario"
          @click:editar="abrirEditar"
          @click:eliminar="abrirDialogoEliminar"
        ></DetalleDesecho>
        <FormularioDesecho
          v-if="dialogoFormulario"
          :desecho="desecho"
          :cargando="cargando"
          @click:guardar="clickGuardar"
        ></FormularioDesecho>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      max-width="565"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
//decoradores
import { Vue, Component } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//tipos
import { FiltrosDesecho } from "@/typings/components/desechos/filtros";
import {
  Desecho,
  Desechos,
} from "@/typings/store/plugins/easyFirestore/desechos";
//componentes
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import TablaDesechos from "@/components/desechos/Tabla.vue";
import DetalleDesecho from "@/components/desechos/Detalle.vue";
import FiltrosDesechos from "@/components/desechos/Filtros.vue";
import FormularioDesecho from "@/components/desechos/FormularioDesecho.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";

@Component({
  computed: mapGetters({
    getDesechos: "desechos/get",
    getRutasUsuario: "usuario/getRutas",
    getPermisosUsuario: "usuario/getPermisos",
  }),
  methods: mapActions({
    setDesecho: "desechos/set",
    deleteDesecho: "desechos/delete",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    SuccessDialog,
    TablaDesechos,
    DetalleDesecho,
    FiltrosDesechos,
    FormularioDesecho,
    ConfirmationDialog,
  },
})
export default class HomeDesechos extends Vue {
  created(): void {
    if (!routeGuard()) {
      return;
    }
  }

  desecho: Desecho | null = null;
  cargando = false;
  dialogoFormulario = false;
  dialogoInfo = false;
  dialogoConfirmacion: {
    model: boolean;
    texto: string;
    si: (() => void) | null;
    no: (() => void) | null;
  } = {
    model: false,
    texto: "",
    si: null,
    no: null,
  };
  dialogoExito = {
    model: false,
    mensaje: "",
    icono: "",
  };
  filtros: FiltrosDesecho = {
    search: "",
  };

  get desechos(): Desechos {
    return this.getDesechos;
  }

  get items(): Array<Desecho> {
    return Object.values(this.desechos);
  }

  abrirNuevo(): void {
    this.desecho = null;
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  clickAbrirInformacion(desecho: Desecho): void {
    if (this.dialogoFormulario && this.desecho) {
      this.abrirDialogoSinGuardar(desecho);
    } else {
      this.abrirInformacion(desecho);
    }
  }

  abrirDialogoSinGuardar(desecho: Desecho): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "desechos.mensaje-confirmacion.message.save-changes"
      ) as string,
      si: () => {
        this.abrirInformacion(desecho);
        this.cerrarDialogoConfirmacion();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  abrirInformacion(desecho: Desecho): void {
    const data = this.desechos[desecho.id ?? ""];
    if (this.desecho?.id === desecho.id) {
      this.desecho = null;
      this.dialogoInfo = false;
    } else if (data) {
      this.desecho = data;
      this.dialogoInfo = true;
      this.dialogoFormulario = false;
    }
  }

  abrirEditar(): void {
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  abrirDialogoEliminar(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t("desechos.mensaje-confirmacion.message.delete") as string,
      si: () => {
        this.clickEliminar();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  clickEliminar(): void {
    const desecho = this.desecho;
    if (desecho) {
      this.deleteDesecho(desecho.id ?? "");
      this.desecho = null;
      this.dialogoInfo = false;
      this.cerrarDialogoConfirmacion();
    }
  }

  async clickGuardar(desecho: Desecho): Promise<void> {
    this.cargando = true;
    await this.setDesecho(desecho);
    this.cargando = false;
    this.dialogoFormulario = false;
    if (this.desecho) {
      this.abrirDialogoExito(
        this.$t("desechos.dialogo-exito.message.save") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t("desechos.dialogo-exito.message.create") as string,
        "desechos"
      );
    }
    this.desecho = null;
  }

  abrirDialogoExito(mensaje: string, icono: string): void {
    this.dialogoExito = {
      model: true,
      mensaje: mensaje,
      icono: icono,
    };
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      mensaje: "",
      icono: "",
    };
  }

  cerrarDialogoConfirmacion(): void {
    this.dialogoConfirmacion = {
      model: false,
      texto: "",
      si: null,
      no: null,
    };
  }
}
</script>
