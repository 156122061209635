<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="pt-10 mr-12 flex-grow-0">
      <v-col>
        <FiltrosPesajes
          v-model="filtros"
          :clientes="clientesOrdenados"
          :servicios="serviciosOrdenados"
          :vehiculos="vehiculosOrdenados"
        ></FiltrosPesajes>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-7 pr-12 mb-9 flex-grow-1">
      <v-col cols="8">
        <TablaPesajes
          :pesaje="pesaje"
          :clientes="clientes"
          :permisos="permisos"
          v-if="!mostrarGaleria"
          :servicios="servicios"
          :search="filtros.search"
          :pesajes="datosFiltrados"
          @click:sincronizar="resincronizar"
          @click:anular="abrirDialogoAnular"
          @click:informacion="clickInformacion"
        ></TablaPesajes>
        <GaleriaImagenes
          v-else
          :pesaje="pesaje"
          :archivos="archivos"
          :editar="editarGaleria"
          :usuario="usuario"
          @click:cerrar="cerrarGaleria"
          v-model="imagenes"
        ></GaleriaImagenes>
      </v-col>
      <v-col cols="4" class="pl-7">
        <DetallePesaje
          v-if="dialogoInfo && !dialogoEditar"
          :pesaje="pesaje"
          :archivos="archivos"
          :permisos="permisos"
          :mostrar-galeria="mostrarGaleria"
          :usuarios="usuarios"
          @click:ver-imagenes="abrirGaleria"
          @click:editar="abrirDialogoEditar"
        ></DetallePesaje>
        <Formulario
          v-else-if="dialogoEditar && !dialogoInfo"
          :arrayServicios="serviciosOrdenados"
          :arrayClientes="clientesOrdenados"
          :arrayChoferes="choferesOrdenados"
          :arrayVehiculos="vehiculosOrdenados"
          :arrayDesechos="desechosOrdenados"
          :arrayRutasPesaje="rutasPesajeOrdenadas"
          :mostrar-galeria="mostrarGaleria"
          :editar-galeria="editarGaleria"
          :pesaje="pesaje"
          :cargando="cargando"
          @click:editar-imagenes="abrirEditarImagenes"
          @click:guardar="guardarPesaje"
        ></Formulario>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      max-width="565"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
// composition-api
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
// helpers

import routeGuard from "@/helpers/routeGuard";
//tipos
import { FiltrosPesaje } from "@/typings/components/pesajes/filtros";
import { Pesaje, PesajePatch } from "@/typings/store/plugins/easyFirestore/pesajes";
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import { Archivo, BlobArchivo } from "@/typings/store/plugins/easyFirestore/archivos";

// composables
import { useI18n } from "@/composables/i18n";
import { useUsuario } from "@/composables/usuario";
import { usePesajes } from "@/composables/pesajes";
import { useUsuarios } from "@/composables/usuarios";
import { useArchivos } from "@/composables/archivos";
import { useDesechos } from "@/composables/desechos";
import { useClientes } from "@/composables/clientes";
import { useChoferes } from "@/composables/choferes";
import { useVehiculos } from "@/composables/vehiculos";
import { useServicios } from "@/composables/servicios";
import { useRutasPesaje } from "@/composables/rutasPesaje";
//componentes
import DetallePesaje from "@/components/pesajes/Detalle.vue";
import FiltrosPesajes from "@/components/pesajes/Filtros.vue";
import TablaPesajes from "@/components/pesajes/TablaPesajes.vue";
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import Formulario from "@/components/pesajes/FormularioPesaje.vue";
import GaleriaImagenes from "@/components/pesajes/GaleriaImagenes.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";


interface Imagenes {
  nuevas: Array<BlobArchivo>;
  eliminar: Array<Archivo>;
}

interface DialogoConfirmacion {
  model: boolean;
  texto: string;
  si: (() => void) | null;
  no: (() => void) | null;
}

export default defineComponent({
  name: "VistaPesajes",
  components: {
    Formulario,
    TablaPesajes,
    SuccessDialog,
    DetallePesaje,
    FiltrosPesajes,
    GaleriaImagenes,
    ConfirmationDialog,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  created(): void {
    if (!routeGuard()) {
      return;
    }
    this.$eventBus.$on("click:editar-pesaje", this.onClickEditarPesaje);
    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    this.filtros.fechas = [
      firstDayOfMonth.format("YYYY-MM-DD"),
      new Date().format("YYYY-MM-DD"),
    ];
  },
  destroyed(): void {
    this.$eventBus.$off("click:editar-pesaje", this.onClickEditarPesaje);
  },
  setup() {
    const { t } = useI18n();

    const _usuario = useUsuario();
    const _pesajes = usePesajes();
    const _usuarios = useUsuarios();
    const _archivos = useArchivos();
    const _desechos = useDesechos();
    const _clientes = useClientes();
    const _choferes = useChoferes();
    const _vehiculos = useVehiculos();
    const _servicios = useServicios();
    const _rutasPesaje = useRutasPesaje();

    const imagenes = reactive<Imagenes>({
      nuevas: [],
      eliminar: [],
    });

    const mostrarGaleria = ref(false);
    const editarGaleria = ref(false);
    const pesaje = ref<Pesaje | null>(null);
    const dialogoInfo = ref(false);
    const dialogoEditar = ref(false);
    const cargando = ref(false);

    const dialogoExito = reactive({
      model: false,
      mensaje: "",
      icono: "",
    });

    const dialogoConfirmacion: DialogoConfirmacion = reactive({
      model: false,
      texto: "",
      si: null,
      no: null,
    });

    const filtros = reactive<FiltrosPesaje>({
      search: "",
      clientes: [],
      vehiculo: null,
      servicios: [],
      errores: false,
      fechas: [],
    });

    const usuario = _usuario.get

    const usuarios = _usuarios.get

    const clientes = _clientes.get

    const servicios = _servicios.get

    const permisos = _usuario.getPermisos

    const archivos = _archivos.getArchivos;

    const vehiculosOrdenados = computed((): Array<Vehiculo> => {
      _vehiculos.setOrdenarArray(true);
      return _vehiculos.getArrayHabilitados.value;
    });


    const desechosOrdenados = computed((): Array<Desecho> => {
      _desechos.setOrdenarArray(true);
      return _desechos.getArray.value;
    });

    const clientesOrdenados = computed((): Array<Cliente> => {
      _clientes.setOrdenarArray(true);
      return _clientes.getArrayHabilitados.value;
    });

    const serviciosOrdenados = computed((): Array<Servicio> => {
      _servicios.setOrdenarArray(true);
      return _servicios.getArray.value;
    });

    const choferesOrdenados = computed((): Array<Chofer> => {
      _choferes.setOrdenarArray(true);
      return _choferes.getArrayHabilitados.value;
    });

    const rutasPesajeOrdenadas = computed((): Array<RutaPesaje> => {
      _rutasPesaje.setOrdenarArray(true);
      return _rutasPesaje.getArrayHabilitados.value;
    });

    const ordenarPesajes = (pesajes: Pesaje[]) => {
      pesajes.sort((p1, p2) => {
        const fecha1: Date | null = p1.entrada.fecha
          ? p1.entrada.fecha.toDate()
          : null;
        const fecha2: Date | null = p2.entrada.fecha
          ? p2.entrada.fecha.toDate()
          : null;
        if (!fecha1) return -1;
        if (!fecha2) return 1;
        return fecha2.getTime() - fecha1.getTime();
      });
      return pesajes;
    };

    const filtroFecha = (_pesaje: Pesaje) => {
      const fechaPesaje = _pesaje.entrada.fecha.toDate();
      const [fechaInicioString, fechaFinString] = filtros.fechas;
      const fechaInicio = fechaInicioString
        ? `${fechaInicioString} 00:00:00`.toDate()
        : new Date();
      const fechaFin = fechaFinString
        ? `${fechaFinString} 23:59:59`.toDate()
        : new Date();
      return fechaPesaje >= fechaInicio && fechaPesaje <= fechaFin;
    };

    const filtroVehiculo = (_pesaje: Pesaje) => {
      if (!filtros.vehiculo) return true;
      return filtros.vehiculo.patente === _pesaje.vehiculo?.patente;
    };

    const filtroCliente = (_pesaje: Pesaje) => {
      const cantidadFiltros = filtros.clientes?.length ?? 0;
      if (cantidadFiltros <= 0) return true;
      return filtros.clientes.some((servicio) =>
        _pesaje.cliente?.nombre
          ?.toLowerCase()
          ?.includes(servicio.nombre.toLowerCase())
      );
    };

    const filtroServicio = (_pesaje: Pesaje) => {
      const cantidadFiltros = filtros.servicios?.length ?? 0;
      if (cantidadFiltros <= 0) return true;
      return filtros.servicios.some(
        (servicio) => servicio.id === _pesaje.servicio?.id
      );
    };

    const filtroError = (_pesaje: Pesaje) => {
      if (!filtros.errores) return true;
      return (
        errorCliente(_pesaje) ||
        errorServicio(_pesaje) ||
        errorCapacidad(_pesaje) ||
        errorCampos(_pesaje) ||
        errorImg(_pesaje)
      );
    };

    const datosFiltrados = computed((): Array<Pesaje> => {
      let pesajes = _pesajes.getArray.value.filter((_pesaje) => {
        const _filtroFecha = filtroFecha(_pesaje);
        const _filtroVehiculo = filtroVehiculo(_pesaje);
        const _filtroCliente = filtroCliente(_pesaje);
        const _filtroServicio = filtroServicio(_pesaje);
        const _filtroError = filtroError(_pesaje);
        const _filtroEstado = _pesaje.estado !== "anulado";
        return (
          _filtroFecha &&
          _filtroVehiculo &&
          _filtroCliente &&
          _filtroServicio &&
          _filtroError &&
          _filtroEstado
        );
      });
      pesajes = ordenarPesajes(pesajes);
      return pesajes;
    });

    watch(pesaje, (newVal) => {
      if (newVal) {
        imagenes.nuevas = [];
        imagenes.eliminar = [];
      }
    });

    const abrirDialogoInfo = (item: Pesaje): void => {
      if (!item.id) return;
      const _pesaje = _pesajes.getById(item.id);
      if (pesaje.value?.id === item.id) {
        dialogoEditar.value = false;
        dialogoInfo.value = false;
        pesaje.value = null;
      } else if (_pesaje) {
        pesaje.value = _pesaje;
        dialogoInfo.value = true;
        dialogoEditar.value = false;
      }
    };

    const clickInformacion = (_pesaje: Pesaje): void => {
      if (dialogoEditar.value) {
        abrirDialogoConfirmarSinGuardar(_pesaje);
      } else {
        abrirDialogoInfo(_pesaje);
      }
    };

    const abrirDialogoEditar = (): void => {
      const id = pesaje.value?.id;
      if (!id) return;
      const _pesaje = _pesajes.getById(id);
      if (_pesaje) {
        pesaje.value = _pesaje;
        dialogoInfo.value = false;
        dialogoEditar.value = true;
      }
    };

    const cerrarDialogoEditar = (): void => {
      dialogoEditar.value = false;
      pesaje.value = null;
    };

    const abrirDialogoAnular = (_pesaje: Pesaje): void => {
      dialogoConfirmacion.model = true;
      dialogoConfirmacion.texto = t(
        "pesajes.mensaje-confirmacion.message.0"
      ) as string;
      dialogoConfirmacion.si = () => {
        const doc = Object.assign({}, _pesaje);
        doc.estado = "anulado";
        _pesajes.set(doc);
        cerrarDialogoConfirmacion();
      };
      dialogoConfirmacion.no = () => {
        cerrarDialogoConfirmacion();
      };
    };

    const abrirDialogoConfirmarSinGuardar = (_pesaje: Pesaje): void => {
      dialogoConfirmacion.model = true;
      dialogoConfirmacion.texto = t(
        "pesajes.mensaje-confirmacion.message.1"
      ) as string;
      dialogoConfirmacion.si = () => {
        abrirDialogoInfo(_pesaje);
        cerrarDialogoConfirmacion();
      };
      dialogoConfirmacion.no = () => {
        cerrarDialogoConfirmacion();
      };
    };

    const cerrarDialogoConfirmacion = (): void => {
      dialogoConfirmacion.model = false;
      dialogoConfirmacion.texto = "";
      dialogoConfirmacion.si = null;
      dialogoConfirmacion.no = null;
    };

    const abrirEditarImagenes = (): void => {
      mostrarGaleria.value = true;
      editarGaleria.value = true;
    };

    const abrirGaleria = (): void => {
      mostrarGaleria.value = true;
      editarGaleria.value = false;
    };

    const cerrarGaleria = (): void => {
      mostrarGaleria.value = false;
      editarGaleria.value = false;
    };

    const abrirDialogoExito = (): void => {
      dialogoExito.model = true;
      dialogoExito.mensaje = t("pesajes.dialogo-exito.message.0") as string;
      dialogoExito.icono = "ag-save";
    };

    const cerrarDialogoExito = (): void => {
      dialogoExito.model = false;
      dialogoExito.mensaje = "";
      dialogoExito.icono = "";
    };

    const eliminarFotoArray = async (fotos: Archivo[], foto: Archivo) => {
      if (foto.id) {
        await _archivos.drop(foto.id);
        const index = fotos.findIndex((_foto) => _foto.id === foto.id);
        if (index > -1) {
          fotos.splice(index, 1);
        }
      }
      return fotos;
    };

    const agregarFotoArray = async (fotos: Archivo[], foto: BlobArchivo) => {
      const id = await _archivos.setBlob(foto);
      const _foto = _archivos.getById(id);
      if (_foto) {
        fotos.push(_foto);
      }
      return fotos;
    };

    const guardarPesaje = async (
      _pesaje: Pesaje | PesajePatch
    ): Promise<void> => {
      if (_pesaje) {
        cargando.value = true;
        let fotos = pesaje.value?.fotos || [];
        for (const archivo of imagenes.eliminar) {
          fotos = await eliminarFotoArray(fotos, archivo);
        }
        for (const archivo of imagenes.nuevas) {
          fotos = await agregarFotoArray(fotos, archivo);
        }
        _pesaje.fotos = fotos;
        _pesaje.sincronizacion = {
          enviado: new Date().toISOString(),
          recibido: null,
        };
        cerrarGaleria();
        _pesajes.set(_pesaje);
        cerrarDialogoEditar();
        abrirDialogoExito();
        cargando.value = false;
      }
    };

    const errorCliente = (_pesaje: Pesaje | null): boolean => {
      const id = _pesaje?.cliente?.id ?? "";
      const _cliente = _clientes.getById(id);
      return !_cliente;
    };

    const errorServicio = (_pesaje: Pesaje | null): boolean => {
      const id = _pesaje?.servicio?.id ?? "";
      const _servicio = _servicios.getById(id);
      return !_servicio;
    };

    const errorCapacidad = (_pesaje: Pesaje | null): boolean => {
      if (!_pesaje) return true;
      const noData = !_pesaje.capacidad;
      const noNumber = isNaN(_pesaje.capacidad);
      const noPositive = _pesaje.capacidad <= 0;
      return noData || noNumber || noPositive;
    };

    const errorCampos = (_pesaje: Pesaje | null): boolean => {
      if (!_pesaje?.vehiculo || !_pesaje?.desecho || !_pesaje?.ruta)
        return true;
      if (!_pesaje?.entrada?.fecha || !_pesaje?.entrada?.chofer) return true;
      if (
        !_pesaje.entrada.peso ||
        isNaN(_pesaje.entrada.peso) ||
        _pesaje.entrada.peso <= 0
      )
        return true;
      if (!_pesaje?.salida?.fecha || !_pesaje?.salida?.chofer) return true;
      return (
        !_pesaje.salida.peso ||
        isNaN(_pesaje.salida.peso) ||
        _pesaje.salida.peso <= 0
      );
    };

    const errorImg = (_pesaje: Pesaje): boolean => {
      const id = _pesaje.cliente?.id ?? "";
      const _cliente = _clientes.getById(id);
      if (_cliente && _cliente.tipo.toLowerCase() === "municipal") {
        return _pesaje.fotos.length === 0;
      }
      return false;
    };

    const onClickEditarPesaje = async (_pesaje: Pesaje): Promise<void> => {
      if (dialogoEditar.value) {
        cerrarDialogoEditar();
        await nextTick();
      }
      dialogoEditar.value = true;
      dialogoInfo.value = false;
      pesaje.value = _pesaje;
    };

    const resincronizar = (pesaje: Pesaje) => {
      if(!pesaje.id) return;
      _pesajes.resynchronize(pesaje.id);
    }

    return {
      usuario,
      usuarios,
      archivos,
      permisos,
      clientes,
      servicios,
      pesaje,
      filtros,
      imagenes,
      cargando,
      dialogoInfo,
      dialogoExito,
      dialogoEditar,
      editarGaleria,
      datosFiltrados,
      mostrarGaleria,
      clientesOrdenados,
      choferesOrdenados,
      desechosOrdenados,
      serviciosOrdenados,
      vehiculosOrdenados,
      dialogoConfirmacion,
      rutasPesajeOrdenadas,
      abrirGaleria,
      cerrarGaleria,
      guardarPesaje,
      resincronizar,
      clickInformacion,
      abrirDialogoAnular,
      abrirDialogoEditar,
      cerrarDialogoExito,
      abrirEditarImagenes,
      onClickEditarPesaje,
    };
  },
});
</script>
