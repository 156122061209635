<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="pt-10 mr-12 flex-grow-0">
      <v-col>
        <FiltrosClientes
          :button-disabled="dialogoFormulario"
          @click:agregar="abrirNuevo"
          :permisos="getPermisosUsuario"
          v-model="filtros"
        ></FiltrosClientes>
      </v-col>
    </v-row>
    <v-row no-gutters class="fill-height pt-7 pr-12 mb-9">
      <v-col cols="8">
        <TablaClientes
          :clientes="items"
          :cliente="clienteSeleccionado"
          :search="filtros.search"
          :permisos="getPermisosUsuario"
          @click:informacion="clickAbrirInformacion"
          @click:switch-estado="cambiarEstado"
        ></TablaClientes>
      </v-col>
      <v-col cols="4" class="pl-7">
        <DetalleCliente
          v-if="clienteSeleccionado && dialogoInfo"
          :cliente="clienteSeleccionado"
          @click:editar="abrirEditar"
          @click:eliminar="abrirDialogoEliminar"
          :permisos="getPermisosUsuario"
        ></DetalleCliente>
        <FormularioCliente
          v-if="dialogoFormulario"
          :cliente="clienteSeleccionado"
          :cargando="cargando"
          @click:guardar="clickGuardar"
        ></FormularioCliente>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      max-width="565"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
//composition
import { defineComponent, ref, computed } from "vue";
//vuex
import { mapGetters } from "vuex";
import store from "@/store";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Archivos } from "@/typings/store/plugins/easyFirestore/archivos";
import { FiltrosCliente } from "@/typings/components/clientes/filtros";
import { DataFormularioClientes } from "@/typings/components/clientes/formulario";
import {
  Cliente,
  ClientePatch,
  Clientes,
} from "@/typings/store/plugins/easyFirestore/clientes";
//componentes
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import TablaClientes from "@/components/clientes/Tabla.vue";
import DetalleCliente from "@/components/clientes/Detalle.vue";
import FiltrosClientes from "@/components/clientes/Filtros.vue";
import FormularioCliente from "@/components/clientes/Formulario.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";

export default defineComponent({
  name: "VistaClientes",
  components: {
    SuccessDialog,
    TablaClientes,
    DetalleCliente,
    FiltrosClientes,
    FormularioCliente,
    ConfirmationDialog,
  },
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  computed: mapGetters({
    getRutasUsuario: "usuario/getRutas",
    getPermisosUsuario: "usuario/getPermisos",
  }),
  created() {
    if (!routeGuard()) {
      return;
    }
  },
  setup() {
    const getters = mapGetters({
      getClientes: "clientes/get",
      getArchivos: "archivos/get",
      getArrayClientes: "clientes/getArray",
    });

    const actions = {
      setCliente: "clientes/set",
      deleteCliente: "clientes/delete",
      setBlobArchivo: "archivos/setBlob",
      deleteArchivo: "archivos/delete",
    };

    const clientes = computed<Clientes>(getters.getClientes);
    const archivos = computed<Archivos>(getters.getArchivos);
    const arrayClientes = computed<Array<Cliente>>(getters.getArrayClientes);

    const filtros = ref<FiltrosCliente>({
      search: "",
      tipo: null,
      estado: null,
    });
    const clienteSeleccionado = ref<Cliente | null>(null);
    const dialogoInfo = ref(false);
    const dialogoFormulario = ref(false);
    const dialogoConfirmacion = ref<{
      model: boolean;
      texto: string;
      si: (() => void) | null;
      no: (() => void) | null;
    }>({
      model: false,
      texto: "",
      si: null,
      no: null,
    });
    const dialogoExito = ref({
      model: false,
      mensaje: "",
      icono: "",
    });
    const cargando = ref(false);

    const items = computed(() => {
      const clientes = arrayClientes.value.filter((cliente) => {
        const estado = cliente.estado;
        const filtroEstado = filtros.value.estado
          ? estado === filtros.value.estado
          : true;
        const filtroTipo = filtros.value.tipo
          ? cliente.tipo === filtros.value.tipo
          : true;
        return filtroEstado && filtroTipo;
      });
      clientes.sort((a, b) => a.nombre.localeCompare(b.nombre));
      return clientes;
    });

    const abrirDialogoExito = (mensaje: string, icono: string) => {
      dialogoExito.value = {
        model: true,
        mensaje: mensaje,
        icono: icono,
      };
    };

    const cerrarDialogoExito = () => {
      dialogoExito.value = {
        model: false,
        mensaje: "",
        icono: "",
      };
    };

    const clickGuardar = async (data: DataFormularioClientes) => {
      cargando.value = true;
      const cliente = data.cliente;
      if (data.archivo) {
        const archivoId = await store.dispatch(
          actions.setBlobArchivo,
          data.archivo
        );
        const archivo = archivos.value[archivoId];
        if (archivo) {
          if (cliente.id) {
            const foto = clientes.value[cliente.id]?.foto;
            if (foto?.id) {
              await store.dispatch(actions.deleteArchivo, foto.id);
            }
          }
          cliente.foto = archivo;
        }
      }
      await store.dispatch(actions.setCliente, cliente);
      cargando.value = false;
      dialogoFormulario.value = false;
      if (clienteSeleccionado.value) {
        abrirDialogoExito(
          i18n.t("clientes.dialogo-exito.message.save") as string,
          "ag-save"
        );
      } else {
        abrirDialogoExito(
          i18n.t("clientes.dialogo-exito.message.create") as string,
          "bxs-user-plus"
        );
      }
      clienteSeleccionado.value = null;
    };

    const cerrarDialogoConfirmacion = () => {
      dialogoConfirmacion.value = {
        model: false,
        texto: "",
        si: null,
        no: null,
      };
    };

    const clickEliminar = () => {
      const cliente = clienteSeleccionado.value;
      if (cliente?.id) {
        if (cliente.foto?.id) {
          store.dispatch(actions.deleteArchivo, cliente.foto.id);
        }
        store.dispatch(actions.deleteCliente, cliente.id);
        clienteSeleccionado.value = null;
        dialogoInfo.value = false;
        cerrarDialogoConfirmacion();
      }
    };

    const abrirDialogoEliminar = () => {
      dialogoConfirmacion.value = {
        model: true,
        texto: i18n.t("clientes.mensaje-confirmacion.message.delete") as string,
        si: () => {
          clickEliminar();
        },
        no: () => {
          cerrarDialogoConfirmacion();
        },
      };
    };

    const clickAbrirInformacion = (cliente: Cliente) => {
      if (dialogoFormulario.value && clienteSeleccionado.value) {
        abrirDialogoSinGuardar(cliente);
      } else {
        abrirInformacion(cliente);
      }
    };

    const abrirInformacion = (cliente: Cliente) => {
      const data = clientes.value[cliente.id ?? ""];
      if (cliente.id === clienteSeleccionado.value?.id) {
        clienteSeleccionado.value = null;
        dialogoInfo.value = false;
      } else if (data) {
        clienteSeleccionado.value = data;
        dialogoInfo.value = true;
      }
      dialogoFormulario.value = false;
    };

    const abrirDialogoSinGuardar = (cliente: Cliente) => {
      dialogoConfirmacion.value = {
        model: true,
        texto: i18n.t(
          "clientes.mensaje-confirmacion.message.save-changes"
        ) as string,
        si: () => {
          abrirInformacion(cliente);
          cerrarDialogoConfirmacion();
        },
        no: () => {
          cerrarDialogoConfirmacion();
        },
      };
    };

    const abrirNuevo = () => {
      clienteSeleccionado.value = null;
      dialogoFormulario.value = true;
      dialogoInfo.value = false;
    };

    const abrirEditar = () => {
      dialogoFormulario.value = true;
      dialogoInfo.value = false;
    };

    const cambiarEstado = (cliente: Cliente) => {
      if (!cliente.id) return;
      const estado = cliente.estado;
      const doc: ClientePatch = { id: cliente.id, estado: estado };
      doc.estado = estado === "deshabilitado" ? "habilitado" : "deshabilitado";
      store.dispatch(actions.setCliente, doc);
    };

    return {
      clienteSeleccionado,
      dialogoFormulario,
      dialogoInfo,
      dialogoConfirmacion,
      cargando,
      filtros,
      items,
      dialogoExito,
      abrirNuevo,
      abrirEditar,
      abrirDialogoEliminar,
      clickAbrirInformacion,
      abrirInformacion,
      cambiarEstado,
      clickGuardar,
      cerrarDialogoExito,
    };
  },
});
</script>
