<template>
  <v-btn
    :block="block"
    large
    :class="classes"
    @click="onClick"
  >
    <svg-icon name="three-dots" v-if="loading"></svg-icon>
    <v-row v-else no-gutters justify="center" align="center">
      <v-col cols="auto" class="pr-4" v-if="icon">
        <v-icon color="white" v-if="icon.startsWith('mdi')">
          {{ icon }}
        </v-icon>
        <svg-icon
          v-else
          :name="icon"
          color="white"
        ></svg-icon>
      </v-col>
      <v-col
        cols="auto"
        class="white--text text-initial metropolis font-weight-bold text-body-1"
      >
        <slot></slot>
      </v-col>
    </v-row>
  </v-btn>
</template>

<script lang="ts">
//composition
import {computed, defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ActionButton",
  emits: [
    "click"
  ],
  components: {
    SvgIcon
  },
  props: {
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const validColors = ["primary", "secondary", "error", "warning"];

    const classes = computed(() => {
      const classes = ["rounded-input"];
      if (validColors.includes(props.color)) {
        if (props.dialog) {
          classes.push("elevation-dark");
        } else {
          classes.push(`elevation-${props.color}`);
        }
        classes.push(`gradient-${props.color}`);
      } else {
        classes.push(props.color);
      }
      if (props.disabled) {
        classes.push("button-disabled button-inactive");
      }
      return classes.join(" ");
    });

    const onClick = (ev: MouseEvent): void => {
      ctx.emit("click", ev);
    };

    return {
      classes,
      onClick
    };
  }
});
</script>
