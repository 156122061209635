<template>
  <v-card
    dark
    color="rgba(45,61,67,0.55)"
    class="border-grey rounded-input glass-card elevation-black"
    width="612"
    min-height="455"
  >
    <v-container class="px-11 py-8">
      <v-row justify="center" align="center" class="pt-7 pb-4">
        <v-col cols="auto">
          <v-img
            width="407px"
            height="68px"
            contain
            :src="require('@/assets/svg/sistema/logotipo-white.svg')"
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col
          cols="auto"
          class="pt-0 secondary--text font-weight-bold metropolis font-size-25"
        >
          {{ $t("login.form-recovery-password.title") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="pb-1 pl-6 metropolis font-size-20">
          {{ $t("login.form-recovery-password.email.label") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <v-text-field
            class="rounded-input metropolis font-size-20"
            :placeholder="t('login.form-recovery-password.email.placeholder')"
            hide-details="auto"
            type="email"
            outlined
            dark
            v-model="correo"
            :error-messages="errorCorreo"
            @input="v$.correo.$touch()"
            @blur="v$.correo.$touch()"
            @keypress.enter="recuperar"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="auto" class="pt-0">
          <v-card color="transparent" flat height="32">
            <v-container class="fill-height">
              <v-row justify="center" align="center">
                <v-col cols="auto" class="error--text text-center">
                  {{ errorSignIn ? t(`login.error.${errorSignIn}`) : "" }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            dark
            block
            x-large
            class="rounded-input elevation-black font-size-20 font-weight-bold metropolis letter-spacing-button"
            @click="recuperar"
            :disabled="cargando"
            :loading="cargando"
          >
            {{ $t("login.form-recovery-password.submit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive, watch } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  props: {
    errorSignIn: {
      type: String,
      default: null,
    },
  },
  emits: ["click-recuperar"],
  setup(props, ctx) {
    const { t } = useI18n();

    watch(
      () => props.errorSignIn,
      (value) => {
        if (value) {
          cargando.value = false;
        }
      }
    );

    const correo = ref("");

    const cargando = ref(false);

    const state = reactive({
      correo,
    });

    const rules = {
      correo: {
        required,
        email,
      },
    };

    const v$ = useVuelidate(rules, state);

    const errorCorreo = computed((): string[] => {
      const error: Array<string> = [];
      if (!v$.value.correo.$dirty) return error;
      v$.value.correo.required ||
        error.push(
          t("login.form-recovery-password.email.error.required") as string
        );
      v$.value.correo.email ||
        error.push(
          t("login.form-recovery-password.email.error.invalid") as string
        );
      return error;
    });

    const onClickRecuperar = (data: { email: string }): void => {
      ctx.emit("click-recuperar", data);
    };

    const recuperar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) {
        return;
      }
      cargando.value = true;
      onClickRecuperar({ email: correo.value });
    };

    return {
      t,
      v$,
      correo,
      errorCorreo,
      cargando,
      onClickRecuperar,
      recuperar,
    };
  },
});
</script>

<style scoped>
@supports (backdrop-filter: blur(2px)) {
  .glass-card {
    background: rgba(45, 61, 67, 0.5) !important;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

.letter-spacing-button {
  letter-spacing: 1px !important;
}
</style>
