<template>
  <v-row
    justify="center"
    align="center"
    no-gutters
  >
    <v-col cols="3">
      <v-row
        no-gutters
        justify="space-between"
        align="center"
      >
        <v-col
          cols="auto"
          :class="{
            'metropolis font-weight-bold': true,
            'darker--text': !dialog,
            'white--text': dialog
          }"
        >
          {{ label }}
        </v-col>
        <v-col
          cols="auto"
          class="pa-0 error--text metropolis"
          v-if="required"
        >
          *
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="9" class="pl-2">
      <v-select
        :solo="!dialog"
        :outlined="dialog"
        dense
        hide-details="auto"
        :placeholder="placeholder"
        :class="{
          'rounded-input metropolis': true,
          'elevation-input-dense': !dialog,
          'border-input': dialog
        }"
        :menu-props="{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition',
        }"
        :error-messages="errorMessages"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        v-model="vModel"
        :return-object="returnObject"
        :clearable="clearable"
        :readonly="readonly"
        :disabled="disabled"
        :dark="dialog"
        @input="onInput"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
      >
        <template v-slot:item="{ item }">
          <span :class="`metropolis darker--text text-${textCase}`">
            {{ getText(item) }}
          </span>
        </template>
        <template v-slot:selection="{ item }">
          <span :class="`metropolis ${dialog ? 'white' : 'darker'}--text text-${textCase}`">
            {{ getText(item) }}
          </span>
        </template>
        <template v-slot:append>
          <div class="icon-container" v-if="dialog">
            <svg-icon
              name="ionic-md-arrow-dropdown"
              color="text"
              width="12px"
              height="12px"
            ></svg-icon>
          </div>
          <v-icon color="primary" v-else>
            mdi-chevron-down
          </v-icon>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//composition
import {computed, defineComponent} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "FormSelect",
  props: {
    value: {
      type: [String, Number, Object]
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: "value"
    },
    itemText: {
      type: String,
      default: "text"
    },
    textCase: {
      type: String,
      default: "initial"
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array as () => Array<string>,
      default: () => []
    }
  },
  components: {
    SvgIcon
  },
  emits: [
    "input",
    "change",
    "focus",
    "blur"
  ],
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    const getText = (item: { [key: string]: string } | string): string => {
      if (typeof item === "object") {
        if (item[props.itemText]) {
          return item[props.itemText];
        }
      }
      return JSON.stringify(item);
    };

    const onInput = (value: unknown): void => {
      ctx.emit("input", value);
    };

    const onChange = (value: unknown): void => {
      ctx.emit("change", value);
    };

    const onFocus = (ev: FocusEvent): void => {
      ctx.emit("focus", ev);
    };

    const onBlur = (ev: FocusEvent): void => {
      ctx.emit("blur", ev);
    };

    return {
      vModel,
      getText,
      onInput,
      onChange,
      onFocus,
      onBlur
    };
  }
});
</script>

<style scoped>
.icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
