<template>
  <div class="fill-height d-flex flex-column pa-4">
    <HeaderListaBarredores
      v-model="seleccionMultipleModel"
      @click:nueva-ruta="onClickNuevaRuta"
      @click:agregar-barredor="onClickAgregar"
      :mostrar-boton-agregar="mostrarBotonAgregar"
    ></HeaderListaBarredores>
    <v-row no-gutters class="mt-3 overflow-auto scrollbar">
      <v-col>
        <v-row
          no-gutters
          v-for="item in items"
          :key="item.barredor.id"
          align="center"
          class="pt-2"
          justify="space-between"
        >
          <v-col cols="6">
            <v-row no-gutters align="center">
              <v-col cols="auto" class="pr-2">
                <v-btn icon small @click="seleccionar(item)">
                  <v-icon>
                    {{ getIcon(item) }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="pr-2">
                <v-icon :color="item.color" size="20px"> mdi-circle</v-icon>
              </v-col>
              <v-col
                cols="auto"
                class="metropolis text-body-1 darker--text text-capitalize"
              >
                {{ item.numero }}. {{ item.nombre }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters justify="end">
              <v-col cols="auto" class="pr-2">
                <ChipTiempo
                  :tiempo="tiempoPings[item.id]"
                  :barredor="item.barredor"
                  :fecha="fecha"
                ></ChipTiempo>
              </v-col>
              <v-col cols="auto" class="px-2" v-if="!historico">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :color="item.conectado ? 'primary-light' : 'disabled'"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <svg-icon
                        name="ag-check"
                        color="primary"
                        v-if="item.conectado"
                        width="12px"
                      ></svg-icon>
                      <v-icon v-else color="darker" size="12px">
                        mdi-pause
                      </v-icon>
                    </v-chip>
                  </template>
                  <span class="metropolis">
                    {{ getTextEstado(item) }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto">
                <v-row no-gutters justify="end" align="center">
                  <v-col cols="auto" v-if="mostrarBotonEditar">
                    <v-tooltip bottom v-if="item.ruta">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="onClickMostrarRuta(item.barredor)"
                        >
                          <svg-icon
                            v-if="!item.mostrarRuta"
                            name="ags-map-marker-alt-slash"
                            color="secondary"
                            height="20px"
                            width="20px"
                          ></svg-icon>
                          <v-icon v-else size="20px" color="secondary">
                            mdi-map-marker
                          </v-icon>
                        </v-btn>
                      </template>
                      <span class="metropolis">
                        {{ getTextRuta(item) }}
                      </span>
                    </v-tooltip>
                    <v-card
                      v-else
                      flat
                      color="transparent"
                      width="36px"
                      height="36px"
                    ></v-card>
                  </v-col>
                  <v-col cols="auto" v-if="mostrarBotonEditar">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="onClickEditar(item.barredor)"
                        >
                          <svg-icon
                            name="ag-edit"
                            color="yellow"
                            width="20px"
                            height="20px"
                          ></svg-icon>
                        </v-btn>
                      </template>
                      <span class="metropolis">
                        {{ $t("barredores.tab.barredores.list.boton-editar.tooltip") }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="auto" v-if="mostrarBotonEliminar">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="onClickEliminar(item.barredor)"
                        >
                          <v-icon size="20px" color="error">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>
                      <span class="metropolis">
                        {{ $t("barredores.tab.barredores.list.boton-eliminar.tooltip") }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

//tipos
import {Permisos} from "@/typings/store/modules/permisos";
import {Barredor} from "@/typings/store/plugins/easyFirestore/barredores";
import {Rastreador} from "@/typings/store/plugins/easyFirestore/rastreadores";
import {RutaBarredor, TiempoPings} from "@/typings/store/plugins/easyFirestore/rutasBarredor";

//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ChipTiempo from "@/components/barredores/listaBarredores/ChipTiempo.vue";
import HeaderListaBarredores from "@/components/barredores/listaBarredores/HeaderListaBarredores.vue";

interface ItemLista {
  id: string;
  color: string;
  numero: number;
  nombre: string;
  conectado: boolean;
  barredor: Barredor;
  mostrarRuta: boolean;
  ruta: RutaBarredor | null;
}

@Component({
  components: {
    SvgIcon,
    ChipTiempo,
    HeaderListaBarredores,
  },
})
export default class ListaBarredores extends Vue {
  @VModel() seleccionados!: Barredor[];

  @Prop() readonly fecha!: string;
  @Prop() readonly permisos!: Permisos;
  @Prop() readonly tiempoPings!: TiempoPings;
  @Prop() readonly seleccionMultiple!: boolean;
  @Prop() readonly barredores!: Array<Barredor>;
  @Prop() readonly rastreadores!: Array<Rastreador>;

  get historico(): boolean {
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    const fecha = this.fecha.toDate();
    return fecha.getTime() < hoy.getTime();
  }

  get mostrarBotonAgregar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEditar(): boolean {
    return this.permisos.barredores !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.barredores === "eliminar";
  }

  get items(): Array<ItemLista> {
    const ordenados = this.barredores.slice();
    ordenados.sort((a, b) => a.numero - b.numero);
    return ordenados.map((barredor) => {
      const rastreador = this.rastreadores.find((r) => r.id === barredor.rastreador?.id,);
      const hoy = new Date();
      const diferenciaMs = hoy.getTime() - (rastreador?.conectado?.toDate()?.getTime() ?? 0);
      const diferenciaMin = Math.round(diferenciaMs / 60000);
      return {
        barredor,
        ruta: barredor.ruta,
        id: barredor.id ?? "",
        color: barredor.color,
        numero: barredor.numero,
        nombre: barredor.nombre,
        conectado: diferenciaMin < 3,
        mostrarRuta: barredor.mostrarRuta,
      };
    });
  }

  get seleccionMultipleModel(): boolean {
    return this.seleccionMultiple;
  }

  set seleccionMultipleModel(value: boolean) {
    this.onInputSeleccionMultiple(value);
  }

  @Emit("input:seleccion-multiple")
  onInputSeleccionMultiple(val: boolean): boolean {
    return val;
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }

  @Emit("click:nueva-ruta")
  onClickNuevaRuta(): void {
    return;
  }

  @Emit("click:editar")
  onClickEditar(barredor: Barredor): Barredor {
    return barredor;
  }

  @Emit("click:eliminar")
  onClickEliminar(barredor: Barredor): Barredor {
    return barredor;
  }

  @Emit("click:mostrar-ruta")
  onClickMostrarRuta(barredor: Barredor): Barredor {
    return barredor;
  }

  @Emit("click:seleccionar")
  onClickSeleccionar(barredor: Barredor): Barredor {
    return barredor;
  }

  seleccionar(item: ItemLista): void {
    const barredor = item.barredor;
    this.onClickSeleccionar(barredor);
  }

  getTextEstado(item: ItemLista): string {
    const index = item.conectado ? 0 : 1;
    return this.$t(`barredores.tab.barredores.list.boton-estado.tooltip.${index}`,) as string;
  }

  getTextRuta(item: ItemLista): string {
    const index = item.mostrarRuta ? 0 : 1;
    return this.$t(`barredores.tab.barredores.list.boton-ruta.tooltip.${index}`,) as string;
  }

  estaSeleccionado(item: ItemLista): boolean {
    return this.seleccionados.some(seleccionado => seleccionado.id === item.barredor.id);
  }

  getIcon(item: ItemLista): string {
    const seleccionado = this.estaSeleccionado(item);
    if (this.seleccionMultipleModel) return seleccionado ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline";
    return seleccionado ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline";
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
