<template>
  <v-app-bar
    app
    flat
    height="44px"
    color="#ffffff"
    class="app-bar"
  >
    <div class="app-bar__container">
      <UserInformation
        :usuario="usuario"
        :empresa="empresa"
      ></UserInformation>
      <div>
        <svg-icon
          width="auto"
          height="1rem"
          :src="require(`@/assets/svg/sistema/logotipo.svg`)"
        />
      </div>
      <div @click="dialogoConfirmacion = true">
        <svg-icon
          color="primary"
          name="ag-arrow-right-from-bracket"
        />
      </div>
    </div>
    <v-dialog
      max-width="287px"
      v-model="dialogoConfirmacion"
      content-class="border-radius-12"
    >
      <ConfirmLogout
        @click:yes="onClickLogout"
        @click:no="dialogoConfirmacion = false"
      />
    </v-dialog>
  </v-app-bar>
</template>

<script lang="ts">
// Vue
import {defineComponent, ref} from "vue";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Empresa} from "@/typings/store/plugins/easyFirestore/empresas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ConfirmLogout from "@/components/appCore/ConfirmLogout.vue";
import UserInformation from "@/components/appCore/UserInformation.vue";

export default defineComponent({
  name: "AppBarMobile",
  components: {
    SvgIcon,
    ConfirmLogout,
    UserInformation,
  },
  props: {
    usuario: {
      required: true,
      type: Object as () => Usuario,
    },
    empresa: {
      required: true,
      type: Object as () => Empresa,
    },
  },
  emits: [
    "click:logout",
  ],
  setup(_props, ctx) {
    // Data

    const dialogoConfirmacion = ref(false);

    // Emits

    const onClickLogout = () => {
      ctx.emit("click:logout");
    };

    return {
      // Data
      dialogoConfirmacion,
      // Emits
      onClickLogout,
    };
  },
});
</script>

<style scoped>
.app-bar {
  transform: none !important;
  contain: initial !important;
  filter: drop-shadow(0rem 0.25rem 1.375rem rgba(53, 79, 87, 0.2));
}

.app-bar >>> .v-toolbar__content {
  padding: 0 !important;
}

.app-bar__container {
  width: 100%;
  display: grid;
  padding: 0 1rem;
  align-items: center;
  grid-template-columns: auto 1fr auto;
}
</style>
