<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters align="end" class="pt-10 mr-12 flex-grow-0">
      <v-col>
        <FiltrosRastreadores
          :rastreador-seleccionado="rastreadorSeleccionado"
          v-model="filtros"
          @click:agregar="onClickAgregar"
        ></FiltrosRastreadores>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-7 pr-12 mb-9 flex-grow-1">
      <v-col cols="8">
        <TablaRastreadores
          :rastreadores="rastreadores"
          :search="filtros.buscar"
          :rastreador="rastreadorSeleccionado"
          @click:informacion="onClickInformacion"
          @click:switch="onClickSwitch"
        ></TablaRastreadores>
      </v-col>
      <v-col cols="4" class="pl-7">
        <FormularioRastreador
          v-if="mostrarFormulario"
          :rastreador="rastreadorSeleccionado"
          :empresas="arrayEmpresas"
          @click:guardar="onClickGuardar"
        ></FormularioRastreador>
        <InformacionRastreador
          v-if="mostrarInformacion"
          :rastreador="rastreadorSeleccionado"
          :empresas="arrayEmpresas"
          @click:eliminar="onClickEliminar"
          @click:editar="onClickEditar"
        ></InformacionRastreador>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      max-width="565"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
//composition
import {computed, defineComponent, ref} from "vue";
//vuex
import {mapGetters} from "vuex";
import store from "@/store";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import {Empresa} from "@/typings/store/plugins/easyFirestore/empresas";
import {Rastreador, RastreadorPatch} from "@/typings/store/plugins/easyFirestore/rastreadores";
import {FiltrosRastreador} from "@/typings/components/rastreadores/filtros";
//componentes
import TablaRastreadores from "@/components/rastreadores/Tabla.vue";
import FiltrosRastreadores from "@/components/rastreadores/Filtros.vue";
import FormularioRastreador from "@/components/rastreadores/Formulario.vue";
import InformacionRastreador from "@/components/rastreadores/Informacion.vue";
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";

export default defineComponent({
  name: "RastreadoresView",
  components: {
    ConfirmationDialog,
    SuccessDialog,
    FormularioRastreador,
    TablaRastreadores,
    FiltrosRastreadores,
    InformacionRastreador
  },
  computed: mapGetters({
    getRutasUsuario: "usuario/getRutas",
    getPermisosUsuario: "usuario/getPermisos"
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  created() {
    if (!routeGuard()) {
      return;
    }
  },
  setup() {
    const getters = mapGetters({
      getArrayRastreadores: "rastreadores/getArray",
      getArrayEmpresas: "empresas/getArray"
    });

    const arrayRastreadores = computed<Array<Rastreador>>(getters.getArrayRastreadores);
    const arrayEmpresas = computed<Array<Empresa>>(getters.getArrayEmpresas);

    const filtros = ref<FiltrosRastreador>({
      buscar: "",
      estado: null,
      tipo: null
    });
    const dialogoConfirmacion = ref<{
      model: boolean;
      texto: string;
      si: (() => void) | null;
      no: (() => void) | null;
    }>({
      model: false,
      texto: "",
      si: null,
      no: null
    });
    const dialogoExito = ref({
      model: false,
      mensaje: "",
      icono: ""
    });
    const rastreadorSeleccionado = ref<Rastreador | null>(null);
    const mostrarInformacion = ref(false);
    const mostrarFormulario = ref(false);

    const rastreadores = computed(() => {
      return arrayRastreadores.value.filter(rastreador => {
        const filtroEstado = filtros.value.estado !== null ? filtros.value.estado === rastreador.estado : true;
        const filtroTipo = filtros.value.tipo !== null ? filtros.value.tipo === rastreador.tipo : true;
        return filtroEstado && filtroTipo;
      });
    });

    const onClickInformacion = (rastreador: Rastreador) => {
      if (rastreadorSeleccionado.value !== null && mostrarFormulario.value) {
        dialogoConfirmacion.value = {
          model: true,
          texto: i18n.t("rastreadores.confirmacion.editar") as string,
          si: () => {
            cerrarDialogoConfirmacion();
            abrirInformacion(rastreador);
          },
          no: () => {
            cerrarDialogoConfirmacion();
          }
        };
      } else {
        abrirInformacion(rastreador);
      }
    };

    const abrirInformacion = (rastreador: Rastreador) => {
      if (rastreadorSeleccionado.value?.id === rastreador.id) {
        rastreadorSeleccionado.value = null;
        mostrarInformacion.value = false;
      } else {
        rastreadorSeleccionado.value = rastreador;
        mostrarInformacion.value = true;
      }
      mostrarFormulario.value = false;
    };

    const onClickAgregar = () => {
      rastreadorSeleccionado.value = null;
      mostrarFormulario.value = true;
      mostrarInformacion.value = false;
    };

    const onClickGuardar = (rastreador: Rastreador) => {
      store.dispatch("rastreadores/set", rastreador);
      if(rastreadorSeleccionado.value !== null) {
        dialogoExito.value = {
          model: true,
          mensaje: i18n.t("rastreadores.exito.editar") as string,
          icono: "ag-save"
        };
      } else {
        dialogoExito.value = {
          model: true,
          mensaje: i18n.t("rastreadores.exito.crear") as string,
          icono: "fas-tablet-rugged"
        };
      }
      rastreadorSeleccionado.value = null;
      mostrarFormulario.value = false;
      mostrarInformacion.value = false;
    };

    const onClickEliminar = (rastreador: Rastreador) => {
      dialogoConfirmacion.value = {
        model: true,
        texto: i18n.t("rastreadores.confirmacion.eliminar") as string,
        si: () => {
          cerrarDialogoConfirmacion();
          rastreadorSeleccionado.value = null;
          mostrarInformacion.value = false;
          store.dispatch("rastreadores/delete", rastreador.id);
        },
        no: () => {
          cerrarDialogoConfirmacion();
        }
      };
    }

    const onClickEditar = (rastreador: Rastreador) => {
      rastreadorSeleccionado.value = rastreador;
      mostrarFormulario.value = true;
      mostrarInformacion.value = false;
    };

    const cerrarDialogoExito = () => {
      dialogoExito.value = {
        model: false,
        mensaje: "",
        icono: ""
      };
    };

    const cerrarDialogoConfirmacion = () => {
      dialogoConfirmacion.value = {
        model: false,
        texto: "",
        si: null,
        no: null
      };
    };

    const onClickSwitch = (rastreador: Rastreador) => {
      if (!rastreador.id) return;
      const estado = rastreador.estado;
      const doc: RastreadorPatch = {id: rastreador.id, estado: estado};
      doc.estado = estado === "deshabilitado" ? "habilitado" : "deshabilitado";
      store.dispatch("rastreadores/set", doc);
    }

    return {
      filtros,
      dialogoExito,
      dialogoConfirmacion,
      rastreadorSeleccionado,
      mostrarInformacion,
      mostrarFormulario,
      rastreadores,
      arrayEmpresas,
      onClickInformacion,
      onClickAgregar,
      onClickGuardar,
      onClickEliminar,
      onClickEditar,
      onClickSwitch,
      cerrarDialogoExito
    };
  }
});
</script>
