<template>
  <v-card flat color="transparent" :width="width">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="auto" v-if="mostrarSwitch">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch
                  v-model="switchValue"
                  :loading="cargando"
                  color="primary"
                  class="ma-0 pa-0"
                  dense
                  hide-details
                  inset
                ></v-switch>
              </div>
            </template>
            <span class="metropolis">
              {{ switchValue ? $t("users.table.tooltip.switch-on") : $t("users.table.tooltip.switch-off") }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <icon-button
            :icon="
              isSelected ? 'fas-circle-xmark' : 'ionic-md-information-circle'
            "
            :color="isSelected ? 'error' : 'primary'"
            @click="onClickInfo"
          >
            {{ $t(`users.table.tooltip.${isSelected ? "cerrar-info" : "info"}`) }}
          </icon-button>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// helpers
import { log } from "@/helpers/env";
// tipos
import { Item } from "@/typings/components/usuarios/tabla";
import { Permisos } from "@/typings/store/modules/permisos";
import {
  EstadoUsuario,
  Usuario,
} from "@/typings/store/plugins/easyFirestore/usuarios";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: Item;
  @Prop() readonly width?: string | number;
  @Prop() readonly permisos!: Permisos;
  @Prop() readonly seleccionado!: Usuario | null;

  cargando = false;

  get isSelected(): boolean {
    return this.seleccionado !== null && this.seleccionado.id === this.item.id;
  }

  get mostrarSwitch(): boolean {
    return this.permisos.usuarios !== "ver";
  }

  get estado(): EstadoUsuario {
    const estado = this.item.data.estado;
    if (!estado) {
      return "activo";
    }
    return estado;
  }

  get switchValue(): boolean {
    this.cargando = false;
    return this.estado !== "deshabilitado";
  }

  set switchValue(value: boolean) {
    if (log) {
      console.log("set switchValue", value);
    }
    this.onClickSwitch();
    this.cargando = true;
  }

  @Emit("click-info")
  onClickInfo(): Item {
    return this.item;
  }

  @Emit("click-switch")
  onClickSwitch(): Item {
    return this.item;
  }
}
</script>
