<template>
  <v-card
    dark
    color="rgba(45,61,67,0.55)"
    class="border-grey rounded-input glass-card elevation-black"
    width="612"
    min-height="598"
  >
    <v-container class="px-11 py-8">
      <v-row justify="center" align="center" class="pt-7 pb-4">
        <v-col cols="auto">
          <v-img
            width="407"
            height="68px"
            contain
            :src="require('@/assets/svg/sistema/logotipo-white.svg')"
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col
          cols="auto"
          class="pt-0 secondary--text font-weight-bold font-size-25 metropolis"
        >
          {{ $t("login.form-activate-account.title") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="pb-1 pl-6 metropolis font-size-20">
          {{ $t("login.form-activate-account.password.label") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-1">
          <v-text-field
            class="rounded-input metropolis font-size-20"
            :placeholder="t('login.form-activate-account.password.placeholder')"
            :append-icon="verPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="verPassword ? 'text' : 'password'"
            outlined
            dark
            v-model="password"
            :error-messages="errorPassword"
            @input="v$.password.$touch()"
            @blur="v$.password.$touch()"
            @keypress.enter="submit"
            @click:append="verPassword = !verPassword"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="pb-1 pl-6 metropolis font-size-20">
          {{ $t("login.form-activate-account.re-password.label") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <v-text-field
            class="rounded-input metropolis font-size-20"
            :placeholder="
              t('login.form-activate-account.re-password.placeholder')
            "
            :append-icon="verRePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="verRePassword ? 'text' : 'password'"
            outlined
            dark
            v-model="rePassword"
            :error-messages="errorRePassword"
            @input="v$.rePassword.$touch()"
            @blur="v$.rePassword.$touch()"
            @keypress.enter="submit"
            @click:append="verRePassword = !verRePassword"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            dark
            block
            x-large
            class="rounded-input elevation-black font-size-20 font-weight-bold metropolis letter-spacing-button"
            @click="submit"
          >
            {{ $t("login.form-activate-account.submit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

export default defineComponent({
  emits: ["click-btn-submit"],
  setup(props, ctx) {
    const { t } = useI18n();

    const password = ref("");
    const rePassword = ref("");

    const verPassword = ref(false);
    const verRePassword = ref(false);

    const state = reactive({
      password,
      rePassword,
    });

    const rules = {
      password: {
        required,
        minLength: minLength(6),
      },
      rePassword: {
        required,
        minLength: minLength(6),
        sameAs: sameAs(rePassword),
      },
    };

    const v$ = useVuelidate(rules, state);

    const errorPassword = computed((): string[] => {
      const error: Array<string> = [];
      if (!v$.value.password.$dirty) return error;
      !v$.value.password.required.$invalid ||
        error.push(
          t("login.form-activate-account.password.error.required") as string
        );
      !v$.value.password.minLength.$invalid ||
        error.push(
          t("login.form-activate-account.password.error.min-length") as string
        );
      !v$.value.rePassword.sameAs.$invalid ||
        error.push(
          t("login.form-activate-account.password.error.same-as") as string
        );
      return error;
    });

    const errorRePassword = computed((): string[] => {
      const error: Array<string> = [];
      if (!v$.value.rePassword.$dirty) return error;
      !v$.value.rePassword.required.$invalid ||
        error.push(
          t("login.form-activate-account.re-password.error.required") as string
        );
      !v$.value.rePassword.minLength.$invalid ||
        error.push(
          t(
            "login.form-activate-account.re-password.error.min-length"
          ) as string
        );
      !v$.value.rePassword.sameAs.$invalid ||
        error.push(
          t("login.form-activate-account.re-password.error.same-as") as string
        );
      return error;
    });

    const onClickBtnSubmit = (data: { password: string }) => {
      ctx.emit("click-btn-submit", data);
    };

    const submit = (): void => {
      v$.value.$touch();
      if (!v$.value.$invalid) {
        onClickBtnSubmit({ password: password.value });
      }
    };

    return {
      t,
      v$,
      password,
      rePassword,
      verPassword,
      verRePassword,
      errorPassword,
      errorRePassword,
      submit,
    };
  },
});
</script>

<style scoped>
@supports (backdrop-filter: blur(2px)) {
  .glass-card {
    background: rgba(45, 61, 67, 0.5) !important;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

.letter-spacing-button {
  letter-spacing: 1px !important;
}
</style>
