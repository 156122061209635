<template>
  <div class="fill-height">
    <v-row justify="center" align="center" no-gutters>
      <v-col
        cols="auto"
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("users.info.title") }}
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters align="center" class="pt-2">
      <v-col cols="12" class="primary--text">
        <SubirImagen :preview="foto" only-preview></SubirImagen>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.names.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ nombres }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.last-names.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ apellidos }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.uid.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ rut }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.role.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ cargo }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.email.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ correo }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.phone.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ telefono }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.client.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ cliente }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.services.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ servicios }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-4">
      <v-col cols="3" class="text-truncate metropolis">
        {{ $t("users.info.item.type.label") }}
      </v-col>
      <v-col cols="9" class="font-weight-bold metropolis">
        {{ tipo }}
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// decoradores
import { Component, Vue, Prop } from "vue-property-decorator";
// tipos
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
//componentes
import SubirImagen from "@/components/SubirImagen.vue";

@Component({
  components: {
    SubirImagen,
  },
})
export default class InfoUsuario extends Vue {
  @Prop() readonly usuario!: Usuario | null;

  get foto(): string {
    return this.usuario?.foto?.url || "";
  }

  get nombres(): string {
    return (
      this.usuario?.nombres || (this.$t("users.info.item.no-data") as string)
    );
  }

  get apellidos(): string {
    return (
      this.usuario?.apellidos || (this.$t("users.info.item.no-data") as string)
    );
  }

  get rut(): string {
    return this.usuario?.rut || (this.$t("users.info.item.no-data") as string);
  }

  get cargo(): string {
    return (
      this.usuario?.cargo || (this.$t("users.info.item.no-data") as string)
    );
  }

  get correo(): string {
    return (
      this.usuario?.correo || (this.$t("users.info.item.no-data") as string)
    );
  }

  get telefono(): string {
    return (
      this.usuario?.telefono || (this.$t("users.info.item.no-data") as string)
    );
  }

  get cliente(): string {
    return (
      this.usuario?.cliente?.nombre ||
      (this.$t("users.info.item.no-data") as string)
    );
  }

  get servicios(): string {
    const items = 1;
    const servicios = this.usuario?.servicios?.map((servicio, index, servicios) => {
        if (index < items) {
          return servicio.nombre;
        } else if (index === items) {
          const otros = servicios.length - index;
          if (otros === 1) {
            return `${otros} ${this.$t("users.info.item.services.other")}`;
          } else {
            return `${otros} ${this.$t("users.info.item.services.others")}`;
          }
        } else {
          return "";
        }
    }).filter((servicio) => servicio !== "").join(", ");
    return servicios || (this.$t("users.info.item.no-data") as string);
  }

  get tipo(): string {
    return this.usuario?.tipo || (this.$t("users.info.item.no-data") as string);
  }
}
</script>
