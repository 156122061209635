<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters v-if="pingActual">
      <v-col>
        <v-row no-gutters>
          <v-col cols="7" class="metropolis text-body-1 black--text">
            {{ $t("barredores.tab.monitoreo.tab.ruta.label.0") }}
          </v-col>
          <v-col
            cols="5"
            class="metropolis text-body-1 black--text font-weight-bold"
          >
            {{ hora }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3">
          <v-col cols="7" class="metropolis text-body-1 black--text">
            {{ $t("barredores.tab.monitoreo.tab.ruta.label.1") }}
          </v-col>
          <v-col
            cols="5"
            class="metropolis text-body-1 black--text font-weight-bold"
          >
            {{
              $t("barredores.tab.monitoreo.tab.ruta.unidades.0", {
                velocity: velocidad,
              })
            }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3">
          <v-col cols="7" class="metropolis text-body-1 black--text">
            {{ $t("barredores.tab.monitoreo.tab.ruta.label.2") }}
          </v-col>
          <v-col
            cols="5"
            class="metropolis text-body-1 black--text font-weight-bold"
          >
            {{
              $t("barredores.tab.monitoreo.tab.ruta.unidades.1", {
                distance: distanciaUltimoPing,
              })
            }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3">
          <v-col cols="7" class="metropolis text-body-1 black--text">
            {{ $t("barredores.tab.monitoreo.tab.ruta.label.3") }}
          </v-col>
          <v-col
            cols="5"
            class="metropolis text-body-1 black--text font-weight-bold"
          >
            {{ tiempoUltimoPing }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3">
          <v-col cols="7" class="metropolis text-body-1 black--text">
            {{ $t("barredores.tab.monitoreo.tab.ruta.label.4") }}
          </v-col>
          <v-col
            cols="5"
            class="metropolis text-body-1 black--text font-weight-bold"
          >
            {{
              $t("barredores.tab.monitoreo.tab.ruta.unidades.2", {
                distance: distanciaAcumulada,
              })
            }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-3" justify="end">
          <v-col cols="auto">
            <v-btn
              @click="onClickEliminarPing"
              class="rounded-input gradient-error elevation-error metropolis text-initial text-body-1 white--text"
            >
              {{ $t("barredores.tab.monitoreo.tab.ruta.button-eliminar.text") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters v-else align="center" justify="center">
      <v-col
        cols="8"
        class="text-center text--text metropolis text-body-1"
        v-html="$t('barredores.tab.monitoreo.tab.ruta.message')"
      ></v-col>
    </v-row>
    <v-row no-gutters class="pt-3 flex-grow-0" justify="center" v-if="esUltimo">
      <v-col cols="auto">
        <v-btn
          @click="onClickEliminarRutaCompleta"
          class="rounded-input gradient-error elevation-error metropolis text-initial text-body-1 white--text"
        >
          {{
            $t("barredores.tab.monitoreo.tab.ruta.button-eliminar-todos.text")
          }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-3 flex-grow-0">
      <v-col
        class="black--text metropolis text-center text-body-1 font-weight-bold"
      >
        {{
          $t("barredores.tab.monitoreo.tab.ruta.total-distance", {
            total: distanciaTotal,
          })
        }}
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { PingRastreador } from "@/typings/store/plugins/easyFirestore/pingsRastreador";

@Component
export default class RutaMonitoreo extends Vue {
  @Prop() readonly pingsFiltrados!: Array<PingRastreador>;
  @Prop() readonly pingActual!: PingRastreador | null;

  get hora(): string {
    return this.pingActual?.fecha?.toDate()?.format("HH:mm:ss") ?? "";
  }

  get velocidad(): string {
    return this.pingActual?.velocidad?.toFixed(2) ?? "";
  }

  get distanciaUltimoPing(): string {
    const index = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    if (index >= 0) {
      const anterior = this.pingsFiltrados[index - 1];
      if (anterior) {
        const posicionActual = this.pingActual?.posicion;
        const posicionAnterior = anterior.posicion;
        if (posicionActual && posicionAnterior) {
          const distancia =
            this.distanceBetween(posicionActual, posicionAnterior) * 1000;
          return distancia.toFixed(2);
        }
      }
    }
    return "0";
  }

  get tiempoUltimoPing(): string {
    const index = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    if (index >= 0) {
      const anterior = this.pingsFiltrados[index - 1];
      if (anterior) {
        const fechaActual = this.pingActual?.fecha?.toDate();
        const fechaAnterior = anterior.fecha?.toDate();
        if (fechaActual && fechaAnterior) {
          const diferencia = fechaActual.getTime() - fechaAnterior.getTime();
          return this.msToTime(diferencia);
        }
      }
    }
    return "00:00:00";
  }

  get distanciaAcumulada(): string {
    const indexActual = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    const pings = this.pingsFiltrados.slice(0, indexActual + 2);
    let distancia = 0;
    let index = 0;
    for (const ping of pings) {
      const anterior = pings[index - 1];
      if (anterior) {
        distancia += this.distanceBetween(ping.posicion, anterior.posicion);
      }
      index++;
    }
    return distancia.toFixed(2);
  }

  get distanciaTotal(): string {
    let distanciaTotal = 0;
    let index = 0;
    for (const ping of this.pingsFiltrados) {
      const anterior = this.pingsFiltrados[index - 1];
      if (anterior) {
        distanciaTotal += this.distanceBetween(
          ping.posicion,
          anterior.posicion
        );
      }
      index++;
    }
    return distanciaTotal.toFixed(2);
  }

  get esUltimo(): boolean {
    const index = this.pingsFiltrados.findIndex(
      (ping) => ping.id === this.pingActual?.id
    );
    return index === this.pingsFiltrados.length - 1;
  }

  @Emit("click:eliminar-ruta-completa")
  onClickEliminarRutaCompleta(): void {
    return;
  }

  @Emit("click:eliminar-ping")
  onClickEliminarPing(): PingRastreador | null {
    return this.pingActual;
  }
}
</script>
