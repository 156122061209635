<template>
  <v-container class="pa-0">
    <v-row no-gutters class="row-buttons" align="end">
      <v-col cols="4">
        <v-btn
          block
          :height="tab === 0 ? '50px' : '44px'"
          depressed
          color="yellow"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 0 ? 'button-inactive' : ''
          }`"
          @click="tab = 0"
        >
          {{ $t("reportes.detalle-pesaje.entrada-salida.tab.entrada.text") }}
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          block
          :height="tab === 1 ? '50px' : '44px'"
          depressed
          color="purple"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 1 ? 'button-inactive' : ''
          }`"
          @click="tab = 1"
        >
          {{ $t("reportes.detalle-pesaje.entrada-salida.tab.salida.text") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card
          color="#FCFCFC"
          class="elevation-black rounded-input rounded-tl-0"
        >
          <v-card-text class="pa-0">
            <v-tabs-items v-model="tab" class="rounded-input rounded-tl-0">
              <v-tab-item class="rounded-input">
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                >
                  <v-col cols="4">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.entrada.label.0") }}
                  </v-col>
                  <v-col class="font-weight-bold">
                    {{ fechaEntrada }}
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                >
                  <v-col cols="4">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.entrada.label.1") }}
                  </v-col>
                  <v-col class="font-weight-bold">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.entrada.peso", {peso: pesoEntrada}) }}
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                >
                  <v-col cols="4">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.entrada.label.3") }}
                  </v-col>
                  <v-col class="font-weight-bold">
                    {{ observacionEntrada }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-2">
                  <v-col>
                    <MenuInfoChofer
                      parent="reportes.detalle-pesaje.entrada-salida.tab.entrada"
                      :chofer="choferEntrada"
                      top
                    ></MenuInfoChofer>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="rounded-input">
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                >
                  <v-col cols="4">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.salida.label.0") }}
                  </v-col>
                  <v-col class="font-weight-bold">
                    {{ fechaSalida }}
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                >
                  <v-col cols="4">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.salida.label.1") }}
                  </v-col>
                  <v-col class="font-weight-bold">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.salida.peso", {peso: pesoSalida}) }}
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                >
                  <v-col cols="4">
                    {{ $t("reportes.detalle-pesaje.entrada-salida.tab.salida.label.3") }}
                  </v-col>
                  <v-col class="font-weight-bold">
                    {{ observacionSalida }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2 px-2">
                  <v-col>
                    <MenuInfoChofer
                      parent="reportes.detalle-pesaje.entrada-salida.tab.salida"
                      :chofer="choferSalida"
                      top
                    ></MenuInfoChofer>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <v-row no-gutters class="pa-2">
              <v-col cols="6" class="pr-4">
                <v-btn
                  depressed
                  block
                  large
                  :class="`text-initial elevation-secondary rounded-input gradient-secondary white--text ${
                    mostrarGaleria ? 'button-inactive button-disabled' : ''
                  }`"
                  @click="onClickVerImagenes"
                >
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <svg-icon name="ags-camera" color="white"></svg-icon>
                    </v-col>
                    <v-col
                      class="metropolis font-weight-bold text-body-1 white--text"
                    >
                      {{ $t("reportes.detalle-pesaje.entrada-salida.boton-ver-imagenes.text") }}
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pl-4">
                <v-btn
                  depressed
                  color="primary"
                  block
                  large
                  class="text-initial elevation-primary rounded-input gradient-primary"
                  @click="onClickImprimir"
                >
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <svg-icon name="ags-print" color="white"></svg-icon>
                    </v-col>
                    <v-col class="metropolis font-weight-bold text-body-1">
                      {{ $t("reportes.detalle-pesaje.entrada-salida.boton-imprimir.text") }}
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
//decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
//tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";
import {Chofer} from "@/typings/store/plugins/easyFirestore/choferes";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import MenuInfoChofer from "@/components/transporte/choferes/MenuInfo.vue";

@Component({
  components: {
    SvgIcon,
    MenuInfoChofer,
  },
})
export default class EntradaSalida extends Vue {
  @Prop() readonly pesaje!: Pesaje;
  @Prop() readonly mostrarGaleria!: boolean;

  tab = 0;

  get noValueMessage(): string {
    return this.$t(
      "reportes.detalle-pesaje.entrada-salida.tab.no-value-message",
    ) as string;
  }

  get fechaEntrada(): string {
    const fechaEntrada = this.pesaje.entrada?.fecha;
    return fechaEntrada
      ? fechaEntrada.toDate().format("DD-MM-YYYY, HH:mm")
      : this.noValueMessage;
  }

  get pesoEntrada(): string {
    const peso = this.pesaje.entrada?.peso?.toCurrency();
    return peso ? peso : this.noValueMessage;
  }

  get observacionEntrada(): string {
    return (
      this.pesaje.entrada?.observacion?.capitalizeFirstLetter() ||
      this.noValueMessage
    );
  }

  get choferEntrada(): Chofer | null {
    return this.pesaje.entrada?.chofer || null;
  }

  get fechaSalida(): string {
    const fechaSalida = this.pesaje.salida?.fecha;
    return fechaSalida
      ? fechaSalida.toDate().format("DD-MM-YYYY, HH:mm")
      : this.noValueMessage;
  }

  get pesoSalida(): string {
    const peso = this.pesaje.salida?.peso?.toCurrency();
    return peso ? peso : this.noValueMessage;
  }

  get observacionSalida(): string {
    return (
      this.pesaje.salida?.observacion?.capitalizeFirstLetter() ||
      this.noValueMessage
    );
  }

  get choferSalida(): Chofer | null {
    return this.pesaje.salida?.chofer || null;
  }

  @Emit("click:ver-imagenes")
  onClickVerImagenes(): void {
    return;
  }

  @Emit("click:imprimir")
  onClickImprimir(): void {
    return;
  }
}
</script>

<style scoped>
.row-buttons {
  height: 50px;
}

.button {
  transition: all 150ms ease;
  opacity: 1;
}
</style>
