<template>
  <v-img
    :src="require('@/assets/webp/background-login.webp')"
    :lazy-src="require('@/assets/webp/background-login.webp')"
    height="100vh"
    class="pa-0 ma-0"
  >
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="auto"></v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <FormularioLogin
            v-if="estado === 0"
            :errorSignIn="errorSignIn"
            @click-btn="login"
            @recuperar-password="clickRecuperarPassword"
          ></FormularioLogin>
          <RecuperarPassword
            v-else-if="estado === 1"
            :errorSignIn="errorSignIn"
            @click-recuperar="recuperarPassword"
          ></RecuperarPassword>
          <RecuperarPasswordExito
            v-else-if="estado === 2"
            @close="recuperarPasswordExitoClose"
          ></RecuperarPasswordExito>
          <ActivarCuenta
            v-else-if="estado === 3"
            @click-btn-submit="activarCuenta"
          ></ActivarCuenta>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="auto">
          <a
            class="white--text text-decoration-underline metropolis font-size-20"
            @click="$router.push({ name: 'terminosCondiciones' })"
          >
            {{ $t(`login.link.terms-and-conditions`) }}
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script lang="ts">
// decoradores
import { Vue, Component, Watch } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// componentes
import FormularioLogin from "@/components/login/FormularioLogin.vue";
import RecuperarPassword from "@/components/login/RecuperarPassword.vue";
import RecuperarPasswordExito from "@/components/login/RecuperarPasswordExito.vue";
import ActivarCuenta from "@/components/login/ActivarCuenta.vue";

const routeGuard = (vm: Vue): boolean => {
  const rutas = vm.getRutasUsuario;
  if (rutas.length > 0) {
    const to = rutas[0].to;
    if (to && to.name !== vm.$route.name) {
      vm.$router.replace(to);
      return false;
    }
  }
  return true;
};

@Component({
  computed: mapGetters({
    getRutasUsuario: "usuario/getRutas",
    getErrorSingInUsuario: "usuario/getErrorSingIn",
    getPrimerInicioUsuario: "usuario/getPrimerInicio",
    getIntentosUsuario: "usuario/getIntentos",
  }),
  methods: mapActions({
    signInUsuario: "usuario/signIn",
    recuperarPasswordUsuario: "usuario/recuperarPassword",
    activarUsuario: "usuario/activar",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    FormularioLogin,
    RecuperarPassword,
    RecuperarPasswordExito,
    ActivarCuenta,
  },
})
export default class Login extends Vue {
  created(): void {
    if (!routeGuard(this)) {
      return;
    }
  }

  correo = "";

  estados = {
    login: 0,
    recuperarPassword: 1,
    recuperarPasswordExito: 2,
    activarCuenta: 3,
  };

  estado = this.estados.login;

  get errorSignIn(): string | null {
    return this.getErrorSingInUsuario;
  }

  get primerInicio(): boolean {
    return this.getPrimerInicioUsuario;
  }

  @Watch("primerInicio")
  onPrimerInicioChanged(val: boolean): void {
    if (val) {
      this.estado = this.estados.activarCuenta;
    }
  }

  login(data: { email: string; password: string }): void {
    this.correo = data.email;
    this.signInUsuario({
      email: data.email,
      password: data.password,
    });
  }

  async recuperarPassword(data: { email: string }): Promise<void> {
    await this.recuperarPasswordUsuario(data.email);
    this.estado = this.estados.recuperarPasswordExito;
  }

  recuperarPasswordExitoClose(): void {
    this.estado = this.estados.login;
  }

  clickRecuperarPassword(correo: string): void {
    this.correo = correo;
    this.estado = this.estados.recuperarPassword;
  }

  formularioActivarCuenta(): void {
    if (!this.correo) {
      return;
    }
    this.estado = this.estados.activarCuenta;
  }

  activarCuenta(data: { password: string }): void {
    this.activarUsuario({
      email: this.correo,
      password: data.password,
    });
  }
}
</script>
