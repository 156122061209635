<template>
  <div class="d-flex flex-column fill-height">
    <v-row align="center" justify="center" no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("desechos.detalle.title") }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("desechos.detalle.labels.0") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ nombre }}
          </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center" class="pt-4">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("desechos.detalle.labels.2") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ descripcion }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0">
      <v-col cols="6" class="pr-2" v-if="mostrarBotonEditar">
        <v-btn
          block
          large
          class="rounded-input elevation-warning gradient-warning"
          @click="onClickEditar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon name="ag-edit" color="white"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("desechos.detalle.boton-editar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="6" class="pl-2" v-if="mostrarBotonEliminar">
        <v-btn
          block
          large
          class="rounded-input elevation-error gradient-error"
          @click="onClickEliminar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <v-icon color="white"> mdi-delete</v-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("desechos.detalle.boton-eliminar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class DetalleDesecho extends Vue {
  @Prop() readonly desecho!: Desecho;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.desechos !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.desechos === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("desechos.detalle.no-value-message") as string;
  }

  get nombre(): string {
    return this.desecho.nombre || this.noValueMessage;
  }

  get descripcion(): string {
    return this.desecho.descripcion || this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
</script>
