<template>
  <v-snackbar v-model="vModel" :timeout="-1" color="warning">
    <v-container class="pa-0 ma-0">
      <v-row dense align="center">
        <v-col cols="100%">
          {{ $t("app.snackbar-close-tab.text") }}
        </v-col>
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script lang="ts">
// composition-api
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    value: Boolean,
  },
  emits: ["input"],
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });
    return {
      vModel,
    };
  },
});
</script>
