<template>
  <div class="d-flex flex-column fill-height">
    <v-row align="center" justify="center" no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("clientes.detalle.title") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-2 flex-grow-0">
      <v-col>
        <SubirImagen
          :preview="cliente.foto ? cliente.foto.url : ''"
          only-preview
        ></SubirImagen>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="nombre"
              :label="String($t('clientes.detalle.labels.0'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="rut"
              :label="String($t('clientes.detalle.labels.1'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="tipo"
              :label="String($t('clientes.detalle.labels.2'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="giro.capitalizeFirstLetter()"
              :label="String($t('clientes.detalle.labels.3'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="ciudad"
              :label="String($t('clientes.detalle.labels.4'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="direccion"
              :label="String($t('clientes.detalle.labels.5'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="contacto"
              :label="String($t('clientes.detalle.labels.6'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="correo"
              :label="String($t('clientes.detalle.labels.7'))"
            ></information-text>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col>
            <information-text
              :value="estado"
              :label="String($t('clientes.detalle.labels.8'))"
            ></information-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0">
      <v-col cols="6" class="pr-2" v-if="mostrarBotonEditar">
        <action-button
          @click="onClickEditar"
          icon="ag-edit"
          color="warning"
          block
        >
          {{ $t("clientes.detalle.boton-editar.text") }}
        </action-button>
      </v-col>
      <v-col cols="6" class="pl-2" v-if="mostrarBotonEliminar">
        <action-button
          @click="onClickEliminar"
          icon="mdi-delete"
          color="error"
          block
        >
          {{ $t("clientes.detalle.boton-eliminar.text") }}
        </action-button>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//composition
import { defineComponent, computed } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SubirImagen from "@/components/SubirImagen.vue";
import InformationText from "@/components/custom/InformationText.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "DetalleCliente",
  components: {
    ActionButton,
    InformationText,
    SubirImagen,
  },
  emits: ["click:editar", "click:eliminar"],
  props: {
    cliente: {
      type: Object as () => Cliente,
      required: true,
    },
    permisos: {
      type: Object as () => Permisos,
      required: true,
    },
  },
  setup(props, ctx) {
    const noValueMessage = i18n.t("clientes.detalle.no-value-message") as string;

    const mostrarBotonEditar = props.permisos.clientes !== "ver";
    const mostrarBotonEliminar = props.permisos.clientes === "eliminar";

    const id = computed(() => props.cliente.id || noValueMessage);
    const nombre = computed(() => props.cliente.nombre || noValueMessage);
    const rut = computed(() => props.cliente.rut || noValueMessage);
    const tipo = computed(() => props.cliente.tipo || noValueMessage);
    const giro = computed(() => props.cliente.giro || noValueMessage);
    const ciudad = computed(() => props.cliente.ciudad || noValueMessage);
    const direccion = computed(() => props.cliente.direccion || noValueMessage);
    const contacto = computed(() => props.cliente.contacto || noValueMessage);
    const correo = computed(() => props.cliente.correo || noValueMessage);
    const estado = computed(() => props.cliente.estado || noValueMessage);

    const onClickEditar = () => {
      ctx.emit("click:editar");
    };

    const onClickEliminar = () => {
      ctx.emit("click:eliminar");
    };

    return {
      mostrarBotonEditar,
      mostrarBotonEliminar,
      id,
      nombre,
      rut,
      tipo,
      giro,
      ciudad,
      direccion,
      contacto,
      correo,
      estado,
      onClickEditar,
      onClickEliminar,
    };
  },
});
</script>
