<template>
  <div class="d-flex flex-column fill-height pa-4">
    <v-row no-gutters align="center" class="flex-grow-0">
      <v-col
        cols="3"
        class="font-weight-bold darker--text metropolis text-body-1"
      >
        {{ $t("barredores.tab.monitoreo.select.label") }}
      </v-col>
      <v-col>
        <v-select
          hide-details
          dense
          flat
          solo
          :placeholder="$t('barredores.tab.monitoreo.select.placeholder')"
          class="rounded-input elevation-input metropolis"
          :items="barredoresOrdenados"
          v-model="dataMonitoreo.barredor"
          return-object
          item-value="id"
          :menu-props="{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }"
        >
          <template v-slot:item="{ item }">
            <span class="metropolis text-body-1 darker--text text-capitalize">
              {{ item.numero }} - {{ item.nombre }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span class="metropolis text-body-1 darker--text text-capitalize">
              {{ item.numero }} - {{ item.nombre }}
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="row-buttons flex-grow-0 mt-3"
      align="end"
      v-if="dataMonitoreo.barredor"
    >
      <v-col cols="5">
        <v-btn
          block
          :height="tab === 0 ? '50px' : '44px'"
          depressed
          color="secondary"
          :class="{
            'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
            'button-inactive': tab !== 0,
          }"
          @click="tab = 0"
        >
          {{ $t("barredores.tab.monitoreo.tab.sin-movimiento.text") }}
        </v-btn>
      </v-col>
      <v-col cols="5">
        <v-btn
          color="primary"
          block
          :height="tab === 1 ? '50px' : '44px'"
          depressed
          :class="{
            'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
            'button-inactive': tab !== 1,
          }"
          @click="tab = 1"
        >
          {{ $t("barredores.tab.monitoreo.tab.ruta.text") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters class="pb-3" v-if="dataMonitoreo.barredor">
      <v-col>
        <v-card
          height="100%"
          class="elevation-black rounded-input rounded-tl-0"
        >
          <v-card-text class="fill-height">
            <v-tabs-items v-model="tab" class="fill-height">
              <v-tab-item class="fill-height">
                <SinMovimiento
                  :pings-filtrados="pingsFiltrados"
                ></SinMovimiento>
              </v-tab-item>
              <v-tab-item class="fill-height">
                <RutaMonitoreo
                  :pings-filtrados="pingsFiltrados"
                  :ping-actual="pingActual"
                  @click:eliminar-ping="onClickEliminarPing"
                  @click:eliminar-ruta-completa="onClickEliminarRutaCompleta"
                ></RutaMonitoreo>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//composition
import { watch, ref, computed, defineComponent } from "vue";
//tipos
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
import { PingRastreador } from "@/typings/store/plugins/easyFirestore/pingsRastreador";
//componentes
import SinMovimiento from "@/components/barredores/monitoreo/SinMovimiento.vue";
import RutaMonitoreo from "@/components/barredores/monitoreo/Ruta.vue";

export default defineComponent({
  name: "MonitoreoBarredores",
  components: {
    SinMovimiento,
    RutaMonitoreo,
  },
  props: {
    value: {
      type: Object as () => {
        tipo: "sin-movimiento" | "ruta";
        barredor: Barredor | null;
      },
      required: true,
    },
    pingActual: {
      type: Object as () => PingRastreador | null,
    },
    barredores: {
      type: Array as () => Array<Barredor>,
      required: true,
    },
    pingsRastreador: {
      type: Array as () => Array<PingRastreador>,
      required: true,
    },
    fecha: {
      type: String as () => string,
      required: true,
    },
  },
  emits: ["click:eliminar-ping", "click:eliminar-ruta-completa", "input"],
  setup(props, ctx) {
    const tab = ref(0);

    const dataMonitoreo = computed<{
      tipo: "sin-movimiento" | "ruta";
      barredor: Barredor | null;
    }>({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      },
    });

    const barredoresOrdenados=computed(() => {
      const barredores= props.barredores;
      return barredores.sort((barredorA,barredorB) => {
        return barredorA.numero - barredorB.numero
      })
    });

    const pingsFiltrados = computed(() => {
      const pings = props.pingsRastreador.filter((ping) => {
        const filtroBarredor = ping.barredores.some(
          (barredor) => barredor.id === dataMonitoreo.value.barredor?.id
        );
        const horarioBarredor = dataMonitoreo.value.barredor?.horario;
        const inicio = props.fecha.toDate();
        inicio.setHours(0, 0, 0, 0);
        const fin = props.fecha.toDate();
        fin.setHours(23, 59, 59, 999);
        if (horarioBarredor) {
          const [horarioInicio, horarioFin] = horarioBarredor.split("-");
          const [horaInicio, minutoInicio] = horarioInicio
            .split(":")
            .map(Number);
          const [horaFin, minutoFin] = horarioFin.split(":").map(Number);
          inicio.setHours(horaInicio, minutoInicio, 0, 0);
          fin.setHours(horaFin, minutoFin, 59, 999);
        }
        const fecha = ping.fecha.toDate();
        const filtroFecha = inicio <= fecha && fecha <= fin;
        return filtroBarredor && filtroFecha;
      });
      pings.sort(
        (a, b) => a.fecha.toDate().getTime() - b.fecha.toDate().getTime()
      );
      return pings;
    });

    watch(() => dataMonitoreo.value.barredor,() => {
        dataMonitoreo.value.tipo = tab.value === 0 ? "sin-movimiento" : "ruta";
      }
    );

    watch(() => tab.value, () => {
        dataMonitoreo.value.tipo = tab.value === 0 ? "sin-movimiento" : "ruta";
      },{ immediate: true }
    );

    const onClickEliminarPing = (ping: PingRastreador) => {
      ctx.emit("click:eliminar-ping", ping);
    };

    const onClickEliminarRutaCompleta = () => {
      ctx.emit("click:eliminar-ruta-completa", dataMonitoreo.value.barredor);
    };

    return {
      dataMonitoreo,
      barredoresOrdenados,
      tab,
      pingsFiltrados,
      onClickEliminarPing,
      onClickEliminarRutaCompleta,
    };
  },
});
</script>

<style scoped>
.row-buttons {
  height: 50px;
}

.button {
  transition: all 150ms ease;
  opacity: 1;
}
</style>
