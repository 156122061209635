<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="buscar"
    :items-per-page="9"
    hide-default-footer
    height="calc(100% - 89px)"
    class="elevation-black white fill-height table rounded-input"
  >
    <template v-slot:[`item.estado`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="getColorCirculo(item)"
            size="20px"
            v-on="on"
            v-bind="attrs"
          >
            mdi-circle
          </v-icon>
        </template>
        <span class="metropolis text-capitalize">
          {{ item.estado }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.nombre`]="{ item }">
      <ItemTabla
        :valor="item.nombre"
        :clases="clasesItemTabla(item).capitalize"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.rut`]="{ item }">
      <ItemTabla
        :valor="item.rut"
        :clases="clasesItemTabla(item).normal"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.cargo`]="{ item }">
      <ItemTabla
        :valor="item.cargo"
        :clases="clasesItemTabla(item).capitalize"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.correo`]="{ item }">
      <ItemTabla
        :valor="item.correo"
        :clases="clasesItemTabla(item).normal"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.opciones`]="{ item }">
      <OpcionesTabla
        :item="item"
        :permisos="permisos"
        :seleccionado="seleccionado"
        @click-info="onClickInfo"
        @click-switch="onClickSwitch"
      ></OpcionesTabla>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer :value="props"></data-table-footer>
    </template>
  </v-data-table>
</template>

<script lang="ts">
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Item, Items } from "@/typings/components/usuarios/tabla";
import { DataTableHeaders } from "@/typings/vuetify/v-data-table";
// componentes
import ItemTabla from "@/components/usuarios/tabla/Item.vue";
import OpcionesTabla from "@/components/usuarios/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";

@Component({
  components: {
    OpcionesTabla,
    DataTableFooter,
    ItemTabla,
  },
})
export default class TablaUsuarios extends Vue {
  @Prop() readonly usuarios!: Array<Usuario>;
  @Prop() readonly seleccionado!: Usuario | null;
  @Prop() readonly buscar?: string;
  @Prop() readonly permisos!: Permisos;

  headers: DataTableHeaders = [
    {
      text: "",
      value: "estado",
      sortable: false,
    },
    {
      text: this.$t("users.table.header.name") as string,
      value: "nombre",
      align: "center",
    },
    {
      text: this.$t("users.table.header.uid") as string,
      value: "rut",
      align: "center",
    },
    {
      text: this.$t("users.table.header.role") as string,
      value: "cargo",
      align: "center",
    },
    {
      text: this.$t("users.table.header.email") as string,
      value: "correo",
      align: "center",
    },
    {
      text: "",
      value: "opciones",
      align: "end",
      sortable: false,
    },
  ];

  get items(): Items {
    const noData = this.$t("users.table.item.no-data") as string;
    return this.usuarios.map((usuario) => {
      const id = usuario.id;
      const nombres = usuario.nombres || noData;
      const apellidos = usuario.apellidos || "";
      const nombre = `${nombres} ${apellidos}`;
      const rut = usuario.rut || noData;
      const cargo = usuario.cargo || noData;
      const correo = usuario.correo || noData;
      const estado = usuario.estado || noData;
      return {
        id,
        nombre,
        rut,
        cargo,
        correo,
        estado,
        data: usuario,
      };
    });
  }

  @Emit("click-info")
  onClickInfo(item: Item): Usuario {
    return item.data;
  }

  @Emit("click-switch")
  onClickSwitch(item: Item): Usuario {
    return item.data;
  }

  clasesItemTabla(item: Item): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.seleccionado?.id === item.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }

  getColorCirculo(item: Item): string {
    if (item.estado === "activo") {
      return "primary";
    } else if (item.estado === "deshabilitado") {
      return "error";
    }
    return "text";
  }
}
</script>
