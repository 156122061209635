<template>
  <v-card
    dark
    color="rgba(45,61,67,0.55)"
    class="border-grey rounded-input glass-card elevation-black"
    width="612"
    min-height="598"
  >
    <v-container class="px-11 py-8">
      <v-row justify="center" align="center" class="py-7">
        <v-col cols="auto">
          <v-img
            width="407"
            height="68px"
            contain
            :src="require('@/assets/svg/sistema/logotipo-white.svg')"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="pb-1 pl-6 metropolis font-size-20">
          {{ $t("login.form.email.label") }}
        </v-col>
      </v-row>
      <v-row class="pb-6">
        <v-col class="py-1">
          <v-text-field
            class="metropolis font-size-20 rounded-input"
            :placeholder="t('login.form.email.placeholder')"
            hide-details="auto"
            type="email"
            outlined
            dark
            v-model="correo"
            :error-messages="errorCorreo"
            @blur="v$.correo.$touch()"
            @focus="v$.correo.$reset()"
            @keypress.enter="login"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="pb-1 pl-6 metropolis font-size-20">
          {{ $t("login.form.password.label") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <v-text-field
            class="rounded-input metropolis font-size-20"
            :placeholder="t('login.form.password.placeholder')"
            :append-icon="verPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="verPassword ? 'text' : 'password'"
            hide-details="auto"
            outlined
            dark
            v-model="password"
            :error-messages="errorPassword"
            @blur="v$.password.$touch()"
            @focus="v$.password.$reset()"
            @keypress.enter="login"
            @click:append="verPassword = !verPassword"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="auto" class="pt-2">
          <a
            class="white--text text-decoration-underline metropolis font-size-16"
            @click="onRecuperarPassword"
          >
            {{ $t(`login.link.recovery-password`) }}
          </a>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="auto" class="pt-0">
          <v-card color="transparent" flat height="60">
            <v-container class="fill-height">
              <v-row justify="center" align="center">
                <v-col cols="auto" class="error--text text-center metropolis">
                  {{ errorSignIn ? t(`login.error.${errorSignIn}`) : "" }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="secondary"
            dark
            block
            x-large
            class="rounded-input elevation-black font-size-20 font-weight-bold metropolis letter-spacing-button"
            @click="login"
            :disabled="cargando"
            :loading="cargando"
          >
            {{ $t("login.form.submit") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive, watch } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

export default defineComponent({
  props: {
    errorSignIn: {
      type: String,
      default: null,
    },
  },
  emits: ["recuperar-password", "click-btn"],
  setup(props, ctx) {
    const { t } = useI18n();

    watch(
      () => props.errorSignIn,
      (value) => {
        if (value) {
          cargando.value = false;
        }
      }
    );

    const password = ref("");
    const correo = ref("");

    const verPassword = ref(false);

    const cargando = ref(false);

    const state = reactive({
      correo,
      password,
    });

    const rules = {
      correo: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    };

    const v$ = useVuelidate(rules, state);

    const errorCorreo = computed((): string[] => {
      if (!v$.value.correo.$dirty) return [];
      const error: Array<string> = [];
      v$.value.correo.required ||
        error.push(t("login.form.email.error.required") as string);
      v$.value.correo.email ||
        error.push(t("login.form.email.error.invalid") as string);
      return error;
    });

    const errorPassword = computed((): string[] => {
      if (!v$.value.password.$dirty) return [];
      const error: Array<string> = [];
      v$.value.password.required ||
        error.push(t("login.form.password.error.required") as string);
      v$.value.password.minLength ||
        error.push(t("login.form.password.error.min-length") as string);
      return error;
    });

    const onRecuperarPassword = (): void => {
      ctx.emit("recuperar-password", correo.value);
    };

    const onClickBtn = (data: { email: string; password: string }): void => {
      ctx.emit("click-btn", data);
    };

    const login = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) {
        return;
      }
      cargando.value = true;
      onClickBtn({ email: correo.value, password: password.value });
    };

    return {
      t,
      v$,
      password,
      correo,
      verPassword,
      cargando,
      errorCorreo,
      errorPassword,
      onRecuperarPassword,
      onClickBtn,
      login,
    };
  },
});
</script>

<style scoped>
@supports (backdrop-filter: blur(2px)) {
  .glass-card {
    background: rgba(255, 255, 255, 0.01) !important;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}

.letter-spacing-button {
  letter-spacing: 1px !important;
}
</style>
