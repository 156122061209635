<template>
  <div class="vista-home__container">
    <div class="vista-home__logo">
      <img
        alt="WeighTruck"
        src="../assets/svg/sistema/logotipo.svg"
      >
    </div>
    <div class="vista-home__routes-container">
      <div class="vista-home__routes">
        <BotonHome
          :ruta="ruta"
          :key="ruta.id"
          v-for="ruta in rutas"
          @click="onRouteClick(ruta)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import {computed, defineComponent} from "vue";

//helpers
import routeGuard from "@/helpers/routeGuard";

// Composables
import {useUsuario} from "@/composables/usuario";
import {useRoute, useRouter} from "vue-router/composables";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";

// Componentes
import BotonHome from "@/components/home/BotonHome.vue";

export default defineComponent({
  name: "VistaHome",
  components: {
    BotonHome,
  },
  beforeRouteEnter(_to,_from,next){
    next(routeGuard);
  }, 
  created(): void {
    if (!routeGuard()) {
      return;
    }
  },
  setup() {
    
    // Composables
    
    const route = useRoute();
    const router = useRouter();
    const {getRutas} = useUsuario();

    // Computed

    const rutas = computed(() => getRutas.value.filter(ruta => {
      const mobile = vuetify.framework.breakpoint.mdAndDown;
      return mobile ? ruta.mobile : ruta.desktop;
    }));

    // Methods

    const onRouteClick = (ruta: Ruta) => {
      if (ruta.to?.name && route.name !== ruta.to.name) {
        router.push(ruta.to);
      }
    };

    return {
      // Computed
      rutas,
      // Methods
      onRouteClick,
    };
  },
});
</script>

<style scoped>
.vista-home__container {
  height: 100%;
  display: grid;
  row-gap: 2.5rem;
  padding-top: 5rem;
  grid-template-rows: auto 1fr;
}

.vista-home__logo {
  display: flex;
  justify-content: center;
}

.vista-home__logo img {
  width: 18.75rem;
}

.vista-home__routes-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.vista-home__routes {
  gap: 1rem;
  width: 60%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
</style>
