<template>
  <v-list dense class="pa-0 ma-0">
    <v-list-item-group
      v-model="selected"
      class="overflow-auto list-group"
    >
      <v-list-item
        v-for="(ruta, index) in rutas"
        :key="index"
        :to="ruta.to"
        :value="ruta.to?.name"
        :active-class="miniVariant ? '' : 'selected-item'"
        class="list-item pa-0 ml-7 mr-3 my-0"
      >
        <template>
          <v-list-item-action
            :class="{
              'pl-4': !miniVariant,
              'py-1 ma-0': true,
            }"
          >
            <v-card
              :class="`icon-card pa-3 ${
                active(ruta) ? 'gradient-primary' : 'white'
              }`"
              width="39px"
              height="39px"
            >
              <v-row
                no-gutters
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-col cols="auto">
                  <v-icon
                    :color="active(ruta) ? '#FFFFFF' : 'darker'"
                    v-if="ruta.icon.startsWith('mdi')"
                    size="16px"
                  >
                    {{ ruta.icon }}
                  </v-icon>
                  <svg-icon
                    v-else
                    width="16px"
                    height="16px"
                    :src="
                      require(`@/assets/svg/sistema/modulos/${ruta.icon}.svg`)
                    "
                    :color="active(ruta) ? '#FFFFFF' : '#31343A'"
                  ></svg-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-list-item-action>
          <v-list-item-content v-if="!miniVariant" class="pl-7">
            <v-list-item-title
              :class="{
                'white--text': active(ruta),
                'darker--text ': !active(ruta),
                'font-weight-bold': active(ruta),
                'text-capitalize metropolis': true,
              }"
            >
              {{ ruta.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
    <v-list-item @click="onLogout" class="item-logout ml-7 mr-3 mt-4 mb-20">
      <v-list-item-action class="d-flex justify-center">
        <svg-icon name="ag-logout" width="30px" height="30px" color="darker"/>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="text-capitalize darker--text metropolis">
          {{ $t("nav-drawer.listas-rutas.logout.text") }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="miniVariant = !miniVariant"
      class="item-arrow ml-7 mr-3 ma-0"
    >
      <v-list-item-action class="d-flex justify-center">
        <svg-icon
          :name="miniVariant ? 'ag-arrow-expand' : 'ag-arrow-compress'"
          width="26px"
          height="26px"
          color="darker"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="text-capitalize darker--text metropolis">
          {{ $t("nav-drawer.listas-rutas.contraer.text") }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="ml-7 mr-3 mb-md-9 mb-16 mt-3">
      <v-list-item-title
        :class="{
          'font-size-10': miniVariant,
          'font-size-12': !miniVariant,
          'metropolis text-center': true,
        }"
      >
        {{ $t("nav-drawer.listas-rutas.version.text", {number: version}) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
//decoradores
import {Component, Vue, Prop, Emit, VModel} from "vue-property-decorator";
//tipos
import {Ruta} from "@/typings/store/modules/rutas";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class ListaRutasNavegador extends Vue {
  @Prop() readonly rutas!: Array<Ruta>;

  @VModel() miniVariant!: boolean;

  get version(): string {
    return process.env.VUE_APP_VERSION ?? "0.0.0";
  }

  selected = this.$route.name;

  @Emit("logout")
  onLogout(): void {
    return;
  }

  active(ruta: Ruta): boolean {
    return ruta.to?.name === this.$route.name;
  }
}
</script>

<style scoped>
.list-item::before,
.item-logout::before,
.item-arrow::before,
.selected-item::before {
  background: transparent !important;
}

.selected-item {
  background: var(--v-darker-base) !important;
  box-shadow: 8px 15px 19px rgba(49, 52, 58, 0.17);
}

.icon-card {
  box-shadow: 5px 7px 10px rgba(49, 52, 58, 0.06) !important;
  border-radius: 10px !important;
}

.list-item {
  border-radius: 10px !important;
}

.list-item >>> * {
  font-size: 18px !important;
}

.item-arrow,
.item-logout {
  border-radius: 10px !important;
  box-shadow: 8px 15px 19px rgba(45, 61, 67, 0.06) !important;
}

.item-logout {
  background: #ffffff !important;
}

.item-arrow {
  background: #cfdce2 !important;
}

.item-arrow >>> *,
.item-logout >>> * {
  font-weight: 800 !important;
  font-size: 18px !important;
}

.list-group {
  height: calc(100vh - 396px);
}

.list-group::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.list-group::-webkit-scrollbar-thumb {
  background: #31343a;
  border-radius: 30px;
}

.list-group.white-scroll::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.list-group::-webkit-scrollbar-thumb:hover {
  background: #31343a;
}

.list-group::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

.list-group.white-scroll::-webkit-scrollbar-track {
  background: transparent;
}

@media (max-width: 992px) {
  .list-group {
    height: calc(100vh - 424px);
  }
}
</style>
