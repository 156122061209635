<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-row no-gutters>
        <v-col
          class="text-center primary--text text-h6 metropolis font-weight-bold"
        >
          {{ $t("reportes.detalle-pesaje.title") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoVehiculo
            :vehiculo="vehiculo"
            parent="reportes.detalle-pesaje"
          ></MenuInfoVehiculo>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoCliente
            :cliente="cliente"
            parent="reportes.detalle-pesaje"
          ></MenuInfoCliente>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoDesecho
            :desecho="desecho"
            parent="reportes.detalle-pesaje"
          ></MenuInfoDesecho>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoServicio
            :servicio="servicio"
            parent="reportes.detalle-pesaje"
          ></MenuInfoServicio>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoRutaPesaje
            :ruta="ruta"
            parent="reportes.detalle-pesaje"
          ></MenuInfoRutaPesaje>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <v-card
            color="white"
            ref="activator-card"
            class="elevation-black rounded-input"
          >
            <v-card-text class="px-4 py-2">
              <v-row
                no-gutters
                align="center"
                class="metropolis text-body-1 black--text"
              >
                <v-col cols="4">
                  {{ $t("reportes.detalle-pesaje.volumen.label") }}
                </v-col>
                <v-col cols="8" class="font-weight-bold">
                  {{ $t("reportes.detalle-pesaje.volumen.value", { volumen }) }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col
          cols="auto"
          class="metropolis text--text font-size-14 font-weight-bold"
        >
          {{ $t("reportes.detalle-pesaje.created") }}
        </v-col>
        <v-col class="pl-2 metropolis text--text font-size-14 text-capitalize">
          {{ fechaCreacion }} - {{ creadoPor }}
        </v-col>
      </v-row>
      <v-row no-gutters class="px-2" v-if="fechaEdicion">
        <v-col
          cols="auto"
          class="metropolis text--text font-size-14 font-weight-bold"
        >
          {{ $t("reportes.detalle-pesaje.updated") }}
        </v-col>
        <v-col class="pl-2 metropolis text--text font-size-14 text-capitalize">
          {{ fechaEdicion }} - {{ editadoPor }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-6">
        <v-col>
          <EntradaSalida
            :pesaje="pesaje"
            :mostrar-galeria="mostrarGaleria"
            @click:ver-imagenes="onClickVerImagenes"
            @click:imprimir="onClickImprimir"
          ></EntradaSalida>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
// composition-api
import { defineComponent, computed, PropType, ref, watchEffect } from "vue";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Usuarios } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
// composables
import { useKgM3 } from "@/composables/kgM3";
//componentes
import EntradaSalida from "@/components/reportes/EntradaSalida.vue";
import MenuInfoCliente from "@/components/clientes/MenuInfo.vue";
import MenuInfoDesecho from "@/components/desechos/MenuInfo.vue";
import MenuInfoVehiculo from "@/components/transporte/vehiculos/MenuInfo.vue";
import MenuInfoServicio from "@/components/servicios/MenuInfo.vue";
import MenuInfoRutaPesaje from "@/components/transporte/rutas/MenuInfo.vue";

export default defineComponent({
  components: {
    EntradaSalida,
    MenuInfoCliente,
    MenuInfoDesecho,
    MenuInfoServicio,
    MenuInfoVehiculo,
    MenuInfoRutaPesaje,
  },
  props: {
    pesaje: {
      type: Object as PropType<Pesaje | null>,
      required: true,
    },
    mostrarGaleria: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    usuarios: {
      type: Object as PropType<Usuarios>,
      required: true,
    },
  },
  emits: ["click:ver-imagenes", "click:imprimir"],
  setup(props, ctx) {
    const _kgM3 = useKgM3();

    const volumen = ref(0);

    const ruta = computed((): RutaPesaje | null => {
      return props.pesaje?.ruta ?? null;
    });

    const vehiculo = computed((): Vehiculo | null => {
      return props.pesaje?.vehiculo ?? null;
    });

    const cliente = computed((): Cliente | null => {
      return props.pesaje?.cliente ?? null;
    });

    const desecho = computed((): Desecho | null => {
      return props.pesaje?.desecho ?? null;
    });

    const servicio = computed((): Servicio | null => {
      return props.pesaje?.servicio ?? null;
    });

    const fechaCreacion = computed((): string => {
      return (
        props.pesaje?.created_at?.toDate()?.format("DD-MM-YYYY, HH:mm") ?? ""
      );
    });

    const creadoPor = computed((): string => {
      const id = props.pesaje?.created_by;
      if (!id) return "";
      const usuario = props.usuarios[id];
      if (!usuario) return "";
      return usuario.nombres + " " + usuario.apellidos;
    });

    const fechaEdicion = computed((): string => {
      return (
        props.pesaje?.updated_at?.toDate()?.format("DD-MM-YYYY, HH:mm") ?? ""
      );
    });

    const editadoPor = computed((): string => {
      const id = props.pesaje?.updated_by;
      if (!id) return "";
      const usuario = props.usuarios[id];
      if (!usuario) return "";
      return usuario.nombres + " " + usuario.apellidos;
    });

    const onClickVerImagenes = (): void => {
      ctx.emit("click:ver-imagenes");
    };

    const onClickImprimir = (): void => {
      ctx.emit("click:imprimir");
    };

    const cargarVolumen = async (): Promise<void> => {
      if (!props.pesaje) return;
      const _cliente = props.pesaje.cliente;
      const fecha = props.pesaje.entrada?.fecha?.toDate() || new Date();
      const kgM3 = _cliente
        ? await _kgM3.getKgM3By({ cliente: _cliente, fecha })
        : 0;
      const tipoServicio = props.pesaje.servicio?.tipo;
      const peso = props.pesaje.entrada.peso - props.pesaje.salida.peso;
      const pesoNeto = tipoServicio === "Ingreso" ? peso : peso * -1;
      volumen.value = _kgM3.getM3(
        pesoNeto,
        props.pesaje.capacidad,
        props.pesaje.cobro,
        kgM3
      );
    };

    watchEffect(async () => {
      if (props.pesaje) {
        await cargarVolumen();
      }
    });

    return {
      volumen,
      ruta,
      vehiculo,
      cliente,
      desecho,
      servicio,
      fechaCreacion,
      creadoPor,
      fechaEdicion,
      editadoPor,
      onClickVerImagenes,
      onClickImprimir,
      cargarVolumen,
    };
  },
});
</script>
