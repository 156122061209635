<template>
  <v-data-table
    height="calc(100% - 89px)"
    :headers="headers"
    :items="items"
    :items-per-page="8"
    class="elevation-black white fill-height table rounded-input metropolis"
    hide-default-footer
  >
    <template v-slot:[`item.estado`]="{ item }">
      <ItemCirculo :item="item"></ItemCirculo>
    </template>
    <template v-slot:[`item.fecha`]="{ item }">
      <ItemTabla
        :valor="item.fecha"
        :clases="getClasses(item).normal"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.entrada`]="{ item }">
      <ItemTabla
        :valor="item.entrada"
        :clases="getClasses(item).normal"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.salida`]="{ item }">
      <ItemTabla
        :valor="item.salida"
        :clases="getClasses(item).normal"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.patente`]="{ item }">
      <ItemTabla
        :valor="item.patente.toUpperCase()"
        :clases="getClasses(item).uppercase"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.cliente`]="{ item }">
      <ItemTabla
        :valor="item.cliente"
        :clases="getClasses(item).capitalize"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.pesoNeto`]="{ item }">
      <ItemTabla
        :valor="item.pesoNeto"
        :clases="getClasses(item).normal"
      ></ItemTabla>
    </template>
    <template v-slot:[`item.opciones`]="{ item }">
      <OpcionesTabla
        :item="item"
        :seleccionado="pesaje"
        @click:informacion="onClickInformacion"
      ></OpcionesTabla>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer :value="props"></data-table-footer>
    </template>
  </v-data-table>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { Clientes } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicios } from "@/typings/store/plugins/easyFirestore/servicios";
import { Item, Header } from "@/typings/components/reportes/tabla";
//componentes
import ItemTabla from "@/components/reportes/tabla/Item.vue";
import ItemCirculo from "@/components/reportes/tabla/ItemCirculo.vue";
import OpcionesTabla from "@/components/reportes/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import { log } from "@/helpers/env";

@Component({
  components: {
    ItemTabla,
    ItemCirculo,
    OpcionesTabla,
    DataTableFooter,
  },
})
export default class TablaReportes extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;
  @Prop() readonly pesaje!: Pesaje | null;
  @Prop() readonly servicios!: Servicios;
  @Prop() readonly clientes!: Clientes;

  get headers(): Array<Header> {
    return [
      {
        text: "",
        value: "estado",
        sortable: false,
      },
      {
        text: this.$t("reportes.tabla.headers.0") as string,
        value: "fecha",
      },
      {
        text: this.$t("reportes.tabla.headers.1") as string,
        value: "entrada",
      },
      {
        text: this.$t("reportes.tabla.headers.2") as string,
        value: "salida",
      },
      {
        text: this.$t("reportes.tabla.headers.3") as string,
        value: "patente",
      },
      {
        text: this.$t("reportes.tabla.headers.5") as string,
        value: "cliente",
      },
      {
        text: this.$t("reportes.tabla.headers.4") as string,
        value: "pesoNeto",
      },
      {
        text: "",
        value: "opciones",
        sortable: false,
      },
    ];
  }

  get items(): Array<Item> {
    if (log) console.time("components.reportes.Tabla.items");
    const resultado = this.pesajes.map((pesaje) => {
      const patente = pesaje.vehiculo?.patente ?? "-";
      const estado = pesaje.estado;
      const fecha = pesaje.entrada.fecha
        ? pesaje.entrada.fecha.toDate().format("DD/MM/YYYY")
        : "-";
      const entrada = pesaje.entrada.fecha
        ? pesaje.entrada.fecha.toDate().format("HH:mm")
        : "-";
      const salida = pesaje.salida.fecha
        ? pesaje.salida.fecha.toDate().format("HH:mm")
        : "-";
      const mayoresCero = pesaje.entrada.peso > 0 && pesaje.salida.peso > 0;
      const peso = pesaje.entrada.peso - pesaje.salida.peso;
      const pesoNeto = mayoresCero ? Math.abs(peso).toCurrency() : "-";
      const cliente = pesaje.cliente?.nombre ?? "-";
      return {
        patente,
        estado,
        fecha,
        entrada,
        salida,
        pesoNeto,
        cliente,
        pesaje,
      };
    });
    if (log) console.timeEnd("components.reportes.Tabla.items");
    return resultado;
  }

  @Emit("click:informacion")
  onClickInformacion({ pesaje }: Item): Pesaje {
    return pesaje;
  }

  getClasses({ pesaje }: Item): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.pesaje?.id === pesaje.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }
}
</script>

<style scoped>
.line-break {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
