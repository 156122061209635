<template>
  <v-card
    dark
    color="rgba(45,61,67,0.55)"
    class="border-grey rounded-input glass-card elevation-black"
    width="612"
    min-height="455"
  >
    <v-container class="px-11 py-8">
      <v-row justify="center" align="center" class="pt-7 pb-4">
        <v-col cols="auto">
          <v-img
            width="407"
            height="68px"
            contain
            :src="require('@/assets/svg/sistema/logotipo-white.svg')"
          ></v-img>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col cols="auto" class="py-8">
          <svg-icon
            name="ag-check"
            color="secondary"
            height="91px"
            width="119px"
          ></svg-icon>
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col
          cols="auto"
          class="px-10 text-center font-weight-bold font-size-32 metropolis"
        >
          {{ $t("login.recovery-password-success.text") }}
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// decoradores
import { Vue, Component, Emit } from "vue-property-decorator";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class Formulario extends Vue {
  async mounted(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.onClose();
  }

  @Emit("close")
  onClose(): void {
    return;
  }
}
</script>

<style scoped>
@supports (backdrop-filter: blur(2px)) {
  .glass-card {
    background: rgba(45, 61, 67, 0.5) !important;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
}
</style>
