<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="pt-10 mr-12 flex-grow-0">
      <v-col>
        <FiltrosServicios
          :button-disabled="dialogoFormulario"
          :permisos="getPermisosUsuario"
          v-model="filtros"
          @click:agregar="abrirNuevo"
        ></FiltrosServicios>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-7 pr-12 mb-9 flex-grow-1">
      <v-col cols="8">
        <TablaServicios
          :servicios="items"
          :search="filtros.search"
          :servicio="servicio"
          @click:informacion="clickAbrirInformacion"
        ></TablaServicios>
      </v-col>
      <v-col cols="4" class="pl-7">
        <DetalleServicio
          v-if="servicio && dialogoInfo"
          :servicio="servicio"
          :permisos="getPermisosUsuario"
          @click:editar="abrirEditar"
          @click:eliminar="abrirDialogoEliminar"
        ></DetalleServicio>
        <FormularioServicio
          v-if="dialogoFormulario"
          :servicio="servicio"
          :cargando="cargando"
          @click:guardar="clickGuardar"
        ></FormularioServicio>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      max-width="565"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
//decoradores
import { Vue, Component } from "vue-property-decorator";
//vuex
import { mapActions, mapGetters } from "vuex";
// Helpers
import routeGuard from "@/helpers/routeGuard";
//tipos
import { FiltrosServicio } from "@/typings/components/servicios/filtros";
import {
  Servicio,
  Servicios,
} from "@/typings/store/plugins/easyFirestore/servicios";
//componentes
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import TablaServicios from "@/components/servicios/Tabla.vue";
import DetalleServicio from "@/components/servicios/Detalle.vue";
import FiltrosServicios from "@/components/servicios/Filtros.vue";
import FormularioServicio from "@/components/servicios/FormularioServicio.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";

@Component({
  computed: mapGetters({
    getServicios: "servicios/get",
    getRutasUsuario: "usuario/getRutas",
    getPermisosUsuario: "usuario/getPermisos",
  }),
  methods: mapActions({
    setServicio: "servicios/set",
    deleteServicio: "servicios/delete",
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    SuccessDialog,
    TablaServicios,
    DetalleServicio,
    FiltrosServicios,
    FormularioServicio,
    ConfirmationDialog,
  },
})
export default class VistaServicios extends Vue {
  created(): void {
    if (!routeGuard()) {
      return;
    }
  }
  servicio: Servicio | null = null;
  cargando = false;
  dialogoFormulario = false;
  dialogoInfo = false;
  dialogoConfirmacion: {
    model: boolean;
    texto: string;
    si: (() => void) | null;
    no: (() => void) | null;
  } = {
    model: false,
    texto: "",
    si: null,
    no: null,
  };
  dialogoExito = {
    model: false,
    mensaje: "",
    icono: "",
  };
  filtros: FiltrosServicio = {
    tipo: null,
    search: "",
  };

  get servicios(): Servicios {
    return this.getServicios;
  }

  get items(): Array<Servicio> {
    const servicios = Object.values(this.servicios).filter((servicio) =>
      this.filtros.tipo ? servicio.tipo === this.filtros.tipo : true
    );
    servicios.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return servicios;
  }

  get codigos(): Array<string> {
    return [];
  }

  abrirNuevo(): void {
    this.servicio = null;
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  clickAbrirInformacion(servicio: Servicio): void {
    if (this.dialogoFormulario && this.servicio) {
      this.abrirDialogoSinGuardar(servicio);
    } else {
      this.abrirInformacion(servicio);
    }
  }

  abrirDialogoSinGuardar(servicio: Servicio): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t(
        "servicios.mensaje-confirmacion.message.save-changes"
      ) as string,
      si: () => {
        this.abrirInformacion(servicio);
        this.cerrarDialogoConfirmacion();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  abrirInformacion(servicio: Servicio): void {
    const data = this.servicios[servicio.id ?? ""];
    if (servicio.id === this.servicio?.id) {
      this.servicio = null;
      this.dialogoInfo = false;
    } else if (data) {
      this.servicio = data;
      this.dialogoInfo = true;
      this.dialogoFormulario = false;
    }
  }

  abrirEditar(): void {
    this.dialogoFormulario = true;
    this.dialogoInfo = false;
  }

  abrirDialogoEliminar(): void {
    this.dialogoConfirmacion = {
      model: true,
      texto: this.$t("servicios.mensaje-confirmacion.message.delete") as string,
      si: () => {
        this.clickEliminar();
      },
      no: () => {
        this.cerrarDialogoConfirmacion();
      },
    };
  }

  clickEliminar(): void {
    const servicio = this.servicio;
    if (servicio) {
      this.deleteServicio(servicio.id ?? "");
      this.servicio = null;
      this.dialogoInfo = false;
      this.cerrarDialogoConfirmacion();
    }
  }

  async clickGuardar(servicio: Servicio): Promise<void> {
    this.cargando = true;
    await this.setServicio(servicio);
    this.cargando = false;
    this.dialogoFormulario = false;
    if (this.servicio) {
      this.abrirDialogoExito(
        this.$t("servicios.dialogo-exito.message.save") as string,
        "ag-save"
      );
    } else {
      this.abrirDialogoExito(
        this.$t("servicios.dialogo-exito.message.create") as string,
        "servicios"
      );
    }
    this.servicio = null;
  }

  abrirDialogoExito(mensaje: string, icono: string): void {
    this.dialogoExito = {
      model: true,
      mensaje: mensaje,
      icono: icono,
    };
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      mensaje: "",
      icono: "",
    };
  }

  cerrarDialogoConfirmacion(): void {
    this.dialogoConfirmacion = {
      model: false,
      texto: "",
      si: null,
      no: null,
    };
  }
}
</script>
