<template>
  <v-container class="pa-0">
    <v-row no-gutters class="metropolis text-body-1" align="center">
      <v-col
        cols="3"
        :class="`font-weight-bold pr-2 ${
          manual ? 'white--text' : 'black--text'
        }`"
      >
        {{ $t("bascula.formulario.vehiculo.label") }}
      </v-col>
      <v-col cols="3" class="px-2">
        <v-combobox
          dense
          return-object
          item-text="patente"
          hide-details="auto"
          :placeholder="$t('bascula.formulario.vehiculo.placeholder')"
          :dark="manual"
          :solo="!manual"
          v-model="vehiculo"
          :items="arrayVehiculos"
          :outlined="manual"
          :error-messages="vehiculoErrors"
          :class="`rounded-input metropolis  ${
            manual ? 'border-input' : 'elevation-input'
          }`"
          :menu-props="{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }"
        >
          <template v-slot:item="{ item }">
            <span class="metropolis darker--text text-uppercase">
              {{ item.patente }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              :class="`metropolis text-uppercase text-truncate ${
                manual ? 'white--text' : 'darker--text'
              }`"
            >
              {{ item.patente }}
            </span>
          </template>
          <template v-slot:append>
            <svg-icon
              name="ionic-md-arrow-dropdown"
              color="text"
              width="12px"
              height="12px"
            ></svg-icon>
          </template>
        </v-combobox>
      </v-col>
      <v-col
        cols="3"
        :class="`font-weight-bold px-2 ${
          manual ? 'white--text' : 'black--text'
        }`"
      >
        {{ $t("bascula.formulario.desecho.label") }}
      </v-col>
      <v-col cols="3" class="pl-2">
        <v-select
          dense
          return-object
          item-text="nombre"
          hide-details="auto"
          :placeholder="$t('bascula.formulario.desecho.placeholder')"
          :dark="manual"
          :solo="!manual"
          :items="arrayDesechos"
          v-model="desecho"
          :outlined="manual"
          :error-messages="desechoErrors"
          :class="`rounded-input metropolis  ${
            manual ? 'border-input' : 'elevation-input'
          }`"
          :menu-props="{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }"
        >
          <template v-slot:item="{ item }">
            <span class="metropolis darker--text text-capitalize">
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              :class="`metropolis text-capitalize text-truncate ${
                manual ? 'white--text' : 'darker--text'
              }`"
            >
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:append>
            <svg-icon
              name="ionic-md-arrow-dropdown"
              color="text"
              width="12px"
              height="12px"
            ></svg-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters class="metropolis pt-2" align="center">
      <v-col
        cols="3"
        :class="`font-weight-bold pr-2 ${
          manual ? 'white--text' : 'black--text'
        }`"
      >
        {{ $t("bascula.formulario.capacidad.label") }}
      </v-col>
      <v-col cols="3" class="px-2">
        <v-text-field
          dense
          hide-details="auto"
          :placeholder="$t('bascula.formulario.capacidad.placeholder')"
          :dark="manual"
          :solo="!manual"
          :outlined="manual"
          :error-messages="capacidadErrors"
          v-model.number="capacidad"
          v-mask="['#########']"
          :class="`rounded-input metropolis  ${
            manual ? 'border-input' : 'elevation-input'
          }`"
        ></v-text-field>
      </v-col>
      <v-col
        cols="3"
        :class="`font-weight-bold px-2 ${
          manual ? 'white--text' : 'black--text'
        }`"
      >
        {{ $t("bascula.formulario.cobro.label") }}
      </v-col>
      <v-col cols="3" class="pl-2">
        <v-text-field
          dense
          hide-details
          :placeholder="$t('bascula.formulario.cobro.placeholder')"
          :dark="manual"
          :solo="!manual"
          :outlined="manual"
          v-model.number="cobro"
          v-mask="['#########']"
          :class="`rounded-input metropolis  ${
            manual ? 'border-input' : 'elevation-input'
          }`"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="metropolis pt-2" align="center">
      <v-col
        cols="3"
        :class="`font-weight-bold pr-2 ${
          manual ? 'white--text' : 'black--text'
        }`"
      >
        {{ $t("bascula.formulario.servicio.label") }}
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-select
          dense
          return-object
          item-text="nombre"
          hide-details="auto"
          :placeholder="$t('bascula.formulario.servicio.placeholder')"
          :dark="manual"
          :solo="!manual"
          :outlined="manual"
          :items="arrayServicios"
          v-model="servicio"
          :error-messages="servicioErrors"
          :class="`rounded-input metropolis ${
            manual ? 'border-input' : 'elevation-input'
          }`"
          :menu-props="{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }"
        >
          <template v-slot:item="{ item }">
            <span class="metropolis darker--text text-capitalize">
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              :class="`metropolis text-capitalize text-truncate ${
                manual ? 'white--text' : 'darker--text'
              }`"
            >
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:append>
            <svg-icon
              name="ionic-md-arrow-dropdown"
              color="text"
              width="12px"
              height="12px"
            ></svg-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters class="metropolis pt-2" align="center">
      <v-col
        cols="3"
        :class="`font-weight-bold pr-2 ${
          manual ? 'white--text' : 'black--text'
        }`"
      >
        {{ $t("bascula.formulario.cliente.label") }}
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-combobox
          dense
          return-object
          item-text="nombre"
          hide-details="auto"
          :placeholder="$t('bascula.formulario.cliente.placeholder')"
          :dark="manual"
          :solo="!manual"
          :items="arrayClientes"
          v-model="cliente"
          :outlined="manual"
          :error-messages="clienteErrors"
          :class="`rounded-input metropolis  ${
            manual ? 'border-input' : 'elevation-input'
          }`"
          :menu-props="{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }"
        >
          <template v-slot:item="{ item }">
            <span class="metropolis darker--text text-capitalize">
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              :class="`metropolis text-capitalize text-truncate ${
                manual ? 'white--text' : 'darker--text'
              }`"
            >
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:append>
            <svg-icon
              name="ionic-md-arrow-dropdown"
              color="text"
              width="12px"
              height="12px"
            ></svg-icon>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row no-gutters class="metropolis pt-2" align="center">
      <v-col
        cols="3"
        :class="`font-weight-bold pr-2 ${
          manual ? 'white--text' : 'black--text'
        }`"
      >
        {{ $t("bascula.formulario.ruta.label") }}
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-combobox
          dense
          return-object
          item-text="nombre"
          hide-details="auto"
          :placeholder="$t('bascula.formulario.ruta.placeholder')"
          :items="arrayRutasPesaje"
          :dark="manual"
          v-model="ruta"
          :solo="!manual"
          :outlined="manual"
          :error-messages="rutaErrors"
          :class="`rounded-input metropolis  ${
            manual ? 'border-input' : 'elevation-input'
          }`"
          :menu-props="{
            contentClass: 'rounded-input elevation-black',
            transition: 'slide-y-transition',
          }"
        >
          <template v-slot:item="{ item }">
            <span class="metropolis darker--text text-capitalize">
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span
              :class="`metropolis text-capitalize text-truncate ${
                manual ? 'white--text' : 'darker--text'
              }`"
            >
              {{ item.nombre }}
            </span>
          </template>
          <template v-slot:append>
            <svg-icon
              name="ionic-md-arrow-dropdown"
              color="text"
              width="12px"
              height="12px"
            ></svg-icon>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-2" justify="center" v-if="!manual">
      <v-col cols="auto" class="pr-2">
        <v-btn
          depressed
          height="48px"
          color="#FFF0B7"
          class="rounded-input"
          @click="onClickLimpiar"
        >
          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto">
              <svg-icon name="agr-sync-alt" color="yellow"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 metropolis font-weight-bold yellow--text text-initial text-body-1"
            >
              {{ $t("bascula.formulario.boton-limpiar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="px-2">
        <v-btn
          color="disabled"
          depressed
          height="48px"
          class="rounded-input"
          @click="onClickManual"
        >
          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto">
              <svg-icon name="ag-document-add" color="dialogDark"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 metropolis font-weight-bold text-initial dialogDark--text text-body-1"
            >
              {{ $t("bascula.formulario.boton-manual.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="pl-2">
        <v-btn
          depressed
          height="48px"
          color="primary-light"
          :class="`rounded-input ${
            !fijado
              ? 'button-inactive button-disabled'
              : guardando
              ? 'button-disabled'
              : ''
          }`"
          @click="onClickGuardar"
        >
          <svg-icon
            v-if="guardando"
            name="three-dots"
            color="primary"
          ></svg-icon>
          <v-row v-else no-gutters align="center" justify="center">
            <v-col cols="auto">
              <svg-icon name="ag-save" color="primary"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 metropolis font-weight-bold text-initial primary--text text-body-1"
            >
              {{ $t("bascula.formulario.boton-guardar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
// decoradores
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";
// tipos
import {
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import {
  EstadoRutaPesaje,
  RutaPesaje,
  TipoRutaPesaje,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FormularioBascula extends Vue {
  @VModel() pesajeData!: PesajeData;
  @Prop() readonly pesaje!: Pesaje | null;
  @Prop() readonly arrayVehiculos!: Array<Vehiculo>;
  @Prop() readonly arrayDesechos!: Array<Desecho>;
  @Prop() readonly arrayServicios!: Array<Servicio>;
  @Prop() readonly arrayClientes!: Array<Cliente>;
  @Prop() readonly arrayRutasPesaje!: Array<RutaPesaje>;
  @Prop() readonly capacidadErrors!: Array<string>;
  @Prop() readonly vehiculoErrors!: Array<string>;
  @Prop() readonly desechoErrors!: Array<string>;
  @Prop() readonly servicioErrors!: Array<string>;
  @Prop() readonly clienteErrors!: Array<string>;
  @Prop() readonly rutaErrors!: Array<string>;
  @Prop({ type: Boolean }) readonly fijado!: boolean;
  @Prop({ type: Boolean }) readonly manual!: boolean;
  @Prop({ type: Boolean }) readonly guardando!: boolean;

  get vehiculo(): Vehiculo | null {
    const vehiculo = this.pesajeData.vehiculo || this.pesaje?.vehiculo || null;
    return vehiculo;
  }
  set vehiculo(value: Vehiculo | null) {
    let vehiculo: Vehiculo | null = null;
    if (typeof value === "string") {
      vehiculo = {
        patente: (value as string).toLowerCase(),
        tipo: "camion",
        tara: -1,
        alertaPeso: -1,
        marca: "",
        modelo: "",
        año: -1,
        combustible: "diesel",
        numeroChasis: "",
        numeroMotor: "",
        foto: null,
        estado: "habilitado",
      };
    } else {
      vehiculo = Object.assign({}, value);
    }
    this.$set(this.pesajeData, "vehiculo", vehiculo);
  }

  get desecho(): Desecho | null {
    const desecho = this.pesajeData.desecho || this.pesaje?.desecho || null;
    return desecho;
  }
  set desecho(value: Desecho | null) {
    const desecho = value ? Object.assign({}, value) : null;
    this.$set(this.pesajeData, "desecho", desecho);
  }

  get capacidad(): number {
    const capacidad = this.pesajeData.capacidad || this.pesaje?.capacidad || 0;
    return capacidad;
  }
  set capacidad(value: number) {
    const capacidad = value || 0;
    this.$set(this.pesajeData, "capacidad", capacidad);
  }

  get cobro(): number {
    const cobro = this.pesajeData.cobro || this.pesaje?.cobro || 0;
    return cobro;
  }
  set cobro(value: number) {
    const cobro = value || 0;
    this.$set(this.pesajeData, "cobro", cobro);
  }

  get servicio(): Servicio | null {
    const servicio = this.pesajeData.servicio || this.pesaje?.servicio || null;
    return servicio;
  }
  set servicio(value: Servicio | null) {
    const servicio = value ? Object.assign({}, value) : null;
    this.$set(this.pesajeData, "servicio", servicio);
  }

  get cliente(): Cliente | null {
    const cliente = this.pesajeData.cliente || this.pesaje?.cliente || null;
    return cliente;
  }
  set cliente(val: Cliente | null) {
    let cliente: Cliente | null;
    if (typeof val == "string") {
      cliente = {
        nombre: (val as string).toLowerCase(),
        rut: "",
        giro: "",
        ciudad: "",
        direccion: "",
        contacto: "",
        correo: "",
        foto: null,
        estado: "habilitado",
        tipo: "particular",
      };
    } else {
      cliente = Object.assign({}, val);
    }
    this.$set(this.pesajeData, "cliente", cliente);
  }

  get ruta(): RutaPesaje | null {
    const ruta = this.pesajeData.ruta || this.pesaje?.ruta || null;
    return ruta;
  }
  set ruta(val: RutaPesaje | null) {
    let ruta: RutaPesaje | null;
    if (typeof val == "string") {
      const data = (val as string).toLowerCase().split("-");
      const nombre = data[0] ? data[0].trim() : (val as string).toLowerCase();
      const tipo = data[1] ? data[1].trim() : "";
      const estado = "habilitado";
      ruta = {
        nombre: nombre,
        tipo: tipo as TipoRutaPesaje,
        descripcion: "",
        estado: estado as EstadoRutaPesaje,
      };
    } else {
      ruta = Object.assign({}, val);
    }
    this.$set(this.pesajeData, "ruta", ruta);
  }

  @Emit("click:limpiar")
  onClickLimpiar(): void {
    return;
  }

  @Emit("click:manual")
  onClickManual(): void {
    return;
  }

  @Emit("click:guardar")
  onClickGuardar(): void {
    return;
  }
}
</script>
