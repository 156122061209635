<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-text-field
              :label="String($t('rastreadores.formulario.text-fields.0.label'))"
              :placeholder="String($t('rastreadores.formulario.text-fields.0.placeholder'))"
              v-model="imei"
              required
              :error-messages="erroresImei"
            ></form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-text-field
              :label="String($t('rastreadores.formulario.text-fields.1.label'))"
              :placeholder="String($t('rastreadores.formulario.text-fields.1.placeholder'))"
              v-model="marca"
              required
              :error-messages="erroresMarca"
            ></form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-text-field
              :label="String($t('rastreadores.formulario.text-fields.2.label'))"
              :placeholder="String($t('rastreadores.formulario.text-fields.2.placeholder'))"
              v-model="modelo"
              required
              :error-messages="erroresModelo"
            ></form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-select
              :label="String($t('rastreadores.formulario.text-fields.3.label'))"
              :placeholder="String($t('rastreadores.formulario.text-fields.3.placeholder'))"
              v-model="tipo"
              :items="tipos"
              required
              :error-messages="erroresTipo"
            ></form-select>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-select
              :label="String($t('rastreadores.formulario.text-fields.4.label'))"
              :placeholder="String($t('rastreadores.formulario.text-fields.4.placeholder'))"
              v-model="empresa"
              :items="empresas"
              required
              item-value="id"
              item-text="nombre"
              text-case="capitalize"
              :error-messages="erroresEmpresa"
            ></form-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0" justify="center">
      <v-col cols="auto">
        <action-button
          block
          color="primary"
          :icon="rastreador !== null ? 'ag-save' : 'ag-add'"
          @click="onClickGuardar"
        >
          {{ textoBoton }}
        </action-button>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//composition
import { ref, computed, defineComponent } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//validadores
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
//tipos
import {
  EstadoRastreador,
  Rastreador,
  TipoRastreador,
} from "@/typings/store/plugins/easyFirestore/rastreadores";
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
//componentes
import FormTextField from "@/components/custom/FormTextField.vue";
import FormSelect from "@/components/custom/FormSelect.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "FormularioRastreador",
  components: {
    ActionButton,
    FormSelect,
    FormTextField,
  },
  emits: ["click:guardar"],
  props: {
    rastreador: {
      type: Object as () => Rastreador | null,
    },
    empresas: {
      type: Array as () => Array<Empresa>,
    },
  },
  setup(props, ctx) {
    const imei = ref("");
    const marca = ref("");
    const modelo = ref("");
    const tipo = ref<TipoRastreador>("persona");
    const estado = ref<EstadoRastreador>("habilitado");
    const empresa = ref<string | null>(null);

    if (props.rastreador) {
      imei.value = props.rastreador.imei;
      marca.value = props.rastreador.marca ?? "";
      modelo.value = props.rastreador.modelo ?? "";
      tipo.value = props.rastreador.tipo ?? "persona";
      estado.value = props.rastreador.estado ?? "habilitado";
      empresa.value = props.rastreador.empresa ?? null;
    }

    const rules = computed(() => ({
      imei: { required },
      marca: { required },
      modelo: { required },
      tipo: { required },
      empresa: { required },
    }));

    const v$ = useVuelidate(rules, {
      imei,
      marca,
      modelo,
      tipo,
      empresa,
    });

    const titulo = computed(() => {
      return i18n.t(
        `rastreadores.formulario.titulo.${
          props.rastreador ? "editar" : "crear"
        }`
      ) as string;
    });

    const tipos = computed(() => {
      return [
        {
          text: i18n.t("rastreadores.formulario.tipos.0") as string,
          value: "persona",
        },
        {
          text: i18n.t("rastreadores.formulario.tipos.1") as string,
          value: "vehiculo",
        },
      ];
    });

    const textoBoton = computed(() => {
      return i18n.t(
        `rastreadores.formulario.boton.${props.rastreador ? "editar" : "crear"}`
      ) as string;
    });

    const erroresImei = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.imei.$dirty) return errores;
      if (v$.value.imei.required.$invalid) {
        const error = i18n.t(
          "rastreadores.formulario.text-fields.0.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresMarca = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.marca.$dirty) return errores;
      if (v$.value.marca.required.$invalid) {
        const error = i18n.t(
          "rastreadores.formulario.text-fields.1.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresModelo = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.modelo.$dirty) return errores;
      if (v$.value.modelo.required.$invalid) {
        const error = i18n.t(
          "rastreadores.formulario.text-fields.2.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTipo = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errores;
      if (v$.value.tipo.required.$invalid) {
        const error = i18n.t(
          "rastreadores.formulario.text-fields.3.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresEmpresa = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.empresa.$dirty) return errores;
      if (v$.value.empresa.required.$invalid) {
        const error = i18n.t(
          "rastreadores.formulario.text-fields.4.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onClickGuardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || !empresa.value) return;
      const rastreador: Rastreador = {
        id: imei.value,
        imei: imei.value,
        marca: marca.value,
        modelo: modelo.value,
        tipo: tipo.value,
        empresa: empresa.value,
        estado: estado.value,
        conectado: "",
        gps: "",
        fecha: "",
        posicion: null,
        bateriaBaja: "",
      };
      ctx.emit("click:guardar", rastreador);
    };

    return {
      v$,
      imei,
      marca,
      modelo,
      tipo,
      empresa,
      titulo,
      tipos,
      textoBoton,
      erroresImei,
      erroresMarca,
      erroresModelo,
      erroresTipo,
      erroresEmpresa,
      onClickGuardar,
    };
  },
});
</script>
