<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-2 flex-grow-0">
      <v-col>
        <SubirImagen
          v-model="file"
          :preview="foto"
          :nueva="!vehiculo"
        ></SubirImagen>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.patente.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.patente.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="patente"
              v-mask="'SSXX##'"
              :error-messages="erroresPatente"
              @focus="v$.patente.$reset()"
              @blur="v$.patente.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.tipo.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-select
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.tipo.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="tipo"
              :items="tipos"
              :error-messages="erroresTipo"
              @focus="v$.tipo.$reset()"
              @blur="v$.tipo.$touch()"
              :menu-props="{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }"
            >
              <template v-slot:item="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold"
                >
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold"
                >
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:append>
                <v-icon color="primary"> mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.marca.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.marca.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="marca"
              :error-messages="erroresMarca"
              @focus="v$.marca.$reset()"
              @blur="v$.marca.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.modelo.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.modelo.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="modelo"
              :error-messages="erroresModelo"
              @focus="v$.modelo.$reset()"
              @blur="v$.modelo.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.year.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.year.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="año"
              v-mask="'####'"
              :error-messages="erroresAño"
              @focus="v$.año.$reset()"
              @blur="v$.año.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.combustible.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-select
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.combustible.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="combustible"
              :items="combustibles"
              :error-messages="erroresCombustible"
              @focus="v$.combustible.$reset()"
              @blur="v$.combustible.$touch()"
              :menu-props="{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }"
            >
              <template v-slot:item="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold"
                >
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold"
                >
                  {{ item.text }}
                </span>
              </template>
              <template v-slot:append>
                <v-icon color="primary"> mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.numero-chasis.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.numero-chasis.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="numeroChasis"
              :error-messages="erroresNumeroChasis"
              @focus="v$.numeroChasis.$reset()"
              @input="v$.numeroChasis.$touch()"
              @blur="v$.numeroChasis.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ $t("transporte.tab.flota.formulario.numero-motor.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.flota.formulario.numero-motor.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="numeroMotor"
              :error-messages="erroresNumeroMotor"
              @focus="v$.numeroMotor.$reset()"
              @input="v$.numeroMotor.$touch()"
              @blur="v$.numeroMotor.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0 pt-2" justify="center">
      <v-col cols="4">
        <v-btn
          large
          block
          height="48px"
          :class="`rounded-input elevation-primary gradient-primary ${
            cargando ? 'button-disabled' : ''
          }`"
          @click="guardar"
        >
          <svg-icon name="three-dots" v-if="cargando"></svg-icon>
          <v-row v-else no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon
                :name="vehiculo ? 'ag-save' : 'ag-add'"
                color="white"
              ></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ textoBoton }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// helpers
import { imageToWebp } from "@/helpers/image";
// tipos
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { DataFormularioVehiculo } from "@/typings/components/vehiculos/formulario";
import {
  Combustible,
  EstadoVehiculo,
  TipoVehiculo,
  Vehiculo,
} from "@/typings/store/plugins/easyFirestore/vehiculos";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: { SvgIcon, SubirImagen },
  props: {
    vehiculo: {
      default: null,
      type: Object as () => Vehiculo | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
    usuario: {
      default: null,
      type: Object as () => Usuario | null,
    },
  },
  created(): void {
    if (this.vehiculo) {
      this.id = this.vehiculo.id ?? "";
      this.patente = this.vehiculo.patente ?? "";
      this.tipo = this.vehiculo.tipo ?? null;
      this.marca = this.vehiculo.marca ?? "";
      this.modelo = this.vehiculo.modelo ?? "";
      this.año = this.vehiculo.año?.toString() ?? "";
      this.combustible = this.vehiculo.combustible ?? null;
      this.numeroChasis = this.vehiculo.numeroChasis ?? "";
      this.numeroMotor = this.vehiculo.numeroMotor ?? "";
      this.estado = this.vehiculo.estado ?? "habilitado";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const patente = ref("");
    const tipo = ref<TipoVehiculo | null>(null);
    const marca = ref("");
    const modelo = ref("");
    const año = ref("");
    const combustible = ref<Combustible | null>(null);
    const numeroChasis = ref("");
    const numeroMotor = ref("");
    const file = ref<File | null>(null);
    const estado = ref<EstadoVehiculo>("habilitado");

    const tipos = computed((): Array<{ text: string; value: TipoVehiculo }> => {
      return [
        {
          text: t("transporte.tab.flota.formulario.tipo.values[0]") as string,
          value: "auto",
        },
        {
          text: t("transporte.tab.flota.formulario.tipo.values[1]") as string,
          value: "camioneta",
        },
        {
          text: t("transporte.tab.flota.formulario.tipo.values[2]") as string,
          value: "camion",
        },
      ];
    });

    const combustibles = computed(
      (): Array<{ text: string; value: Combustible }> => {
        return [
          {
            text: t(
              "transporte.tab.flota.formulario.combustible.values[0]"
            ) as string,
            value: "bencina",
          },
          {
            text: t(
              "transporte.tab.flota.formulario.combustible.values[1]"
            ) as string,
            value: "diesel",
          },
        ];
      }
    );

    const titulo = computed((): string => {
      const index = props.vehiculo ? 0 : 1;
      return t(`transporte.tab.flota.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.vehiculo ? 0 : 1;
      return t(
        `transporte.tab.flota.formulario.boton.text[${index}]`
      ) as string;
    });

    const foto = computed((): string => {
      return file.value
        ? URL.createObjectURL(file.value)
        : props.vehiculo?.foto?.url ?? "";
    });

    const state = reactive({
      patente,
      tipo,
      combustible,
      marca,
      modelo,
      año,
      numeroChasis,
      numeroMotor,
    });

    const rules = {
      patente: { required },
      tipo: { required },
      combustible: { required },
      marca: { required },
      modelo: { required },
      año: { required },
      numeroChasis: { required },
      numeroMotor: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresPatente = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.patente.$dirty) return errores;
      if (v$.value.patente.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.patente.error-messages[0]"
          ) as string
        );
      const regex = /^([A-Z]{2}[1-9][0-9]{3})$|^([A-Z]{4}[1-9][0-9])$/gi;
      if (!regex.test(patente.value))
        errores.push(
          t(
            "transporte.tab.flota.formulario.patente.error-messages[0]"
          ) as string
        );
      return errores;
    };

    const erroresTipo = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.tipo.$dirty) return errores;
      if (v$.value.tipo.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.tipo.error-message") as string
        );
      return errores;
    };

    const erroresMarca = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.marca.$dirty) return errores;
      if (v$.value.marca.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.marca.error-message") as string
        );
      return errores;
    };

    const erroresModelo = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.modelo.$dirty) return errores;
      if (v$.value.modelo.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.modelo.error-message") as string
        );
      return errores;
    };

    const erroresAño = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.año.$dirty) return errores;
      if (v$.value.año.required.$invalid)
        errores.push(
          t("transporte.tab.flota.formulario.year.error-message") as string
        );
      return errores;
    };

    const erroresCombustible = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.combustible.$dirty) return errores;
      if (v$.value.combustible.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.combustible.error-message"
          ) as string
        );
      return errores;
    };

    const erroresNumeroChasis = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.numeroChasis.$dirty) return errores;
      if (v$.value.numeroChasis.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-chasis.error-messages[0]"
          ) as string
        );
      if (numeroChasis.value.length > 17)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-chasis.error-messages[1]"
          ) as string
        );
      return errores;
    };

    const erroresNumeroMotor = (): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.numeroMotor.$dirty) return errores;
      if (v$.value.numeroMotor.required.$invalid)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-motor.error-messages[0]"
          ) as string
        );
      if (numeroMotor.value.length > 17)
        errores.push(
          t(
            "transporte.tab.flota.formulario.numero-motor.error-messages[1]"
          ) as string
        );
      return errores;
    };

    const guardar = async (): Promise<void> => {
      v$.value.$touch();
      const regexPatente = /^([A-Z]{2}[1-9][0-9]{3})$|^([A-Z]{4}[1-9][0-9])$/gi;
      if (
        v$.value.$invalid ||
        !regexPatente.test(patente.value) ||
        numeroChasis.value.length > 17 ||
        numeroMotor.value.length > 17
      )
        return;
      const vehiculo: Vehiculo = {
        patente: patente.value,
        tipo: tipo.value ?? "camion",
        tara: -1,
        alertaPeso: -1,
        marca: marca.value,
        modelo: modelo.value,
        año: año.value.toInt(),
        combustible: combustible.value ?? "bencina",
        numeroChasis: numeroChasis.value,
        numeroMotor: numeroMotor.value,
        foto: null,
        estado: estado.value,
      };
      if (id.value) vehiculo.id = id.value;
      const data: DataFormularioVehiculo = { vehiculo };
      if (file.value) {
        const imagenRaw = file.value;
        const fecha = new Date();
        const extencion = imagenRaw.type.split("/")[1];
        let nombre = `${fecha.valueOf()}.${extencion}`;
        let imagen = await imageToWebp(imagenRaw);
        if (imagen) {
          nombre = `${fecha.valueOf()}.webp`;
        } else {
          imagen = imagenRaw;
        }
        const fechaCarpeta = new Date(fecha);
        fechaCarpeta.setHours(0, 0, 0, 0);
        const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
        const ref = `vehiculos/${nombreCarpeta}/${nombre}`;
        data.archivo = {
          ref: ref,
          file: imagen,
          nombre: nombre,
        };
      }
      ctx.emit("click:guardar", data);
    };

    return {
      t,
      v$,
      id,
      patente,
      tipo,
      marca,
      modelo,
      año,
      combustible,
      numeroChasis,
      numeroMotor,
      file,
      estado,
      erroresPatente,
      erroresTipo,
      erroresMarca,
      erroresModelo,
      erroresAño,
      erroresCombustible,
      erroresNumeroChasis,
      erroresNumeroMotor,
      guardar,
      titulo,
      textoBoton,
      foto,
      tipos,
      combustibles,
    };
  },
});
</script>
