<template>
  <div class="mx-percent mt-lg-35 mt-16">
    <v-row no-gutters>
      <v-col
        class="text-center font-size-lg-32 font-size-25 font-weight-semi-bold poppins dialogDark--text"
        v-html="$t('pagina.testimonios.titulo')"
      ></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-carousel
          :show-arrows="testimonios.length > 1"
          hide-delimiters
          height="auto"
          class="carousel-testimonios"
        >
          <v-carousel-item
            v-for="testimonio in testimonios"
            :key="testimonio.id"
          >
            <v-row no-gutters justify="center" class="pt-lg-16 pt-8">
              <v-col cols="auto">
                <v-img
                  :src="testimonio.imagen.url"
                  class="rounded-pill elevation-image"
                  width="200px"
                  height="200px"
                ></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-lg-4 mt-8" justify="center">
              <v-col
                cols="auto"
                class="px-1"
                v-for="estrella in testimonio.calificacion"
                :key="`estrella-${estrella}-${testimonio.id}`"
              >
                <svg-icon name="ags-star" color="success"></svg-icon>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-lg-4 mt-8" justify="center">
              <v-col
                cols="12"
                lg="auto"
                class="poppins success--text font-size-16 font-weight-bold text-uppercase text-center"
              >
                {{ testimonio.empresa }}
              </v-col>
              <v-col
                v-if="!isMobile"
                cols="auto"
                class="poppins dialogDark--text font-size-16 font-weight-bold text-uppercase px-1"
              >
                -
              </v-col>
              <v-col
                cols="12"
                lg="auto"
                class="poppins dialogDark--text font-size-16 font-weight-bold text-uppercase text-center"
              >
                {{ testimonio.direccion }}.
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4" justify="center">
              <v-col
                cols="12"
                lg="8"
                class="text-center poppins font-size-14 dialogDark--text"
              >
                “{{ testimonio.comentario.capitalizeFirstLetter() }}”
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-col
                class="text-center poppins dialogDark--text font-size-16 font-weight-bold text-capitalize"
              >
                {{ testimonio.representante }}.
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Testimonio } from "@/typings/store/plugins/easyFirestore/testimonios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class TestimoniosPagina extends Vue {
  @Prop() readonly testimonios!: Array<Testimonio>;

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
</script>

<style scoped>
@media screen and (min-width: 1264px) {
  .mt-lg-35 {
    margin-top: 140px !important;
  }
}

.elevation-image {
  box-shadow: 14px 14px 37px rgba(0, 0, 0, 0.16);
}

.carousel-testimonios >>> .v-btn {
  background: var(--v-success-base) !important;
}
</style>
