<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        icon
        v-bind="attrs"
        v-on="on"
        @click="onClick"
      >
        <v-icon
          :color="color"
          v-if="icon.startsWith('mdi')"
          size="28px"
        >
          {{ icon }}
        </v-icon>
        <svg-icon
          v-else
          :name="icon"
          :color="color"
        ></svg-icon>
      </v-btn>
    </template>
    <span class="metropolis">
      <slot></slot>
    </span>
  </v-tooltip>
</template>

<script lang="ts">
//composition
import {defineComponent} from "vue"
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "IconButton",
  components: {
    SvgIcon
  },
  props: {
    color: {
      type: String,
      default: "black"
    },
    icon: {
      type: String,
      required: true
    }
  },
  emits: [
    "click"
  ],
  setup(props, ctx) {
    const onClick = (ev: MouseEvent) => {
      ctx.emit("click", ev);
    };

    return {
      onClick
    };
  }
})
</script>
