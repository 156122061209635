<template>
  <v-container class="pa-0 ma-0">
    <v-row no-gutters>
      <v-col
        cols="12"
        class="metropolis text--text text-body-1 px-5"
      >
        {{ label }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-select
        dense
        solo
        clearable
        hide-details
        color="text"
        :placeholder="placeholder"
        class="metropolis rounded-input elevation-input"
        :menu-props="{
          contentClass: 'rounded-input elevation-black',
          transition: 'slide-y-transition'
        }"
        :items="items"
        v-model="vModel"
        :item-text="itemText"
        :item-value="itemValue"
        :multiple="multiple"
        :return-object="returnObject"
        @change="onChange"
      >
        <template v-slot:item="{ item, attrs }" v-if="multiple">
          <v-row no-gutters>
            <v-col cols="auto">
              <v-checkbox
                v-model="attrs.inputValue"
                hide-details
                dense
                class="pa-0 ma-0"
              ></v-checkbox>
            </v-col>
            <v-col class="metropolis text-body-1 text-capitalize">
              {{ getText(item) }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item }" v-else>
          <span class="metropolis darker--text text-body-1 text-capitalize">
            {{ getText(item) }}
          </span>
        </template>
        <template v-slot:selection="{ index }" v-if="multiple">
          <v-col
            cols="auto"
            v-if="index === 0"
            class="text-capitalize text-truncate metropolis text-body-1 pa-0"
          >
            {{ textMultiple }}
          </v-col>
        </template>
        <template v-slot:selection="{ item }" v-else>
          <span class="metropolis darker--text text-body-1 text-capitalize">
            {{ getText(item) }}
          </span>
        </template>
        <template v-slot:append>
          <svg-icon
            name="ionic-md-arrow-dropdown"
            color="text"
            width="12px"
            height="12px"
          ></svg-icon>
        </template>
      </v-select>
    </v-row>
  </v-container>
</template>

<script lang="ts">
//composition
import {defineComponent, computed} from "vue";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "FilterSelect",
  components: {
    SvgIcon
  },
  emits: [
    "change",
    "input"
  ],
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean
    },
    returnObject: {
      type: Boolean
    },
    textMultiple: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    value: {
      type: [String, Number, Object]
    }
  },
  setup(props, ctx) {
    const vModel = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    const onChange = (): void => {
      ctx.emit("change", vModel.value);
    };

    const getText = (item: { [key: string]: string } | string): string => {
      if (typeof item === "object") {
        if (item[props.itemText]) {
          return item[props.itemText];
        }
      }
      return JSON.stringify(item);
    }

    return {
      vModel,
      getText,
      onChange
    };
  }
})
</script>
