<template>
  <v-row no-gutters class="mr-12 pb-9 fill-height">
    <v-col cols="8">
      <TablaChoferes
        :search="search"
        :chofer="chofer"
        :permisos="permisos"
        :arrayChoferes="arrayChoferes"
        @click:switch-estado="onClickSwitchEstado"
        @click:informacion="onClickInformacion"
      ></TablaChoferes>
    </v-col>
    <v-col cols="4" class="pl-7">
      <FormularioChofer
        v-if="mostrarFormulario && !mostrarDetalle"
        :chofer="chofer"
        :cargando="cargando"
        :usuario="usuario"
        @click:guardar="onClickGuardar"
      ></FormularioChofer>
      <DetalleChofer
        v-if="mostrarDetalle && !mostrarFormulario"
        :chofer="chofer"
        :permisos="permisos"
        @click:editar="onClickEditar"
        @click:eliminar="onClickEliminar"
      ></DetalleChofer>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//deoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Permisos } from "@/typings/store/modules/permisos";
import { DataFormularioChofer } from "@/typings/components/choferes/formulario";
//componentes
import DetalleChofer from "@/components/transporte/choferes/Detalle.vue";
import TablaChoferes from "@/components/transporte/choferes/Tabla.vue";
import FormularioChofer from "@/components/transporte/choferes/FormularioChofer.vue";

@Component({
  components: {
    DetalleChofer,
    TablaChoferes,
    FormularioChofer,
  },
})
export default class TabChoferes extends Vue {
  @Prop() readonly arrayChoferes!: Array<Chofer>;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarDetalle!: boolean;
  @Prop() readonly chofer!: Chofer | null;
  @Prop() readonly search!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly permisos!: Permisos;

  @Emit("click:guardar")
  onClickGuardar(data: DataFormularioChofer): DataFormularioChofer {
    return data;
  }

  @Emit("click:informacion")
  onClickInformacion(chofer: Chofer): Chofer {
    return chofer;
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(chofer: Chofer): Chofer {
    return chofer;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
</script>
