<template>
  <v-data-table
    height="258px"
    :headers="headers"
    :items="items"
    class="elevation-black white fill-height table rounded-input metropolis"
    hide-default-footer
    @click:row="clickItem"
    :items-per-page="10"
  >
    <template v-slot:[`item.estado`]="{ item }">
      <TooltipEstado
        :pesaje="item.pesaje"
        @click:sincronizar="onClickSincronizar"
      />
    </template>
    <template v-slot:[`item.entrada`]="{ item }">
      <v-col cols="auto" :class="getClasses(item)">
        {{ item.entrada }}
      </v-col>
    </template>
    <template v-slot:[`item.salida`]="{ item }">
      <v-col cols="auto" :class="getClasses(item)">
        {{ item.salida }}
      </v-col>
    </template>
    <template v-slot:[`item.patente`]="{ item }">
      <v-col cols="auto" :class="getClasses(item, 'uppercase')">
        {{ item.patente }}
      </v-col>
    </template>
    <template v-slot:[`item.pesoNeto`]="{ item }">
      <v-col cols="auto" :class="getClasses(item)">
        {{ item.pesoNeto }}
      </v-col>
    </template>
    <template v-slot:[`item.opciones`]="{ item }">
      <v-row no-gutters justify="end">
        <v-col cols="auto" class="pr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="item.pesaje.estado !== 'finalizado'"
                v-on="on"
                v-bind="attrs"
                @click.stop="onClickImprimir(item.pesaje)"
              >
                <svg-icon
                  name="ags-print"
                  :color="item.pesaje.estado !== 'finalizado' ? 'disabled' : 'secondary'"
                ></svg-icon>
              </v-btn>
            </template>
            <span class="metropolis">
              {{ text("boton-imprimir.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto" class="px-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-on="on"
                v-bind="attrs"
                @click.stop="onClickImagenes(item.pesaje)"
              >
                <svg-icon name="ag-image" color="purple"></svg-icon>
              </v-btn>
            </template>
            <span class="metropolis">
              {{ text("boton-imagenes.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto" class="pl-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-on="on"
                v-bind="attrs"
                @click.stop="onClickInformacion(item.pesaje)"
              >
                <svg-icon
                  color="primary"
                  name="ionic-md-information-circle"
                ></svg-icon>
              </v-btn>
            </template>
            <span class="metropolis">
              {{ text("boton-informacion.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer :value="props"/>
    </template>
  </v-data-table>
</template>

<script lang="ts">
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import {log} from "@/helpers/env";

// Composables
import {useI18n} from "@/composables/i18n";

// Tipos
import {Pesaje} from "@/typings/store/plugins/easyFirestore/pesajes";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import TooltipEstado from "@/components/bascula/tablaBascula/TooltipEstado.vue";

interface ItemTabla {
  salida: string;
  pesaje: Pesaje;
  estado: boolean;
  entrada: string;
  patente: string;
  pesoNeto: string;
}

export default defineComponent({
  name: "TablaBascula",
  components: {
    SvgIcon,
    TooltipEstado,
    DataTableFooter,
  },
  props: {
    pesajes: {
      required: true,
      type: Array as () => Pesaje[],
    },
    pesaje: {
      type: Object as () => Pesaje | null,
    },
  },
  emits: [
    "click:item",
    "click:imprimir",
    "click:imagenes",
    "click:sincronizar",
    "click:informacion",
  ],
  setup(props, ctx) {
    // Composables

    const {t} = useI18n();

    // Computed

    const headers = computed(() => [{
      text: "",
      value: "estado",
      sortable: false,
    }, {
      value: "entrada",
      text: text("headers.0"),
    }, {
      value: "salida",
      text: text("headers.1"),
    }, {
      value: "patente",
      text: text("headers.2"),
    }, {
      value: "pesoNeto",
      text: text("headers.3"),
    }, {
      text: "",
      sortable: false,
      value: "opciones",
    }]);

    const items = computed(() => {
      if (log) console.time("TablaBascula.items");
      const resultado = props.pesajes.map((pesaje) => {
        const estado = pesaje.estado === "finalizado";
        const patente = pesaje.vehiculo?.patente ?? "-";
        const fechaEntradaString = pesaje.entrada?.fecha;
        const entrada = fechaEntradaString ? fechaEntradaString.toDate().format("HH:mm") : "-";
        const fechaSalidaString = pesaje.salida?.fecha;
        const salida = fechaSalidaString ? fechaSalidaString.toDate().format("HH:mm") : "-";
        const mayoresCero = pesaje.entrada.peso > 0 && pesaje.salida.peso > 0;
        const peso = pesaje.entrada.peso - pesaje.salida.peso;
        const pesoFinal = pesaje.servicio?.tipo === "Egreso" ? peso * -1 : peso
        const pesoNeto = mayoresCero ? pesoFinal.toCurrency() : "-";
        return {
          estado,
          salida,
          pesaje,
          entrada,
          patente,
          pesoNeto,
        };
      });
      if (log) console.timeEnd("TablaBascula.items");
      return resultado;
    });

    // Methods

    const text = (key: string) => t(`bascula.tabla.${key}`) as string;

    const seleccionado = ({pesaje}: { pesaje: Pesaje }) => pesaje.id === props.pesaje?.id;

    const clickItem = ({pesaje}: { pesaje: Pesaje }) => {
      if (pesaje.estado !== "finalizado") {
        onClickItem(pesaje);
      }
    };

    const getClasses = ({pesaje}: ItemTabla, textTransform?: "capitalize" | "uppercase") => {
      const seleccionado = props.pesaje?.id === pesaje.id;
      const classes = ['pa-0 metropolis text-center text-body-1'];
      if (textTransform === "capitalize") classes.push("text-capitalize");
      if (textTransform === "uppercase") classes.push("text-uppercase");
      if (seleccionado) classes.push("primary--text font-weight-bold");
      return classes.join(" ");
    };

    // Emits

    const onClickImprimir = (pesaje: Pesaje) => {
      ctx.emit("click:imprimir", pesaje.id ?? "");
    };

    const onClickImagenes = (pesaje: Pesaje) => {
      ctx.emit("click:imagenes", pesaje);
    };

    const onClickInformacion = (pesaje: Pesaje) => {
      ctx.emit("click:informacion", pesaje);
    };

    const onClickItem = (pesaje: Pesaje) => {
      ctx.emit("click:item", pesaje);
    };

    const onClickSincronizar = (pesaje: Pesaje) => {
      ctx.emit("click:sincronizar", pesaje);
    };

    return {
      // Computed
      items,
      headers,
      // Methods
      text,
      clickItem,
      getClasses,
      seleccionado,
      // Emits
      onClickItem,
      onClickImprimir,
      onClickImagenes,
      onClickSincronizar,
      onClickInformacion,
    };
  },
});
</script>
