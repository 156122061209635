<template>
  <v-card flat color="transparent">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="end" align="center">
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon :color="color" size="20px" v-on="on" v-bind="attrs">
                mdi-circle
              </v-icon>
            </template>
            <span class="metropolis line-break">
              {{ texto }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { Item } from "@/typings/components/reportes/tabla";

@Component
export default class ItemCirculo extends Vue {
  @Prop() readonly item!: Item;

  get color(): string {
    const { pesaje } = this.item;
    return pesaje.estado === "finalizado" ? "primary" : "text";
  }

  get texto(): string {
    const { pesaje } = this.item;
    const index = pesaje.estado === "finalizado" ? 0 : 1;
    return this.$t("reportes.tabla.estado." + index) as string;
  }
}
</script>
