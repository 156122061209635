<template>
  <v-container class="pa-0 ma-0">
    <v-row justify="start" no-gutters align="end">
      <v-col cols="4" class="pr-2">
        <v-text-field
          hide-details
          dense
          solo
          clearable
          color="text"
          :placeholder="$t('transporte.search.placeholder')"
          class="metropolis rounded-input elevation-input"
          v-model="search"
        >
          <template v-slot:append>
            <svg-icon name="ag-search" color="text"></svg-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="4" class="pl-2" v-if="tab === 1">
        <v-container class="pa-0 ma-0">
          <v-row no-gutters>
            <v-col cols="12" class="metropolis text--text text-body-1 px-5">
              {{ $t("transporte.filtro.tipo-vehiculo.label") }}
            </v-col>
            <v-col cols="12">
              <v-select
                dense
                solo
                clearable
                hide-details
                color="text"
                :placeholder="$t('transporte.filtro.tipo-vehiculo.placeholder')"
                class="metropolis rounded-input elevation-input"
                :items="tiposVehiculos"
                v-model="filtroTipoVehiculo"
                :menu-props="{
                  contentClass: 'rounded-input elevation-black',
                  transition: 'slide-y-transition',
                }"
              >
                <template v-slot:item="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:append>
                  <svg-icon
                    name="ionic-md-arrow-dropdown"
                    color="text"
                    width="12px"
                    height="12px"
                  ></svg-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="4" class="pl-2" v-if="tab === 2">
        <v-container class="pa-0 ma-0">
          <v-row no-gutters>
            <v-col cols="12" class="metropolis text--text text-body-1 px-5">
              {{ $t("transporte.filtro.tipo-ruta.label") }}
            </v-col>
            <v-col cols="12">
              <v-select
                dense
                solo
                clearable
                hide-details
                color="text"
                :placeholder="$t('transporte.filtro.tipo-ruta.placeholder')"
                class="metropolis rounded-input elevation-input"
                :items="tiposRutas"
                v-model="filtroTipoRutaPesaje"
                :menu-props="{
                  contentClass: 'rounded-input elevation-black',
                  transition: 'slide-y-transition',
                }"
              >
                <template v-slot:item="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:append>
                  <svg-icon
                    name="ionic-md-arrow-dropdown"
                    color="text"
                    width="12px"
                    height="12px"
                  ></svg-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="4" class="pl-2">
        <v-container class="pa-0 ma-0">
          <v-row no-gutters>
            <v-col cols="12" class="metropolis text--text text-body-1 px-5">
              {{ $t("transporte.filtro.estado.label") }}
            </v-col>
            <v-col cols="12">
              <v-select
                dense
                solo
                clearable
                hide-details
                color="text"
                :placeholder="$t('transporte.filtro.estado.placeholder')"
                class="metropolis rounded-input elevation-input"
                :items="estados"
                v-model="filtroEstado"
                :menu-props="{
                  contentClass: 'rounded-input elevation-black',
                  transition: 'slide-y-transition',
                }"
              >
                <template v-slot:item="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:append>
                  <svg-icon
                    name="ionic-md-arrow-dropdown"
                    color="text"
                    width="12px"
                    height="12px"
                  ></svg-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
//tipos
import { EstadoChofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { FiltrosTransporte } from "@/typings/components/transporte/filtros";
import { EstadoIdentificador } from "@/typings/store/plugins/easyFirestore/identificadores";
import {
  EstadoRutaPesaje,
  TipoRutaPesaje,
} from "@/typings/store/plugins/easyFirestore/rutasPesaje";
import {
  Combustible,
  EstadoVehiculo,
  TipoVehiculo,
} from "@/typings/store/plugins/easyFirestore/vehiculos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosTransportes extends Vue {
  @VModel() filtros!: FiltrosTransporte;
  @Prop() readonly tab!: number;

  get tiposVehiculos(): Array<{ text: string; value: TipoVehiculo }> {
    return [
      {
        text: this.$t("transporte.filtro.tipo-vehiculo.values[0]") as string,
        value: "auto",
      },
      {
        text: this.$t("transporte.filtro.tipo-vehiculo.values[1]") as string,
        value: "camioneta",
      },
      {
        text: this.$t("transporte.filtro.tipo-vehiculo.values[2]") as string,
        value: "camion",
      },
    ];
  }

  get tiposRutas(): Array<{ text: string; value: TipoRutaPesaje }> {
    return [
      {
        text: this.$t("transporte.filtro.tipo-ruta.values[0]") as string,
        value: "rural",
      },
      {
        text: this.$t("transporte.filtro.tipo-ruta.values[1]") as string,
        value: "urbano",
      },
    ];
  }

  get combustibles(): Array<{ text: string; value: Combustible }> {
    return [
      {
        text: this.$t("transporte.filtro.combustible.values[0]") as string,
        value: "bencina",
      },
      {
        text: this.$t("transporte.filtro.combustible.values[1]") as string,
        value: "diesel",
      },
    ];
  }

  get estados(): Array<{
    text: string;
    value: "habilitado" | "deshabilitado";
  }> {
    return [
      {
        text: this.$t("transporte.filtro.estado.values[0]") as string,
        value: "habilitado",
      },
      {
        text: this.$t("transporte.filtro.estado.values[1]") as string,
        value: "deshabilitado",
      },
    ];
  }

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  get filtroTipoVehiculo(): TipoVehiculo | null {
    return this.filtros.tipoVehiculo;
  }

  set filtroTipoVehiculo(value: TipoVehiculo | null) {
    this.filtros.tipoVehiculo = value;
  }

  get filtroTipoRutaPesaje(): TipoRutaPesaje | null {
    return this.filtros.tipoRuta;
  }

  set filtroTipoRutaPesaje(value: TipoRutaPesaje | null) {
    this.filtros.tipoRuta = value;
  }

  get filtroCombustible(): Combustible | null {
    return this.filtros.combustible;
  }

  set filtroCombustible(value: Combustible | null) {
    this.filtros.combustible = value;
  }

  get filtroEstado():
    | EstadoVehiculo
    | EstadoRutaPesaje
    | EstadoChofer
    | EstadoIdentificador
    | null {
    return this.filtros.estado;
  }

  set filtroEstado(
    value:
      | EstadoVehiculo
      | EstadoRutaPesaje
      | EstadoChofer
      | EstadoIdentificador
      | null
  ) {
    this.filtros.estado = value;
  }
}
</script>
