<template>
  <div class="parent-container" ref="parentContainer">
    <div class="book-container">
      <div ref="flipbook">
        <div
          v-for="index in 22"
          :class="`imagen imagen-${index}`"
          :key="`imagen-${index}`"
          data-density="soft"
        ></div>
      </div>
      <v-col cols="12" md="6">
        <v-row no-gutters class="pt-md-6 pt-3" align="center" justify="center">
          <v-col cols="auto">
            <v-btn
              @click="previousPage"
              color="white"
              icon
              :large="!mobile"
              :small="mobile"
            >
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="4"
            class="text-md-h6 text-body-2 white--text text-center"
          >
            {{ currentPage }} / {{ pageCount }}
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="nextPage"
              color="white"
              icon
              :large="!mobile"
              :small="mobile"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              @click="changeFullscreen"
              color="white"
              icon
              :large="!mobile"
              :small="mobile"
            >
              <v-icon v-if="!fullscreen"> mdi-fullscreen </v-icon>
              <v-icon v-else> mdi-fullscreen-exit </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { PageFlip } from "page-flip";

@Component
export default class Revista extends Vue {
  currentPage = 1;
  pageCount = 1;
  pageFlip: PageFlip | null = null;
  horizontal = false;
  fullscreen = false;

  created(): void {
    document.addEventListener("keydown", this.keypressHandler);
    document.addEventListener("fullscreenchange", this.fullscreenChangeHandler);
  }

  async mounted(): Promise<void> {
    let width = this.mobile ? this.width : this.width * 0.375;
    let height = width * 1.125;
    let usePortrait = this.mobile;
    if (height > this.height - 28) {
      usePortrait = false;
      height = this.height - 28;
      width = height / 1.125;
      this.horizontal = true;
    }
    this.pageFlip = new PageFlip(this.flipbook, {
      width: width,
      height: height,
      size: "fixed",
      usePortrait: usePortrait,
      flippingTime: 750,
      showCover: true,
      maxShadowOpacity: 0.75,
    });
    this.pageFlip?.loadFromHTML(document.querySelectorAll(".imagen"));
    this.pageFlip?.on("flip", (event) => {
      this.currentPage = event.data + 1;
    });
    this.pageCount = this.pageFlip.getPageCount();
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }

  get width(): number {
    return this.$vuetify.breakpoint.width;
  }

  get height(): number {
    return this.$vuetify.breakpoint.height;
  }

  get flipbook(): HTMLElement {
    return this.$refs.flipbook as HTMLElement;
  }

  get parentContainer(): HTMLElement {
    return this.$refs.parentContainer as HTMLElement;
  }

  changeFullscreen(): void {
    if (this.fullscreen) {
      document.exitFullscreen();
      this.fullscreen = false;
    } else {
      this.parentContainer.requestFullscreen();
      this.fullscreen = true;
    }
  }

  fullscreenChangeHandler(): void {
    this.fullscreen = document.fullscreenElement !== null;
  }

  nextPage(): void {
    this.pageFlip?.flipNext("top");
  }

  previousPage(): void {
    this.pageFlip?.flipPrev("top");
  }

  keypressHandler(event: KeyboardEvent): void {
    if (event.key === "ArrowRight") {
      this.nextPage();
    } else if (event.key === "ArrowLeft") {
      this.previousPage();
    } else if (event.key.toLowerCase() === "f") {
      this.changeFullscreen();
    }
  }

  destroyed(): void {
    document.removeEventListener("keypress", this.keypressHandler);
    document.removeEventListener(
      "fullscreenchange",
      this.fullscreenChangeHandler
    );
    this.pageFlip?.destroy();
  }
}
</script>

<style scoped>
.parent-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.book-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imagen-1 {
  background: url("../assets/revista/page-1.png");
  background-size: cover;
}

.imagen-2 {
  background: url("../assets/revista/page-2.png");
  background-size: cover;
}

.imagen-3 {
  background: url("../assets/revista/page-3.png");
  background-size: cover;
}

.imagen-4 {
  background: url("../assets/revista/page-4.png");
  background-size: cover;
}

.imagen-5 {
  background: url("../assets/revista/page-5.png");
  background-size: cover;
}

.imagen-6 {
  background: url("../assets/revista/page-6.png");
  background-size: cover;
}

.imagen-7 {
  background: url("../assets/revista/page-7.png");
  background-size: cover;
}

.imagen-8 {
  background: url("../assets/revista/page-8.png");
  background-size: cover;
}

.imagen-9 {
  background: url("../assets/revista/page-9.png");
  background-size: cover;
}

.imagen-10 {
  background: url("../assets/revista/page-10.png");
  background-size: cover;
}

.imagen-11 {
  background: url("../assets/revista/page-11.png");
  background-size: cover;
}

.imagen-12 {
  background: url("../assets/revista/page-12.png");
  background-size: cover;
}

.imagen-13 {
  background: url("../assets/revista/page-13.png");
  background-size: cover;
}

.imagen-14 {
  background: url("../assets/revista/page-14.png");
  background-size: cover;
}

.imagen-15 {
  background: url("../assets/revista/page-15.png");
  background-size: cover;
}

.imagen-16 {
  background: url("../assets/revista/page-16.png");
  background-size: cover;
}

.imagen-17 {
  background: url("../assets/revista/page-17.png");
  background-size: cover;
}

.imagen-18 {
  background: url("../assets/revista/page-18.png");
  background-size: cover;
}

.imagen-19 {
  background: url("../assets/revista/page-19.png");
  background-size: cover;
}

.imagen-20 {
  background: url("../assets/revista/page-20.png");
  background-size: cover;
}

.imagen-21 {
  background: url("../assets/revista/page-21.png");
  background-size: cover;
}

.imagen-22 {
  background: url("../assets/revista/page-22.png");
  background-size: cover;
}

.imagen.--left {
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.imagen.--right {
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
</style>
