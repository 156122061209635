<template>
  <v-row no-gutters justify="end">
    <v-col
      cols="auto"
      v-for="notificacion in notificacionesMostrar"
      :key="notificacion.id"
    >
      <v-tooltip bottom :disabled="notificacion.abierta">
        <template v-slot:activator="{ on, attrs }">
          <div
            :class="`notification-card ${
              notificacion.abierta ? 'opened' : 'closed'
            }`"
            v-on="on"
            v-bind="attrs"
            @click="abrirNotificacion(notificacion)"
            @transitionend.self="checkEliminar"
          >
            <v-row no-gutters align="center" class="fill-height">
              <v-col
                cols="auto"
                class="white--text metropolis font-weight-bold text-body-1"
              >
                {{ notificacion.titulo }}
              </v-col>
              <v-col class="pl-2 metropolis white--text text-body-1">
                {{ notificacion.mensaje }}
              </v-col>
              <v-col cols="auto" class="pl-2">
                <v-btn small icon @click.stop="clickEditar(notificacion)">
                  <svg-icon
                    width="20px"
                    height="20px"
                    name="ag-edit"
                    color="white"
                  />
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  small
                  icon
                  @click.stop="cerrarNotificacion(notificacion)"
                >
                  <v-icon size="20px" color="white"> mdi-close </v-icon>
                </v-btn>
              </v-col>
              <div class="notification-icon-container">
                <svg-icon
                  name="ag-alert-triangle-fill"
                  :color="notificacion.abierta ? 'error' : 'white'"
                  :width="notificacion.abierta ? '16px' : '24px'"
                  :height="notificacion.abierta ? '16px' : '24px'"
                ></svg-icon>
              </div>
            </v-row>
          </div>
        </template>
        <div
          class="metropolis font-weight-bold text-uppercase white--text text-body-1"
        >
          {{ notificacion.titulo }}
        </div>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import {
  Notificacion,
  Notificaciones,
} from "@/typings/store/plugins/easyFirestore/notificaciones";

import SvgIcon from "@/components/custom/SvgIcon.vue";

interface NotificacionesLeidas {
  [key: string]: string;
}

@Component({
  components: {
    SvgIcon,
  },
})
export default class ListaNotificaciones extends Vue {
  @Prop() readonly pesajes!: Array<Pesaje>;

  created(): void {
    this.cargarLocalStorage();
    this.onPesajesFiltradosChange(this.pesajesFiltrados);
    const ultima =
      this.notificacionesMostrar[this.notificacionesMostrar.length - 1];
    if (ultima) this.abrirNotificacion(ultima);
    this.revisarTiempoPesajes();
    this.intervalId = window.setInterval(() => {
      this.revisarTiempoPesajes();
    }, 1000 * 60);
  }

  notificaciones: Notificaciones = {};
  notificacionesLeidas: NotificacionesLeidas = {};
  intervalId: number | null = null;
  idEliminar: string | null = null;

  get pesajesFiltrados(): Array<Pesaje> {
    return this.pesajes.filter((pesaje) => {
      const fechaPesaje = pesaje.entrada.fecha.toDate();
      const fechaHoy = new Date();
      fechaHoy.setHours(0, 0, 0, 0);
      return fechaPesaje.getTime() >= fechaHoy.getTime();
    });
  }

  get pesajesSinSalida(): Array<Pesaje> {
    return this.pesajesFiltrados.filter((pesaje) => pesaje.salida.peso === 0);
  }

  get ordenadas(): Array<Notificacion> {
    const arrayNotificaciones = Object.values(this.notificaciones);
    arrayNotificaciones.sort((a, b) => a.numero - b.numero);
    return arrayNotificaciones;
  }

  get notificacionesMostrar(): Array<Notificacion> {
    const max = this.$vuetify.breakpoint.width < 1920 ? 1 : 4;
    const notificaciones = this.ordenadas.slice(0, max);
    notificaciones.sort((a, b) => b.numero - a.numero);
    return notificaciones;
  }

  get lengthNotificaciones(): number {
    return this.ordenadas[this.ordenadas.length - 1]?.numero ?? 0;
  }

  @Watch("pesajesFiltrados", { deep: true })
  onPesajesFiltradosChange(pesajes: Array<Pesaje>): void {
    for (const pesaje of pesajes) {
      const pesoEntrada = pesaje.entrada.peso;
      const pesoSalida = pesaje.salida.peso;
      const diferenciaPeso = pesoEntrada - pesoSalida;
      const pesoNeto =
        pesaje.servicio?.tipo === "Egreso"
          ? diferenciaPeso * -1
          : diferenciaPeso;
      const patente = pesaje.vehiculo?.patente ?? "";
      const taraVehiculo = pesaje.vehiculo?.tara ?? 4000;
      const alertaPesaje = pesaje.vehiculo?.alertaPeso ?? 600;
      const pesoAlertaMinimo = taraVehiculo - alertaPesaje;
      const pesoAlertaMaximo = taraVehiculo + alertaPesaje;
      const idPeso = `peso-negativo-${pesaje.id}`;
      if (pesoEntrada > 0 && pesoSalida > 0 && pesoNeto < 0) {
        if (!this.notificacionesLeidas[idPeso]) {
          this.nuevaNotificacion({
            id: idPeso,
            titulo: patente.toUpperCase(),
            mensaje: this.$t(
              "notificaciones.error-messages.peso-negativo"
            ) as string,
            numero: this.lengthNotificaciones + 1,
            abierta: false,
            payload: {
              id: pesaje.id ?? "",
            },
          });
        }
      } else {
        const notificacion = this.notificaciones[idPeso];
        if (notificacion) this.removeNotificacion(notificacion);
      }
      const alertasMayoresCero = pesoAlertaMinimo > 0 && pesoAlertaMaximo > 0;
      const salidaMayorCero = pesoSalida > 0;
      const idTara = `peso-fuera-rango-${pesaje.id}`;
      if (
        alertasMayoresCero &&
        salidaMayorCero &&
        (pesoSalida > pesoAlertaMaximo || pesoSalida < pesoAlertaMinimo)
      ) {
        if (!this.notificacionesLeidas[idTara]) {
          this.nuevaNotificacion({
            id: `peso-fuera-rango-${pesaje.id}`,
            titulo: patente.toUpperCase(),
            mensaje: this.$t("notificaciones.error-messages.tara") as string,
            numero: this.lengthNotificaciones + 1,
            abierta: false,
            payload: {
              id: pesaje.id ?? "",
            },
          });
        }
      } else {
        const notificacion = this.notificaciones[idTara];
        if (notificacion) this.removeNotificacion(notificacion);
      }
    }
  }

  cargarLocalStorage(): void {
    const notificacionesLeidas = window.localStorage.getItem(
      "notificaciones_leidas"
    );
    if (notificacionesLeidas)
      this.notificacionesLeidas = JSON.parse(notificacionesLeidas);
    const primeraLeida = Object.values(this.notificacionesLeidas)[0];
    const fechaPimera = primeraLeida?.toDate();
    const hoy = new Date();
    if (fechaPimera && fechaPimera.getDate() !== hoy.getDate()) {
      this.notificacionesLeidas = {};
      window.localStorage.setItem(
        "notificaciones_leidas",
        JSON.stringify(this.notificacionesLeidas)
      );
    }
  }

  revisarTiempoPesajes(): void {
    const fechaHoy = new Date();
    for (const pesaje of this.pesajesSinSalida) {
      const fechaEntrada = pesaje.entrada.fecha.toDate();
      const diferenciaMinutos = Math.floor(
        (fechaHoy.getTime() - fechaEntrada.getTime()) / (1000 * 60)
      );
      const patente = pesaje.vehiculo?.patente ?? "";
      const id = `tiempo-excedido-${pesaje.id}`;
      if (diferenciaMinutos > 25) {
        if (!this.notificacionesLeidas[id]) {
          this.nuevaNotificacion({
            id: `tiempo-excedido-${pesaje.id}`,
            titulo: patente.toUpperCase(),
            mensaje: this.$t("notificaciones.error-messages.tiempo") as string,
            numero: this.lengthNotificaciones + 1,
            abierta: false,
            payload: {
              id: pesaje.id ?? "",
            },
          });
        }
      } else {
        const notificacion = this.notificaciones[id];
        if (notificacion) this.removeNotificacion(notificacion);
      }
    }
  }

  checkEliminar(): void {
    if (this.idEliminar) {
      this.$set(
        this.notificacionesLeidas,
        this.idEliminar,
        new Date().toISOString()
      );
      window.localStorage.setItem(
        "notificaciones_leidas",
        JSON.stringify(this.notificacionesLeidas)
      );
      this.$delete(this.notificaciones, this.idEliminar);
      const ultima =
        this.notificacionesMostrar[this.notificacionesMostrar.length - 1];
      if (ultima) this.$set(ultima, "abierta", true);
      this.idEliminar = null;
    }
  }

  abrirNotificacion(notificacion: Notificacion): void {
    for (const n of this.notificacionesMostrar) {
      this.$set(n, "abierta", false);
    }
    this.$set(notificacion, "abierta", true);
  }

  nuevaNotificacion(notificacion: Notificacion): void {
    this.$set(this.notificaciones, notificacion.id, notificacion);
  }

  cerrarNotificacion(notificacion: Notificacion): void {
    this.$set(notificacion, "abierta", false);
    this.idEliminar = notificacion.id;
  }

  removeNotificacion(notificacion: Notificacion): void {
    this.$delete(this.notificaciones, notificacion.id);
  }

  clickEditar(notificacion: Notificacion): void {
    const pesaje = this.pesajes.find(
      (pesaje) => pesaje.id === notificacion?.payload?.id
    );
    if (pesaje) {
      this.$eventBus.$emit(
        "click:editar-pesaje",
        JSON.parse(JSON.stringify(pesaje))
      );
    }
  }

  destroyed(): void {
    if (this.intervalId !== null) window.clearInterval(this.intervalId);
  }
}
</script>

<style scoped>
.notification-card {
  width: 48px;
  transition: width 750ms ease-in-out;
  position: relative;
  border-radius: 10px;
  padding: 0 16px;
  margin: 0 8px;
  height: 48px;
  cursor: pointer;
}

.notification-card.opened {
  margin-right: 24px;
  width: calc(64px * 7);
  background: var(--v-error-base);
}

.notification-card.closed {
  width: 48px;
  background: var(--v-yellow-base);
  overflow: hidden;
}

.notification-card > .row > *:not(.notification-icon-container) {
  transition: all 150ms ease-in-out;
}

.notification-card.opened > .row > *:not(.notification-icon-container) {
  transition-delay: 600ms;
  opacity: 1;
}

.notification-card.closed > .row > *:not(.notification-icon-container) {
  opacity: 0;
}

.notification-icon-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-card.opened .notification-icon-container {
  transition: right 500ms ease-in-out;
  top: 8px;
  right: -16px;
  background: white;
  border: 2px solid var(--v-error-base);
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.notification-card.closed .notification-icon-container {
  top: 12px;
  right: 12px;
}

@media (max-width: 1919px) {
  .notification-card.opened {
    width: calc(100% - 24px);
  }
}
</style>
