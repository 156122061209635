<template>
  <div class="d-flex flex-column fill-height">
    <v-row align="center" justify="center" no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("transporte.tab.flota.detalle.title") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-2 flex-grow-0">
      <v-col>
        <SubirImagen
          :preview="vehiculo.foto ? vehiculo.foto.url : ''"
          only-preview
        ></SubirImagen>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.0") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-uppercase">
            {{ patente }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.1") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ tipo }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.2") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ marca }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.3") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ modelo }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.4") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ año }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.5") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ combustible }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.6") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ numeroChasis }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.flota.detalle.label.7") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ numeroMotor }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0">
      <v-col cols="6" class="pr-2" v-if="mostrarBotonEditar">
        <v-btn
          block
          large
          height="48px"
          class="rounded-input elevation-warning gradient-warning"
          @click="onClickEditar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon name="ag-edit" color="white"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("transporte.tab.flota.detalle.boton-editar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="6" class="pl-2" v-if="mostrarBotonEliminar">
        <v-btn
          block
          large
          height="48px"
          class="rounded-input elevation-error gradient-error"
          @click="onClickEliminar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <v-icon color="white"> mdi-delete </v-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("transporte.tab.flota.detalle.boton-eliminar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";

@Component({
  components: {
    SvgIcon,
    SubirImagen,
  },
})
export default class DetalleVehiculo extends Vue {
  @Prop() readonly vehiculo!: Vehiculo;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.transporte === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.flota.no-value-message") as string;
  }

  get patente(): string {
    return this.vehiculo.patente || this.noValueMessage;
  }

  get tipo(): string {
    return this.vehiculo.tipo || this.noValueMessage;
  }

  get marca(): string {
    return this.vehiculo.marca || this.noValueMessage;
  }

  get modelo(): string {
    return this.vehiculo.modelo || this.noValueMessage;
  }

  get año(): string | number {
    return this.vehiculo.año || this.noValueMessage;
  }

  get combustible(): string {
    return this.vehiculo.combustible || this.noValueMessage;
  }

  get numeroChasis(): string {
    return this.vehiculo.numeroChasis || this.noValueMessage;
  }

  get numeroMotor(): string {
    return this.vehiculo.numeroMotor || this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
</script>
