<template>
  <div class="lista-header__container">
    <v-switch
      dense
      hide-details
      color="primary"
      v-model="model"
      class="metropolis ma-0"
      :label="t('switch-multiple')"
    ></v-switch>
    <div
      v-if="mostrarBotonAgregar"
      class="lista-header__buttons"
    >
      <v-btn
        x-small
        depressed
        width="36px"
        height="36px"
        @click="onClickNuevaRuta"
        class="gradient-secondary rounded-input"
      >
        <v-icon color="white">
          mdi-map-marker-path
        </v-icon>
      </v-btn>
      <v-btn
        depressed
        color="primary-light"
        class="rounded-input"
        @click="onClickAgregarBarredor"
      >
        <div class="lista-header__add-button">
          <svg-icon
            color="primary"
            name="bxs-user-plus"
          ></svg-icon>
          <span>
            {{ t("boton-add.text") }}
          </span>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
// Composition
import {computed, defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "HeaderListaBarredores",
  components: {
    SvgIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    mostrarBotonAgregar: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "input",
    "click:nueva-ruta",
    "click:agregar-barredor",
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value: boolean) {
        ctx.emit("input", value);
      },
    });

    // Methods

    const t = (key: string) => i18n.t(`barredores.tab.barredores.list.${key}`) as string;

    // Emits

    const onClickNuevaRuta = () => {
      ctx.emit("click:nueva-ruta");
    };

    const onClickAgregarBarredor = () => {
      ctx.emit("click:agregar-barredor");
    };

    return {
      // VModel
      model,
      // Methods
      t,
      // Emits
      onClickNuevaRuta,
      onClickAgregarBarredor,
    };
  },
});
</script>

<style scoped>
.lista-header__container {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;
}

.lista-header__buttons {
  display: grid;
  column-gap: 1rem;
  grid-auto-flow: column;
}

.lista-header__add-button {
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto;
}

.lista-header__add-button span {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: initial;
  color: var(--v-primary-base);
  text-transform: initial !important;
  font-family: "Metropolis", sans-serif;
}
</style>
