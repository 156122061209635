<template>
  <v-row no-gutters class="fill-height">
    <v-col>
      <v-row no-gutters>
        <v-col
          class="text-center primary--text text-h6 metropolis font-weight-bold"
        >
          {{ $t("pesajes.detalle.title") }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoVehiculo
            :vehiculo="vehiculo"
            parent="pesajes.detalle"
          ></MenuInfoVehiculo>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoCliente
            :cliente="cliente"
            parent="pesajes.detalle"
          ></MenuInfoCliente>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoDesecho
            :desecho="desecho"
            parent="pesajes.detalle"
          ></MenuInfoDesecho>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoServicio
            :servicio="servicio"
            parent="pesajes.detalle"
          ></MenuInfoServicio>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col>
          <MenuInfoRutaPesaje
            :ruta="ruta"
            parent="pesajes.detalle"
          ></MenuInfoRutaPesaje>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2 px-2">
        <v-col
          cols="auto"
          class="metropolis text--text font-size-14 font-weight-bold"
        >
          {{ $t("pesajes.detalle.created") }}
        </v-col>
        <v-col class="pl-2 metropolis text--text font-size-14 text-capitalize">
          {{ fechaCreacion }} - {{ creadoPor }}
        </v-col>
      </v-row>
      <v-row no-gutters class="px-2" v-if="fechaEdicion">
        <v-col
          cols="auto"
          class="metropolis text--text font-size-14 font-weight-bold"
        >
          {{ $t("pesajes.detalle.updated") }}
        </v-col>
        <v-col class="pl-2 metropolis text--text font-size-14 text-capitalize">
          {{ fechaEdicion }} - {{ editadoPor }}
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-6">
        <v-col>
          <EntradaSalida
            :pesaje="pesaje"
            :permisos="permisos"
            :mostrar-galeria="mostrarGaleria"
            @click:ver-imagenes="onClickVerImagenes"
            @click:editar="onClickEditar"
          ></EntradaSalida>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Usuarios } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Permisos } from "@/typings/store/modules/permisos";
import { RutaPesaje } from "@/typings/store/plugins/easyFirestore/rutasPesaje";
//componentes
import EntradaSalida from "@/components/pesajes/EntradaSalida.vue";
import MenuInfoCliente from "@/components/clientes/MenuInfo.vue";
import MenuInfoDesecho from "@/components/desechos/MenuInfo.vue";
import MenuInfoServicio from "@/components/servicios/MenuInfo.vue";
import MenuInfoVehiculo from "@/components/transporte/vehiculos/MenuInfo.vue";
import MenuInfoRutaPesaje from "@/components/transporte/rutas/MenuInfo.vue";

@Component({
  components: {
    EntradaSalida,
    MenuInfoDesecho,
    MenuInfoCliente,
    MenuInfoVehiculo,
    MenuInfoServicio,
    MenuInfoRutaPesaje,
  },
})
export default class DetallePesaje extends Vue {
  @Prop() readonly pesaje!: Pesaje | null;
  @Prop() readonly mostrarGaleria!: boolean;
  @Prop() readonly permisos!: Permisos;
  @Prop() readonly usuarios!: Usuarios;

  get ruta(): RutaPesaje | null {
    return this.pesaje?.ruta ?? null;
  }

  get vehiculo(): Vehiculo | null {
    return this.pesaje?.vehiculo ?? null;
  }

  get cliente(): Cliente | null {
    return this.pesaje?.cliente ?? null;
  }

  get desecho(): Desecho | null {
    return this.pesaje?.desecho ?? null;
  }

  get servicio(): Servicio | null {
    return this.pesaje?.servicio ?? null;
  }

  get estado(): string {
    return this.pesaje?.estado === "salida" ? "Salida pendiente" : "Finalizado";
  }

  get fechaCreacion(): string {
    return this.pesaje?.created_at?.toDate().format("DD-MM-YYYY, HH:mm") ?? "";
  }

  get creadoPor(): string {
    const id = this.pesaje?.created_by;
    if (!id) return "";
    const usuario = this.usuarios[id];
    if (!usuario) return "";
    return usuario.nombres + " " + usuario.apellidos;
  }

  get fechaEdicion(): string {
    return this.pesaje?.updated_at?.toDate().format("DD-MM-YYYY, HH:mm") ?? "";
  }

  get editadoPor(): string {
    const id = this.pesaje?.updated_by;
    if (!id) return "";
    const usuario = this.usuarios[id];
    if (!usuario) return "";
    return usuario.nombres + " " + usuario.apellidos;
  }

  @Emit("click:ver-imagenes")
  onClickVerImagenes(): void {
    return;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }
}
</script>
