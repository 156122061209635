<template>
  <v-row no-gutters class="pt-10 mr-12 flex-grow-0" align="end">
    <v-col>
      <v-row no-gutters>
        <v-col cols="2" class="pr-2">
          <v-row no-gutters>
            <v-col class="metropolis text--text text-body-1 px-5">
              {{ $t("monitoreo.filtro.periodo.label") }}
            </v-col>
          </v-row>
          <v-row no-gutters class="pt-2">
            <v-col>
              <v-menu
                :close-on-content-click="false"
                v-model="menuPeriodo"
                :close-on-click="filtros.periodo !== 6"
                content-class="elevation-black rounded-input"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    v-on="on"
                    v-bind="attrs"
                    ref="activator-card"
                    class="elevation-black rounded-input card-menu"
                    @click="openMenuPeriodo"
                    :ripple="false"
                  >
                    <v-card-text
                      class="py-3 px-5 metropolis text-body-1 darker--text"
                    >
                      <v-row no-gutters align="center">
                        <v-col>
                          {{ textoPeriodo }}
                        </v-col>
                        <v-col cols="auto">
                          <svg-icon
                            name="ionic-md-arrow-dropdown"
                            color="text"
                            width="12px"
                            height="12px"
                          ></svg-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>
                <v-list v-if="filtros.periodo !== 6">
                  <v-list-item-group v-model="filtros.periodo" mandatory>
                    <v-list-item
                      v-for="(periodo, index) in periodos"
                      :key="`periodo-${index}`"
                      :value="periodo.value"
                      @click="seleccionarPeriodo(periodo.value)"
                    >
                      <v-list-item-title class="metropolis">
                        {{ periodo.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-date-picker
                  v-else
                  :width="widthPeriodo"
                  class="metropolis calendar range"
                  v-model="fechasPeriodo"
                  no-title
                  range
                  :allowed-dates="$allowedDatesBeforeToday"
                >
                  <v-btn
                    class="metropolis text-body-1 text-initial white--text gradient-error rounded-input elevation-error"
                    @click="limpiarPeriodo()"
                  >
                    {{ $t("monitoreo.filtro.periodo.boton-cancelar.text") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="metropolis text-body-1 text-initial white--text gradient-primary rounded-input elevation-primary"
                    @click="confirmarPeriodo()"
                  >
                    {{ $t("monitoreo.filtro.periodo.boton-aplicar.text") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="px-2">
          <v-row no-gutters class="px-5">
            <v-col class="metropolis text--text text-body-1">
              {{ $t("monitoreo.filtro.cliente.label") }}
            </v-col>
          </v-row>
          <v-row no-gutters class="pt-2">
            <v-col>
              <v-select
                solo
                dense
                multiple
                clearable
                hide-details
                return-object
                color="black"
                :placeholder="$t('monitoreo.filtro.cliente.placeholder')"
                item-text="nombre"
                class="rounded-input elevation-input metropolis"
                :items="clientesOrdenados"
                v-model="filtros.clientes"
                :menu-props="{
                  contentClass: 'rounded-input elevation-black',
                  transition: 'slide-y-transition',
                }"
              >
                <template v-slot:item="{ item, attrs }">
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="attrs.inputValue"
                        hide-details
                        dense
                        class="pa-0 ma-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="metropolis text-body-1 text-capitalize">
                      {{ item.nombre }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ index }">
                  <v-col
                    cols="auto"
                    v-if="index === 0"
                    class="text-capitalize text-truncate metropolis text-body-1 pa-0"
                  >
                    {{ textoFiltroClientes }}
                  </v-col>
                </template>
                <template v-slot:append>
                  <svg-icon
                    name="ionic-md-arrow-dropdown"
                    width="12px"
                    height="12px"
                    color="text"
                  ></svg-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="px-2">
          <v-row no-gutters class="px-5">
            <v-col class="metropolis text--text text-body-1">
              {{ $t("monitoreo.filtro.servicio.label") }}
            </v-col>
          </v-row>
          <v-row no-gutters class="pt-2">
            <v-col>
              <v-select
                solo
                dense
                multiple
                clearable
                hide-details
                return-object
                color="black"
                :placeholder="$t('monitoreo.filtro.servicio.placeholder')"
                item-text="nombre"
                class="rounded-input elevation-input metropolis"
                :items="serviciosOrdenados"
                v-model="filtros.servicios"
                :menu-props="{
                  contentClass: 'rounded-input elevation-black',
                  transition: 'slide-y-transition',
                }"
              >
                <template v-slot:item="{ item, attrs }">
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="attrs.inputValue"
                        hide-details
                        dense
                        class="pa-0 ma-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="metropolis text-body-1 text-capitalize">
                      {{ item.nombre }}
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ index }">
                  <v-col
                    cols="auto"
                    v-if="index === 0"
                    class="text-capitalize text-truncate metropolis text-body-1 pa-0"
                  >
                    {{ textoFiltroServicios }}
                  </v-col>
                </template>
                <template v-slot:append>
                  <svg-icon
                    name="ionic-md-arrow-dropdown"
                    width="12px"
                    height="12px"
                    color="text"
                  ></svg-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto" v-if="tab === 1">
      <v-row no-gutters>
        <v-col class="pr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="50px"
                width="50px"
                :color="unidadDesechos === 'kg' ? 'darker' : 'disabled'"
                depressed
                :class="`rounded-input metropolis text-initial text-body-1 font-weight-bold ${
                  unidadDesechos === 'kg' ? 'disabled--text' : 'text--text'
                }`"
                x-small
                v-on="on"
                v-bind="attrs"
                @click="filtros.unidadDesechos = 'kg'"
              >
                {{ $t("monitoreo.filtro.boton-kg.text") }}
              </v-btn>
            </template>
            <span class="metropolis text-body-1">
              {{ $t("monitoreo.filtro.boton-kg.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col class="px-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="50px"
                width="50px"
                :color="unidadDesechos === 't' ? 'darker' : 'disabled'"
                depressed
                :class="`rounded-input metropolis text-initial text-body-1 font-weight-bold ${
                  unidadDesechos === 't' ? 'disabled--text' : 'text--text'
                }`"
                x-small
                v-on="on"
                v-bind="attrs"
                @click="filtros.unidadDesechos = 't'"
              >
                {{ $t("monitoreo.filtro.boton-t.text") }}
              </v-btn>
            </template>
            <span class="metropolis text-body-1">
              {{ $t("monitoreo.filtro.boton-t.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
        <v-col class="pl-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="50px"
                width="50px"
                :color="unidadDesechos === 'm3' ? 'darker' : 'disabled'"
                depressed
                :class="`rounded-input metropolis text-initial text-body-1 font-weight-bold ${
                  unidadDesechos === 'm3' ? 'disabled--text' : 'text--text'
                }`"
                x-small
                @click="filtros.unidadDesechos = 'm3'"
                v-on="on"
                v-bind="attrs"
              >
                {{ $t("monitoreo.filtro.boton-m3.text") }}
              </v-btn>
            </template>
            <span class="metropolis text-body-1">
              {{ $t("monitoreo.filtro.boton-m3.tooltip") }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SvgIcon from "../custom/SvgIcon.vue";
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Filtros } from "@/typings/components/monitoreo/filtros";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FiltrosMonitoreo extends Vue {
  @VModel() filtros!: Filtros;
  @Prop() readonly clientes!: Array<Cliente>;
  @Prop() readonly servicios!: Array<Servicio>;
  @Prop() readonly tab!: number;

  created(): void {
    this.fechasPeriodo = [
      new Date().format("YYYY-MM-DD"),
      new Date().format("YYYY-MM-DD"),
    ];
  }

  get periodos(): Array<{ text: string; value: number }> {
    return [
      {
        text: this.$t("monitoreo.filtro.periodo.value.0") as string,
        value: 1,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.1") as string,
        value: 2,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.2") as string,
        value: 3,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.3") as string,
        value: 4,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.4") as string,
        value: 5,
      },
      {
        text: this.$t("monitoreo.filtro.periodo.value.5") as string,
        value: 6,
      },
    ];
  }

  fechasPeriodo: Array<string> = [];
  menuPeriodo = false;
  widthPeriodo = 0;

  get activatorCard(): HTMLElement | null {
    return (
      (this.$refs["activator-card"] as (Vue & { $el: HTMLElement }) | null)
        ?.$el ?? null
    );
  }

  get textoPeriodo(): string {
    if (this.filtros.periodo === 6) {
      return this.fechasPeriodo
        .map((fecha) => fecha.toDate().format("DD/MM/YY"))
        .join(" ~ ");
    } else {
      return this.filtros.periodo
        ? this.periodos[this.filtros.periodo - 1].text
        : "Selecciona";
    }
  }

  get unidadDesechos(): "kg" | "m3" | "t" {
    return this.filtros.unidadDesechos;
  }

  get filtroFechas(): Array<Date> {
    let fechaInicio: Date | null = new Date();
    let fechaFin: Date | null = new Date();
    switch (this.filtros.periodo) {
      case 1:
        fechaInicio.setDate(fechaInicio.getDate() - 7);
        break;
      case 2:
        fechaInicio.setDate(fechaInicio.getDate() - 30);
        break;
      case 3:
        fechaInicio.setDate(fechaInicio.getDate() - 90);
        break;
      case 4:
        fechaInicio.setMonth(0);
        fechaInicio.setDate(1);
        break;
      case 5:
        fechaInicio.setMonth(0);
        fechaInicio.setDate(1);
        fechaInicio.setFullYear(fechaInicio.getFullYear() - 1);
        fechaFin.setMonth(11);
        fechaFin.setDate(31);
        fechaFin.setFullYear(fechaFin.getFullYear() - 1);
        break;
      case 6:
        return this.filtros.fechas;
      default:
        return [];
    }
    fechaInicio?.setHours(0, 0, 0, 0);
    fechaFin?.setHours(23, 59, 59, 999);
    return [fechaInicio, fechaFin];
  }

  get clientesOrdenados(): Array<Cliente> {
    const clientes = this.clientes.slice();
    clientes.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return clientes;
  }

  get serviciosOrdenados(): Array<Servicio> {
    const servicios = this.servicios.slice();
    servicios.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return servicios;
  }

  get periodoSeleccionado(): number {
    return this.filtros.periodo;
  }

  get diferenciaDiasHoy(): number {
    const fechaHoy = new Date();
    const fechaInicio = this.filtroFechas[0];
    if (!fechaInicio) return 0;
    const diferencia = fechaHoy.getTime() - fechaInicio.getTime();
    return Math.round(diferencia / (1000 * 60 * 60 * 24));
  }

  get textoFiltroClientes(): string {
    const length = this.filtros.clientes.length;
    if (length === 1) {
      return this.filtros.clientes[0].nombre;
    } else {
      return this.$tc("monitoreo.filtro.cliente.selected", length);
    }
  }

  get textoFiltroServicios(): string {
    const length = this.filtros.servicios.length;
    if (length === 1) {
      return this.filtros.servicios[0].nombre;
    } else {
      return this.$tc("monitoreo.filtro.servicio.selected", length);
    }
  }

  @Emit("fetch:pesajes")
  onFetchPesajes(fechas: Array<Date>): Array<Date> {
    return fechas;
  }

  @Watch("fechasPeriodo")
  onFechasPeriodoChange(): void {
    const [fechaInicioString, fechaFinString] = this.fechasPeriodo;
    if (
      fechaInicioString &&
      fechaFinString &&
      fechaInicioString.localeCompare(fechaFinString) > 0
    ) {
      this.fechasPeriodo = [fechaFinString, fechaInicioString];
    }
  }

  @Watch("filtroFechas", { immediate: true })
  onFiltroFechasChange(fechas: Array<Date>): void {
    this.filtros.fechas = fechas;
    if (this.diferenciaDiasHoy > 100) {
      const fechaMenos100 = new Date();
      fechaMenos100.setDate(fechaMenos100.getDate() - 100);
      const fechaInicio = fechas[0];
      if (fechaInicio) {
        this.onFetchPesajes([fechaInicio, fechaMenos100]);
      }
    }
  }

  limpiarPeriodo(): void {
    this.filtros.periodo = 1;
    this.fechasPeriodo = [];
  }

  confirmarPeriodo(): void {
    this.menuPeriodo = false;
    const [fechaInicioString, fechaFinString] = this.fechasPeriodo;
    const fechaInicio = fechaInicioString
      ? fechaInicioString.toDate()
      : new Date();
    fechaInicio.setHours(0, 0, 0, 0);
    const fechaFin = fechaFinString
      ? fechaFinString.toDate()
      : fechaInicioString
      ? fechaInicioString.toDate()
      : new Date();
    fechaFin.setHours(23, 59, 59, 999);
    this.fechasPeriodo = [
      fechaInicio.format("YYYY-MM-DD"),
      fechaFin.format("YYYY-MM-DD"),
    ];
    this.filtros.fechas = [fechaInicio, fechaFin];
  }

  openMenuPeriodo(): void {
    const bounds = this.activatorCard?.getBoundingClientRect();
    this.widthPeriodo = bounds?.width ?? 0;
  }

  seleccionarPeriodo(value: number): void {
    if (value !== 6) this.menuPeriodo = false;
  }
}
</script>
