<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{
                  t("transporte.tab.identificadores.formulario.nombre.label")
                }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t(
                  'transporte.tab.identificadores.formulario.nombre.placeholder'
                )
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="nombre"
              :error-messages="erroresNombre"
              @focus="v$.nombre.$reset()"
              @blur="v$.nombre.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{ t("transporte.tab.identificadores.formulario.epc.label") }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="
                t('transporte.tab.identificadores.formulario.epc.placeholder')
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="epc"
              :error-messages="erroresEPC"
              @focus="v$.epc.$reset()"
              @input="v$.epc.$touch()"
              @blur="v$.epc.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3">
            <v-row no-gutters align="center">
              <v-col cols="11" class="metropolis text-body-1 darker--text">
                {{
                  t("transporte.tab.identificadores.formulario.vehiculo.label")
                }}
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9" class="pl-2">
            <v-select
              solo
              dense
              hide-details="auto"
              :placeholder="
                t(
                  'transporte.tab.identificadores.formulario.vehiculo.placeholder'
                )
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="vehiculo"
              :items="arrayVehiculos"
              return-object
              item-value="id"
              :error-messages="erroresVehiculo"
              @focus="v$.vehiculo.$reset()"
              @blur="v$.vehiculo.$touch()"
              :menu-props="{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }"
            >
              <template v-slot:item="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold text-uppercase"
                >
                  {{ item.patente }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  class="metropolis darker--text text-body-1 font-weight-bold text-uppercase"
                >
                  {{ item.patente }}
                </span>
              </template>
              <template v-slot:append>
                <v-icon color="primary"> mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0 pt-2" justify="center">
      <v-col cols="5">
        <v-btn
          large
          block
          height="48px"
          :class="`rounded-input elevation-primary gradient-primary ${
            cargando ? 'button-disabled' : ''
          }`"
          @click="guardar"
        >
          <svg-icon name="three-dots" v-if="cargando"></svg-icon>
          <v-row v-else no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon
                :name="identificador ? 'ag-save' : 'ag-add'"
                color="white"
              ></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ textoBoton }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Archivo } from "@/typings/store/plugins/easyFirestore/archivos";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import {
  EstadoIdentificador,
  Identificador,
} from "@/typings/store/plugins/easyFirestore/identificadores";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: { SvgIcon },
  props: {
    identificador: {
      default: null,
      type: Object as () => Identificador | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
    arrayVehiculos: {
      default: () => [],
      type: Array as () => Vehiculo[],
    },
  },
  created(): void {
    if (this.identificador) {
      this.id = this.identificador.id ?? "";
      this.nombre = this.identificador.nombre;
      this.epc = this.identificador.epc;
      this.vehiculo = this.identificador.vehiculo;
      this.estado = this.identificador.estado;
      this.fotos = this.identificador.fotos;
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const nombre = ref("");
    const epc = ref("");
    const vehiculo = ref<Vehiculo | null>(null);
    const estado = ref<EstadoIdentificador>("habilitado");
    const fotos = ref<Array<Archivo>>([]);

    const titulo = computed((): string => {
      const index = props.identificador ? 0 : 1;
      const textos = t(
        `transporte.tab.identificadores.formulario.title`
      ) as unknown as string[];
      return textos[index];
    });

    const textoBoton = computed((): string => {
      const index = props.identificador ? 0 : 1;
      const textos = t(
        `transporte.tab.identificadores.formulario.boton.text`
      ) as unknown as string[];
      return textos[index];
    });

    const state = reactive({
      nombre,
      epc,
      vehiculo,
    });

    const rules = {
      nombre: { required },
      epc: { required },
      vehiculo: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.nombre.error-messages[0]"
          ) as string
        );
      if (nombre.value.length > 15)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.nombre.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresEPC = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.epc.$dirty) return errores;
      if (v$.value.epc.required.$invalid)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.epc.error-messages[0]"
          ) as string
        );
      if (epc.value.length > 24)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.epc.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresVehiculo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.vehiculo.$dirty) return errores;
      if (v$.value.vehiculo.required.$invalid)
        errores.push(
          t(
            "transporte.tab.identificadores.formulario.vehiculo.error-message"
          ) as string
        );
      return errores;
    });

    const guardar = (): void => {
      v$.value.$touch();
      if (
        v$.value.$invalid ||
        nombre.value.length > 15 ||
        epc.value.length > 24 ||
        !vehiculo.value
      )
        return;
      const identificador: Identificador = {
        nombre: nombre.value,
        epc: epc.value,
        vehiculo: vehiculo.value,
        estado: estado.value,
        fotos: fotos.value,
      };
      if (id.value) identificador.id = id.value;
      ctx.emit("click:guardar", identificador);
    };

    return {
      t,
      v$,
      id,
      nombre,
      epc,
      vehiculo,
      estado,
      fotos,
      titulo,
      textoBoton,
      erroresNombre,
      erroresEPC,
      erroresVehiculo,
      guardar,
    };
  },
});
</script>
