<template>
  <v-data-table
    :items-per-page="itemsPerPage"
    :headers="headers"
    :items="items"
    :search="search"
    hide-default-footer
    height="calc(96vh - 439px)"
    class="elevation-black white table rounded-input"
    v-resize="onResize"
  >
    <template v-slot:[`item.nombre`]="{ item }">
      <v-col cols="auto" :class="getClasses(item).capitalize">
        {{ item.nombre }}
      </v-col>
    </template>
    <template v-slot:[`item.rut`]="{ item }">
      <v-col cols="auto" :class="getClasses(item).uppercase">
        {{ item.rut }}
      </v-col>
    </template>
    <template v-slot:[`item.cargo`]="{ item }">
      <v-col cols="auto" :class="getClasses(item).capitalize">
        {{ item.cargo }}
      </v-col>
    </template>
    <template v-slot:[`item.contratacion`]="{ item }">
      <v-col cols="auto" :class="getClasses(item).normal">
        {{ item.contratacion }}
      </v-col>
    </template>
    <template v-slot:[`item.opciones`]="{ item }">
      <OpcionesTabla
        :seleccionado="chofer"
        :permisos="permisos"
        :item="item"
        @click:switch="clickSwitchEstado"
        @click:informacion="onClickInformacion"
      ></OpcionesTabla>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer :value="props"></data-table-footer>
    </template>
  </v-data-table>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//helpers
import "@/helpers/extensions";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Permisos } from "@/typings/store/modules/permisos";
import { Item } from "@/typings/components/transporte/choferes";
//componentes
import DataTableFooter from "@/components/custom/DataTableFooter.vue";
import OpcionesTabla from "@/components/transporte/choferes/tabla/Opciones.vue";

@Component({
  components: {
    DataTableFooter,
    OpcionesTabla,
  },
})
export default class TablaChoferes extends Vue {
  @Prop() readonly arrayChoferes!: Array<Chofer>;
  @Prop() readonly search!: string;
  @Prop() readonly chofer!: Chofer | null;
  @Prop() readonly permisos!: Permisos;

  itemsPerPage = 9;

  get mostrarSwitch(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get headers(): Array<{ value: string; text: string; sortable?: boolean }> {
    return [
      {
        text: this.$t("transporte.tab.choferes.tabla.headers[0]") as string,
        value: "nombre",
      },
      {
        text: this.$t("transporte.tab.choferes.tabla.headers[1]") as string,
        value: "rut",
      },
      {
        text: this.$t("transporte.tab.choferes.tabla.headers[2]") as string,
        value: "cargo",
      },
      {
        text: this.$t("transporte.tab.choferes.tabla.headers[3]") as string,
        value: "contratacion",
      },
      {
        text: "",
        value: "opciones",
        sortable: false,
      },
    ];
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.choferes.no-value-message") as string;
  }

  get items(): Array<Item> {
    return this.arrayChoferes.map((chofer) => {
      const id = chofer.id ?? "";
      const nombre = chofer.nombre || this.noValueMessage;
      const rut = chofer.rut || this.noValueMessage;
      const cargo = chofer.cargo || this.noValueMessage;
      const contratacion =
        chofer.fechaContratacion?.toDate()?.format("DD-MM-YYYY") ||
        this.noValueMessage;
      return {
        id,
        nombre,
        rut,
        cargo,
        contratacion,
        chofer,
      };
    });
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(chofer: Chofer): Chofer {
    return chofer;
  }

  @Emit("click:informacion")
  onClickInformacion(chofer: Chofer): Chofer {
    return chofer;
  }

  getClasses({ chofer }: Item): {
    capitalize: string;
    uppercase: string;
    normal: string;
  } {
    const seleccionado = this.chofer?.id === chofer.id;
    return {
      capitalize: `pa-0 metropolis text-center text-body-1 text-capitalize ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      uppercase: `pa-0 metropolis text-center text-body-1 text-uppercase ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
      normal: `pa-0 metropolis text-center text-body-1 ${
        seleccionado ? "primary--text font-weight-bold" : ""
      }`,
    };
  }

  clickSwitchEstado({ chofer }: Item): void {
    this.onClickSwitchEstado(chofer);
  }

  onResize(): void {
    const height = window.innerHeight;
    this.itemsPerPage = Math.floor((height * 0.96 - 439) / 50);
  }
}
</script>

<style scoped></style>
