<template>
  <div class="d-flex flex-column fill-height">
    <v-row align="center" justify="center" no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("transporte.tab.identificadores.detalle.title") }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.identificadores.detalle.label.0") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ nombre }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.identificadores.detalle.label.1") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ epc }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.identificadores.detalle.label.2") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-uppercase">
            {{ vehiculo }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.identificadores.detalle.label.3") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ estado }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0">
      <v-col cols="6" class="pr-2" v-if="mostrarBotonEditar">
        <v-btn
          block
          large
          height="48px"
          class="rounded-input elevation-warning gradient-warning"
          @click="onClickEditar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon name="ag-edit" color="white"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("transporte.tab.identificadores.detalle.boton-editar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="6" class="pl-2" v-if="mostrarBotonEliminar">
        <v-btn
          block
          large
          height="48px"
          class="rounded-input elevation-error gradient-error"
          @click="onClickEliminar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <v-icon color="white"> mdi-delete </v-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("transporte.tab.identificadores.detalle.boton-eliminar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { Identificador } from "@/typings/store/plugins/easyFirestore/identificadores";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class InfoRfid extends Vue {
  @Prop() readonly identificador!: Identificador;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.transporte === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.identificadores.no-value-message") as string;
  }

  get nombre(): string {
    return this.identificador.nombre || this.noValueMessage;
  }

  get epc(): string {
    return this.identificador.epc || this.noValueMessage;
  }

  get vehiculo(): string {
    return this.identificador.vehiculo?.patente || this.noValueMessage;
  }

  get estado(): string {
    return this.identificador.estado || this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
</script>
