<template>
  <v-card height="100%" class="rounded-input elevation-black">
    <v-card-text class="pa-0 fill-height">
      <v-img
        v-if="fotoSeleccionada"
        :src="fotoSeleccionada.url"
        class="rounded-input"
        height="100%"
        width="100%"
      >
        <v-row no-gutters class="fill-height">
          <v-col class="d-flex flex-column">
            <v-row no-gutters justify="end" class="px-4 pt-4">
              <v-col cols="auto">
                <v-btn
                  x-small
                  width="45px"
                  height="45px"
                  depressed
                  class="gradient-error rounded-input"
                  @click="fotoSeleccionada = null"
                >
                  <v-icon color="white"> mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters align="end" class="py-2" justify="center">
              <v-col cols="auto">
                <v-btn
                  class="rounded-input elevation-error gradient-error"
                  @click="clickEliminar"
                >
                  <v-row no-gutters justify="center">
                    <v-col cols="auto" class="pr-2">
                      <v-icon color="white"> mdi-delete </v-icon>
                    </v-col>
                    <v-col
                      class="white--text font-weight-bold metropolis text-initial text-body-1 pl-2"
                    >
                      {{ $t("pesajes.galeria-imagenes.boton-eliminar.text") }}
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-img>
      <div v-else class="d-flex flex-column fill-height">
        <v-row no-gutters justify="end" class="px-4 pt-4 flex-grow-0">
          <v-col cols="auto">
            <v-btn
              x-small
              width="45px"
              height="45px"
              depressed
              class="gradient-error rounded-input"
              @click="onClickCerrar"
            >
              <v-icon color="white"> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2" v-if="fotos.length > 0">
          <v-col
            cols="3"
            v-for="(foto, index) in fotos"
            :key="`foto-${index}`"
            class="pa-2"
          >
            <v-card flat class="rounded-input" @click="seleccionarFoto(foto)">
              <v-img :src="foto.url" class="rounded-input"></v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else no-gutters class="pt-3 px-2" justify="center">
          <v-col cols="auto" class="text--text metropolis text-body-1">
            {{ $t("pesajes.galeria-imagenes.no-data-message") }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          v-if="editar"
          justify="center"
          class="flex-grow-0 py-2"
        >
          <input
            type="file"
            ref="inputFile"
            hidden
            @change="onChangeFileHandler"
          />
          <v-col cols="auto">
            <v-btn
              class="rounded-input elevation-primary gradient-primary"
              @click="clickAgregar"
            >
              <v-row no-gutters justify="center">
                <v-col cols="auto" class="pr-2">
                  <svg-icon name="ags-camera" color="white"></svg-icon>
                </v-col>
                <v-col
                  class="white--text font-weight-bold metropolis text-initial text-body-1 pl-2"
                >
                  {{ $t("pesajes.galeria-imagenes.boton-agregar.text") }}
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
//decoradores
import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
// helpers
import { imageToWebp } from "@/helpers/image";
//tipos
import { Pesaje } from "@/typings/store/plugins/easyFirestore/pesajes";
import {
  Archivo,
  Archivos,
  BlobArchivo,
} from "@/typings/store/plugins/easyFirestore/archivos";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class GaleriaImagenes extends Vue {
  @VModel() imagenes!: {
    nuevas: Array<BlobArchivo>;
    eliminar: Array<Archivo>;
  };
  @Prop() readonly pesaje!: Pesaje;
  @Prop() readonly archivos!: Archivos;
  @Prop({ type: Boolean }) readonly editar!: boolean;
  @Prop() readonly usuario!: Usuario;

  created(): void {
    this.fotosPesaje = this.pesaje.fotos;
  }

  fotoSeleccionada: Archivo | BlobArchivo | null = null;
  fotosPesaje: Array<Archivo> = [];

  get fotos(): Array<Archivo | BlobArchivo> {
    const fotosPesaje = this.fotosPesaje.filter(
      (foto) =>
        !this.imagenes.eliminar.some((eliminar) => eliminar.id === foto.id)
    );
    const fotosNuevas = this.imagenes.nuevas.map((foto) => ({
      ...foto,
      url: URL.createObjectURL(foto.file),
    }));
    return [...fotosPesaje, ...fotosNuevas];
  }

  get inputFile(): HTMLInputElement {
    return this.$refs.inputFile as HTMLInputElement;
  }

  @Watch("pesaje")
  onPesajeChange(): void {
    this.fotoSeleccionada = null;
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }

  seleccionarFoto(foto: Archivo | BlobArchivo): void {
    this.fotoSeleccionada = foto;
  }

  clickAgregar(): void {
    this.inputFile.click();
  }

  clickEliminar(): void {
    const foto = this.fotoSeleccionada;
    if ((foto as Archivo)?.id) {
      this.imagenes.eliminar.push(foto as Archivo);
    } else {
      const index = this.imagenes.nuevas.indexOf(foto as BlobArchivo);
      this.imagenes.nuevas.splice(index, 1);
    }
    this.fotoSeleccionada = null;
  }

  async onChangeFileHandler(event: Event): Promise<void> {
    const file = (event.target as HTMLInputElement | null)?.files?.[0];
    if (file) {
      const imagenRaw = file;
      const fecha = new Date();
      const extencion = imagenRaw.type.split("/")[1];
      let nombre = `${fecha.valueOf()}.${extencion}`;
      let imagen = await imageToWebp(imagenRaw);
      if (imagen) {
        nombre = `${fecha.valueOf()}.webp`;
      } else {
        imagen = imagenRaw;
      }
      const fechaCarpeta = new Date(fecha);
      fechaCarpeta.setHours(0, 0, 0, 0);
      const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
      const ref = `pesajes/${nombreCarpeta}/${nombre}`;
      this.imagenes.nuevas.push({
        ref: ref,
        file: imagen,
        nombre: nombre,
      });
    }
  }
}
</script>

<style scoped></style>
