<template>
  <v-row no-gutters class="row-buttons flex-grow-0" align="end">
    <v-col cols="3">
      <v-btn
        block
        :height="tab === 0 ? '50px' : '44px'"
        depressed
        :color="tab==0 ?'yellow':'disabled'"
        :class="{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'disabled-light--text tab-inactive': tab !== 0
        }"
        @click="tab = 0"
      >
        {{ $t("barredores.tab.barredores.text") }}
      </v-btn>
    </v-col>
    <v-col cols="3" v-if="isAdmin">
      <v-btn
        :color="tab==1 ? 'purple':'disabled'"
        block
        :height="tab === 1 ? '50px' : '44px'"
        depressed
        :class="{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          'disabled-light--text tab-inactive': tab !== 1
        }"
        @click="tab = 1"
      >
        {{ $t("barredores.tab.rutas.text") }}
      </v-btn>
    </v-col>
    <v-col cols="3" v-if="isAdmin">
      <v-btn
        :color="tab==2 ? 'primary':'disabled'"
        block
        :height="tab === 2 ? '50px' : '44px'"
        depressed
        :class="{
          'rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button': true,
          ' disabled-light--text tab-inactive': tab !== 2
        }"
        @click="tab = 2"
      >
        {{ $t("barredores.tab.monitoreo.text") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";

@Component
export default class TabsBarredores extends Vue {
  @VModel() tab!: number;
  @Prop() readonly usuario!: Usuario;

  get isAdmin(): boolean {
    return this.usuario.tipo === "administrador";
  }
}
</script>

<style scoped></style>
