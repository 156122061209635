<template>
  <div class="d-flex flex-column fill-height">
    <v-row align="center" justify="center" no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ $t("transporte.tab.choferes.detalle.title") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-2 flex-grow-0">
      <v-col>
        <SubirImagen
          :preview="chofer.foto ? chofer.foto.url : ''"
          only-preview
        ></SubirImagen>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.choferes.detalle.label.0") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ nombre }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.choferes.detalle.label.1") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-uppercase">
            {{ rut }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.choferes.detalle.label.2") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ cargo.capitalizeFirstLetter() }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.choferes.detalle.label.3") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ nacimiento }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.choferes.detalle.label.4") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ contratacion }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.choferes.detalle.label.5") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis">
            {{ contacto }}
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4" align="center" justify="center">
          <v-col cols="3" class="text-truncate metropolis">
            {{ $t("transporte.tab.choferes.detalle.label.6") }}
          </v-col>
          <v-col cols="9" class="font-weight-bold metropolis text-capitalize">
            {{ estado }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0 pb-2">
      <v-col cols="6" class="pr-2" v-if="mostrarBotonEditar">
        <v-btn
          block
          large
          height="48px"
          class="rounded-input elevation-warning gradient-warning"
          @click="onClickEditar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon name="ag-edit" color="white"></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("transporte.tab.choferes.detalle.boton-editar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="6" class="pl-2" v-if="mostrarBotonEliminar">
        <v-btn
          block
          large
          height="48px"
          class="rounded-input elevation-error gradient-error"
          @click="onClickEliminar"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <v-icon color="white"> mdi-delete </v-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("transporte.tab.choferes.detalle.boton-eliminar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//tipos
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { Permisos } from "@/typings/store/modules/permisos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";

@Component({
  components: {
    SvgIcon,
    SubirImagen,
  },
})
export default class DetalleChofer extends Vue {
  @Prop() readonly chofer!: Chofer;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonEditar(): boolean {
    return this.permisos.transporte !== "ver";
  }

  get mostrarBotonEliminar(): boolean {
    return this.permisos.transporte === "eliminar";
  }

  get noValueMessage(): string {
    return this.$t("transporte.tab.choferes.no-value-message") as string;
  }

  get nombre(): string {
    return this.chofer.nombre;
  }

  get rut(): string {
    return this.chofer.rut ?? this.noValueMessage;
  }

  get cargo(): string {
    return this.chofer.cargo ?? this.noValueMessage;
  }

  get nacimiento(): string {
    return (
      this.chofer.fechaNacimiento?.toDate()?.format("DD-MM-YYYY") ??
      this.noValueMessage
    );
  }

  get contratacion(): string {
    return (
      this.chofer.fechaContratacion?.toDate()?.format("DD-MM-YYYY") ??
      this.noValueMessage
    );
  }

  get contacto(): string {
    return this.chofer.contacto ?? this.noValueMessage;
  }

  get estado(): string {
    return this.chofer.estado ?? this.noValueMessage;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
</script>
