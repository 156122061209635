<template>
  <v-container class="pa-0 ma-0">
    <v-row no-gutters :justify="onlyPreview ? 'center' : 'end'" align="end">
      <v-col cols="4" class="d-flex flex-column align-center">
        <v-card
          color="disabled"
          width="150px"
          :height="height"
          class="rounded-input elevation-black"
        >
          <v-container class="fill-height pa-0">
            <v-row
              no-gutters
              class="fill-height"
              align="center"
              justify="center"
            >
              <input type="file" hidden ref="input" @change="subirImagen" />
              <v-col cols="auto">
                <v-img
                  :src="preview"
                  v-if="preview"
                  width="150px"
                  :height="height"
                  class="rounded-input"
                />
                <svg-icon
                  v-else
                  :src="require('@/assets/svg/icons/ags/ags-image.svg')"
                  color="white"
                  width="110px"
                  :height="iconHeight"
                ></svg-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="4" v-if="!onlyPreview">
        <v-btn
          class="gradient-warning rounded-input elevation-warning"
          @click="clickSeleccionarImagen"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-1">
              <svg-icon
                :src="require('@/assets/svg/icons/ags/ags-camera.svg')"
                color="white"
                width="16px"
                height="16px"
              ></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="font-weight-bold white--text metropolis text-body-1 text-initial pl-1"
            >
              {{ textoBoton }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import SvgIcon from "./custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class SubirImagen extends Vue {
  @VModel() file!: File | null;
  @Prop() readonly preview?: string;
  @Prop({ default: "150px" }) readonly height!: string | number;
  @Prop({ default: "110px" }) readonly iconHeight!: string | number;
  @Prop({ type: Boolean }) readonly onlyPreview!: boolean;
  @Prop({ type: Boolean }) readonly nueva!: boolean;

  get textoBoton(): string {
    const index = this.nueva ? 0 : 1;
    return this.$t(`subir-imagen.boton.texto.${index}`) as string;
  }

  get input(): HTMLInputElement {
    return this.$refs.input as HTMLInputElement;
  }

  clickSeleccionarImagen(): void {
    this.input.click();
  }

  subirImagen(payload: Event): void {
    const input = payload.target as HTMLInputElement;
    this.file = input.files?.[0] ?? null;
  }
}
</script>
