<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3" class="pr-2">
            <v-row no-gutters justify="space-between">
              <v-col cols="auto" class="metropolis font-weight-bold">
                {{ $t("desechos.formulario.nombre.label") }}
              </v-col>
              <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="t('desechos.formulario.nombre.placeholder')"
              class="rounded-input elevation-input-dense metropolis"
              v-model="nombre"
              :error-messages="nombreErrors"
              @focus="v$.nombre.$reset()"
              @blur="v$.nombre.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col cols="3" class="metropolis text-body-1 font-weight-bold">
            {{ $t("desechos.formulario.descripcion.label") }}
          </v-col>
          <v-col cols="9">
            <v-text-field
              solo
              dense
              hide-details="auto"
              :placeholder="t('desechos.formulario.descripcion.placeholder')"
              class="rounded-input elevation-input-dense metropolis"
              v-model="descripcion"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0 pt-2" justify="center">
      <v-col cols="6" class="pr-2">
        <v-btn
          block
          large
          :class="`rounded-input elevation-primary gradient-primary ${
            crearDisabled ? 'button-inactive button-disabled' : ''
          }`"
          @click="guardar"
        >
          <svg-icon name="three-dots" v-if="cargando"></svg-icon>
          <v-row v-else no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon
                :name="desecho ? 'ag-save' : 'desechos'"
                color="white"
              ></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t(`desechos.formulario.boton.text.${desecho ? "0" : "1"}`) }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="6" class="pl-2" v-if="desecho">
        <v-btn
          block
          large
          class="rounded-input elevation-error gradient-error button-disabled button-inactive"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <v-icon color="white"> mdi-delete</v-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ $t("desechos.formulario.boton-eliminar.text") }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    desecho: {
      default: null,
      type: Object as () => Desecho | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    const desecho = this.desecho;
    if (desecho?.id) {
      this.id = desecho.id;
      this.nombre = desecho.nombre;
      this.descripcion = desecho.descripcion ?? "";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref<string | null>(null);
    const nombre = ref("");
    const tipo = ref<string | null>(null);
    const descripcion = ref("");
    const flag = ref(true);

    const titulo = computed((): string => {
      const index = props.desecho ? 1 : 0;
      return t(`desechos.formulario.title.${index}`) as string;
    });

    const state = reactive({
      nombre,
    });

    const rules = {
      nombre: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid) {
        const error = t(
          "desechos.formulario.nombre.error-messages.0"
        ) as string;
        errores.push(error);
      }
      if (nombre.value.length > 20) {
        const error = t(
          "desechos.formulario.nombre.error-messages.1"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const crearDisabled = computed((): boolean => {
      if (props.desecho) return false;
      return !nombre.value || nombreErrors.value.length > 0;
    });

    const onClickGuardar = (data: Desecho) => {
      ctx.emit("click:guardar", data);
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      flag.value = false;
      const desecho: Desecho = {
        nombre: nombre.value.toLowerCase(),
        descripcion: descripcion.value.toLowerCase(),
      };
      if (props.desecho) desecho.id = props.desecho.id;
      onClickGuardar(desecho);
    };

    return {
      t,
      v$,
      id,
      nombre,
      tipo,
      descripcion,
      flag,
      titulo,
      nombreErrors,
      crearDisabled,
      guardar,
    };
  },
});
</script>
