<template>
  <v-card color="dialog" class="rounded-input elevation-dark">
    <v-card-text>
      <v-row no-gutters class="pt-4" align="center">
        <v-col
          cols="8"
          offset="2"
          class="text-h6 metropolis white--text text-center text-capitalize font-weight-bold"
        >
          {{ titulo }}
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            x-small
            width="44px"
            height="44px"
            class="gradient-error elevation-error rounded-input"
            @click="onClickCerrar"
          >
            <v-icon color="white"> mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3">
        <v-col>
          <GmapMap
            :center="center"
            :zoom="17"
            :options="{
              clickableIcons: false,
              draggableCursor: 'default',
            }"
            map-type-id="terrain"
            class="google-map"
            @click="mapClickHandler"
          >
            <GmapPolyline
              :path.sync="path"
              :options="{
                strokeColor: '#ff0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }"
              @click="polylineClickHandler"
            ></GmapPolyline>
            <GmapMarker v-if="ultima" :position="ultima"></GmapMarker>
          </GmapMap>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3" align="center" justify="space-between">
        <v-col>
          <v-row no-gutters align="center">
            <v-col
              cols="4"
              class="white--text font-weight-bold metropolis text-body-1"
            >
              {{ $t("barredores.tab.barredores.nuevaRuta.barredor.label") }}
            </v-col>
            <v-col cols="8" class="pl-2">
              <v-select
                dense
                outlined
                :placeholder="
                  $t('barredores.tab.barredores.nuevaRuta.barredor.placeholder')
                "
                class="rounded-input border-input metropolis"
                dark
                v-model="barredor"
                hide-details="auto"
                :items="barredores"
                :error-messages="erroresBarredor"
                return-object
                item-value="id"
              >
                <template v-slot:item="{ item }">
                  <span
                    class="metropolis text-body-1 darker--text text-capitalize"
                  >
                    {{ item.numero }} - {{ item.nombre }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span
                    class="metropolis text-body-1 text-truncate white--text text-capitalize"
                  >
                    {{ item.numero }} - {{ item.nombre }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-2 pt-md-0 px-3">
          <v-row no-gutters align="center" justify="center">
            <v-col
              cols="4"
              class="white--text font-weight-bold metropolis text-body-1"
            >
              {{ $t("barredores.tab.barredores.nuevaRuta.fecha.label") }}
            </v-col>
            <v-col cols="8">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
                transition="slide-y-transition"
                content-class="rounded-input elevation-black"
              >
                <template v-slot:activator="{ on, attrs, value }">
                  <div class="d-flex flex-column">
                    <v-card
                      v-on="on"
                      v-bind="attrs"
                      outlined
                      class="border-grey rounded-input card-menu background-transparent"
                      :ripple="false"
                    >
                      <v-card-text
                        class="py-2 px-5 metropolis white--text text-body-1"
                      >
                        <v-row no-gutters align="center">
                          <v-col class="text-truncate" cols="11">
                            {{ textoFecha }}
                          </v-col>
                          <v-col cols="1" class="text-right">
                            <svg-icon
                              :class="`select-icon ${value ? 'selected' : ''}`"
                              name="ionic-md-arrow-dropdown"
                              color="text"
                              width="12px"
                              height="12px"
                            ></svg-icon>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <span
                      v-if="erroresFecha.length > 0"
                      class="metropolis font-size-12 error--text"
                    >
                      {{ erroresFecha.join(", ") }}
                    </span>
                  </div>
                </template>
                <v-date-picker
                  v-model="fecha"
                  no-title
                  @input="menu = false"
                  class="metropolis calendar"
                  :allowed-dates="$allowedDatesBeforeToday"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-2 pt-md-0">
          <v-row no-gutters justify="end" align="center">
            <v-col
              cols="3"
              class="white--text metropolis font-weight-bold text-body-1"
            >
              {{ $t("barredores.tab.rutas.formulario.posicion.label") }}
            </v-col>
            <v-col class="px-2">
              <v-text-field
                hide-details
                dense
                outlined
                :placeholder="
                  $t('barredores.tab.rutas.formulario.posicion.placelholder')
                "
                class="metropolis rounded-input border-input"
                :value="ultima ? `${ultima.lat}, ${ultima.lng}` : ''"
                readonly
                dark
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn
                x-small
                width="40px"
                height="40px"
                class="rounded-input border-grey gradient-error"
                @click="eliminarUltimo"
                depressed
              >
                <v-icon color="white"> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center mt-6">
        <v-col cols="4">
          <v-btn
            @click="guardar"
            block
            large
            class="text-initial white--text metropolis text-body-1 font-weight-bold gradient-primary elevation-dark rounded-input"
          >
            {{ $t("barredores.tab.barredores.nuevaRuta.button-add.text") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import {
  PingRastreador,
  Posicion,
} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

declare interface GoogleMapClickEvent {
  latLng: {
    lat: () => number;
    lng: () => number;
  };
}

export default defineComponent({
  components: { SvgIcon },
  props: {
    empresa: {
      default: null,
      type: Object as () => Empresa | null,
    },
    barredores: {
      default: () => [],
      type: Array as () => Barredor[],
    },
  },
  created(): void {
    if (this.empresa?.posicion) {
      this.center = this.empresa.posicion;
    }
  },
  emits: ["click:cerrar", "click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const center = ref({
      lat: -35.4320809,
      lng: -71.6546965,
    });
    const menu = ref(false);
    const path = ref<Array<Posicion>>([]);
    const barredor = ref<Barredor | null>(null);
    const fecha = ref("");

    const titulo = computed((): string => {
      return t("barredores.tab.barredores.nuevaRuta.titulo") as string;
    });

    const ultima = computed((): Posicion | null => {
      return path.value.length > 0 ? path.value[path.value.length - 1] : null;
    });

    const textoFecha = computed((): string => {
      return (
        (fecha.value ? fecha.value.toDate().format("DD/MM/YYYY") : null) ||
        (t("barredores.tab.barredores.nuevaRuta.fecha.placeholder") as string)
      );
    });

    const state = reactive({
      barredor,
      fecha,
    });

    const rules = {
      barredor: { required },
      fecha: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresBarredor = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.barredor.$dirty) return errores;
      if (v$.value.barredor.required.$invalid) {
        const error = t(
          "barredores.tab.barredores.nuevaRuta.barredor.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresFecha = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fecha.$dirty) return errores;
      if (v$.value.fecha.required.$invalid) {
        const error = t(
          "barredores.tab.barredores.nuevaRuta.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onClickCerrar = (): void => {
      ctx.emit("click:cerrar");
    };

    const onClickGuardar = (data: Array<PingRastreador>): void => {
      ctx.emit("click:guardar", data);
    };

    const mapClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const polylineClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const eliminarUltimo = (): void => {
      const temp = path.value.slice();
      temp.pop();
      path.value = temp;
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || !barredor.value) return;
      const pings: Array<PingRastreador> = [];
      const fechaTemp = fecha.value.toDate();
      fechaTemp.setHours(7, 0, 0, 0);
      for (const posicion of path.value) {
        const ping: PingRastreador = {
          imei: barredor.value.rastreador?.imei ?? "",
          fecha: fechaTemp.toISOString(),
          posicion: posicion,
          empresa: props.empresa?.id ?? "",
          orientacion: 1,
          valido: true,
          velocidad: 4,
          barredores: [barredor.value],
        };
        pings.push(ping);
        fechaTemp.setMinutes(fechaTemp.getMinutes() + 1);
      }
      onClickGuardar(pings);
    };

    return {
      t,
      v$,
      center,
      menu,
      path,
      barredor,
      fecha,
      titulo,
      ultima,
      textoFecha,
      erroresBarredor,
      erroresFecha,
      onClickCerrar,
      onClickGuardar,
      mapClickHandler,
      polylineClickHandler,
      eliminarUltimo,
      guardar,
    };
  },
});
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 960px) {
  .google-map {
    height: 35vh;
  }
}

.background-transparent {
  background-color: transparent !important;
}
</style>
