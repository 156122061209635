<template>
  <v-img
    class="mt-lg-16 mt-8"
    :src="require('../../assets/webp/background-footer.webp')"
    :lazy-src="require('../../assets/webp/background-footer-lazy.webp')"
  >
    <v-row
      no-gutters
      align="center"
      class="mx-percent mt-lg-31 mt-25"
      justify-lg="space-around"
      justify="center"
    >
      <v-col cols="auto" lg="3">
        <svg-icon
          :src="require('../../assets/svg/sistema/logotipo-white.svg')"
          :width="isMobile ? '240px' : '323px'"
          height="auto"
        ></svg-icon>
      </v-col>
      <v-col cols="12" lg="4">
        <v-row no-gutters class="pt-lg-0 pt-16">
          <v-col
            cols="12"
            lg="6"
            v-for="informacion in informaciones"
            :key="`informacion-${informacion.id}`"
          >
            <v-row
              no-gutters
              :class="
                informacion.id > 2 || (isMobile && informacion.id > 1)
                  ? 'mt-lg-4 mt-8'
                  : ''
              "
            >
              <v-col
                class="text-center secondary--text font-size-18 font-weight-bold poppins"
              >
                {{ informacion.titulo }}
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
              <v-col
                class="text-center white--text font-size-16 poppins line-break"
              >
                {{ informacion.texto }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="auto" class="pt-10 pt-lg-0">
        <div class="corfo">
          <div class="corfo__container">
            <div class="corfo__text">
              {{ $t("pagina.footer.corfo.titulo") }}
            </div>
            <img
              class="corfo__imagen"
              :alt="String($t('pagina.footer.corfo.alt-corfo'))"
              src="../../assets/png/one-page/corfo.png"
            />
          </div>
          <img
            class="corfo__gobierno"
            src="../../assets/png/one-page/gobierno.png"
            :alt="String($t('pagina.footer.corfo.alt-gobierno'))"
          >
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-8 mt-lg-21 mt-25">
      <v-col class="text-center white--text poppins font-size-13">
        {{ $t("pagina.footer.copyright") }}
      </v-col>
    </v-row>
  </v-img>
</template>

<script lang="ts">
//decoradores
import { Component, Vue } from "vue-property-decorator";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: { SvgIcon },
})
export default class FooterPagina extends Vue {
  get informaciones(): Array<{ id: number; titulo: string; texto: string }> {
    return [
      {
        id: 1,
        titulo: this.$t("pagina.footer.informacion.direccion.titulo") as string,
        texto: this.$t("pagina.footer.informacion.direccion.texto") as string,
      },
      {
        id: 2,
        titulo: this.$t("pagina.footer.informacion.email.titulo") as string,
        texto: this.$t("pagina.footer.informacion.email.texto") as string,
      },
      {
        id: 3,
        titulo: this.$t("pagina.footer.informacion.horario.titulo") as string,
        texto: this.$t("pagina.footer.informacion.horario.texto") as string,
      },
      {
        id: 4,
        titulo: this.$t("pagina.footer.informacion.telefono.titulo") as string,
        texto: this.$t("pagina.footer.informacion.telefono.texto") as string,
      },
    ];
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile;
  }
}
</script>

<style scoped>
@media screen and (min-width: 1264px) {
  .mt-lg-31 {
    margin-top: 124px !important;
  }

  .mt-lg-21 {
    margin-top: 84px !important;
  }
}

.mt-25 {
  margin-top: 100px !important;
}

.corfo {
  display: grid;
  column-gap: 2.5rem;
  align-items: center;
  grid-template-columns: auto auto;
}

.corfo__container {
  display: grid;
  row-gap: 0.75rem;
  align-items: flex-start;
}

.corfo__text {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1rem;
  font-family: "Gob", sans-serif;
}

.corfo__imagen {
  width: 9.9375rem;
}
</style>
