<template>
  <v-row no-gutters justify="space-between" align="end">
    <v-col cols="8">
      <v-row no-gutters align="end">
        <v-col cols="4" class="pr-2">
          <v-text-field
            hide-details
            dense
            solo
            clearable
            color="text"
            :placeholder="$t('servicios.search.placeholder')"
            class="metropolis rounded-input elevation-input"
            v-model="search"
          >
            <template v-slot:append>
              <svg-icon name="ag-search" color="text"></svg-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="3" class="pl-2">
          <v-row no-gutters>
            <v-col class="metropolis text--text text-body-1 px-5">
              {{ $t("servicios.filtro.tipo.label") }}
            </v-col>
          </v-row>
          <v-row no-gutters class="pt-2">
            <v-col>
              <v-select
                dense
                solo
                clearable
                hide-details
                color="text"
                :placeholder="$t('servicios.filtro.tipo.placeholder')"
                class="metropolis rounded-input elevation-input"
                :items="tipos"
                v-model="filtroTipo"
                :menu-props="{
                  contentClass: 'rounded-input elevation-black',
                  transition: 'slide-y-transition',
                }"
              >
                <template v-slot:item="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span class="metropolis darker--text text-body-1">
                    {{ item.text }}
                  </span>
                </template>
                <template v-slot:append>
                  <svg-icon
                    name="ionic-md-arrow-dropdown"
                    color="text"
                    width="12px"
                    height="12px"
                  ></svg-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="auto" v-if="mostrarBotonAgregar">
      <add-button
        icon="servicios"
        :disabled="buttonDisabled"
        @click="onClickAgregar"
      >
        {{ $t("servicios.boton-add.text") }}
      </add-button>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";
//tipos
import { Permisos } from "@/typings/store/modules/permisos";
import { TipoServicio } from "../../typings/store/plugins/easyFirestore/servicios";
import { FiltrosServicio } from "../../typings/components/servicios/filtros";
//componentes
import AddButton from "@/components/custom/AddButton.vue";
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    AddButton,
    SvgIcon,
  },
})
export default class FiltrosServicios extends Vue {
  @VModel() filtros!: FiltrosServicio;
  @Prop() buttonDisabled!: boolean;
  @Prop() readonly permisos!: Permisos;

  get mostrarBotonAgregar(): boolean {
    return this.permisos.servicios !== "ver";
  }

  get tipos(): Array<{ text: string; value: TipoServicio }> {
    return [
      {
        text: this.$t("servicios.filtro.tipo.values[0]") as string,
        value: "Egreso",
      },
      {
        text: this.$t("servicios.filtro.tipo.values[1]") as string,
        value: "Ingreso",
      },
    ];
  }

  get search(): string {
    return this.filtros.search;
  }

  set search(value: string) {
    this.filtros.search = value;
  }

  get filtroTipo(): TipoServicio | null {
    return this.filtros.tipo;
  }

  set filtroTipo(value: TipoServicio | null) {
    this.filtros.tipo = value;
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }
}
</script>
