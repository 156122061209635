<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    hide-default-footer
    height="calc(100% - 89px)"
    :items-per-page="9"
    class="elevation-black white fill-height table rounded-input"
  >
    <template v-slot:[`item.imei`]="{item}">
      <table-item
        :value="item.imei"
        :classes="getClasses(item, true)"
      ></table-item>
    </template>
    <template v-slot:[`item.marca`]="{item}">
      <table-item
        :value="item.marca"
        :classes="getClasses(item)"
      ></table-item>
    </template>
    <template v-slot:[`item.modelo`]="{item}">
      <table-item
        :value="item.modelo"
        :classes="getClasses(item)"
      ></table-item>
    </template>
    <template v-slot:[`item.tipo`]="{item}">
      <table-item
        :value="item.tipo"
        :classes="getClasses(item)"
      ></table-item>
    </template>
    <template v-slot:[`item.opciones`]="{item}">
      <OpcionesTabla
        :item="item"
        :seleccionado="rastreador"
        @click:informacion="onClickInformacion"
        @click:switch="onClickSwitch"
      ></OpcionesTabla>
    </template>
    <template v-slot:footer="{ props }">
      <data-table-footer :value="props"></data-table-footer>
    </template>
  </v-data-table>
</template>

<script lang="ts">
//componsition
import {computed, defineComponent} from "vue";
//i18n
import i18n from "@/plugins/i18n";
//tipos
import {Rastreador} from "@/typings/store/plugins/easyFirestore/rastreadores";
import {Item} from "@/typings/components/rastreadores/tabla";
//components
import TableItem from "@/components/custom/TableItem.vue";
import OpcionesTabla from "@/components/rastreadores/tabla/Opciones.vue";
import DataTableFooter from "@/components/custom/DataTableFooter.vue"

export default defineComponent({
  name: "TablaRastreadores",
  components: {
    DataTableFooter,
    OpcionesTabla,
    TableItem
  },
  emits: [
    "click:informacion",
    "click:switch"
  ],
  props: {
    search: {
      type: String,
      default: ""
    },
    rastreadores: {
      type: Array as () => Rastreador[],
      required: true
    },
    rastreador: {
      type: Object as () => Rastreador | null,
    }
  },
  setup(props, ctx) {
    const noRegistrado = computed(() => {
      return i18n.t("rastreadores.tabla.no-registrado") as string;
    });

    const headers = computed(() => {
      return [{
        text: i18n.t('rastreadores.tabla.headers.0') as string,
        value: "imei"
      }, {
        text: i18n.t('rastreadores.tabla.headers.1') as string,
        value: "marca"
      }, {
        text: i18n.t('rastreadores.tabla.headers.2') as string,
        value: "modelo"
      }, {
        text: i18n.t('rastreadores.tabla.headers.3') as string,
        value: "tipo"
      }, {
        text: "",
        value: "opciones",
        sortable: false
      }];
    })

    const items = computed(() => {
      return props.rastreadores.map(rastreador => {
        const id = rastreador.id ?? "";
        const imei = rastreador.imei ?? noRegistrado.value;
        const marca = rastreador.marca ?? noRegistrado.value;
        const modelo = rastreador.modelo ?? noRegistrado.value;
        const tipo = rastreador.tipo ?? noRegistrado.value;
        return {
          id,
          imei,
          marca,
          modelo,
          tipo,
          rastreador
        };
      });
    });

    const getClasses = ({id}: Item, uppercase?: boolean): string => {
      const classes = [`pa-0 metropolis text-center text-body-1 text-${uppercase ? "uppercase" : "capitalize"}`];
      if (props.rastreador?.id === id) classes.push("primary--text font-weight-bold");
      return classes.join(" ");
    }

    const onClickInformacion = ({rastreador}: Item) => {
      ctx.emit("click:informacion", rastreador);
    }

    const onClickSwitch = ({rastreador}: Item) => {
      ctx.emit("click:switch", rastreador);
    }

    return {
      headers,
      items,
      getClasses,
      onClickInformacion,
      onClickSwitch
    };
  }
});
</script>
