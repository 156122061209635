<template>
  <v-container class="py-7" v-if="pageCount > 1">
    <v-row no-gutters justify="center" align="center" v-if="pageCount > 0">
      <v-col cols="auto">
        <v-btn small icon :disabled="currentPage === 1" @click="previousPage">
          <svg-icon
            name="ionic-md-chevron-left"
            width="20px"
            height="20px"
            color="disabled"
          ></svg-icon>
        </v-btn>
      </v-col>
      <v-col
        v-for="page in pages"
        :key="`page-button-${page}`"
        cols="auto"
        class="px-2"
      >
        <v-btn
          x-small
          width="39px"
          height="39px"
          :color="currentPage === page ? 'secondary' : 'disabled'"
          :class="{
            'pa-0 rounded-input': true,
            'gradient-secondary elevation-secondary white--text':
              currentPage === page,
            'elevation-black text--text': currentPage !== page,
          }"
          @click="model.options.page = page"
        >
          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto" class="font-weight-bold metropolis text-body-1">
              {{ page }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          small
          icon
          :disabled="currentPage === pageCount"
          @click="nextPage"
        >
          <svg-icon
            name="ionic-md-chevron-right"
            width="20px"
            height="20px"
            color="disabled"
          ></svg-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
//composition
import { computed, defineComponent } from "vue";
//tipos
import { DataTableFooterProps } from "@/typings/vuetify/v-data-table";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "DataTableFooter",
  props: {
    value: {
      type: Object as () => DataTableFooterProps,
      required: true,
    },
  },
  components: {
    SvgIcon,
  },
  emits: ["input"],
  setup(props, ctx) {
    const model = computed({
      get() {
        return props.value;
      },
      set(value: DataTableFooterProps) {
        ctx.emit("input", value);
      },
    });

    const currentPage = computed(() => model.value.pagination.page);
    const pageCount = computed(() => model.value.pagination.pageCount);

    const pages = computed(() => {
      const page = currentPage.value;
      if (page === 1) {
        const pages = [page, page + 1, page + 2];
        return pages.filter((p) => p <= pageCount.value);
      } else if (page === pageCount.value) {
        const pages = [page - 2, page - 1, page];
        return pages.filter((p) => p > 0);
      } else {
        const pages = [page - 1, page, page + 1];
        return pages.filter((p) => p > 0 && p <= pageCount.value);
      }
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        model.value.options.page = currentPage.value - 1;
      }
    };

    const nextPage = () => {
      if (currentPage.value < pageCount.value) {
        model.value.options.page = currentPage.value + 1;
      }
    };

    return {
      model,
      currentPage,
      pageCount,
      pages,
      previousPage,
      nextPage,
    };
  },
});
</script>
