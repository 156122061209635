<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters justify="center" align="center">
      <v-col
        cols="auto"
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-2">
      <v-col>
        <SubirImagen
          v-model="file"
          :preview="foto"
          :nueva="!chofer"
        ></SubirImagen>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-2">
      <v-col cols="3">
        <v-row no-gutters align="center">
          <v-col cols="11" class="metropolis text-body-1 darker--text">
            {{ t("transporte.tab.choferes.formulario.nombre.label") }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
        </v-row>
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-text-field
          solo
          dense
          hide-details="auto"
          :placeholder="
            t('transporte.tab.choferes.formulario.nombre.placeholder')
          "
          class="rounded-input elevation-input-dense metropolis font-weight-bold"
          v-model="nombre"
          :error-messages="erroresNombre"
          @focus="v$.nombre.$reset()"
          @blur="v$.nombre.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters class="pt-2">
      <v-col cols="3">
        <v-row no-gutters align="center">
          <v-col cols="11" class="metropolis text-body-1 darker--text">
            {{ t("transporte.tab.choferes.formulario.rut.label") }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
        </v-row>
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-text-field
          solo
          dense
          hide-details="auto"
          :placeholder="t('transporte.tab.choferes.formulario.rut.placeholder')"
          class="rounded-input elevation-input-dense metropolis font-weight-bold"
          v-model="rut"
          v-mask="['#######-X', '########-X']"
          :error-messages="erroresRut"
          @focus="v$.rut.$reset()"
          @blur="v$.rut.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="3">
        <v-row no-gutters align="center">
          <v-col cols="11" class="metropolis text-body-1 darker--text">
            {{ t("transporte.tab.choferes.formulario.cargo.label") }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
        </v-row>
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-text-field
          solo
          dense
          hide-details="auto"
          :placeholder="
            t('transporte.tab.choferes.formulario.cargo.placeholder')
          "
          class="rounded-input elevation-input-dense metropolis font-weight-bold"
          v-model="cargo"
          :error-messages="erroresCargo"
          @focus="v$.cargo.$reset()"
          @blur="v$.cargo.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="3">
        <v-row no-gutters align="center">
          <v-col cols="11" class="metropolis text-body-1 darker--text">
            {{ t("transporte.tab.choferes.formulario.fecha-nacimiento.label") }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
        </v-row>
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-menu
          v-model="menuFechaNacimiento"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="slide-y-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          content-class="rounded-input elevation-black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              solo
              dense
              readonly
              hide-details
              :placeholder="
                t(
                  'transporte.tab.choferes.formulario.fecha-nacimiento.placeholder'
                )
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="fechaNacimientoMostrar"
              v-on="on"
              v-bind="attrs"
              :error-messages="erroresFechaNacimiento"
              @focus="v$.fechaNacimiento.$reset()"
              @blur="v$.fechaNacimiento.$touch()"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-es"
            class="calendar"
            v-model="fechaNacimiento"
            @input="menuFechaNacimiento = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="3">
        <v-row no-gutters align="center">
          <v-col cols="11" class="metropolis text-body-1 darker--text">
            {{
              t("transporte.tab.choferes.formulario.fecha-contratacion.label")
            }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pa-0 error--text metropolis"> * </v-col>
        </v-row>
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-menu
          v-model="menuFechaContratacion"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="slide-y-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          content-class="rounded-input elevation-black"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              solo
              dense
              readonly
              hide-details
              :placeholder="
                t(
                  'transporte.tab.choferes.formulario.fecha-contratacion.placeholder'
                )
              "
              class="rounded-input elevation-input-dense metropolis font-weight-bold"
              v-model="fechaContratacionMostrar"
              v-on="on"
              v-bind="attrs"
              :error-messages="erroresFechaContratacion"
              @focus="v$.fechaContratacion.$reset()"
              @blur="v$.fechaContratacion.$touch()"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-es"
            class="calendar"
            v-model="fechaContratacion"
            @input="menuFechaContratacion = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col cols="3" class="metropolis text-body-1 darker--text">
        {{ t("transporte.tab.choferes.formulario.telefono.label") }}
      </v-col>
      <v-col cols="9" class="pl-2">
        <v-text-field
          solo
          dense
          hide-details="auto"
          :placeholder="
            t('transporte.tab.choferes.formulario.telefono.placeholder')
          "
          class="rounded-input elevation-input-dense metropolis font-weight-bold"
          v-model="contacto"
          v-mask="'+56#########'"
          :error-messages="erroresContacto"
          @focus="v$.contacto.$reset()"
          @blur="v$.contacto.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0 pt-2 pb-1" justify="center">
      <v-col cols="4">
        <v-btn
          large
          block
          height="48px"
          :class="`rounded-input elevation-primary gradient-primary ${
            cargando ? 'button-disabled' : ''
          }`"
          @click="guardar"
        >
          <svg-icon name="three-dots" v-if="cargando"></svg-icon>
          <v-row v-else no-gutters justify="center" align="center">
            <v-col cols="auto" class="pr-2">
              <svg-icon
                :name="chofer ? 'ag-save' : 'ag-add'"
                color="white"
              ></svg-icon>
            </v-col>
            <v-col
              cols="auto"
              class="pl-2 white--text text-initial metropolis font-weight-bold text-body-1"
            >
              {{ textoBoton }}
            </v-col>
          </v-row>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// helpers
import { imageToWebp } from "@/helpers/image";
//tipos
import {
  Chofer,
  EstadoChofer,
} from "@/typings/store/plugins/easyFirestore/choferes";
import { DataFormularioChofer } from "@/typings/components/choferes/formulario";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import SubirImagen from "@/components/SubirImagen.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
    SubirImagen,
  },
  props: {
    chofer: {
      default: null,
      type: Object as () => Chofer | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
  },
  created(): void {
    if (this.chofer) {
      this.id = this.chofer.id ?? "";
      this.nombre = this.chofer.nombre;
      this.rut = this.chofer.rut ?? "";
      this.cargo = this.chofer.cargo ?? "";
      this.fechaNacimiento =
        this.chofer.fechaNacimiento?.toDate()?.format("YYYY-MM-DD") ?? "";
      this.fechaContratacion =
        this.chofer.fechaContratacion?.toDate()?.format("YYYY-MM-DD") ?? "";
      this.contacto = this.chofer.contacto ?? "";
      this.estado = this.chofer.estado ?? "habilitado";
    }
  },
  emits: ["click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const id = ref("");
    const nombre = ref("");
    const rut = ref("");
    const cargo = ref("");
    const fechaNacimiento = ref("");
    const menuFechaNacimiento = ref(false);
    const fechaContratacion = ref("");
    const menuFechaContratacion = ref(false);
    const contacto = ref("+56");
    const estado = ref<EstadoChofer>("habilitado");
    const file = ref<File | null>(null);

    const titulo = computed((): string => {
      const index = props.chofer ? 0 : 1;
      return t(`transporte.tab.choferes.formulario.title.${index}`) as string;
    });

    const textoBoton = computed((): string => {
      const index = props.chofer ? 0 : 1;
      return t(
        `transporte.tab.choferes.formulario.boton.text.${index}`
      ) as string;
    });

    const foto = computed((): string => {
      return file.value
        ? URL.createObjectURL(file.value)
        : props.chofer?.foto?.url ?? "";
    });

    const fechaContratacionMostrar = computed((): string => {
      return fechaContratacion.value
        ? fechaContratacion.value.toDate().format("DD/MM/YYYY")
        : "";
    });

    const fechaNacimientoMostrar = computed((): string => {
      return fechaNacimiento.value
        ? fechaNacimiento.value.toDate().format("DD/MM/YYYY")
        : "";
    });

    const state = reactive({
      nombre,
      rut,
      cargo,
      fechaNacimiento,
      fechaContratacion,
      contacto,
    });

    const rules = {
      nombre: { required },
      rut: { required },
      cargo: { required },
      fechaNacimiento: { required },
      fechaContratacion: { required },
      contacto: { required },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.nombre.error-messages[0]"
          ) as string
        );
      if (nombre.value.length > 30)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.nombre.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresRut = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rut.$dirty) return errores;
      if (v$.value.rut.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.rut.error-messages[0]"
          ) as string
        );
      if (!rut.value.validateRUT())
        errores.push(
          t(
            "transporte.tab.choferes.formulario.rut.error-messages[1]"
          ) as string
        );
      return errores;
    });

    const erroresCargo = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.cargo.$dirty) return errores;
      if (v$.value.cargo.required.$invalid)
        errores.push(
          t("transporte.tab.choferes.formulario.cargo.error-message") as string
        );
      return errores;
    });

    const erroresFechaNacimiento = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaNacimiento.$dirty) return errores;
      if (v$.value.fechaNacimiento.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.fecha-nacimiento.error-message"
          ) as string
        );
      return errores;
    });

    const erroresFechaContratacion = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaContratacion.$dirty) return errores;
      if (v$.value.fechaContratacion.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.fecha-contratacion.error-message"
          ) as string
        );
      return errores;
    });

    const erroresContacto = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.contacto.$dirty) return errores;
      if (v$.value.contacto.required.$invalid)
        errores.push(
          t(
            "transporte.tab.choferes.formulario.telefono.error-message"
          ) as string
        );
      return errores;
    });

    const guardar = async (): Promise<void> => {
      v$.value.$touch();
      if (
        v$.value.$invalid ||
        !rut.value.validateRUT() ||
        nombre.value.length > 30
      )
        return;
      const chofer: Chofer = {
        nombre: nombre.value,
        rut: rut.value,
        cargo: cargo.value,
        fechaNacimiento: fechaNacimiento.value.toDate().toISOString(),
        fechaContratacion: fechaContratacion.value.toDate().toISOString(),
        contacto: contacto.value,
        estado: estado.value,
        foto: null,
      };
      if (id.value) chofer.id = id.value;
      const data: DataFormularioChofer = { chofer };
      if (file.value) {
        const imagenRaw = file.value;
        const fecha = new Date();
        const extencion = imagenRaw.type.split("/")[1];
        let nombre = `${fecha.valueOf()}.${extencion}`;
        let imagen = await imageToWebp(imagenRaw);
        if (imagen) {
          nombre = `${fecha.valueOf()}.webp`;
        } else {
          imagen = imagenRaw;
        }
        const fechaCarpeta = new Date(fecha);
        fechaCarpeta.setHours(0, 0, 0, 0);
        const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
        const ref = `choferes/${nombreCarpeta}/${nombre}`;
        data.archivo = {
          ref: ref,
          file: imagen,
          nombre: nombre,
        };
      }
      ctx.emit("click:guardar", data);
    };

    return {
      t,
      v$,
      id,
      nombre,
      rut,
      cargo,
      fechaNacimiento,
      menuFechaNacimiento,
      fechaContratacion,
      menuFechaContratacion,
      contacto,
      estado,
      file,
      titulo,
      textoBoton,
      foto,
      fechaContratacionMostrar,
      fechaNacimientoMostrar,
      erroresNombre,
      erroresRut,
      erroresCargo,
      erroresFechaNacimiento,
      erroresFechaContratacion,
      erroresContacto,
      guardar,
    };
  },
});
</script>
