<template>
  <v-row no-gutters align="center" justify="center">
    <v-col cols="3" class="text-truncate metropolis">
      {{ label }}
    </v-col>
    <v-col cols="9" :class="`font-weight-bold metropolis text-${textCase} line-break`">
      {{ value }}
    </v-col>
  </v-row>
</template>

<script lang="ts">
//componsition
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'InformationText',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    textCase: {
      type: String,
      default: "capitalize"
    }
  }
});
</script>
