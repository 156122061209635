<template>
  <v-card color="#FAFAFA" class="rounded-input elevation-black" height="221px">
    <div class="d-flex flex-column fill-height">
      <v-row no-gutters class="flex-grow-0 pt-2">
        <v-col class="black--text font-weight-bold metropolis pl-2">
          {{ $t("monitoreo.tab.desechos.grafico-3.title") }}
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col>
          <DonutChart
            :series="series"
            :labels="labels"
            :formatter="formatter"
          ></DonutChart>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script lang="ts">
//decoradores
import { Component, Prop, Vue } from "vue-property-decorator";
//tipos
import { PesajeMonitoreoDesecho } from "@/typings/components/monitoreo/desecho";
//componentes
import DonutChart from "@/components/monitoreo/charts/DonutChart.vue";

@Component({
  components: { DonutChart },
})
export default class TipoDesechos extends Vue {
  @Prop() readonly items!: Array<PesajeMonitoreoDesecho>;
  @Prop() readonly unidad?: string;

  get helper(): { [key: string]: number } {
    const helper: { [key: string]: number } = {};
    for (const pesaje of this.items) {
      const tipo = pesaje.tipoDesecho;
      if (tipo) {
        helper[tipo] = (helper[tipo] || 0) + pesaje.valor;
      }
    }
    return helper;
  }

  get series(): Array<number> {
    const values = Object.values(this.helper);
    values.sort((a, b) => b - a);
    return values.slice(0, 5);
  }

  get labels(): Array<string> {
    const keys = Object.keys(this.helper);
    keys.sort((a, b) => this.helper[b] - this.helper[a]);
    return keys.slice(0, 5).map((key) => key.capitalizeFirstLetter());
  }

  formatter(value: number): string {
    if (this.unidad === "t") {
      return `${(value / 1000).toFixed(1)} T`;
    } else {
      return `${value} ${this.unidad}`;
    }
  }
}
</script>
