<template>
  <v-container>
    <v-row no-gutters class="pt-0 pb-11" justify="center">
      <v-col cols="auto">
        <svg-icon
          :src="require('../assets/svg/sistema/logotipo.svg')"
          width="560px"
          height="150%"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-11">
      <v-col class="text-center primary--text titulo-terminos">
        {{ $t("terminos.title") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-14">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.0.parrafo-1") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.0.parrafo-2") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-6">
      <v-col class="primary--text subtitulo-terminos">
        {{ $t("terminos.body.1.subtitle") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.1.parrafo-1") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-6">
      <v-col class="primary--text subtitulo-terminos">
        {{ $t("terminos.body.2.subtitle") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.2.parrafo-1") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.2.parrafo-2") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-6">
      <v-col class="primary--text subtitulo-terminos">
        {{ $t("terminos.body.3.subtitle") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.3.parrafo-1") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.3.parrafo-2") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-6">
      <v-col class="primary--text subtitulo-terminos">
        {{ $t("terminos.body.4.subtitle") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.4.parrafo-1") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-6">
      <v-col class="primary--text subtitulo-terminos">
        {{ $t("terminos.body.5.subtitle") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.5.parrafo-1") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.5.parrafo-2") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4">
      <v-col class="text-justify cuerpo-terminos">
        {{ $t("terminos.body.5.parrafo-3") }}
      </v-col>
    </v-row>
    <v-row no-gutters class="py-16" justify="center">
      <v-col cols="4">
        <v-btn
          block
          height="59px"
          color="secondary"
          class="rounded-input metropolis font-size-20 font-weight-bold elevation-button"
          @click="$router.push({ name: 'login' })"
        >
          {{ $t("terminos.boton-volver.text") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class TerminosCondiciones extends Vue {}
</script>

<style scoped>
.titulo-terminos {
  font-size: 40px !important;
  font-family: "Metropolis", sans-serif !important;
  font-weight: bold !important;
  line-height: initial !important;
}

.subtitulo-terminos {
  font-size: 30px !important;
  font-family: "Metropolis", sans-serif !important;
  font-weight: bold !important;
  line-height: initial !important;
}
.cuerpo-terminos {
  font-size: 30px !important;
  font-family: "Metropolis", sans-serif !important;
  font-weight: normal !important;
  line-height: 1.3 !important;
}

.elevation-button {
  box-shadow: 8px 15px 19px rgba(25, 72, 90, 0.18);
  letter-spacing: 1px;
}
</style>
