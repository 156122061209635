<template>
  <v-card color="dialog" class="rounded-input elevation-dark">
    <v-card-text>
      <v-row no-gutters class="pt-4" align="center">
        <v-col
          cols="8"
          offset="2"
          class="text-h6 white--text font-weight-bold metropolis text-center text-capitalize"
        >
          {{ titulo }}
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            x-small
            width="44px"
            height="44px"
            class="gradient-error elevation-error rounded-input"
            @click="onClickCerrar"
          >
            <v-icon color="white"> mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3" align="center">
        <v-col cols="3" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold white--text">
              {{ $t("barredores.tab.barredores.formulario.nombre.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 white--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-text-field
            dark
            dense
            outlined
            hide-details="auto"
            :placeholder="
              t('barredores.tab.barredores.formulario.nombre.placeholder')
            "
            class="rounded-input border-input metropolis"
            v-model="nombre"
            :error-messages="nombreErrors"
            @focus="v$.nombre.$reset()"
            @blur="v$.nombre.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3" align="center">
        <v-col cols="3" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold white--text">
              {{
                t("barredores.tab.barredores.formulario.identificador.label")
              }}
            </v-col>
            <v-col cols="auto" class="pa-0 white--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-text-field
            dark
            :placeholder="
              t(
                'barredores.tab.barredores.formulario.identificador.placeholder'
              )
            "
            hide-details="auto"
            dense
            outlined
            v-model.number="numero"
            class="rounded-input border-input metropolis"
            v-mask="['#####']"
            :error-messages="numeroErrors"
            @focus="v$.numero.$reset()"
            @blur="v$.numero.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3" align="center">
        <v-col cols="3" class="metropolis font-weight-bold white--text">
          {{ $t("barredores.tab.barredores.formulario.telefono.label") }}
        </v-col>
        <v-col>
          <v-text-field
            dark
            :placeholder="
              t('barredores.tab.barredores.formulario.telefono.placeholder')
            "
            hide-details="auto"
            dense
            outlined
            v-model.number="telefono"
            class="rounded-input border-input metropolis"
            v-mask="['+56#########']"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3" align="center">
        <v-col cols="3" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold white--text">
              {{ $t("barredores.tab.barredores.formulario.rastreador.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 white--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-select
            dark
            :placeholder="
              t('barredores.tab.barredores.formulario.rastreador.placeholder')
            "
            hide-details="auto"
            dense
            outlined
            :items="rastreadores"
            v-model="rastreador"
            item-value="id"
            return-object
            :error-messages="rastreadorErrors"
            class="metropolis rounded-input border-input"
            @focus="v$.rastreador.$reset()"
            @blur="v$.rastreador.$touch()"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:selection="{ item }">
              <span class="metropolis">
                {{ item.imei }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <span class="metropolis">
                {{ item.imei }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3" align="center">
        <v-col cols="3" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold white--text">
              {{ $t("barredores.tab.barredores.formulario.hora-inicio.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 white--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-text-field
            dark
            dense
            outlined
            hide-details="auto"
            :placeholder="
              t('barredores.tab.barredores.formulario.hora-inicio.placeholder')
            "
            class="metropolis fonbold rounded-input border-input"
            v-model="horaInicio"
            type="time"
            :error-messages="horaInicioErrors"
            @focus="v$.horaInicio.$reset()"
            @blur="v$.horaInicio.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3" align="center">
        <v-col cols="3" class="pr-2">
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="metropolis font-weight-bold white--text">
              {{ $t("barredores.tab.barredores.formulario.hora-fin.label") }}
            </v-col>
            <v-col cols="auto" class="pa-0 white--text metropolis"> * </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-text-field
            dark
            dense
            outlined
            hide-details="auto"
            :placeholder="
              t('barredores.tab.barredores.formulario.hora-fin.placeholder')
            "
            type="time"
            class="metropolis fonbold rounded-input border-input"
            v-model="horaFin"
            :error-messages="horaFinErrors"
            @focus="v$.horaFin.$reset()"
            @blur="v$.horaFin.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3" align="center">
        <v-col cols="3" class="metropolis font-weight-bold white--text">
          {{ $t("barredores.tab.barredores.formulario.ruta.label") }}
        </v-col>
        <v-col class="white--text">
          <v-select
            dark
            dense
            outlined
            return-object
            :placeholder="
              t('barredores.tab.barredores.formulario.ruta.placeholder')
            "
            class="rounded-input border-input metropolis"
            hide-details="auto"
            :items="arrayRutas"
            v-model="rutaSelect"
            :menu-props="{
              contentClass: 'rounded-input elevation-black',
              transition: 'slide-y-transition',
            }"
          >
            <template v-slot:selection="{ item }">
              <span class="metropolis text-capitalize">
                {{ item.text }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <span class="metropolis text-capitalize">
                {{ item.text }}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3" align="center">
        <v-col cols="3" class="metropolis font-weight-bold white--text">
          {{ $t("barredores.tab.barredores.formulario.color.label") }}
        </v-col>
        <v-col cols="auto">
          <v-menu
            offset-y
            content-class="rounded-input elevation-black"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters justify-md="end" align="center">
                <v-col class="pr-2" cols="auto">
                  <v-btn
                    depressed
                    color="purple"
                    v-bind="attrs"
                    v-on="on"
                    class="text-initial metropolis font-weight-bold elevation-dark white--text rounded-input"
                  >
                    {{
                      t("barredores.tab.barredores.formulario.color.text-boton")
                    }}
                  </v-btn>
                </v-col>
                <v-col class="pl-2" cols="auto">
                  <v-card
                    flat
                    class="rounded-input"
                    :color="color"
                    width="2rem"
                    height="2rem"
                  ></v-card>
                </v-col>
              </v-row>
            </template>
            <v-color-picker
              show-swatches
              hide-sliders
              hide-mode-switch
              hide-inputs
              hide-canvas
              v-model="color"
            ></v-color-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center mt-6">
        <v-col cols="4">
          <v-btn
            @click="guardar"
            block
            large
            class="text-initial metropolis font-weight-bold white--text elevation-dark gradient-primary rounded-input"
          >
            {{ $t("barredores.tab.barredores.formulario.boton-guardar.text") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { RutaBarredor } from "@/typings/store/plugins/easyFirestore/rutasBarredor";
import { Barredor } from "@/typings/store/plugins/easyFirestore/barredores";
import { Rastreador } from "@/typings/store/plugins/easyFirestore/rastreadores";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  props: {
    barredor: {
      default: null,
      type: Object as () => Barredor | null,
    },
    barredores: {
      default: () => [],
      type: Array as () => Barredor[],
    },
    rutas: {
      default: () => [],
      type: Array as () => RutaBarredor[],
    },
    rastreadores: {
      default: () => [],
      type: Array as () => Rastreador[],
    },
  },
  created(): void {
    if (this.barredor) {
      this.id = this.barredor.id ?? "";
      this.nombre = this.barredor.nombre;
      this.color = this.barredor.color ?? "#FF0000";
      this.ruta = this.barredor.ruta ?? null;
      this.numero = this.barredor.numero ?? 0;
      this.rastreador = this.barredor.rastreador ?? null;
      const horarioSplit = this.barredor.horario.split("-");
      this.horaInicio = horarioSplit[0];
      this.horaFin = horarioSplit[1];
      this.telefono = this.barredor.telefono ?? "";
    }
  },
  emits: ["click:cerrar", "click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const rastreador = ref<Rastreador | null>(null);
    const horaInicio = ref("00:00");
    const horaFin = ref("23:59");
    const numero = ref(0);
    const id = ref("");
    const nombre = ref("");
    const color = ref("#FF0000");
    const telefono = ref("");
    const ruta = ref<RutaBarredor | null>(null);

    const numeroRepetido = computed((): boolean => {
      return props.barredores.some(
        (barredor) =>
          barredor.numero === numero.value && barredor.id !== id.value
      );
    });

    const titulo = computed((): string => {
      const index = props.barredor ? 0 : 1;
      return t(`barredores.tab.barredores.formulario.title.${index}`) as string;
    });

    const rutaSelect = computed({
      get: () => {
        return {
          value: ruta.value?.id ?? null,
          text: ruta.value?.nombre ?? "Sin ruta",
        };
      },
      set: (data: { value: string | null; text: string }) => {
        if (data?.value) {
          ruta.value = props.rutas.find((r) => r.id === data.value) ?? null;
        } else {
          ruta.value = null;
        }
      },
    });

    const arrayRutas = computed(
      (): Array<{ value: string | null; text: string }> => {
        const rutas: Array<{ value: string | null; text: string }> =
          props.rutas.map((ruta) => ({
            value: ruta.id ?? "",
            text: ruta.nombre,
          }));
        rutas.unshift({
          value: null,
          text: t(
            "barredores.tab.barredores.formulario.ruta.no-value-message"
          ) as string,
        });
        return rutas;
      }
    );

    const state = reactive({
      nombre,
      numero,
      rastreador,
      horaInicio,
      horaFin,
    });

    const rules = {
      nombre: { required },
      numero: { required },
      rastreador: { required },
      horaInicio: { required },
      horaFin: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errors;
      if (v$.value.nombre.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.nombre.error-message"
          ) as string
        );
      return errors;
    });

    const rastreadorErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.rastreador.$dirty) return errors;
      if (v$.value.rastreador.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.rastreador.error-message"
          ) as string
        );
      return errors;
    });

    const numeroErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.numero.$dirty) return errors;
      if (v$.value.numero.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.identificador.error-message.0"
          ) as string
        );
      if (numeroRepetido.value)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.identificador.error-message.1"
          ) as string
        );
      return errors;
    });

    const horaInicioErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.horaInicio.$dirty) return errors;
      if (v$.value.horaInicio.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.hora-inicio.error-message"
          ) as string
        );
      return errors;
    });

    const horaFinErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.horaFin.$dirty) return errors;
      if (v$.value.horaFin.required.$invalid)
        errors.push(
          t(
            "barredores.tab.barredores.formulario.hora-fin.error-message"
          ) as string
        );
      return errors;
    });

    const onClickCerrar = (): void => {
      ctx.emit("click:cerrar");
    };

    const onClickGuardar = (doc: Barredor): void => {
      ctx.emit("click:guardar", doc);
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || numeroRepetido.value) return;
      const barredor: Barredor = {
        nombre: nombre.value,
        id: id.value,
        color: color.value,
        ruta: ruta.value,
        numero: numero.value || 0,
        horario: `${horaInicio.value}-${horaFin.value}`,
        mostrarRuta: false,
        telefono: telefono.value,
        rastreador: rastreador.value,
      };
      onClickGuardar(barredor);
    };

    return {
      t,
      v$,
      rastreador,
      horaInicio,
      horaFin,
      numero,
      id,
      nombre,
      color,
      telefono,
      ruta,
      rutaSelect,
      arrayRutas,
      nombreErrors,
      rastreadorErrors,
      numeroErrors,
      horaInicioErrors,
      horaFinErrors,
      titulo,
      numeroRepetido,
      onClickCerrar,
      guardar,
    };
  },
});
</script>

<style>
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(100%) !important;
}
</style>
