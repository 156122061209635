<template>
  <div class="mx-percent mt-lg-35 mt-16">
    <v-row no-gutters align="center">
      <v-col cols="12" lg="6">
        <v-row no-gutters>
          <v-col
            class="font-size-lg-60 font-size-41 poppins font-weight-medium dialogDark--text text-lg-right text-center line-height-1"
          >
            {{ $t("pagina.contacto.titulo") }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            class="font-size-lg-60 font-size-36 poppins font-weight-bold success--text text-lg-right text-center line-height-1"
          >
            {{ $t("pagina.contacto.subtitulo") }}
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pl-lg-22 pt-lg-0 pt-16">
        <v-row no-gutters align="center">
          <v-col
            cols="3"
            class="poppins dialogDark--text font-size-16 font-weight-medium"
          >
            {{ $t("pagina.contacto.formulario.nombre.label") }}
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="nombre"
              hide-details="auto"
              class="input-contacto poppins dialogDark--text"
              :error-messages="erroresNombre"
              @focus="v$.nombre.$reset()"
              @blur="v$.nombre.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-5">
          <v-col
            cols="3"
            class="poppins dialogDark--text font-size-16 font-weight-medium"
          >
            {{ $t("pagina.contacto.formulario.telefono.label") }}
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="telefono"
              hide-details="auto"
              v-mask="'+56#########'"
              class="input-contacto poppins dialogDark--text"
              :error-messages="erroresTelefono"
              @focus="v$.telefono.$reset()"
              @blur="v$.telefono.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-5">
          <v-col
            cols="3"
            class="poppins dialogDark--text font-size-16 font-weight-medium"
          >
            {{ $t("pagina.contacto.formulario.email.label") }}
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="mail"
              hide-details="auto"
              class="input-contacto poppins dialogDark--text"
              :error-messages="erroresEmail"
              @focus="v$.mail.$reset()"
              @blur="v$.mail.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-5">
          <v-col
            cols="3"
            class="poppins dialogDark--text font-size-16 font-weight-medium"
          >
            {{ $t("pagina.contacto.formulario.rut-empresa.label") }}
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="rutEmpresa"
              hide-details="auto"
              v-mask="['#######-X', '########-X']"
              class="input-contacto poppins dialogDark--text"
              :error-messages="erroresRutEmpresa"
              @focus="v$.rutEmpresa.$reset()"
              @blur="v$.rutEmpresa.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-5">
          <v-col
            cols="3"
            class="poppins dialogDark--text font-size-16 font-weight-medium"
          >
            {{ $t("pagina.contacto.formulario.empresa.label") }}
          </v-col>
          <v-col>
            <v-select
              dense
              outlined
              v-model="tipoEmpresa"
              :items="tiposEmpresa"
              :placeholder="t('pagina.contacto.formulario.empresa.placeholder')"
              class="input-contacto poppins dialogDark--text"
              :menu-props="{
                contentClass: 'rounded-input elevation-black',
                transition: 'slide-y-transition',
              }"
              hide-details="auto"
              :error-messages="erroresTipoEmpresa"
              @focus="v$.tipoEmpresa.$reset()"
              @blur="v$.tipoEmpresa.$touch()"
            >
              <template v-slot:item="{ item }">
                <span class="poppins font-size-16 dialogDark--text">
                  {{ item }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span class="poppins font-size-16 dialogDark--text">
                  {{ item }}
                </span>
              </template>
              <template v-slot:append>
                <v-row
                  no-gutters
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-col>
                    <svg-icon
                      name="ag-caret-down"
                      color="dialogDark"
                      width="12px"
                      height="12px"
                    ></svg-icon>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-lg-16 mt-8" justify="center">
      <v-col cols="auto">
        <v-btn
          color="secondary"
          depressed
          class="border-radius-23 font-size-13 poppins font-weight-medium"
          x-large
          height="46px"
          width="186px"
          @click="enviar"
        >
          {{ $t("pagina.contacto.formulario.boton-enviar.texto") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { Mail } from "@/typings/store/plugins/easyFirestore/mail";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  emits: ["click:enviar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const nombre = ref("");
    const telefono = ref("");
    const mail = ref("");
    const rutEmpresa = ref("");
    const tipoEmpresa = ref("");

    const tiposEmpresa = computed((): Array<string> => {
      return [
        t("pagina.contacto.formulario.empresa.opciones.0") as string,
        t("pagina.contacto.formulario.empresa.opciones.1") as string,
        t("pagina.contacto.formulario.empresa.opciones.2") as string,
        t("pagina.contacto.formulario.empresa.opciones.3") as string,
        t("pagina.contacto.formulario.empresa.opciones.4") as string,
        t("pagina.contacto.formulario.empresa.opciones.5") as string,
      ];
    });

    const state = reactive({
      nombre,
      telefono,
      mail,
      rutEmpresa,
      tipoEmpresa,
    });

    const rules = {
      nombre: {
        required,
      },
      telefono: {
        required,
      },
      mail: {
        required,
        email,
      },
      rutEmpresa: {
        required,
      },
      tipoEmpresa: {
        required,
      },
    };

    const v$ = useVuelidate(rules, state);

    const erroresNombre = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.nombre.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTelefono = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.telefono.$dirty) return errores;
      if (v$.value.telefono.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.telefono.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresEmail = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.mail.$dirty) return errores;
      if (v$.value.mail.required.$invalid) {
        const error = t("pagina.contacto.formulario.email.errores.0") as string;
        errores.push(error);
      }
      if (v$.value.mail.email) {
        const error = t("pagina.contacto.formulario.email.errores.1") as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresRutEmpresa = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.rutEmpresa.$dirty) return errores;
      if (v$.value.rutEmpresa.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.rut-empresa.errores.0"
        ) as string;
        errores.push(error);
      }
      if (!rutEmpresa.value.validateRUT()) {
        const error = t(
          "pagina.contacto.formulario.rut-empresa.errores.1"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTipoEmpresa = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.tipoEmpresa.$dirty) return errores;
      if (v$.value.tipoEmpresa.required.$invalid) {
        const error = t(
          "pagina.contacto.formulario.empresa.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onClickEnviar = (mail: Mail): void => {
      ctx.emit("click:enviar", mail);
    };

    const enviar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid || !rutEmpresa.value.validateRUT()) return;
      const nuevo: Mail = {
        to: "contacto@weightruck.cl",
        message: {
          subject: "Contacto desde la web",
          html: `
          <p><b>Nombre:</b> ${nombre.value}</p>
          <p><b>Telefono:</b> ${telefono.value}</p>
          <p><b>Email:</b> ${mail.value}</p>
          <p><b>RUT Empresa:</b> ${rutEmpresa.value}</p>
          <p><b>Tipo Empresa:</b> ${tipoEmpresa.value}</p>
        `,
        },
      };
      onClickEnviar(nuevo);
      v$.value.$reset();
      nombre.value = "";
      telefono.value = "";
      mail.value = "";
      rutEmpresa.value = "";
      tipoEmpresa.value = "";
    };

    return {
      t,
      v$,
      nombre,
      telefono,
      mail,
      rutEmpresa,
      tipoEmpresa,
      tiposEmpresa,
      erroresNombre,
      erroresTelefono,
      erroresEmail,
      erroresRutEmpresa,
      erroresTipoEmpresa,
      enviar,
    };
  },
});
</script>

<style scoped>
@media screen and (min-width: 1264px) {
  .mt-lg-35 {
    margin-top: 140px !important;
  }

  .font-size-lg-60 {
    font-size: 60px !important;
  }

  .pl-lg-22 {
    padding-left: 5.5rem !important;
  }
}

.font-size-41 {
  font-size: 41px;
}

.input-contacto {
  border-radius: 18px !important;
}

.input-contacto >>> fieldset {
  border: 1px solid #2d3d43 !important;
}

.input-contacto >>> input::placeholder {
  color: var(--v-dialogDark-base) !important;
  opacity: 1 !important;
  font-size: 16px;
}

.input-contacto >>> input::-ms-input-placeholder {
  color: var(--v-dialogDark-base) !important;
  font-size: 16px;
}

.input-contacto >>> .v-input__append-inner {
  margin: 0 !important;
  height: 100% !important;
}

.border-radius-23 {
  border-radius: 23px !important;
}

.line-height-1 {
  line-height: 1.1 !important;
}
</style>
