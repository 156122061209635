<template>
  <div
    @click="onClick"
    class="boton-home__container"
  >
    <SvgIcon
      width="2.5rem"
      height="2.5rem"
      color="primary"
      :src="require(`@/assets/svg/sistema/modulos/${ruta.icon}.svg`)"
    />
    <div class="boton-home__text">
      {{ ruta.text }}
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import {defineComponent} from "vue";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "BotonHome",
  components: {SvgIcon},
  props: {
    ruta: {
      required: true,
      type: Object as () => Ruta,
    },
  },
  emits: [
    "click",
  ],
  setup(_props, ctx) {
    // Emits

    const onClick = () => {
      ctx.emit("click");
    };

    return {
      // Emits
      onClick,
    };
  },
});
</script>

<style scoped>
.boton-home__container {
  display: grid;
  cursor: pointer;
  padding: 0.75rem;
  row-gap: 0.75rem;
  align-content: center;
  border-radius: 0.5rem;
  justify-items: center;
  background-color: #FFFFFF;
  transition: background-color 200ms ease;
  box-shadow: 8px 15px 19px rgba(40, 62, 68, 0.06);
}

.boton-home__container:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.boton-home__container:active {
  background-color: rgba(0, 0, 0, 0.06);
}

.boton-home__text {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: var(--v-dialogDark-base);
  font-family: 'Metropolis', sans-serif;
}
</style>
