<template>
  <v-card color="dialog" class="rounded-input elevation-dark">
    <v-card-text>
      <v-row no-gutters class="pt-4" align="center">
        <v-col
          cols="8"
          offset="2"
          class="text-h6 metropolis white--text text-center text-capitalize font-weight-bold"
        >
          {{ titulo }}
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn
            x-small
            width="44px"
            height="44px"
            class="gradient-error elevation-error rounded-input"
            @click="onClickCerrar"
          >
            <v-icon color="white"> mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3">
        <v-col>
          <GmapMap
            :center="center"
            :zoom="17"
            :options="{
              clickableIcons: false,
              draggableCursor: 'default',
              styles: require('@/assets/json/gmap/MapStyles.json'),
            }"
            map-type-id="terrain"
            class="google-map"
            @click="mapClickHandler"
          >
            <GmapPolyline
              :path.sync="path"
              :options="{
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }"
              @click="polylineClickHandler"
            ></GmapPolyline>
            <GmapMarker v-if="ultima" :position="ultima"></GmapMarker>
          </GmapMap>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-3" align="center" justify="space-between">
        <v-col>
          <v-row no-gutters align="center">
            <v-col
              cols="3"
              class="white--text font-weight-bold metropolis text-body-1"
            >
              {{ $t("barredores.tab.rutas.formulario.nombre.label") }}
            </v-col>
            <v-col cols="9" class="pl-2">
              <v-text-field
                hide-details
                dense
                outlined
                :placeholder="
                  t('barredores.tab.rutas.formulario.nombre.placeholder')
                "
                class="rounded-input border-input"
                dark
                v-model="nombre"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-2 pt-md-0">
          <v-row no-gutters align="center" justify="center">
            <v-col
              cols="auto"
              class="white--text font-weight-bold metropolis text-body-1 pr-2"
            >
              {{ $t("barredores.tab.rutas.formulario.color.label") }}
            </v-col>
            <v-col cols="auto" class="pl-2">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    flat
                    v-on="on"
                    v-bind="attrs"
                    class="rounded-input border-grey"
                    :color="color"
                    width="40px"
                    height="40px"
                  ></v-card>
                </template>
                <v-color-picker
                  show-swatches
                  hide-sliders
                  hide-mode-switch
                  hide-inputs
                  hide-canvas
                  v-model="color"
                ></v-color-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-2 pt-md-0">
          <v-row no-gutters justify="end" align="center">
            <v-col
              cols="3"
              class="white--text metropolis font-weight-bold text-body-1"
            >
              {{ $t("barredores.tab.rutas.formulario.posicion.label") }}
            </v-col>
            <v-col class="px-2">
              <v-text-field
                hide-details
                dense
                outlined
                :placeholder="
                  t('barredores.tab.rutas.formulario.posicion.placelholder')
                "
                class="metropolis rounded-input border-input"
                :value="ultima ? `${ultima.lat}, ${ultima.lng}` : ''"
                readonly
                dark
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn
                x-small
                width="40px"
                height="40px"
                class="rounded-input border-grey gradient-error"
                @click="eliminarUltimo"
                depressed
              >
                <v-icon color="white"> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center mt-6">
        <v-col cols="4">
          <v-btn
            @click="guardar"
            block
            large
            class="text-initial white--text metropolis text-body-1 font-weight-bold gradient-primary elevation-dark rounded-input"
          >
            {{ textoBoton }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref, reactive } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
//tipos
import { RutaBarredor } from "@/typings/store/plugins/easyFirestore/rutasBarredor";
import {
  PingRastreador,
  Posicion,
} from "@/typings/store/plugins/easyFirestore/pingsRastreador";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

declare interface GoogleMapClickEvent {
  latLng: {
    lat: () => number;
    lng: () => number;
  };
}

export default defineComponent({
  props: {
    rutaBarredor: {
      default: null,
      type: Object as () => RutaBarredor | null,
    },
    pingsRastreador: {
      default: () => [],
      type: Array as () => PingRastreador[],
    },
  },
  created(): void {
    if (this.rutaBarredor) {
      this.nombre = this.rutaBarredor.nombre;
      this.path = this.rutaBarredor.posiciones.slice();
      this.id = this.rutaBarredor.id ?? "";
      this.color = this.rutaBarredor.color ?? "#FF0000";
      this.center = this.path[this.path.length - 1];
    } else {
      const values = this.pingsRastreador.slice();
      values.sort(
        (a, b) => b.fecha.toDate().getTime() - a.fecha.toDate().getTime()
      );
      const primera = values[0];
      if (primera) this.center = primera.posicion;
    }
  },
  emits: ["click:cerrar", "click:guardar"],
  setup(props, ctx) {
    const { t } = useI18n();

    const center = ref({
      lat: -35.4320809,
      lng: -71.6546965,
    });
    const path = ref<Array<Posicion>>([]);
    const id = ref("");
    const nombre = ref("");
    const color = ref("#FF0000");

    const titulo = computed((): string => {
      return props.rutaBarredor
        ? (t("barredores.tab.rutas.formulario.title[0]") as string)
        : (t("barredores.tab.rutas.formulario.title[1]") as string);
    });

    const textoBoton = computed((): string => {
      const index = props.rutaBarredor ? 0 : 1;
      return t(`barredores.tab.rutas.formulario.boton.text.${index}`) as string;
    });

    const ultima = computed((): Posicion | null => {
      return path.value.length > 0 ? path.value[path.value.length - 1] : null;
    });

    const state = reactive({
      nombre,
    });

    const rules = {
      nombre: { required },
    };

    const v$ = useVuelidate(rules, state);

    const nombreErrors = computed((): Array<string> => {
      const errors: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errors;
      if (v$.value.nombre.required.$invalid)
        errors.push(
          t("barredores.tab.rutas.formulario.nombre.error-message") as string
        );
      return errors;
    });

    const onClickCerrar = (): void => {
      ctx.emit("click:cerrar");
    };

    const onClickGuardar = (data: RutaBarredor): void => {
      ctx.emit("click:guardar", data);
    };

    const mapClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const polylineClickHandler = ({ latLng }: GoogleMapClickEvent): void => {
      const temp = path.value.slice();
      temp.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      path.value = temp;
    };

    const eliminarUltimo = (): void => {
      const temp = path.value.slice();
      temp.pop();
      path.value = temp;
    };

    const guardar = (): void => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      const rutaBarredor: RutaBarredor = {
        nombre: nombre.value,
        posiciones: path.value,
        color: color.value,
      };
      if (id.value) rutaBarredor.id = id.value;
      onClickGuardar(rutaBarredor);
    };

    return {
      t,
      v$,
      center,
      path,
      id,
      nombre,
      color,
      titulo,
      textoBoton,
      ultima,
      nombreErrors,
      onClickCerrar,
      onClickGuardar,
      mapClickHandler,
      polylineClickHandler,
      eliminarUltimo,
      guardar,
    };
  },
});
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 50vh;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 960px) {
  .google-map {
    height: 35vh;
  }
}
</style>
