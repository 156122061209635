<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="elevation-black rounded-input"
          width="100%"
          height="100%"
          color="rgba(207, 220, 226, 0.68)"
        >
          <v-img v-if="!foto" :src="img" height="100%" contain>
            <div class="fill-height d-flex flex-column justify-space-between">
              <v-row
                no-gutters
                justify="space-between"
                class="flex-grow-0 px-3 pt-3"
              >
                <v-col cols="auto">
                  <v-card
                    class="elevation-primary gradient-primary rounded-input px-2"
                    height="32px"
                  >
                    <v-row no-gutters class="fill-height" align="center">
                      <v-col
                        cols="auto"
                        class="white--text font-weight-bold metropolis text-center"
                      >
                        {{
                          $t("bascula.boton-capturar.cantidad", {
                            n: cantidadFotos,
                          })
                        }}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        :attrs="attrs"
                        x-small
                        width="32px"
                        height="32px"
                        class="gradient-secondary rounded-input elevation-secondary"
                      >
                        <svg-icon
                          name="fas-cctv"
                          color="white"
                          width="24px"
                          height="24px"
                        ></svg-icon>
                      </v-btn>
                    </template>
                    <span class="metropolis">
                      {{
                        $t("bascula.boton-capturar.boton-cambiar-camara.tooltip")
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center" align="end" v-if="!img">
                <v-col cols="auto">
                  <svg-icon
                    name="ags-image"
                    color="#F8F9FA"
                    width="240px"
                    height="240px"
                  ></svg-icon>
                </v-col>
              </v-row>
              <v-row no-gutters class="flex-grow-0 pa-3" justify="end">
                <v-col cols="auto">
                  <v-btn
                    :class="`gradient-error rounded-input elevation-black ${
                      disabledBtnFoto ? 'button-disabled button-inactive' : ''
                    }`"
                    @click="onClickCrearFoto"
                  >
                    <v-row no-gutters>
                      <v-col cols="auto" class="pr-2">
                        <svg-icon name="ags-camera" color="white"></svg-icon>
                      </v-col>
                      <v-col
                        class="pl-2 white--text metropolis font-weight-bold text-body-1 text-initial"
                      >
                        {{ $t("bascula.boton-capturar.boton-tomar-foto.text") }}
                      </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-img>
          <v-img v-else :src="foto" height="100%" width="100%" contain></v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//decoradores
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class BotonCapturar extends Vue {
  @Prop() readonly img!: string;
  @Prop() readonly disabledBtnFoto?: boolean;
  @Prop() readonly cantidadFotos!: number;

  foto = "";
  timeoutId: number | null = null;

  @Emit("click:crear-foto")
  onClickCrearFoto(): string {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.foto = this.img;
    this.timeoutId = window.setTimeout(this.limpiarFoto, 2500);
    return this.foto;
  }

  @Emit("click:cambiar-camara")
  onClickCambiarCamara(): void {
    return;
  }

  limpiarFoto(): void {
    this.foto = "";
  }
}
</script>

<style scoped>
.card-image {
  opacity: 0.68 !important;
}
</style>
