<template>
  <v-card flat color="transparent">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="end" align="center">
        <v-col cols="auto">
          <icon-button
            @click="onClickInfo"
            :icon="
              isSelected ? 'fas-circle-xmark' : 'ionic-md-information-circle'
            "
            :color="isSelected ? 'error' : 'primary'"
          >
            {{
              $t(
                `desechos.tabla.boton-informacion.${
                  isSelected ? "cerrar" : "tooltip"
                }`
              )
            }}
          </icon-button>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
// decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
// tipos
import { Desecho } from "@/typings/store/plugins/easyFirestore/desechos";
import { ItemTablaDesechos } from "@/typings/components/desechos/tabla";
// componentes
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
  },
})
export default class OpcionesTabla extends Vue {
  @Prop() readonly item!: ItemTablaDesechos;
  @Prop() readonly seleccionado!: Desecho | null;

  get isSelected(): boolean {
    return this.seleccionado !== null && this.seleccionado.id === this.item.id;
  }

  @Emit("click:informacion")
  onClickInfo(): ItemTablaDesechos {
    return this.item;
  }
}
</script>
