<template>
  <div class="d-flex flex-column fill-height">
    <v-row
      no-gutters
      align="end"
      justify="space-between"
      class="pt-10 mr-12 flex-grow-0"
    >
      <v-col cols="8">
        <FiltrosUsuario
          v-model="buscar"
          @cambio-estado="cambioFiltroEstado"
        ></FiltrosUsuario>
      </v-col>
      <v-col cols="auto" v-if="mostrarBotonAgregar">
        <add-button
          icon="usuarios"
          :disabled="!botonAgregar"
          @click="clickAgregar"
        >
          {{ $t("users.add-button.text") }}
        </add-button>
      </v-col>
    </v-row>
    <v-row no-gutters class="fill-height pt-7 pr-12 mb-9">
      <v-col cols="8">
        <TablaUsuarios
          :usuarios="items"
          :seleccionado="seleccionado"
          :buscar="buscar"
          :permisos="getPermisosUsuario"
          @click-switch="clickSwitchEstado"
          @click-info="clickInfo"
        ></TablaUsuarios>
      </v-col>
      <v-col cols="4" class="pl-7">
        <div class="d-flex flex-column fill-height">
          <v-row no-gutters>
            <v-col cols="12">
              <InfoUsuario
                v-if="estado === 1"
                :usuario="seleccionado"
              ></InfoUsuario>
              <FormularioUsuario
                v-else-if="estado === 2"
                ref="formulario"
                :seleccionado="seleccionado"
                :arrayClientes="arrayClientes"
                :arrayServicios="arrayServicios"
                :tipoUsuarioPermisos="tipoUsuarioPermisos"
                v-model="doc"
                @cambio-foto="cambioFoto"
              ></FormularioUsuario>
              <PermisosUsuario
                v-else-if="estado === 3"
                ref="permisos"
                :seleccionado="seleccionado"
                :tipoUsuarioPermisos="tipoUsuarioPermisos"
                v-model="doc"
              ></PermisosUsuario>
            </v-col>
          </v-row>
          <v-row no-gutters class="flex-grow-0">
            <v-col cols="12">
              <Botones
                v-if="estado !== 0"
                :estado="estado"
                :guardando="guardando"
                :hideGuardar="!botonGuardar"
                :hideEditar="botonGuardar"
                :disabledGuardar="disabledGuardar"
                :disabledEliminar="disabledEliminar"
                :permisos="getPermisosUsuario"
                @click-editar="clickEditar"
                @click-guardar="clickGuardar"
                @click-eliminar="clickEliminar"
                @click-permisos="clickPermisos"
              ></Botones>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogoConfirmacion.model"
      max-width="565"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
    >
      <confirmation-dialog
        v-if="dialogoConfirmacion.model"
        :text="dialogoConfirmacion.texto"
        @click:yes="dialogoConfirmacion.si"
        @click:no="dialogoConfirmacion.no"
      ></confirmation-dialog>
    </v-dialog>
    <v-dialog
      v-model="dialogoExito.model"
      width="565px"
      overlay-color="overlay"
      overlay-opacity="0.8"
      content-class="rounded-input elevation-dark"
      persistent
    >
      <success-dialog
        v-if="dialogoExito.model"
        :icon="dialogoExito.icono"
        :text="dialogoExito.mensaje"
        @close="cerrarDialogoExito"
      ></success-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
// decoradores
import { Vue, Component } from "vue-property-decorator";
// vuex
import { mapActions, mapGetters } from "vuex";
// helpers
import { log } from "@/helpers/env";
import { idtify } from "@/helpers/string";
import { imageToWebp } from "@/helpers/image";
import routeGuard from "@/helpers/routeGuard";
// tipos
import { Cliente } from "@/typings/store/plugins/easyFirestore/clientes";
import { Servicio } from "@/typings/store/plugins/easyFirestore/servicios";
import { Empresa } from "@/typings/store/plugins/easyFirestore/empresas";
import {
  Archivos,
  BlobArchivo,
} from "@/typings/store/plugins/easyFirestore/archivos";
import {
  EstadoUsuario,
  Usuario,
  UsuarioPatch,
  Usuarios,
} from "@/typings/store/plugins/easyFirestore/usuarios";
import { PermisosTipoUsuario } from "@/typings/store/modules/permisos";
// componentes
import Botones from "@/components/usuarios/Botones.vue";
import InfoUsuario from "@/components/usuarios/Info.vue";
import SuccessDialog from "@/components/custom/SuccessDialog.vue";
import TablaUsuarios from "@/components/usuarios/Tabla.vue";
import FiltrosUsuario from "@/components/usuarios/Filtros.vue";
import PermisosUsuario from "@/components/usuarios/Permisos.vue";
import FormularioUsuario from "@/components/usuarios/FormularioUsuario.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import AddButton from "@/components/custom/AddButton.vue";

@Component({
  computed: mapGetters({
    getArchivos: "archivos/get",
    getUsuarios: "usuarios/get",
    getPermisos: "permisos/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayUsuarios: "usuarios/getArray",
    getArrayClientes: "clientes/getArray",
    getEmpresaUsuario: "usuario/getEmpresa",
    getArrayServicios: "servicios/getArray",
    getPermisosUsuario: "usuario/getPermisos",
    getTipoUsuarioPermisos: "permisos/getTipoUsuario",
  }),
  methods: mapActions({
    setUsuario: "usuarios/set",
    setArchivo: "archivos/set",
    deleteUsuario: "usuarios/delete",
    deleteArchivo: "archivos/delete",
    setBlobArchivo: "archivos/setBlob",
  }),
  beforeRouteEnter(to, from, next) {
    next(routeGuard);
  },
  components: {
    AddButton,
    SuccessDialog,
    ConfirmationDialog,
    Botones,
    InfoUsuario,
    TablaUsuarios,
    FiltrosUsuario,
    PermisosUsuario,
    FormularioUsuario,
  },
})
export default class VistaUsuarios extends Vue {
  created(): void {
    if (!routeGuard()) {
      return;
    }
  }

  buscar = "";
  guardando = false;
  foto: File | null = null;
  doc: UsuarioPatch | null = null;
  temporal: Usuario | null = null;
  seleccionado: Usuario | null = null;
  filtroEstado: "habilitado" | "deshabilitado" | null = null;
  estados = {
    nada: 0,
    info: 1,
    formulario: 2,
    permisos: 3,
  };
  estado = this.estados.nada;
  estadoAnterior = this.estados.nada;
  dialogoConfirmacion: {
    model: boolean;
    texto: string;
    si: (() => void) | null;
    no: (() => void) | null;
  } = {
    model: false,
    texto: "",
    si: null,
    no: null,
  };
  dialogoExito = {
    model: false,
    mensaje: "",
    icono: "",
  };

  get mostrarBotonAgregar(): boolean {
    return this.getPermisosUsuario.usuarios !== "ver";
  }

  get botonAgregar(): boolean {
    return (
      this.estado === this.estados.nada || this.estado === this.estados.info
    );
  }

  get botonGuardar(): boolean {
    return (
      this.estado === this.estados.formulario ||
      this.estado === this.estados.permisos
    );
  }

  get disabledGuardar(): boolean {
    return this.estadoAnterior === this.estados.formulario;
  }

  get disabledEliminar(): boolean {
    return (
      this.estado === this.estados.formulario ||
      this.estado === this.estados.permisos
    );
  }

  get tipoUsuarioPermisos(): PermisosTipoUsuario {
    return this.getTipoUsuarioPermisos;
  }

  get empresa(): Empresa {
    return this.getEmpresaUsuario;
  }

  get items(): Array<Usuario> {
    if (!this.filtroEstado) return this.getArrayUsuarios;
    return this.getArrayUsuarios.filter((item) => {
      const estado =
        item.estado === "deshabilitado" ? "deshabilitado" : "habilitado";
      return estado === this.filtroEstado;
    });
  }

  get arrayClientes(): Array<Cliente> {
    const clientes = this.getArrayClientes.filter(
      (cliente) => cliente.estado === "habilitado"
    );
    clientes.sort((a, b) => a.nombre.localeCompare(b.nombre));
    return clientes;
  }

  get arrayServicios(): Array<Servicio> {
    return this.getArrayServicios;
  }

  get archivos(): Archivos {
    return this.getArchivos;
  }

  get usuarios(): Usuarios {
    return this.getUsuarios;
  }

  cambioFiltroEstado(estado: "habilitado" | "deshabilitado" | null): void {
    this.filtroEstado = estado;
  }

  cambioFoto(foto: File | null): void {
    this.foto = foto;
  }

  clickSwitchEstado(usuario: Usuario): void {
    const estado = usuario.estado ?? "activo";
    const doc: UsuarioPatch = { id: usuario.id, estado: estado };
    const activo: EstadoUsuario =
      usuario.id === usuario.uid ? "activo" : "inactivo";
    doc.estado = estado === "deshabilitado" ? activo : "deshabilitado";
    this.setUsuario(doc);
  }

  clickInfo(usuario: Usuario): void {
    if (
      this.estado === this.estados.formulario ||
      this.estado === this.estados.permisos
    ) {
      this.temporal = usuario;
      if (this.seleccionado) {
        this.dialogoConfirmacion = {
          model: true,
          texto: this.$t("users.discard-message.text") as string,
          si: () => {
            this.clickSiDescartar();
          },
          no: () => {
            this.clickNoDescartar();
          },
        };
        return;
      }
    }
    if (this.seleccionado?.id === usuario.id) {
      this.seleccionado = null;
      this.estado = this.estados.nada;
    } else {
      this.seleccionado = usuario;
      this.estado = this.estados.info;
    }
    this.doc = null;
    this.foto = null;
  }

  cerrarDialogoConfirmacion(): void {
    this.dialogoConfirmacion = {
      model: false,
      texto: "",
      si: null,
      no: null,
    };
  }

  cerrarDialogoExito(): void {
    this.dialogoExito = {
      model: false,
      mensaje: "",
      icono: "",
    };
  }

  clickNoDescartar(): void {
    if (this.doc?.id) {
      this.temporal = null;
    }
    this.cerrarDialogoConfirmacion();
  }

  clickSiDescartar(): void {
    if (this.temporal) {
      this.seleccionado = this.temporal;
      this.doc = null;
      this.foto = null;
      this.estado = this.estados.info;
    }
    this.cerrarDialogoConfirmacion();
  }

  clickPermisos(): void {
    if (this.estado === this.estados.permisos) {
      this.estado = this.estadoAnterior;
      this.estadoAnterior = this.estados.nada;
      return;
    }
    if (this.seleccionado?.id) {
      if (!this.doc) {
        this.doc = {
          id: this.seleccionado.id,
        };
      }
      this.estadoAnterior = this.estado;
      this.estado = this.estados.permisos;
    }
  }

  clickEliminar(): void {
    if (this.seleccionado) {
      this.dialogoConfirmacion = {
        model: true,
        texto: this.$t("users.delete-message.text") as string,
        si: () => {
          this.clickSiEliminar();
        },
        no: () => {
          this.clickNoEliminar();
        },
      };
    }
  }

  clickNoEliminar(): void {
    this.cerrarDialogoConfirmacion();
  }

  async clickSiEliminar(): Promise<void> {
    this.cerrarDialogoConfirmacion();
    if (this.seleccionado?.id) {
      this.estado = this.estados.nada;
      const id = this.seleccionado.id;
      this.seleccionado = null;
      this.guardando = true;
      await this.deleteUsuario(id);
      this.guardando = false;
    }
  }

  clickEditar(): void {
    if (!this.seleccionado) {
      return;
    }
    this.doc = {
      id: this.seleccionado.id,
    };
    this.foto = null;
    this.estado = this.estados.formulario;
  }

  clickAgregar(): void {
    this.seleccionado = null;
    this.doc = {
      id: "",
    };
    this.foto = null;
    this.estado = this.estados.formulario;
  }

  async clickGuardar(): Promise<void> {
    if (this.estado === this.estados.formulario) {
      await this.guardarFormulario();
    }
    if (this.estado === this.estados.permisos) {
      await this.guardarPermisos();
    }
  }

  async guardarPermisos(): Promise<void> {
    if (!this.doc?.id) {
      return;
    }
    this.guardando = true;
    const idUsuario = await this.setUsuario(this.doc);
    this.guardando = false;
    this.doc = null;
    this.foto = null;
    if (idUsuario) {
      this.seleccionado = this.usuarios[idUsuario];
    }
    this.estado = this.estados.info;
    this.dialogoExito = {
      model: true,
      mensaje: "¡Cambios guardados con éxito!",
      icono: "ag-save",
    };
  }

  async guardarFormulario(): Promise<void> {
    const formulario = this.$refs.formulario as Vue & {
      v$: {
        $touch: () => void;
        $invalid: boolean;
      };
    };
    if (!formulario) {
      if (log) {
        console.error("no se encontro el formulario");
      }
      return;
    }
    formulario.v$.$touch();
    if (formulario.v$.$invalid) {
      if (log) {
        console.error("el formulario no es valido");
      }
      return;
    }
    if (!this.doc) {
      return;
    }
    this.guardando = true;
    if (this.doc.id) {
      if (this.foto) {
        if (this.seleccionado?.foto?.id) {
          await this.deleteArchivo(this.seleccionado.foto.id);
        }
        const imagenRaw = this.foto;
        const fecha = new Date();
        const extencion = imagenRaw.type.split("/")[1];
        let nombre = `${fecha.valueOf()}.${extencion}`;
        let imagen = await imageToWebp(imagenRaw);
        if (imagen) {
          nombre = `${fecha.valueOf()}.webp`;
        } else {
          imagen = imagenRaw;
        }
        const fechaCarpeta = new Date(fecha);
        fechaCarpeta.setHours(0, 0, 0, 0);
        const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
        const ref = `usuarios/${nombreCarpeta}/${nombre}`;
        const blobArchivo: BlobArchivo = {
          ref: ref,
          nombre: nombre,
          file: imagen,
        };
        const id = await this.setBlobArchivo(blobArchivo);
        const archivo = this.archivos[id];
        if (archivo) {
          this.doc.foto = archivo;
        }
      }
      const idUsuario = await this.setUsuario(this.doc);
      this.guardando = false;
      this.estado = this.estados.info;
      this.doc = null;
      this.foto = null;
      if (idUsuario) {
        this.seleccionado = this.usuarios[idUsuario];
      }
      this.dialogoExito = {
        model: true,
        mensaje: this.$t("users.success-edit-message.text") as string,
        icono: "ag-save",
      };
      return;
    }
    if (!this.doc?.correo) {
      return;
    }
    const id = idtify(this.doc.correo);
    const doc: Usuario = {
      id: id,
      uid: "",
      nombres: this.doc.nombres ?? "",
      apellidos: this.doc.apellidos ?? "",
      rut: this.doc.rut ?? "",
      cargo: this.doc.cargo ?? "",
      correo: this.doc.correo,
      telefono: this.doc.telefono ?? "",
      cliente: this.doc.cliente ?? null,
      servicios: this.doc.servicios ?? [],
      permisos: this.doc.permisos ?? {},
      empresa: this.empresa.id ?? "11111111-1",
      foto: null,
      tipo: this.doc.tipo ?? "cliente",
      estado: this.doc.estado ?? "inactivo",
    };
    if (this.foto) {
      const imagenRaw = this.foto;
      const fecha = new Date();
      const extencion = imagenRaw.type.split("/")[1];
      let nombre = `${fecha.valueOf()}.${extencion}`;
      let imagen = await imageToWebp(imagenRaw);
      if (imagen) {
        nombre = `${fecha.valueOf()}.webp`;
      } else {
        imagen = imagenRaw;
      }
      const fechaCarpeta = new Date(fecha);
      fechaCarpeta.setHours(0, 0, 0, 0);
      const nombreCarpeta = `${fechaCarpeta.valueOf()}`;
      const ref = `usuarios/${nombreCarpeta}/${nombre}`;
      const blobArchivo: BlobArchivo = {
        ref: ref,
        nombre: nombre,
        file: imagen,
      };
      const id = await this.setBlobArchivo(blobArchivo);
      const archivo = this.archivos[id];
      if (archivo) {
        doc.foto = archivo;
      }
    }
    const idUsuario = await this.setUsuario(doc);
    this.guardando = false;
    this.estado = this.estados.info;
    this.doc = null;
    this.foto = null;
    if (idUsuario) {
      this.seleccionado = this.usuarios[idUsuario];
    }
    this.dialogoExito = {
      model: true,
      mensaje: this.$t("users.success-create-message.text") as string,
      icono: "ag-teamwork",
    };
  }
}
</script>
