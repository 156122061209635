<template>
  <div class="d-flex flex-column fill-height">
    <v-row no-gutters class="flex-grow-0">
      <v-col
        class="text-center text-h6 metropolis font-weight-bold primary--text"
      >
        {{ titulo }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-text-field
              :label="String($t('planes.formulario.text-fields.0.label'))"
              :placeholder="String($t('planes.formulario.text-fields.0.placeholder'))"
              v-model="nombre"
              required
              :error-messages="erroresNombre"
            ></form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-select
              :label="String($t('planes.formulario.text-fields.1.label'))"
              :placeholder="String($t('planes.formulario.text-fields.1.placeholder'))"
              v-model="tipoPlan"
              required
              :items="tiposPlan"
              :error-messages="erroresTipoPlan"
            ></form-select>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-text-field
              :label="String($t('planes.formulario.text-fields.2.label'))"
              :placeholder="String($t('planes.formulario.text-fields.2.placeholder'))"
              v-model.number="precio"
              required
              :error-messages="erroresPrecio"
            ></form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-select
              :label="String($t('planes.formulario.text-fields.3.label'))"
              :placeholder="String($t('planes.formulario.text-fields.3.placeholder'))"
              v-model="tipoPago"
              required
              :items="tiposPago"
              :error-messages="erroresTipoPago"
            ></form-select>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="pt-2">
          <v-col>
            <form-text-field
              :label="String($t('planes.formulario.text-fields.4.label'))"
              :placeholder="String($t('planes.formulario.text-fields.4.placeholder'))"
              v-model="descuento"
            ></form-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters align="start" class="pt-2">
          <v-col>
            <form-text-area
              :label="String($t('planes.formulario.text-fields.5.label'))"
              :placeholder="String($t('planes.formulario.text-fields.5.placeholder'))"
              v-model="descripcion"
              required
              :error-messages="erroresDescripcion"
              no-resize
              auto-grow
            ></form-text-area>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-grow-0" justify="center">
      <v-col cols="auto">
        <action-button
          block
          color="primary"
          :icon="plan !== null ? 'ag-save' : 'ag-add'"
          @click="onClickGuardar"
        >
          {{ textoBoton }}
        </action-button>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
//composition
import { ref, computed, defineComponent } from "vue";
//i18n
import i18n from "@/plugins/i18n";
//vuelidate
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
//tipos
import {
  Plan,
  TipoPlan,
  TipoPagoPlan,
  EstadoPlan,
} from "@/typings/store/plugins/easyFirestore/planes";
//componentes
import FormTextField from "@/components/custom/FormTextField.vue";
import FormSelect from "@/components/custom/FormSelect.vue";
import FormTextArea from "@/components/custom/FormTextArea.vue";
import ActionButton from "@/components/custom/ActionButton.vue";

export default defineComponent({
  name: "FormularioPlanes",
  components: {
    ActionButton,
    FormTextArea,
    FormSelect,
    FormTextField,
  },
  emits: ["click:guardar"],
  props: {
    plan: {
      type: Object as () => Plan | null,
    },
  },
  setup(props, ctx) {
    const id = ref("");
    const nombre = ref("");
    const tipoPlan = ref<TipoPlan>("plan a");
    const precio = ref(0);
    const tipoPago = ref<TipoPagoPlan>("mensual");
    const descuento = ref("");
    const descripcion = ref("");
    const estado = ref<EstadoPlan>("habilitado");

    if (props.plan?.id) {
      id.value = props.plan.id;
      nombre.value = props.plan.nombre;
      tipoPlan.value = props.plan.tipo;
      precio.value = props.plan.precio;
      tipoPago.value = props.plan.tipoPago;
      descuento.value = props.plan.descuento;
      descripcion.value = props.plan.descripcion;
      estado.value = props.plan.estado;
    }

    const rules = computed(() => ({
      nombre: { required },
      tipoPlan: { required },
      precio: { required },
      tipoPago: { required },
      descripcion: { required },
    }));

    const v$ = useVuelidate(rules, {
      nombre,
      tipoPlan,
      precio,
      tipoPago,
      descripcion,
    });

    const tiposPlan = computed(() => {
      return [
        {
          text: i18n.t("planes.formulario.tipos.0") as string,
          value: "plan a",
        },
        {
          text: i18n.t("planes.formulario.tipos.1") as string,
          value: "plan b",
        },
        {
          text: i18n.t("planes.formulario.tipos.2") as string,
          value: "plan c",
        },
        {
          text: i18n.t("planes.formulario.tipos.3") as string,
          value: "plan d",
        },
      ];
    });

    const tiposPago = computed(() => {
      return [
        {
          text: i18n.t("planes.formulario.tipos-pago.0") as string,
          value: "mensual",
        },
        {
          text: i18n.t("planes.formulario.tipos-pago.1") as string,
          value: "anual",
        },
      ];
    });

    const titulo = computed(() => {
      return i18n.t(
        `planes.formulario.titulo.${props.plan ? "editar" : "crear"}`
      ) as string;
    });

    const textoBoton = computed(() => {
      return i18n.t(
        `planes.formulario.boton.${props.plan ? "editar" : "crear"}`
      ) as string;
    });

    const erroresNombre = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.nombre.$dirty) return errores;
      if (v$.value.nombre.required.$invalid) {
        const error = i18n.t(
          "planes.formulario.text-fields.0.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTipoPlan = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.tipoPlan.$dirty) return errores;
      if (v$.value.tipoPlan.required.$invalid) {
        const error = i18n.t(
          "planes.formulario.text-fields.1.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresPrecio = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.precio.$dirty) return errores;
      if (v$.value.precio.required.$invalid) {
        const error = i18n.t(
          "planes.formulario.text-fields.2.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresTipoPago = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.tipoPago.$dirty) return errores;
      if (v$.value.tipoPago.required.$invalid) {
        const error = i18n.t(
          "planes.formulario.text-fields.3.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresDescripcion = computed(() => {
      const errores: Array<string> = [];
      if (!v$.value.descripcion.$dirty) return errores;
      if (v$.value.descripcion.required.$invalid) {
        const error = i18n.t(
          "planes.formulario.text-fields.5.errores.0"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const onClickGuardar = () => {
      v$.value.$touch();
      if (v$.value.$invalid) return;
      const plan: Plan = {
        nombre: nombre.value,
        tipo: tipoPlan.value,
        precio: precio.value,
        tipoPago: tipoPago.value,
        descuento: descuento.value,
        descripcion: descripcion.value,
        estado: estado.value,
      };
      if (id.value) plan.id = id.value;
      ctx.emit("click:guardar", plan);
    };

    return {
      nombre,
      tipoPlan,
      tiposPlan,
      precio,
      tipoPago,
      tiposPago,
      descuento,
      descripcion,
      titulo,
      textoBoton,
      erroresNombre,
      erroresTipoPlan,
      erroresPrecio,
      erroresTipoPago,
      erroresDescripcion,
      onClickGuardar,
    };
  },
});
</script>
