<template>
  <v-container class="pa-0 ma-0">
    <v-row justify="start" no-gutters align="end">
      <v-col cols="4" class="pr-2">
        <v-text-field
          hide-details
          dense
          solo
          clearable
          color="text"
          :placeholder="$t('users.filters.search.placeholder')"
          class="metropolis rounded-input elevation-input"
          v-model="buscar"
        >
          <template v-slot:append>
            <svg-icon name="ag-search" color="text"></svg-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="4" class="pl-2">
        <v-container class="pa-0 ma-0">
          <v-row no-gutters>
            <v-col cols="12" class="metropolis text--text text-body-1 px-5">
              {{ $t("users.filters.estado.label") }}
            </v-col>
            <v-col cols="12">
              <v-select
                dense
                solo
                clearable
                hide-details
                color="text"
                :placeholder="$t('users.filters.estado.placeholder')"
                class="metropolis rounded-input elevation-input"
                :menu-props="{
                  contentClass: 'rounded-input elevation-black',
                  transition: 'slide-y-transition',
                }"
                :items="estados"
                v-model="estado"
                @change="onCambioEstado"
              >
                <template v-slot:item="{ item }">
                  <span
                    class="metropolis darker--text text-body-1 text-capitalize"
                  >
                    {{ item.value }}
                  </span>
                </template>
                <template v-slot:selection="{ item }">
                  <span
                    class="metropolis darker--text text-body-1 text-capitalize"
                  >
                    {{ item.value }}
                  </span>
                </template>
                <template v-slot:append>
                  <svg-icon
                    name="ionic-md-arrow-dropdown"
                    color="text"
                    width="12px"
                    height="12px"
                  ></svg-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
// decoradores
import { Component, Emit, VModel, Vue } from "vue-property-decorator";
// componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// tipos
import { EstadoUsuario } from "@/typings/store/plugins/easyFirestore/usuarios";

@Component({
  components: { SvgIcon },
})
export default class FiltrosUsuario extends Vue {
  @VModel() buscar!: string;

  estado: EstadoUsuario | null | undefined = null;

  get estados(): Array<{
    text: string;
    value: "habilitado" | "deshabilitado";
  }> {
    return [
      {
        text: this.$t("users.filters.estado.values.0") as string,
        value: "habilitado",
      },
      {
        text: this.$t("users.filters.estado.values.1") as string,
        value: "deshabilitado",
      },
    ];
  }

  @Emit("cambio-estado")
  onCambioEstado(): EstadoUsuario | null {
    return this.estado ?? null;
  }
}
</script>
