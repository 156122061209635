<template>
  <v-container class="pa-0">
    <v-row no-gutters class="row-buttons" align="end">
      <v-col cols="4">
        <v-btn
          block
          :height="tab === 0 ? '44px' : '38px'"
          depressed
          color="yellow"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 0 ? 'button-inactive' : ''
          }`"
          @click="tab = 0"
        >
          {{ $t("pesajes.formulario.tab.entrada.text") }}
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          block
          :height="tab === 1 ? '44px' : '38px'"
          depressed
          color="purple"
          :class="`rounded-input rounded-b-0 metropolis white--text text-initial text-h6 font-weight-bold button ${
            tab !== 1 ? 'button-inactive' : ''
          }`"
          @click="tab = 1"
        >
          {{ $t("pesajes.formulario.tab.salida.text") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card
          color="#FCFCFC"
          class="elevation-black rounded-input rounded-tl-0"
        >
          <v-card-text class="pa-0">
            <v-tabs-items v-model="tab" class="rounded-input rounded-tl-0">
              <v-tab-item class="rounded-input">
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="pr-2">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto" class="metropolis font-weight-bold">
                        {{ $t("pesajes.formulario.tab.entrada.fecha.label") }}
                      </v-col>
                      <v-col cols="auto" class="pa-0 error--text metropolis">
                        *
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5" class="pr-2">
                    <v-menu
                      v-model="menuEntrada"
                      :close-on-content-click="false"
                      transition="slide-y-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      content-class="rounded-input elevation-black"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-card
                          v-on="on"
                          v-bind="attrs"
                          flat
                          class="rounded-input border-grey card-menu"
                          :ripple="false"
                          height="38px"
                        >
                          <v-card-text
                            class="pa-1 fill-height metropolis text-body-1 darker--text"
                          >
                            <v-row
                              no-gutters
                              class="fill-height"
                              align="center"
                            >
                              <v-col class="text-truncate" cols="11">
                                {{ fechaEntrada }}
                              </v-col>
                              <v-col cols="1" class="text-right">
                                <svg-icon
                                  :class="`select-icon ${
                                    value ? 'selected' : ''
                                  }`"
                                  name="ionic-md-arrow-dropdown"
                                  color="text"
                                  width="12px"
                                  height="12px"
                                ></svg-icon>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </template>
                      <v-date-picker
                        locale="es-es"
                        v-model="fechaEntrada"
                        class="calendar metropolis"
                        no-title
                        @input="menuEntrada = false"
                        :allowed-dates="$allowedDatesBeforeToday"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" class="pl-2">
                    <v-text-field
                      dense
                      outlined
                      type="time"
                      :placeholder="
                        t('pesajes.formulario.tab.entrada.hora.placeholder')
                      "
                      hide-details="auto"
                      class="border-input metropolis rounded-input"
                      :error-messages="erroresHoraEntrada"
                      v-model="horaEntrada"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="pr-2">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto" class="metropolis font-weight-bold">
                        {{ $t("pesajes.formulario.tab.entrada.peso.label") }}
                      </v-col>
                      <v-col cols="auto" class="pa-0 error--text metropolis">
                        *
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      dense
                      outlined
                      :suffix="t('pesajes.formulario.tab.entrada.peso.suffix')"
                      :placeholder="
                        t('pesajes.formulario.tab.entrada.peso.placeholder')
                      "
                      hide-details="auto"
                      class="border-input metropolis rounded-input"
                      :error-messages="erroresPesoEntrada"
                      v-model.number="pesoEntrada"
                      v-mask="'#########'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="font-weight-bold">
                    {{ $t("pesajes.formulario.tab.entrada.observacion.label") }}
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      :placeholder="
                        t(
                          'pesajes.formulario.tab.entrada.observacion.placeholder'
                        )
                      "
                      class="border-input metropolis rounded-input"
                      v-model="observacionEntrada"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="pr-2">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto" class="metropolis font-weight-bold">
                        {{ $t("pesajes.formulario.tab.entrada.chofer.label") }}
                      </v-col>
                      <v-col cols="auto" class="pa-0 error--text metropolis">
                        *
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-combobox
                      dense
                      outlined
                      hide-details="auto"
                      :placeholder="
                        t('pesajes.formulario.tab.entrada.chofer.placeholder')
                      "
                      class="rounded-input border-input metropolis"
                      item-text="nombre"
                      :items="arrayChoferes"
                      :error-messages="erroresChoferEntrada"
                      v-model="choferEntrada"
                      :menu-props="{
                        contentClass: 'rounded-input elevation-black',
                        transition: 'slide-y-transition',
                      }"
                    >
                      <template v-slot:item="{ item }">
                        <span class="metropolis black--text text-capitalize">
                          {{ item.nombre }}
                        </span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-col
                          class="pa-0 metropolis text-capitalize text-truncate black--text"
                          cols="8"
                        >
                          {{ item.nombre }}
                        </v-col>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="rounded-input">
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="pr-2">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto" class="metropolis font-weight-bold">
                        {{ $t("pesajes.formulario.tab.salida.fecha.label") }}
                      </v-col>
                      <v-col cols="auto" class="pa-0 error--text metropolis">
                        *
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="pr-2" cols="5">
                    <v-menu
                      v-model="menuSalida"
                      :close-on-content-click="false"
                      transition="slide-y-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      content-class="rounded-input elevation-black"
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-card
                          v-on="on"
                          v-bind="attrs"
                          flat
                          class="rounded-input border-grey card-menu"
                          :ripple="false"
                          height="38px"
                        >
                          <v-card-text
                            class="pa-1 fill-height metropolis text-body-1 darker--text"
                          >
                            <v-row
                              no-gutters
                              class="fill-height"
                              align="center"
                            >
                              <v-col class="text-truncate" cols="11">
                                {{ fechaSalida }}
                              </v-col>
                              <v-col cols="1" class="text-right">
                                <svg-icon
                                  :class="`select-icon ${
                                    value ? 'selected' : ''
                                  }`"
                                  name="ionic-md-arrow-dropdown"
                                  color="text"
                                  width="12px"
                                  height="12px"
                                ></svg-icon>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </template>
                      <v-date-picker
                        locale="es-es"
                        v-model="fechaSalida"
                        class="calendar metropolis"
                        no-title
                        @input="menuSalida = false"
                        :allowed-dates="$allowedDatesBeforeToday"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" class="pl-2">
                    <v-text-field
                      dense
                      outlined
                      type="time"
                      :placeholder="
                        t('pesajes.formulario.tab.salida.hora.placeholder')
                      "
                      hide-details="auto"
                      class="border-input metropolis rounded-input"
                      :error-messages="erroresHoraSalida"
                      v-model="horaSalida"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="pr-2">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto" class="metropolis font-weight-bold">
                        {{ $t("pesajes.formulario.tab.salida.peso.label") }}
                      </v-col>
                      <v-col cols="auto" class="pa-0 error--text metropolis">
                        *
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      outlined
                      suffix="kg"
                      :placeholder="
                        t('pesajes.formulario.tab.salida.peso.placeholder')
                      "
                      hide-details="auto"
                      class="border-input metropolis rounded-input"
                      :error-messages="erroresPesoSalida"
                      v-model.number="pesoSalida"
                      v-mask="'#########'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="font-weight-bold">
                    {{ $t("pesajes.formulario.tab.salida.observacion.label") }}
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      :placeholder="
                        t(
                          'pesajes.formulario.tab.salida.observacion.placeholder'
                        )
                      "
                      class="border-input metropolis rounded-input"
                      v-model="observacionSalida"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="metropolis black--text text-body-1 pt-2 px-6"
                  align="center"
                >
                  <v-col cols="4" class="pr-2">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto" class="metropolis font-weight-bold">
                        {{ $t("pesajes.formulario.tab.salida.chofer.label") }}
                      </v-col>
                      <v-col cols="auto" class="pa-0 error--text metropolis">
                        *
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8">
                    <v-combobox
                      dense
                      outlined
                      item-text="nombre"
                      hide-details="auto"
                      :placeholder="
                        t('pesajes.formulario.tab.salida.chofer.placeholder')
                      "
                      class="rounded-input border-input metropolis"
                      :items="arrayChoferes"
                      :error-messages="erroresChoferSalida"
                      v-model="choferSalida"
                      :menu-props="{
                        contentClass: 'rounded-input elevation-black',
                        transition: 'slide-y-transition',
                      }"
                    >
                      <template v-slot:item="{ item }">
                        <span class="metropolis black--text text-capitalize">
                          {{ item.nombre }}
                        </span>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-col
                          class="pa-0 metropolis text-capitalize text-truncate black--text"
                          cols="8"
                        >
                          {{ item.nombre }}
                        </v-col>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <v-row no-gutters class="py-2 px-6">
              <v-col cols="6" class="pr-4">
                <v-btn
                  depressed
                  block
                  large
                  :class="`text-initial elevation-secondary rounded-input gradient-secondary white--text ${
                    galeriaDisabled ? 'button-inactive button-disabled' : ''
                  }`"
                  @click="onClickEditarImagenes"
                >
                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="auto" class="pr-2">
                      <svg-icon name="ags-camera" color="white"></svg-icon>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="metropolis font-weight-bold text-body-1 white--text"
                    >
                      {{ $t("pesajes.formulario.boton-editar-imagenes.text") }}
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pl-4">
                <v-btn
                  depressed
                  color="primary"
                  block
                  large
                  :class="`text-initial elevation-primary rounded-input gradient-primary ${
                    cargando ? 'button-disabled' : ''
                  }`"
                  @click="clickGuardar"
                >
                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    v-if="!cargando"
                  >
                    <v-col cols="auto" class="pr-2">
                      <svg-icon name="ag-save" color="white"></svg-icon>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="metropolis font-weight-bold text-body-1"
                    >
                      {{ $t("pesajes.formulario.boton-guardar.text") }}
                    </v-col>
                  </v-row>
                  <svg-icon v-else name="three-dots" color="white"></svg-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
// vue
import { defineComponent, computed, ref } from "vue";
// composables
import { useI18n } from "@/composables/i18n";
// helpers
import { log } from "@/helpers/env";
//tipos
import {
  Pesaje,
  PesajeData,
} from "@/typings/store/plugins/easyFirestore/pesajes";
import { Chofer } from "@/typings/store/plugins/easyFirestore/choferes";
import { DataEntradaSalida } from "@/typings/components/pesajes/formulario";
//componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
// validaciones
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SvgIcon,
  },
  props: {
    pesaje: {
      default: null,
      type: Object as () => Pesaje | null,
    },
    cargando: {
      default: false,
      type: Boolean,
    },
    arrayChoferes: {
      default: () => [],
      type: Array as () => Chofer[],
    },
    editarGaleria: {
      default: false,
      type: Boolean,
    },
    mostrarGaleria: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["click:guardar", "click:editar-imagenes"],
  setup(props, ctx) {
    const { t } = useI18n();

    const tab = ref(0);
    const menuEntrada = ref(false);
    const menuSalida = ref(false);

    const pesajeData = ref<PesajeData>({});

    const fechaEntrada = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaEntrada || props.pesaje?.entrada?.fecha;
        return (fecha ? fecha.toDate() : new Date()).format("YYYY-MM-DD");
      },
      set: (value) => {
        const fecha = value.toDate();
        const hoy = new Date();
        fecha.setHours(
          hoy.getHours(),
          hoy.getMinutes(),
          hoy.getSeconds(),
          hoy.getMilliseconds()
        );
        const nuevo = Object.assign({}, pesajeData.value, {
          fechaEntrada: fecha.toISOString(),
        });
        pesajeData.value = nuevo;
      },
    });

    const horaEntrada = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaEntrada || props.pesaje?.entrada?.fecha;
        return (fecha ? fecha.toDate() : new Date()).format("HH:mm");
      },
      set: (value) => {
        const [hora, minutos] = value.split(":");
        if (hora && hora.length === 2 && minutos && minutos.length === 2) {
          const fechaRaw =
            pesajeData.value.fechaEntrada ??
            (props.pesaje?.entrada?.fecha || "");
          const fecha = fechaRaw?.toDate();
          fecha.setHours(parseInt(hora, 10));
          fecha.setMinutes(parseInt(minutos, 10));
          const nuevo = Object.assign({}, pesajeData.value, {
            fechaEntrada: fecha.toISOString(),
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const pesoEntrada = computed({
      get: () => {
        const peso =
          pesajeData.value.pesoEntrada ?? (props.pesaje?.entrada?.peso || 0);
        return peso;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          pesoEntrada: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const choferEntrada = computed({
      get: () => {
        const chofer =
          pesajeData.value.choferEntrada ||
          props.pesaje?.entrada?.chofer ||
          null;
        return chofer;
      },
      set: (value) => {
        let chofer: Chofer | null;
        if (typeof value === "string") {
          chofer = {
            nombre: (value as string).toLowerCase(),
            rut: "",
            cargo: "",
            fechaNacimiento: "",
            fechaContratacion: "",
            contacto: "",
            estado: "habilitado",
            foto: null,
          };
        } else {
          chofer = Object.assign({}, value);
        }
        const nuevo = Object.assign({}, pesajeData.value, {
          choferEntrada: chofer,
        });
        pesajeData.value = nuevo;
      },
    });

    const observacionEntrada = computed({
      get: () => {
        const observacion =
          pesajeData.value.observacionEntrada ||
          props.pesaje?.entrada?.observacion ||
          "";
        return observacion;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          observacionEntrada: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const fechaSalida = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaSalida || props.pesaje?.salida?.fecha;
        return fecha ? fecha.toDate().format("YYYY-MM-DD") : "";
      },
      set: (value) => {
        const fecha = value.toDate();
        const nuevo = Object.assign({}, pesajeData.value, {
          fechaSalida: fecha.toISOString(),
        });
        pesajeData.value = nuevo;
      },
    });

    const horaSalida = computed({
      get: () => {
        const fecha =
          pesajeData.value.fechaSalida || props.pesaje?.salida?.fecha;
        return fecha ? fecha.toDate().format("HH:mm") : "";
      },
      set: (value) => {
        const [hora, minutos] = value.split(":");
        if (hora && hora.length === 2 && minutos && minutos.length === 2) {
          const fechaRaw =
            pesajeData.value.fechaSalida ?? (props.pesaje?.salida?.fecha || "");
          const fecha = fechaRaw.toDate();
          fecha.setHours(parseInt(hora, 10));
          fecha.setMinutes(parseInt(minutos, 10));
          const nuevo = Object.assign({}, pesajeData.value, {
            fechaSalida: fecha.toISOString(),
          });
          pesajeData.value = nuevo;
        }
      },
    });

    const pesoSalida = computed({
      get: () => {
        const peso =
          pesajeData.value.pesoSalida ?? (props.pesaje?.salida?.peso || 0);
        return peso;
      },
      set: (value) => {
        const peso = value || 0;
        const nuevo = Object.assign({}, pesajeData.value, {
          pesoSalida: peso,
        });
        pesajeData.value = nuevo;
      },
    });

    const choferSalida = computed({
      get: () => {
        const chofer =
          pesajeData.value.choferSalida || props.pesaje?.salida?.chofer || null;
        return chofer;
      },
      set: (value) => {
        let chofer: Chofer | null;
        if (typeof value == "string") {
          chofer = {
            nombre: (value as string).toLowerCase(),
            rut: "",
            cargo: "",
            fechaNacimiento: "",
            fechaContratacion: "",
            contacto: "",
            estado: "habilitado",
            foto: null,
          };
        } else {
          chofer = Object.assign({}, value);
        }
        const nuevo = Object.assign({}, pesajeData.value, {
          choferSalida: chofer,
        });
        pesajeData.value = nuevo;
      },
    });

    const observacionSalida = computed({
      get: () => {
        const observacion =
          pesajeData.value.observacionSalida ||
          props.pesaje?.salida?.observacion ||
          "";
        return observacion;
      },
      set: (value) => {
        const nuevo = Object.assign({}, pesajeData.value, {
          observacionSalida: value,
        });
        pesajeData.value = nuevo;
      },
    });

    const galeriaDisabled = computed((): boolean => {
      return props.editarGaleria && props.mostrarGaleria;
    });

    const state = {
      fechaEntrada,
      horaEntrada,
      pesoEntrada,
      choferEntrada,
      fechaSalida,
      horaSalida,
      pesoSalida,
      choferSalida,
    };

    const rules = {
      fechaEntrada: { required },
      horaEntrada: { required },
      pesoEntrada: { required },
      choferEntrada: { required },
      fechaSalida: { required },
      horaSalida: { required },
      pesoSalida: { required },
      choferSalida: { required },
    };

    const v$ = useVuelidate(rules, state, { $scope: false });

    const erroresFechaEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaEntrada.$dirty) return errores;
      if (v$.value.fechaEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresHoraEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.horaEntrada.$dirty) return errores;
      if (v$.value.horaEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.hora.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresPesoEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.pesoEntrada.$dirty) return errores;
      if (v$.value.pesoEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.peso.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresChoferEntrada = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.choferEntrada.$dirty) return errores;
      if (v$.value.choferEntrada.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.entrada.chofer.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresFechaSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.fechaSalida.$dirty) return errores;
      if (v$.value.fechaSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.fecha.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresHoraSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.horaSalida.$dirty) return errores;
      if (v$.value.horaSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.hora.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresPesoSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.pesoSalida.$dirty) return errores;
      if (v$.value.pesoSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.peso.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const erroresChoferSalida = computed((): Array<string> => {
      const errores: Array<string> = [];
      if (!v$.value.choferSalida.$dirty) return errores;
      if (v$.value.choferSalida.required.$invalid) {
        const error = t(
          "pesajes.formulario.tab.salida.chofer.error-message"
        ) as string;
        errores.push(error);
      }
      return errores;
    });

    const entradaValida = computed((): boolean => {
      return (
        !v$.value.pesoEntrada.required &&
        !v$.value.choferEntrada.required &&
        !v$.value.fechaEntrada.required &&
        !v$.value.horaEntrada.required
      );
    });

    const salidaValida = computed((): boolean => {
      return (
        !v$.value.pesoSalida.required &&
        !v$.value.choferSalida.required &&
        !v$.value.fechaSalida.required &&
        !v$.value.horaSalida.required
      );
    });

    const onClickEditarImagenes = (): void => {
      ctx.emit("click:editar-imagenes");
    };

    const onClickGuardar = (data: DataEntradaSalida): void => {
      ctx.emit("click:guardar", data);
    };

    const clickGuardar = (): void => {
      v$.value.$touch();
      if (entradaValida.value && !salidaValida.value) {
        tab.value = 1;
      }
      if (log) {
        console.log("vuelidate entrada salida editar", v$.value);
      }
      onClickGuardar({
        pesajeData: pesajeData.value,
        formularioValido: !v$.value.$invalid,
      });
    };

    return {
      t,
      v$,
      tab,
      menuEntrada,
      menuSalida,
      fechaEntrada,
      horaEntrada,
      pesoEntrada,
      choferEntrada,
      observacionEntrada,
      fechaSalida,
      horaSalida,
      pesoSalida,
      choferSalida,
      observacionSalida,
      galeriaDisabled,
      erroresFechaEntrada,
      erroresHoraEntrada,
      erroresPesoEntrada,
      erroresChoferEntrada,
      erroresFechaSalida,
      erroresHoraSalida,
      erroresPesoSalida,
      erroresChoferSalida,
      entradaValida,
      salidaValida,
      onClickEditarImagenes,
      onClickGuardar,
      clickGuardar,
    };
  },
});
</script>

<style scoped>
.row-buttons {
  height: 44px;
}

.button {
  transition: all 150ms ease;
  opacity: 1;
}
</style>
