<template>
  <v-row no-gutters class="mr-12 pb-9 fill-height">
    <v-col cols="8">
      <TablaVehiculos
        :arrayVehiculos="arrayVehiculos"
        :search="search"
        :vehiculo="vehiculo"
        :permisos="permisos"
        @click:switch-estado="onClickSwitchEstado"
        @click:informacion="onClickInformacion"
      ></TablaVehiculos>
    </v-col>
    <v-col cols="4" class="pl-7">
      <FormularioVehiculo
        :vehiculo="vehiculo"
        :cargando="cargando"
        :usuario="usuario"
        v-if="mostrarFormulario && !mostrarDetalle"
        @click:guardar="onClickGuardar"
      ></FormularioVehiculo>
      <DetalleVehiculo
        :permisos="permisos"
        v-if="mostrarDetalle && !mostrarFormulario"
        :vehiculo="vehiculo"
        @click:editar="onClickEditar"
        @click:eliminar="onClickEliminar"
      ></DetalleVehiculo>
    </v-col>
  </v-row>
</template>

<script lang="ts">
//decoradores
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
//tipos
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import { Vehiculo } from "@/typings/store/plugins/easyFirestore/vehiculos";
import { Permisos } from "@/typings/store/modules/permisos";
import { DataFormularioVehiculo } from "@/typings/components/vehiculos/formulario";
//componentes
import TablaVehiculos from "@/components/transporte/vehiculos/Tabla.vue";
import DetalleVehiculo from "@/components/transporte/vehiculos/Detalle.vue";
import FormularioVehiculo from "@/components/transporte/vehiculos/FormularioVehiculo.vue";

@Component({
  components: {
    TablaVehiculos,
    DetalleVehiculo,
    FormularioVehiculo,
  },
})
export default class TabVehiculos extends Vue {
  @Prop() readonly arrayVehiculos!: Array<Vehiculo>;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarDetalle!: boolean;
  @Prop() readonly vehiculo!: Vehiculo | null;
  @Prop() readonly search!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly permisos!: Permisos;

  @Emit("click:guardar")
  onClickGuardar(data: DataFormularioVehiculo): DataFormularioVehiculo {
    return data;
  }

  @Emit("click:informacion")
  onClickInformacion(vehiculo: Vehiculo): Vehiculo {
    return vehiculo;
  }

  @Emit("click:switch-estado")
  onClickSwitchEstado(vehiculo: Vehiculo): Vehiculo {
    return vehiculo;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  @Emit("click:eliminar")
  onClickEliminar(): void {
    return;
  }
}
</script>
